import { Autocomplete, Backdrop, Box, Dialog, Fade, Modal, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {
  ArrowLeft,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { ChevronDown, StarFill } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, GetCurrencySymbol } from '../../controllers/FormatAmount';
import { clientData } from '../../controllers/GetClient';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';
import RazorpayPop from "../Popup/RazoropayPop";

const CreateOrder = () => {
  const navigate = useNavigate()
  const [productPopup, setProductPopup] = useState(false)
  const [customerPopup, setCustomerPopup] = useState(false)
  const [discountConfirmationPopup, setDiscountConfirmationPopup] = useState(false)
  const [discountCustomPopup, setDiscountCustomPopup] = useState(false)
  const [discountPopup, setDiscountPopup] = useState(false)
  const [isNotification, setIsNotification] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [allProduct, setAllProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [viewSelectedProduct, setViewSelectedProduct] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [customersearch, setCustomerSearch] = useState('');
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [allDiscount, setAllDiscount] = useState([]);
  const [defaultShippingMethod, setDefaultShippingMethod] = useState([]);
  const [shipCourierData, setShipCourierData] = useState([]);
  const [selectedShippingPartner, setSelectedShippingPartner] = useState();
  const [search, setSearch] = useState('')
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [PaymentId, setPaymentId] = useState();
  const [paymentMethodName, setPaymentMethodName] = useState('');
  const [selectAddress, setSelectAddress] = useState()
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [confirmationvalue, setConfirmationValue] = useState('');
  const [discountValue, setDiscountValue] = useState('percentage');
  const [discountCustomValue, setDiscountCustomValue] = useState('');

  const GetAllProducts = async () => {
    try {
      if(search?.length >= 3) {
        const payload = encryptData({
          published: true,
          searchkey: search,
          stock_status: ['in_stock', 'available']
        })
        const ApiCall = await API.post(`/admin/product/getProducts`, payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          const result = decryptData(ApiCall?.data)
          setAllProduct(result?.data);
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }
  useEffect(() => {
    if (search?.length === 0 || search?.length >= 3) {
      GetAllProducts();
    }
  }, [search])


  const [isSelected, setIsSelected] = useState([])

  const handleSelectProduct = (product, variant, quantity) => {
    if (product) {
      let existProduct;
      if (variant?.length) {
        existProduct = selectedProduct?.find((x) => x?.variant === variant)
      } else {
        existProduct = selectedProduct?.find((x) => x?.product === product);
      }
      if (!existProduct) {
        if (variant?.length) {
          setSelectedProduct([...selectedProduct, { product, variant, quantity }])
          setIsSelected([...selectedProduct, { product, variant, quantity }])

        } else {
          setSelectedProduct([...selectedProduct, { product, quantity }])
          setIsSelected([...selectedProduct, { product, quantity }])
        }
      } else {
        let removeSelected;
        if (existProduct?.variant?.length > 0) {
          removeSelected = selectedProduct?.filter((x) => x?.variant !== variant)
        } else {
          removeSelected = selectedProduct?.filter((x) => x?.product !== product)
        }
        setSelectedProduct(removeSelected);
        setIsSelected(removeSelected);
      }
    }
  }
  const GetSelectedProducts = async () => {
    try {
      if (selectedRowsId?.length !== 0) {
        const ApiCall = await API.get(`/admin/AdminCart/ViewCart/${selectedRowsId?.length && selectedRowsId[0]?.user?._id}`);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          const result = decryptData(ApiCall?.data)
          setViewSelectedProduct(result?.data)
        }
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  const AddSelectProducts = async () => {
    try {
      const payload = encryptData(selectedProduct)
      const ApiCall = await API.post(`/admin/AdminCart/addMultiProductToCart/${selectedRowsId?.length && selectedRowsId[0]?.user?._id}`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetSelectedProducts();
        setProductPopup(false);
        setIsSelected([])
        setSelectedProduct([])
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }
  const UpdateSelectedProduct = async (product, quantity, variant) => {
    try {
      const payload = encryptData({ cart: viewSelectedProduct?._id, product, quantity, variant })
      const ApiCall = await API.put(`/admin/AdminCart/UpdateCart/${selectedRowsId?.length && selectedRowsId[0]?.user?._id}`, payload);
      GetSelectedProducts();
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  const RemoveProducts = async () => {
    try {
      const data = await API.delete(`/admin/AdminCart/RemoveAllproductfromcart/${selectedRowsId?.length && selectedRowsId[0]?.user?._id}`);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true)
        setNotificationMsg(`${t("lang_removed")} ${t("lang_successfully!")}`)
        setSeverity('success')
        GetSelectedProducts();
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
    // setConfirmationPopup(false)
  };
  const RemoveCartProduct = async (e, id) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        id: id,
        user: selectedRowsId?.length && selectedRowsId[0]?.user?._id
      })
      const data = await API.post(`/admin/AdminCart/removeCartProduct`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true)
        setNotificationMsg(`${t("lang_removed")} ${t("lang_successfully!")}`)
        setSeverity('success')
        GetSelectedProducts();
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
    // setConfirmationPopup(false)
  };

  useEffect(() => {
    // if (productData?.section) {
    setViewSelectedProduct(
      viewSelectedProduct?.products?.map((ele) => {
        return {
          ...ele,
          quantity: ele?.min_order_quantity || 1,
        };
      })
    );
    // }

  }, []);

  // Get Customers Info
  const GetCustomersInfo = async () => {
    try {
      const CustomerInfo = await API.get(`/admin/order/GetCustomers?is_blocked=${false}&key=${customersearch}`);
      if (CustomerInfo.status === 200 || CustomerInfo.status === 304) {
        const result = decryptData(CustomerInfo?.data)
        setcustomers(result?.data)
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    if (customersearch?.length === 0 || customersearch?.length >= 3) {
      GetCustomersInfo();
    }
  }, [customersearch])



  const [dicountsearch, setDiscountSearch] = useState('')

  const GetAllDiscounts = async () => {
    try {
      const ApiCall = await API.get(`/admin/discount/viewDiscount?current_discount=${true}&searchkey=${dicountsearch}`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setAllDiscount(result?.data)
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }
  useEffect(() => {
    if (dicountsearch?.length === 0 || dicountsearch?.length >= 3) {
      GetAllDiscounts();
    }
  }, [dicountsearch])
  const AddApplyDiscount = async (coupon_code) => {
    try {
      const payload = encryptData({
        coupon_code,
        user: selectedRowsId?.length && selectedRowsId[0]?.user?._id
      })
      const ApiCall = await API.post(`/admin/AdminCart/ApplyDiscount`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetSelectedProducts();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }

  const AddDiscount = async () => {
    try {
      const payload = encryptData({
        user: selectedRowsId?.length && selectedRowsId[0]?.user?._id,
        discount_Type: discountValue,
        discount: discountCustomValue,
      })
      const ApiCall = await API.post(`/admin/AdminCart/AddDiscount`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetSelectedProducts();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }

  const RemoveDiscount = async (e) => {
    e.preventDefault();
    try {
      const data = await API.put(`/admin/AdminCart/removeDiscount/${selectedRowsId?.length && selectedRowsId[0]?.user?._id}`);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true)
        setNotificationMsg(`${t("lang_removed")} ${t("lang_successfully!")}`)
        setSeverity('success')
        GetSelectedProducts()
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };

  useEffect(() => {
    GetAllProducts()
    GetCustomersInfo()
    GetAllDiscounts()
  }, [])
  useEffect(() => {
    GetSelectedProducts()
  }, [customers])



  const GetDefaultShippingMethod = async () => {
    try {
      const ApiCall = await API.get(`/admin/shippingmethod/GetDefaultShippingMethod`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setDefaultShippingMethod(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }

  useEffect(() => {
    GetDefaultShippingMethod()
  }, [])



  const clientInfo = clientData
  const GetAllDeliverys = async () => {
    let weight = 0;
    for (const orderProduct of viewSelectedProduct && viewSelectedProduct.products) {
      weight += orderProduct?.product?.weight;
    }
    try {
      const payload = encryptData({
        pickup_postcode: Number(selectedRowsId.map((x) => x?.addresses?.pincode)),
        delivery_postcode: Number(clientInfo?.business_address.pincode),
        cod: 0,
        weight: weight,
      })
      const ApiCall = await API.post(`/admin/shipping/getShippingRates`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setShipCourierData(result)
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }

  const SaveShippingInfo = async (shipingdetails) => {
    setSelectedShippingPartner(shipingdetails);
    GetSelectedProducts();
    setModalOpen(false);
  };

  // Razorpay Integration
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js');
  }, []);

  const [PayAddress, setPayAddress] = useState({
    oaddressLine1: '',
    oaddressLine2: '',
    ocity: '',
    ostate: '',
    opincode: '',
  });
  useEffect(() => {
    if (paymentMethods) {
      setPaymentId(paymentMethods?.records?.find((x) => x.default === true)?._id);
      setPaymentMethodName(
        paymentMethods?.records?.find((x) => x.default === true)?.payment_method_name,
      );
    }
  }, [paymentMethods]);
  // select Payments
  const SelectPayment = async (e, id, PaymentName) => {
    setPaymentId(id);
    setPaymentMethodName(PaymentName);
  };

  const GetPaymentMethod = async () => {
    try {
      const ApiCall = await API?.get(`/admin/paymentmethod/getPaymentMethods?page=1&limit=10&status=true`);
      if (ApiCall?.status === 200 || ApiCall?.status === 304) {
        const result = decryptData(ApiCall?.data)
        setPaymentMethods(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }

  useEffect(() => {
    GetPaymentMethod()
  }, [])
  const [btnLoading, setBtnLoading] = useState(false)
  const isStringified = () => {
    if (typeof selectAddress === 'string') {
      return true;
    } else {
      return false;
    }
  }
  const PlaceOrder = async (e) => {
    e.preventDefault();
    setBtnLoading(true)
    const isString = isStringified()
    try {
      const payload = encryptData({
        payment_method: PaymentId,
        payment_address: isString ? JSON.parse(selectAddress) : selectAddress,
        shipping_address: isString ? JSON.parse(selectAddress) : selectAddress,
      })
      const ApiCall = await API.post(`/admin/AdminCart/PlaceOrder/${selectedRowsId?.length && selectedRowsId[0]?.user?._id}`, payload);
      if (paymentMethodName === "Razorpay") {
        const result = decryptData(ApiCall?.data)
        await RazorpayPop(result?.data, selectedRowsId?.length && selectedRowsId[0]?.user?._id);
      }
      if (paymentMethodName === "Cash On Delivery") {
        navigate('/orders');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false)
  }
  useEffect(() => {
    if (selectedRowsId && selectedRowsId[0]?.addresses?.length > 0) {
      setSelectAddress(selectedRowsId[0]?.addresses[0])
    } else {
      setSelectAddress()
    }
  }, [selectedRowsId])
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 w-full pb-[30px]'>
          <button
            onClick={(e) => { e.preventDefault(); navigate('/orders') }}
            className='back-arrow'><ArrowLeft /></button>
          <div>
            <Breadcrumbs crumbs={[
              { name: t('lang_orders'), path: '/orders' },
              { name: `${t("lang_add_new", { module: 'Orders' })}`, path: '/orders/add-orders' },
            ]} />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{`${t("lang_add_new", { module: 'Orders' })}`}</h4>
          </div>
        </div>

        <div className='flex flex-wrap'>
          <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='w-[90%] lg:w-[70%] xl:w-[60%] bg-transparent mx-auto rounded-lg'>
            <div className='relative mt-4 flex flex-col min-w-0 break-words bg-transparent w-full'>
              <div className='flex-auto'>
                <div id='link1'>
                  <div className='w-full space-y-4 pb-5'>
                    <details
                      open
                      className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-[25px]'
                    >
                      <summary className='text-base flex items-center justify-between font-medium capitalize'>
                        {`${t("lang_customer")} ${t("lang_details")}`}
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className='w-4 h-5' />
                        </span>
                      </summary>
                      <hr className='my-4 border-t main-border-color'></hr>
                      <div className='w-full text-center  '>
                        <>
                          <button className='bulk-btn' onClick={(e) => { e.preventDefault(); setCustomerPopup(true) }}>
                            {`${t("lang_select")} ${t("lang_customer")} ${t("lang_details")}`}
                          </button>
                          {
                            selectedRowsId?.length > 0 && !customerPopup &&
                            selectedRowsId?.map((x, index) => {
                              return (
                                <div key={index} className='text-left text-[15px] space-y-1'>
                                  <p className='font-medium capitalize'>{t("lang_name")} :<span className='ps-2 font-normal table-text'>{x?.user?.name}</span> </p>
                                  <p className='font-medium'>{t("lang_phone")} :<span className='ps-2 font-normal table-text'>{x?.user?.phone}</span> </p>
                                  <p className='font-medium'>{t("lang_email")} :<span className='ps-2 font-normal table-text'>{x?.user?.email}</span> </p>
                                  <div className='font-medium flex flex-wrap max-w-full items-center space-x-2'>
                                    {t("lang_address")} :
                                    {
                                      x?.addresses?.length > 0 && x?.addresses?.length === 1 ?
                                        <span className='ps-2 font-normal table-text'>
                                          {x?.addresses[0]?.addressLine1 ? `${x?.addresses[0]?.addressLine1}, ` : ''}
                                          {x?.addresses[0]?.addressLine2 ? `${x?.addresses[0]?.addressLine2}, ` : ''}
                                          {x?.addresses[0]?.city ? `${x?.addresses[0]?.city}, ` : ''}
                                          {x?.addresses[0]?.state ? `${x?.addresses[0]?.state}, ` : ''}
                                          {x?.addresses[0]?.pincode ? `${x?.addresses[0]?.pincode}` : ''}
                                        </span> :
                                        <div className='relative max-w-full'>
                                          <select onChange={(e) => setSelectAddress(e?.target?.value)} className='ms-2 max-w-full font-normal table-text'>
                                            {
                                              x?.addresses?.length > 0 && x?.addresses?.map((add, index) => {
                                                return (
                                                  <option key={index} value={JSON.stringify(add)}>
                                                    {add?.addressLine1 ? `${add?.addressLine1}, ` : ''}
                                                    {add?.addressLine2 ? `${add?.addressLine2}, ` : ''}
                                                    {add?.city ? `${add?.city}, ` : ''}
                                                    {add?.state ? `${add?.state}, ` : ''}
                                                    {add?.pincode ? `${add?.pincode}` : ''}
                                                  </option>
                                                )
                                              })
                                            }
                                          </select>
                                          <div className='select-arrow'></div>
                                        </div>
                                    }
                                  </div>

                                </div>
                              )
                            })
                          }
                        </>
                      </div>
                    </details>
                  </div>
                </div>

                {selectedRowsId?.length !== 0 && !customerPopup ?
                  <div id='link2'>
                    <div className='w-full space-y-4'>
                      <details
                        open
                        className='secondary-bg-color group shadow  rounded-[6px] p-[25px]'
                      >
                        <summary className='text-base flex items-center justify-between font-medium'>
                          {t("lang_products")}
                          <span className='transition group-open:rotate-180'>
                            <ChevronDown className='w-4 h-5' />
                          </span>
                        </summary>
                        <hr className='mt-4 border-t main-border-color'></hr>
                        <div className='w-full text-center  '>
                          <>
                            {
                              viewSelectedProduct === undefined ? <button
                                className='bulk-btn mt-4 capitalize' onClick={(e) => { e.preventDefault(); setProductPopup(true) }}>
                                {`${t("lang_add")} ${t("lang_product")}`}
                              </button> : <></>
                            }
                            <div className='divide-y  max-h-[450px] overflow-auto'>
                              {
                                viewSelectedProduct?.products?.map((x, index) => {
                                  return (
                                    <div key={index} className='flex space-x-2 py-2 main-border-color items-center'>
                                      <button onClick={(e) => RemoveCartProduct(e, x?._id)} className='close-btn me-2'>
                                        <X className="w-4 h-4 text-red-600" />
                                      </button>
                                      <div className='min-w-[70px] max-w-[70px] h-[70px] border main-border-color flex items-center justify-center'>
                                        <img src={x?.image} className='max-h-full max-w-full mx-auto' alt={x?.name} />
                                      </div>
                                      <div className='w-full'>
                                        <p className='text-[15px] text-left pb-2'>{x?.name}
                                          {x?.variant ?
                                            <span className='ps-1 font-medium'>
                                              ( <>{x?.variant ? (
                                                x?.variant?.group?.map((elem, index) => (
                                                  <span key={index}>
                                                    {elem?.group_value}{" "}
                                                    {x?.variant?.group?.length - 1 === index
                                                      ? ""
                                                      : "/"}
                                                  </span>
                                                ))
                                              ) : (
                                                <></>
                                              )}</> )

                                            </span>
                                            : <></>}
                                        </p>
                                        <div className='flex w-full items-center justify-between'>
                                          <div className="counter w-full text-center text-base font-medium flex items-center">
                                            <div className="flex border main-border-color flex-row justify-center rounded-[6px] relative bg-transparent">
                                              <button
                                                aria-label="minus"
                                                onClick={(e) => { e.preventDefault(); UpdateSelectedProduct(x?.product?._id, x?.quantity > x?.min_order_quantity ? x?.quantity - 1 : x?.min_order_quantity, x?.variant?._id) }}
                                                // style={{ backgroundColor: secondaryBodyBgColor, color: secondaryBodyTextColor }}
                                                className="w-8 h-8 transition-all primary-bg-color duration-300 cursor-pointer outline-none flex items-center justify-center"
                                              >
                                                <span className="m-auto text-md font-semibold">
                                                  −
                                                </span>
                                              </button>
                                              <input
                                                type="number"
                                                className="w-9 h-8 focus:outline-none text-center transition-all duration-300 bg-transparent font-semibold text-sm  table-text flex items-center  outline-none"
                                                name="quantity"
                                                value={x?.quantity || ''}
                                                readOnly
                                              ></input>
                                              <button
                                                aria-label="plus"
                                                onClick={(e) => { e.preventDefault(); UpdateSelectedProduct(x?.product?._id, x?.quantity + 1, x?.variant?._id) }}
                                                // style={{ backgroundColor: secondaryBodyBgColor, color: secondaryBodyTextColor }}
                                                className="w-8 h-8 transition-all primary-bg-color duration-300 cursor-pointer outline-none flex items-center justify-center"
                                              >
                                                <span className="m-auto text-md font-semibold">
                                                  +
                                                </span>
                                              </button>
                                            </div>
                                            <span className='ps-2 text-sm table-text'>× {FormatAmount(x?.selling_price)}</span>
                                          </div>
                                          <span className='pe-2 text-[14px]'>{FormatAmount(x?.quantity * x?.selling_price)}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                            {
                              viewSelectedProduct &&
                              <>
                                <div className='flex items-center border-t main-border-color justify-between pt-2'>
                                  <button
                                    className='inline-block outline-none text-link hover:underline text-[14px] font-medium transition duration-150' onClick={(e) => { e.preventDefault(); setProductPopup(true) }}>
                                    + {`${t("lang_add")} ${t("lang_more")} ${t("lang_product")}`}
                                  </button>
                                  <button className='inline-flex outline-none text-red-600 hover:underline text-[14px] font-medium transition duration-150 capitalize' onClick={(e) => { e.preventDefault(); RemoveProducts() }}>
                                    <Trash2 className="h-4 w-4 me-2 mt-0.5" />{`${t("lang_delete")} ${t("lang_all")} ${t("lang_product")}`}
                                  </button>
                                </div>
                              </>
                            }
                          </>
                        </div>
                      </details>
                    </div>
                  </div>
                  : <></>}

                {selectedRowsId?.length !== 0 && !customerPopup ?
                  <div id='link3'>
                    <div className='w-full space-y-4 pt-5'>
                      <details
                        open
                        className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-[25px]'
                      >
                        <summary className='text-base flex items-center justify-between font-medium capitalize'>
                          {`${t("lang_payment")} ${t("lang_details")}`}
                          <span className='transition group-open:rotate-180'>
                            <ChevronDown className='w-4 h-5' />
                          </span>
                        </summary>
                        <hr className='my-4 main-border-color' />
                        <div className='w-full space-y-1 dark:border-gray-600'>
                          <div className='flex justify-between'>
                            <p className='capitalize'>{`${t("lang_item")} ${t("lang_total")}`}</p>
                            <p>{viewSelectedProduct?.grand_total ? `${(FormatAmount(viewSelectedProduct?.grand_total))} + ${FormatAmount(Number(viewSelectedProduct?.total_discount))}` : <></>}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p className='capitalize'>{t("lang_discount")}
                              {viewSelectedProduct?.total_discount === 0 || viewSelectedProduct?.total_discount === undefined ?
                                <span className='underline text-black-600 text-sm ps-2' onClick={(e) => {
                                  e.preventDefault();
                                  setDiscountConfirmationPopup(true)
                                }}><a href="">{t("lang_edit")}</a></span> : <></>
                              }
                              {viewSelectedProduct?.total_discount !== undefined && viewSelectedProduct?.total_discount > 0 ?
                                <span className='underline text-red-600 text-sm ps-2' onClick={(e) => RemoveDiscount(e)}><a href="">{t("lang_remove")}</a></span>
                                : <></>}
                            </p>
                            <>
                              <div className='flex'>
                                <p>- {viewSelectedProduct?.grand_total ? <>
                                  {viewSelectedProduct?.total_discount !== 0 ? FormatAmount(Number(viewSelectedProduct?.total_discount)) : <></>}
                                </> : <></>} </p>

                              </div>

                            </>
                          </div>
                          <div className='flex justify-between'>
                            <p className='flex justify-between capitalize'>{t("lang_delivery")}
                              {defaultShippingMethod?.shipping_method_name === "Shiprocket" ?
                                <span
                                  className='underline text-orange-600 ps-2'
                                  onClick={(e) => { e.preventDefault(); GetAllDeliverys(); setModalOpen(true) }}>
                                  <a href="" className='capitalize'>{t("lang_edit")}</a></span> : <>{defaultShippingMethod?.shipping_method_name ?
                                    <span className='ps-2'>{defaultShippingMethod?.shipping_method_name}</span>
                                    : <></>}</>}
                            </p>

                            <div>
                              {defaultShippingMethod?.shipping_method_name === "Shiprocket" ?
                                <p className='text-orange-600'>
                                  {viewSelectedProduct?.grand_total ? selectedShippingPartner && FormatAmount(Number(selectedShippingPartner?.rate)) ? <>+</> : <>-</> : <>-</>}
                                  {viewSelectedProduct?.grand_total ? selectedShippingPartner && FormatAmount(Number(selectedShippingPartner?.rate)) : <></>}
                                </p>

                                : <p className='ps-1'>
                                  + {FormatAmount(Number(defaultShippingMethod?.shipping_charge))}
                                </p>
                              }
                            </div>
                          </div>
                          <div className='flex justify-between font-medium'>
                            <span className='capitalize'>{`${t("lang_grand")} ${t("total")}`}</span>
                            <p>
                              {
                                selectedShippingPartner?.rate || defaultShippingMethod?.shipping_charge ?
                                  FormatAmount(Number(viewSelectedProduct?.grand_total)) + FormatAmount(Number(selectedShippingPartner?.rate)) || FormatAmount(Number(viewSelectedProduct?.grand_total)) + (FormatAmount(defaultShippingMethod?.shipping_charge)) || <></>
                                  : FormatAmount(Number(viewSelectedProduct?.grand_total)) || 0
                              }
                            </p>
                          </div>
                        </div>
                        <hr className='my-4 main-border-color' />
                        <div>
                          <p className='text-sm mb-1 text-left capitalize'>
                            {`${t("choose")} ${t("lang_payment")} ${t("lang_method")}`} <span className='text-red-500 inline-block text-sm'>*</span>
                          </p>
                          <Autocomplete
                            sx={{
                              '.MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
                                borderRadius: 0
                              },
                            }}
                            className='autocomplete  table-text'
                            options={paymentMethods?.records ? paymentMethods?.records : []}
                            getOptionLabel={(methods) => methods.payment_method_name}
                            onChange={(e, v) => { v ? SelectPayment(e, v?._id, v?.payment_method_name) : null }}
                            value={paymentMethods?.records?.find((payment) => payment?.payment_method_name === paymentMethodName) || null}
                            renderInput={(methods) => (
                              <TextField onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} placeholder={`${t("lang_select")} ${t("lang_payment")} ${t("lang_method")}`} {...methods} />
                            )}
                          />
                        </div>
                      </details>
                    </div>
                  </div>
                  : <></>}
              </div>
            </div>
          </form>
        </div>
      </div>

      <Dialog
        open={customerPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { setCustomerPopup(false); }}
        aria-describedby="alert-dialog-slide-description customer-popup"
      >
        <div className='secondary-bg-color w-full lg:w-[700px] border main-border-color  space-y-4 rounded-[6px] p-5'>
          <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
            <h6 className='font-medium text-xl capitalize'>{`${t("lang_select")} ${t("lang_customer")}`}</h6>
            <button className='close-btn' onClick={(e) => { e.preventDefault(); setCustomerPopup(false) }}><X className="w-4 h-4" /></button>
          </div>
          <input type='text' value={customersearch} onChange={(e) => setCustomerSearch(e.target.value)} className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 text-sm' placeholder={`${t("lang_search")} ${t("lang_customer")}..`} />
          <div className='h-[450px] overflow-y-auto divide-y'>
            {
              customers?.records?.map((elem, index) => {
                return (
                  <label key={index} className='w-full main-border-color table-text cursor-pointer hover:primary-bg-color flex p-3 items-center space-x-4'>
                    <input type='radio' name='customer' className='w-4 h-4' value={elem?._id || ''} onChange={() => { setSelectedRowsId([elem]) }} />
                    <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-sm'>
                      <span>{elem?.user?.name || '-'}</span>
                      <span>{elem?.user?.email}</span>
                      <span className='lg:text-right'>{elem?.user?.phone}</span>
                    </div>
                    {/* <div className='text-right w-[100px]'>
                      {selectedRowsId[0]?._id === elem?._id ?
                        <button
                          className='btn text-sm text-white'
                          onClick={(e) => { e.preventDefault(); setSelectedRowsId([elem]); GetSelectedProducts(); }}
                        >selected</button> :
                        <button
                          className='btn text-sm text-white'
                          onClick={(e) => { e.preventDefault(); setSelectedRowsId([elem]); GetSelectedProducts(); }}
                        >select</button>
                      }
                    </div> */}

                  </label>
                )
              })
            }
          </div>
          <button className={`${selectedRowsId?.length <= 0 && 'bg-btn cursor-not-allowed opacity-50'} btn text-sm text-white capitalize`} disabled={selectedRowsId?.length <= 0} onClick={(e) => { e.preventDefault(); setCustomerPopup(false); GetSelectedProducts(); }}>{t("lang_done")}</button>
        </div>
      </Dialog>

      {/* Product Popup start */}
      <Dialog
        open={productPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setProductPopup(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='primary-bg-color table-text w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
          <div className='flex border-b main-border-color pb-3 items-center justify-between'>
            <h6 className='font-medium text-xl'>{`${t("select")} ${t("lang_products")}`}</h6>
            <button className='close-btn' onClick={(e) => { e.preventDefault(); setProductPopup(false) }}><X className="w-4 h-4" /></button>
          </div>
          <input value={search} onChange={(e) => setSearch(e.target.value)} className='w-full secondary-bg-color border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 text-sm' placeholder={`${t("lang_search")} ${t("lang_products")}..`} />
          <div className='h-[450px] overflow-y-auto divide-y'>
            {
            allProduct?.records?.length > 0 && search?.length >= 3 ?
              allProduct?.records?.map((elem, index) => {
                return (
                  <div key={index} className='main-border-color'>
                    <label className='flex items-start space-x-4 py-2'>
                      <input type='checkbox'
                        checked={isSelected ? isSelected?.find((x) => x?.product === elem?._id) : ''}
                        value={elem?._id || ''}
                        onClick={(e) => handleSelectProduct(elem?._id, '', elem?.min_order_quantity)}
                        className='block mt-1 w-4 h-4' />
                      <div className='flex space-x-2'>
                        <div className='w-[60px] h-[60px] p-1 border main-border-color flex items-center justify-center'>
                          <img src={elem?.image} className='max-w-full max-h-full' alt='product image' />
                        </div>
                        <div>
                          <p className='text-sm break-all'>{elem?.product_name}</p>
                          {elem?.sku_id && <p className='text-sm break-all'>{elem?.sku_id}</p>}
                          {elem?.selling_price && <p className='text-sm break-all'>{FormatAmount(elem?.selling_price)}</p>}
                        </div>
                      </div>
                    </label>
                    <div className=''>
                      {elem?.variants?.map((x, index) => {
                        return (
                          <label key={index} className='flex ms-5 main-border-color items-start space-x-4 border-t py-2'>
                            <input type='checkbox'
                              checked={isSelected ? isSelected?.find((y) => y?.variant === x?._id) : ''}
                              value={x?._id || ''}
                              onClick={(e) => handleSelectProduct(elem?._id, x?._id, elem?.min_order_quantity)}
                              className='block mt-1 w-4 h-4' />
                            <div className='flex space-x-2'>
                              <div className='w-[60px] h-[60px] p-1 border main-border-color flex items-center justify-center'>
                                <img src={x?.image} className='max-w-full max-h-full' alt='product image' />
                              </div>
                              {x?.group?.map((vars, index) =>
                                <p key={index} className='text-base break-all'>{vars?.group_value}</p>
                              )}
                            </div>
                          </label>
                        )
                      })}
                    </div>
                  </div>
                )
              })
              : search?.length <= 3 ?
                <div className='w-full flex items-center justify-center border px-2 py-2'>
                  {t('lang_enter_three_characters')}
                </div>
                :
                <div className='w-full flex items-center justify-center border px-2 py-2'>
                  {t('lang_Data_not_found')}
                </div>
            }
          </div>
          {search?.length >= 3 && allProduct?.records?.length > 0 && <button className={`btn text-sm text-white capitalize transition duration-300 ${selectedProduct?.length <= 0 && 'bg-btn cursor-not-allowed opacity-50'}`}
            disabled={selectedProduct?.length <= 0}
            onClick={(e) => { e.preventDefault(); AddSelectProducts(); }}
          >{t("lang_Select_Products")}</button>}
        </div>
      </Dialog>
      {/* Product Popup end */}

      {/* discount POP-UP start */}
      <Dialog
        open={discountConfirmationPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { setDiscountConfirmationPopup(false); }}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='w-[600px] max-h-full'>
          <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-5'>
            <div className='flex items-center justify-between text-primary border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='capitalize font-medium'>{`${t("lang_select")} ${t("lang_discount")} ${t("lang_type")}`}</h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => { setDiscountConfirmationPopup(false) }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <div>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={confirmationvalue}
                  className='table-text'
                  onClick={(e) => setConfirmationValue(e.target.value)}
                >
                  <FormControlLabel
                    className='table-text'
                    value="Apply existing coupon"
                    control={<Radio />}
                    label={`${t("lang_apply")} ${t("lang_existing")} ${t("lang_coupon")}`}
                  />
                  <FormControlLabel
                    className='table-text'
                    value="Custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className='text-center'>
              <button type='button'
                onClick={(e) => { e.preventDefault(); setDiscountConfirmationPopup(false); { confirmationvalue === 'Apply existing coupon' ? setDiscountPopup(true) : setDiscountCustomPopup(true) } }}
                className='btn px-5 py-2 text-white rounded-[6px]'>
                {t("lang_done")}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={discountCustomPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { setDiscountCustomPopup(false); }}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='w-[600px] max-h-full'>
          <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-5'>
            <div className='flex text-primary items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='capitalize font-medium'>{`${t("lang_select")} ${t("lang_discount")} ${t("lang_type")}`}</h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => { setDiscountCustomPopup(false) }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <div>

              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={discountValue}
                  onClick={(e) => setDiscountValue(e.target.value)}
                >
                  <FormControlLabel
                    value="percentage"
                    control={<Radio />}
                    label="percentage"
                  />
                  <FormControlLabel
                    value="amount"
                    control={<Radio />}
                    label="Flat amount"
                  />
                </RadioGroup>
              </FormControl>


              <p>
                {discountValue === "percentage" ?
                  `${t("lang_discount")} ${t("lang_in")} ${t("lang_percentage")} *` : `${t("lang_discount")} ${t("lang_in")} ${t("lang_flat")} ${t("lang_amount")} *`}</p>
              <input
                type="number"
                placeholder={discountValue === t("lang_percentage") ? `% ${t("lang_enter")} ${t("lang_percentage")}` : `₹ ${t("lang_enter")} ${t("lang_amount")}`}
                name='percentage'
                value={discountCustomValue}
                onChange={(e) => {
                  setDiscountCustomValue(e.target.value);
                }}
                className='border main-border-color w-full outline-none'
              />

            </div>
            <div className='text-center pt-2'>
              <button type='button'
                onClick={(e) => { e.preventDefault(); AddDiscount(); setDiscountCustomPopup(false); }}
                className='btn px-5 py-2 text-white rounded-[6px]'>
                {t("lang_save")}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={discountPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { setDiscountPopup(false); }}
        aria-describedby="alert-dialog-slide-description"
      >

        <div className='secondary-bg-color w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
          <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
            <h6 className='font-medium text-xl capitalize'>{`${t("lang_select")} ${t("lang_discount")}`}</h6>
            <button className='close-btn' onClick={(e) => { e.preventDefault(); setDiscountPopup(false) }}><X className="w-4 h-4" /></button>
          </div>
          <input type='text' value={dicountsearch} onChange={(e) => setDiscountSearch(e.target.value)} className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 text-sm' placeholder={`${t("lang_search")} ${t("lang_discount")}..`} />
          <div className='h-[450px] overflow-y-auto divide-y'>
            {
              allDiscount?.records?.map((elem, index) => {
                return (
                  <label key={index} className='flex main-border-color justify-between py-2 items-start space-x-4'>
                    <div className=''>
                      <p className='text-base break-all text-green-700 font-medium inline-block'>{elem?.coupon_code}</p>
                      <p className='font-normal table-text text-sm'>{elem?.discount_name}</p>
                      <p className='font-medium flex items-center capitalize'>{t("lang_discount")} :<span className='font-normal ms-1 flex items-center'>{elem?.discount_Type === 'amount' ? GetCurrencySymbol() : '%'}{elem?.discount}</span></p>
                    </div>
                    <button
                      className='btn text-sm text-white capitalize'
                      onClick={(e) => { e.preventDefault(); AddApplyDiscount(elem?.coupon_code); setDiscountPopup(false) }}
                    >{t("lang_apply")}</button>
                  </label>
                )
              })
            }
          </div>
          {/* <button className='btn text-sm text-white' onClick={(e) => { e.preventDefault(); setDiscountPopup(false) }}>Add Discount</button> */}
        </div>

      </Dialog>
      {/* discount POP-UP end */}


      {/* shiprocat coorier popup start */}
      {openModal ? (
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={() => setModalOpen(true)}
          onClose={() => setModalOpen(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box className='absolute z-50 max-h-screen lg:overflow-auto overflow-y-auto top-10 left-10 right-10 bottom-10 max-w-full lg:w-[1000px] mx-auto p-[25px] secondary-bg-color'>
              <Typography>
                <div className='flex items-center text-primary justify-between pb-3 border-b main-border-color'>
                  <h5 className='font-semibold text-3xl capitalize'>{`${t("lang_choose")} ${t("courier")} ${t("lang_partner")}`}</h5>
                  <button
                    aria-label='openmodal'
                    type='button'
                    className='close-btn'
                    data-modal-hide='staticModal'
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    <X className='w-4 h-4' />
                  </button>
                </div>
                <div className='w-full overflow-auto mt-5'>
                  <table className='w-full text-sm'>
                    <thead>
                      <tr className='border main-border-color primary-bg-color text-left'>
                        <td className='p-3 min-w-[300px] font-medium capitalize'>{`${t("courier")} ${t("lang_partner")}`}</td>
                        <td className='font-medium min-w-[100px] capitalize'>{t("lang_rating")}</td>
                        <td className='font-medium min-w-[200px] capitalize'>{`${t("lang_estimated")} ${t("lang_delivery")} ${t("lang_date")}`}</td>
                        <td className='font-medium min-w-[150px] capitalize'>{`${t("lang_shipment")} ${t("lang_rate")}`}</td>
                        <td className='font-medium min-w-[80px] capitalize'>{t("lang_action")}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {shipCourierData?.data?.data?.available_courier_companies?.map(
                        (elem, index) => {
                          return (
                            <tr key={index} className='border main-border-color text-left '>
                              <td className='p-4'>{elem.courier_name}</td>
                              <td className='me-7'>
                                {
                                  elem?.rating === 5 ? <div className='inline-flex items-center space-x-2 bg-[#00b67a] text-white px-3 py-0.5 rounded-[6px]'>
                                    <StarFill />
                                    <span className='font-medium text-sm'>{elem?.rating}</span>
                                  </div> : (elem?.rating >= 4 && elem?.rating <= 5) ?
                                    <div className='inline-flex items-center space-x-2 bg-[#73cf11] text-white px-3 py-0.5 rounded-[6px]'>
                                      <StarFill />
                                      <span className='font-medium text-sm'>{elem?.rating}</span>
                                    </div> : (elem?.rating >= 3 && elem?.rating <= 4) ?
                                      <div className='inline-flex items-center space-x-2 bg-[#ffce00] text-white px-3 py-0.5 rounded-[6px]'>
                                        <StarFill />
                                        <span className='font-medium text-sm'>{elem?.rating}</span>
                                      </div> : (elem?.rating >= 2 && elem?.rating <= 3) ?
                                        <div className='inline-flex items-center space-x-2 bg-[#ff8622] text-white px-3 py-0.5 rounded-[6px]'>
                                          <StarFill />
                                          <span className='font-medium text-sm'>{elem?.rating}</span>
                                        </div> : (elem?.rating < 2) ?
                                          <div className='inline-flex items-center space-x-2 bg-[#ff3722] text-white px-3 py-0.5 rounded-[6px]'>
                                            <StarFill />
                                            <span className='font-medium text-sm'>{elem?.rating}</span>
                                          </div> : <></>
                                }
                              </td>
                              <td>
                                <p>{elem?.etd}</p>
                                <p className='text-gray-500 text-xs font-medium capitalize'>
                                  {t("lang_in")} {elem?.estimated_delivery_days} {t("lang_days")}
                                </p>
                              </td>
                              <td className='font-bold'>{FormatAmount(elem?.rate)}</td>
                              <td>
                                <button
                                  aria-label='select'
                                  className='px-3.5 py-1.5  bg-[#cb354e] transition-all duration-300 text-sm hover:bg-[#2b2f2e] text-white'
                                  onClick={() => SaveShippingInfo(elem)}
                                >
                                  {t("lang_select")}
                                </button>
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </table>
                </div>
              </Typography>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <></>
      )}
      {/* shiprocat coorier popup end */}

      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-40 secondary-bg-color border-t main-border-color p-3 left-0'>
        <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
          <Link
            to={`/orders`}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            {`${t("lang_go_to")} ${t("lang_orders")}`}
          </Link>
          <div className='flex items-center space-x-2'>
            <div>
              {
                ((defaultShippingMethod?.shipping_method_name === "Shiprocket") && !selectedShippingPartner) ||
                  !paymentMethods?.records?.length || !PaymentId || selectedRowsId?.length === 0 || !viewSelectedProduct || !selectAddress ?
                  <button
                    type='button' disabled className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    {`${t("lang_add_new", { module: 'Orders' })}`}
                  </button> :
                  btnLoading ?
                    <button type='button'
                      className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[180px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'>
                      <div className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full">
                      </div>
                      <span>{t("lang_loading")}...</span>
                    </button> :
                    <button
                      onClick={(e) => { PlaceOrder(e) }}
                      className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                    >
                      <span>{`${t("lang_add_new", { module: 'Orders' })}`}</span>
                    </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrder;