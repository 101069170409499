import { t } from 'i18next';
import React from 'react';

const ListItem = ({ item, handleItemClick, isSelected, handleInputChange, onCopy, onRemove }) => {
  return (
    <div
      className={`p-3 mb-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.accordionName}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Duplicate'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='accordionName'>Accordion Name</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='accordionName'
              name='accordionName'
              placeholder='Enter name...'
              value={item?.accordionName || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='accordionDescription'>Accordion Description</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='accordionDescription'
              name='accordionDescription'
              placeholder='Enter description...'
              value={item?.accordionDescription || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const AccordionSettingSection = ({
  section_id,
  accordionValue,
  setAccordionValue,
  handleAccordionValue,
  tabIndex,
  setTabIndex,
  handleAddAccordionItem,
  handleCopyAccordionItem,
  handleAccordionDataRemoveValue,
  btnLoading,
}) => {
  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedTabValue = {
      ...accordionValue,
      item: accordionValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setAccordionValue(updatedTabValue);
    handleAccordionValue(section_id, updatedTabValue);
  };
  const addItem = () => {
    const newId = accordionValue?.item?.length
      ? accordionValue?.item?.[accordionValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      accordionName: `New Accordion`,
      accordionDescription: 'New Accordion Added',
      isEdit: false,
    };
    const updatedItems = accordionValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleAddAccordionItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopyAccordionItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleAccordionDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedTabValue = {
      ...accordionValue,
      item: accordionValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setAccordionValue(updatedTabValue);
    handleAccordionValue(section_id, updatedTabValue);
  };

  return (
    <div className='max-w-md mx-auto mt-8'>
      <h2 className='text-lg font-semibold mb-4'>Accordion Items</h2>
      <div>
        {accordionValue?.item?.map((item, index) => (
          <ListItem
            key={index}
            item={item}
            handleItemClick={() => handleItemClick(index)}
            isSelected={tabIndex === index}
            onCopy={() => copyItem(item, index)}
            onRemove={removeItem}
            handleInputChange={handleInputChange}
          />
        ))}
      </div>
      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + Add Item
      </button>
    </div>
  );
};

export default AccordionSettingSection;
