import React from 'react';
import EditDividerStyleContainer from './EditDividerStyleContainer';

const EditDividerStyleSection = ({
  dividerDesign,
  setDividerDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  previewImage,
  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditDividerStyleContainer
        dividerDesign={dividerDesign}
        setDividerDesign={setDividerDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditDividerStyleSection;
