import { FormControlLabel } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import MuiSwitch from '../../../../controllers/MuiSwitch';
import { Bandaid, Upload } from 'react-bootstrap-icons';
import { Loader } from 'feather-icons-react/build/IconComponents';
import IconList from './IconList';
import { t } from 'i18next';

const AlertSettingSection = ({
  isOpen,
  setIsOpen,
  section_id,
  alertValue,
  handleAlertChange,
  btnLoading,
}) => {
  const [selectedAlertLevel, setSelectedAlertLevel] = useState(alertValue?.alertType || 'info');
  const fileInputRef = useRef(null);

  const dropDownValue = [
    { value: 'info', label: 'Info' },
    { value: 'success', label: 'Success' },
    { value: 'warning', label: 'Warning' },
    { value: 'danger', label: 'Danger' },
  ];

  const AlertValueChange = (e) => {
    const value = e.target.value;
    const type = e.target.name;
    handleAlertChange(section_id, type, value);
  };

  const toggleIconVisibility = (event) => {
    const newShowIcon = event.target.checked;
    handleAlertChange(section_id, 'showIcon', newShowIcon);
  };

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    handleAlertChange(section_id, 'icon', iconComponent);
  };

  useEffect(() => {
    setSelectedAlertLevel(alertValue?.alertType);
  }, [alertValue]);

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert('No file selected. Please select an SVG file.');
      return;
    }
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        const isValidSvg = /<svg[\s\S]*<\/svg>/i.test(imageUrl);
        if (!isValidSvg) {
          alert('Invalid SVG content. Please upload a valid SVG file.');
          return;
        }
        handleAlertChange(section_id, 'icon', imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  return (
    <div className='w-full mb-4 mt-2'>
      <div className='w-full'>
        <label>Alert Type</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            name='alertType'
            value={selectedAlertLevel}
            onChange={AlertValueChange}
          >
            {dropDownValue.map((obj) => (
              <option key={obj.value} value={obj.value}>
                {obj.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='w-full mb-4'>
        <label htmlFor='title'>Title</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='title'
          name='title'
          placeholder='Enter Title...'
          onChange={AlertValueChange}
          value={alertValue?.title}
        />
      </div>

      <div className='w-full mb-4'>
        <label htmlFor='content'>Content</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='content'
          name='content'
          placeholder='Enter Content...'
          onChange={AlertValueChange}
          value={alertValue?.content}
        />
      </div>

      <div className='w-full mb-4'>
        <label className='flex items-center justify-between cursor-pointer'>
          <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
            Show Dismiss Icon
          </span>
          <FormControlLabel
            checked={alertValue?.showIcon}
            control={<MuiSwitch />}
            onChange={toggleIconVisibility}
          />
        </label>
      </div>
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      {alertValue?.showIcon && (
        <div className='w-full mb-4'>
          <div className='flex justify-between items-center'>
            <label>Icon</label>
            <div className='flex space-x-2'>
              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                // onClick={() => handleButtonIconClick('upload')}
                onClick={() => handleSvgClick()}
              >
                <Upload className='text-gray-600' />
              </button>

              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                onClick={() => handleIconClick('iconList')}
              >
                <Loader className='text-gray-600' />
              </button>
            </div>
          </div>
        </div>
      )}
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default AlertSettingSection;
