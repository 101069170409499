import React from 'react';
import EditIconListStyleContainer from './EditIconListStyleContainer';

const EditIconListStyleSection = ({
  previewImage,
  iconListDesign,
  setIconListDesign,
  section_id,
  updateSectionStyle,

  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditIconListStyleContainer
        iconListDesign={iconListDesign}
        setIconListDesign={setIconListDesign}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditIconListStyleSection;
