import React from 'react';
import EditTextEditorStyleContainer from './EditTextEditorStyleContainer';

const EditTextEditorStyleSection = ({
  previewImage,
  textEditorDesign,
  setTextEditorDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditTextEditorStyleContainer
        textEditorDesign={textEditorDesign}
        setTextEditorDesign={setTextEditorDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditTextEditorStyleSection;
