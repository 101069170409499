import React, { useEffect, useState } from 'react'
import API from '../../API'
import Notification from '../Notification'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { clientData, GetClient } from '../../controllers/GetClient'
import { getInitialPage } from '../../controllers/PageHandleFromQuery'
import { Pagination } from '@mui/material'
import { Check, PlusCircle } from 'feather-icons-react/build/IconComponents'
import { t } from 'i18next';
import PurchaseAdminPlan from '../AdminPurchasePlan/PuchaseAdminPlan'
import { decryptData, encryptData } from '../../controllers/encryptionUtils'
import Loader from '../Loader/Loader'
import Shop from '../../Assets/shop.svg'
import NoRecords from './NoRecords'

const AllStores = ({ token }) => {
  const [openTab, setOpenTab] = useState('active');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const navigate = useNavigate();
  const [storeList, setStoreList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(getInitialPage());
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit || 7);
  const [isAdminPlanOpen, setIsAdminPlanOpen] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const plan = searchParams.get('plan');
  const isLogin = sessionStorage.getItem("isLogin");

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token, navigate]);

  const getStoreList = async () => {
    try {
      const response = await API.get(`/admin/user/GetStoreList?store=${openTab === 'active'}&page=${page}&limit=${limit}&search=${searchInput}`);
      if (response.status === 200 || response.status === 304) {
        const decryptedData = decryptData(response.data);
        setStoreList(decryptedData?.tenant || []);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error.message || 'Failed to fetch store list');
      setSeverity('error');
    }
  };

  useEffect(() => {
    getStoreList();
  }, [searchInput, page, limit, openTab]);

  useEffect(() => {
    if (storeList?.records?.length === 1 && !isLogin) {
      const store = storeList?.records[0];
      if (store?.user_email && store?._id) {
        selectStore(store);
        sessionStorage.setItem("isLogin", true);
      }
    }
  }, [storeList?.records, isLogin]);

  const selectStore = async (store) => {
    if (store?.verify_status === 'verified') {
      try {
        setStoreLoading(true);
        const payload = encryptData({ email: store?.user_email, id: store?._id });
        const response = await API.post(`/admin/user/SelectStore`, payload);
        if (response?.data) {
          const result = decryptData(response.data);
          localStorage.setItem('token', result?.data?.token);
          localStorage.setItem('business_name', result?.tenant?.business_name);
          GetClient();
          handleRedirect();
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error.message || 'Failed to select store');
        setSeverity('error');
      } finally {
        setStoreLoading(false);
      }
    }
  };

  const handleRedirect = async () => {
    try {
      const response = await API.get(`/admin/plan/planStatusCheck`);
      if (response.status === 200) {
        setStoreLoading(false);
        window.location.href = "/dashboard";
      }
    } catch (error) {
      setStoreLoading(false);
      if (error?.response?.status === 498) {
        if (!clientData || clientData?.billing_status === false) {
          setIsAdminPlanOpen(true);
        } else {
          navigate('/dashboard');
        }
      } else {
        setIsNotification(true);
        setNotificationMsg(error.message || 'Redirect failed');
        setSeverity('error');
      }
    }
  };

  const closeAdminPlanPopup = (success) => {
    setIsAdminPlanOpen(false);
    if (success === "success") {
      setIsNotification(true);
      setNotificationMsg("Plan Purchase Successfully!");
      setSeverity("success");
      GetClient();
    }
  };

  useEffect(() => {
    if (plan === '') {
      setIsAdminPlanOpen(true);
    }
  }, [plan]);

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {storeLoading && !isLogin ? (
        <div className='h-screen w-screen flex items-center justify-center'>
          <Loader />
        </div>
      ) : (
        <div className='flex bg h-screen w-screen items-center justify-center'>
          <div
            style={{ boxShadow: '0 20px 45px 0 rgba(3, 3, 3, 0.2)' }}
            className='w-full form sm:w-8/12 xl:w-1/3 my-5 mx-5 bg-white p-5 sm:p-7 rounded-[6px]'
          >
            <div className='mb-4 text-center'>
              <img
                className='mx-auto h-6 sm:h-7 w-auto'
                src='https://cdn.shopeasy.ai/shopeasy/icons/shop-easy-black.png'
                alt='Your Company'
              />
            </div>
            <div className='flex items-center justify-between'>
              <h6 className='font-medium text-gray-900'>
                {t('lang_welcom_back')}, {clientData?.user?.name}
              </h6>
              <button
                type='button'
                className='btn inline-flex items-center text-white font-medium -tracking-tight text-[13px]'
                onClick={() => navigate('/createstore')}
              >
                <PlusCircle className='w-4 h-4 me-2' /> {t('lang_Create_Store')}
              </button>
            </div>
            <div className='my-4'>
              <input
                type='text'
                className='w-full outline-none focus:border-gray-400 border'
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder='Search Your Store'
              />
            </div>
            <div className='w-full mt-6'>
              <div className='grid mb-1 w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[6px] p-1'>
                <a
                  className={`tab ${openTab === 'active' ? 'focus-tab' : 'bg-transparent'}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab('active');
                  }}
                  href='#link1'
                >
                  {t('lang_active')}
                </a>
                {storeList?.inactiveCount > 0 && (
                  <a
                    className={`tab ${openTab === 'inactive' ? 'focus-tab' : 'bg-transparent'}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('inactive');
                    }}
                    href='#link1'
                  >
                    {t('lang_Inactive')}
                  </a>
                )}
              </div>
              <div>
                <ul className='mt-4 space-y-2'>
                  {storeList?.records?.length > 0 ? (
                    storeList.records.map((store, index) => (
                      <button
                        key={index}
                        disabled={store?.verify_status !== 'verified'}
                        className='hover:bg-[#F0F0F0] flex w-full text-left disabled:cursor-not-allowed disabled:hover:bg-white items-center justify-between duration-200 py-2 rounded-md cursor-pointer'
                        onClick={() => selectStore(store)}
                      >
                        <div className='flex items-center'>
                          <div className='w-10 h-10 rounded-[6px] me-2 flex items-center font-medium capitalize justify-center bg-btn text-white'>
                            {store?.tenant_name?.charAt(0)}
                          </div>
                          <div>
                            <span className='font-medium block text-sm'>{store?.tenant_name}</span>
                            {store?.custom_domain && (
                              <span className='text-xs table-text block'>
                                {store?.custom_domain}
                              </span>
                            )}
                          </div>
                        </div>
                        {store?.verify_status === 'delete_request' && openTab === 'active' ? (
                          <span
                            className={`px-4 py-0.5 capitalize text-[13px] font-medium rounded-lg 
                          ${
                            store?.verify_status === 'verified' ? 'bg-green-100 text-green-700' : ''
                          } 
                          ${
                            store?.verify_status === 'delete_request'
                              ? 'text-orange-600 bg-orange-100'
                              : ''
                          }`}
                          >
                            {store?.verify_status?.replace('_', ' ')}
                          </span>
                        ) : (
                          openTab === 'active' && (
                            <span className='text-white rounded-full bg-green-600 h-[20px] w-[20px] flex items-center justify-center'>
                              <Check className='w-4 h-4 stroke-white' />
                            </span>
                          )
                        )}
                      </button>
                    ))
                  ) : (
                    // <div className='flex flex-col justify-center items-center'>
                    //   <img className='items-center' src={Shop} width={50} height={50} />
                    //   <h6 className='w-full text-center mt-4'>Create your first online store By Clicking Create Store</h6>
                    // </div>
                    <NoRecords />
                  )}
                </ul>
              </div>
            </div>
            {storeList?.records?.length > 0 && storeList?.totalPages !== 1 && (
              <div className='bg-white border main-border-color flex flex-1 p-2 mt-4 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                <Pagination
                  count={storeList?.totalPages}
                  page={page}
                  onChange={(e, v) => setPage(v)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <PurchaseAdminPlan
        type={'expired'}
        isAdminPlanOpen={isAdminPlanOpen}
        closeAdminPlanPopup={closeAdminPlanPopup}
      />
    </>
  );
};

export default AllStores;
