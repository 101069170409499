import React from 'react';
import SvgSection from '../../controllers/SvgSection';
import { mapPositionToCSS } from '../../controllers/DynamicEditorSwitchCase';

const GeneralIconList = ({ obj }) => {
  return (
    <>
      <div
        className='flex flex-col items-center'
        key={obj?.id}
        id={obj?.id}
        style={{
          paddingTop: `${obj?.style?.padding_top}px`,
          paddingBottom: `${obj?.style?.padding_bottom}px`,
          paddingLeft: `${obj?.style?.padding_left}px`,
          paddingRight: `${obj?.style?.padding_right}px`,
          marginTop: `${obj?.style?.margin_top}px`,
          marginBottom: `${obj?.style?.margin_bottom}px`,
          marginLeft: `${obj?.style?.margin_left}px`,
          marginRight: `${obj?.style?.margin_right}px`,
          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
          transform: `rotate(${obj?.style?.text_rotate}deg)`,
          background:
            obj?.style?.background_gradient === 'gradient'
              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
              : obj?.style?.background_image
              ? `url(${obj?.style?.background_image})`
              : obj?.style?.background,
          backgroundRepeat: obj?.style?.background_repeat,
          backgroundAttachment: obj?.style?.background_attachment,
          objectFit: obj?.style?.background_fit,
          backgroundSize:
            obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
              ? obj?.style?.background_fit
              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
        }}
      >
        {obj?.content?.item?.map((list, index) => (
          <div
            key={index}
            className='w-full flex flex-col items-center'
            style={{
              alignItems:
                obj?.style?.item_align === 'right'
                  ? 'flex-end'
                  : obj?.style?.item_align === 'left'
                  ? 'flex-start'
                  : obj?.style?.item_align === 'center'
                  ? 'center'
                  : undefined,
            }}
          >
            <div
              key={index}
              className='flex items-center mt-4'
              style={{
                marginBottom: `${obj?.style?.space_between}px`,
              }}
            >
              {list?.icon && React.isValidElement(list?.icon) ? (
                React.cloneElement(list?.icon, {
                  width: obj?.style?.icon_width ? `${obj.style.icon_width}px` : '20px',
                  height: obj?.style?.icon_width ? `${obj.style.icon_width}px` : '20px',
                  fill: obj?.style?.icon_color ? obj.style.icon_color : '#000000',
                })
              ) : (
                <SvgSection
                  svgContent={list?.icon}
                  fill={obj?.style?.icon_color ? obj?.style?.icon_color : '#000000'}
                  stroke={obj?.style?.icon_color ? obj?.style?.icon_color : '#000000'}
                  width={obj?.style?.width ? `${obj.style.width}${obj.style.width_unit}` : '20px'}
                  height={
                    obj?.style?.height ? `${obj.style.height}${obj.style.height_unit}` : '20px'
                  }
                  maxWidth={
                    obj?.style?.maxWidth ? `${obj.style.maxWidth}${obj.style.width_unit}` : ''
                  }
                  maxHeight={
                    obj?.style?.maxHeight ? `${obj.style.maxHeight}${obj.style.height_unit}` : ''
                  }
                />
              )}
              <div
                className='ml-2'
                style={{
                  marginLeft: `${obj?.style?.icon_spacing}px`,
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: obj?.style?.title_font_size
                    ? `${obj?.style?.title_font_size}px`
                    : '18px',
                  lineHeight: obj?.style?.title_font_height
                    ? `${obj?.style?.title_font_height}px`
                    : '25px',
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {list?.text}
              </div>
            </div>
            {index < obj?.content?.item?.length - 1 && (
              <div
                style={{
                  borderBottomWidth: obj?.style?.divider_visible
                    ? `${obj?.style?.divider_weight}px`
                    : '0',
                  borderBottomColor: obj?.style?.divider_visible ? obj?.style?.divider_color : '',
                  borderBottomStyle: obj?.style?.divider_visible
                    ? obj?.style?.divider_style
                    : 'none',
                  width: obj?.style?.divider_width ? `${obj?.style?.divider_width}%` : '',
                }}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default GeneralIconList;
