import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const FirstStaticTestimonialSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
  handleFlexBoxClick,
  removeSection,
}) => {
  const filteredSections = sectionAdd?.firstTestimonialComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'testimonial 1',
  );

  return (
    <div className='w-full relative group'>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.sectionId &&
          editSection?.section_index === itemIndex;

        return (
          <ReactDragListView {...dragProps}>
            <section
              id={obj?.sectionId}
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.sectionId, obj?.blockName, itemIndex),
                  openAddSection(e, 'testimonial 1', 'setting');
              }}
            >
              <div
                class='container mx-auto'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                {obj?.content?.htmlTag === 'h1' ? (
                  <div
                    class='mb-12 text-center'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: obj?.style?.title_font_size
                        ? `${obj?.style?.title_font_size}px`
                        : '40px',
                      lineHeight: obj?.style?.title_font_height
                        ? `${obj?.style?.title_font_height}px`
                        : '45px',
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </div>
                ) : obj?.content?.htmlTag === 'h2' ? (
                  <div
                    class='mb-12 text-center'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: obj?.style?.title_font_size
                        ? `${obj?.style?.title_font_size}px`
                        : '36px',
                      lineHeight: obj?.style?.title_font_height
                        ? `${obj?.style?.title_font_height}px`
                        : '41px',
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </div>
                ) : obj?.content?.htmlTag === 'h3' ? (
                  <div
                    class='mb-12 text-center'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: obj?.style?.title_font_size
                        ? `${obj?.style?.title_font_size}px`
                        : '32px',
                      lineHeight: obj?.style?.title_font_height
                        ? `${obj?.style?.title_font_height}px`
                        : '37px',
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </div>
                ) : obj?.content?.htmlTag === 'h4' ? (
                  <div
                    class='mb-12 text-center'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: obj?.style?.title_font_size
                        ? `${obj?.style?.title_font_size}px`
                        : '26px',
                      lineHeight: obj?.style?.title_font_height
                        ? `${obj?.style?.title_font_height}px`
                        : '33px',
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </div>
                ) : obj?.content?.htmlTag === 'h5' ? (
                  <div
                    class='mb-12 text-center'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: obj?.style?.title_font_size
                        ? `${obj?.style?.title_font_size}px`
                        : '22px',
                      lineHeight: obj?.style?.title_font_height
                        ? `${obj?.style?.title_font_height}px`
                        : '29px',
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </div>
                ) : obj?.content?.htmlTag === 'h6' ? (
                  <div
                    class='mb-12 text-center'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: obj?.style?.title_font_size
                        ? `${obj?.style?.title_font_size}px`
                        : '18px',
                      lineHeight: obj?.style?.title_font_height
                        ? `${obj?.style?.title_font_height}px`
                        : '25px',
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </div>
                ) : obj?.content?.htmlTag === 'div' ? (
                  <div
                    class='mb-12 text-center'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </div>
                ) : obj?.content?.htmlTag === 'p' ? (
                  <p
                    class='mb-12 text-center'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </p>
                ) : obj?.content?.htmlTag === 'span' ? (
                  <span
                    class='mb-12 text-center'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </span>
                ) : (
                  ''
                )}
                <div class='flex flex-wrap -m-4'>
                  {obj?.content?.item?.map((value) => {
                    return (
                      <div
                        class={`p-4 ${getWidthClass(obj?.content?.gridValue?.toString())} w-full`}
                      >
                        <div class='h-full bg-gray-100 p-8 rounded'>
                          {value?.icon && React.isValidElement(value?.icon) ? (
                            React.cloneElement(value?.icon, {
                              width: '50px',
                              height: '50px',
                              color: '#6366f1',
                            })
                          ) : (
                            <SvgSection
                              svgContent={value?.icon}
                              width={'24px'}
                              height={'24px'}
                              fill={value?.isFill ? '#6366f1' : ''}
                              stroke={value?.isFill ? '#6366f1' : ''}
                            />
                          )}
                          <span
                            class='w-full leading-relaxed text-gray-500'
                            dangerouslySetInnerHTML={{ __html: value?.description || '-' }}
                          ></span>
                          <a class='inline-flex items-center'>
                            {value?.profile &&
                            (value?.profile.startsWith('data:image/svg+xml') ||
                              value?.profile.includes('<svg')) ? (
                              <SvgSection
                                svgContent={value?.profile}
                                width={'50px'}
                                height={'50px'}
                                fill={value?.profileFill ? '#9ca3af' : ''}
                                stroke={value?.profileFill ? '#9ca3af' : ''}
                              />
                            ) : (
                              <img
                                alt='profile content'
                                className='w-12 h-12 rounded-full flex-shrink-0 object-cover object-center'
                                src={value?.profile}
                              />
                            )}
                            <span class='flex-grow flex flex-col pl-4'>
                              {value?.htmlTag === 'h1' ? (
                                <h1 class='title-font font-medium text-gray-900'>{value?.title}</h1>
                              ) : value?.htmlTag === 'h2' ? (
                                <h2 class='title-font font-medium text-gray-900'>{value?.title}</h2>
                              ) : value?.htmlTag === 'h3' ? (
                                <h3 class='title-font font-medium text-gray-900'>{value?.title}</h3>
                              ) : value?.htmlTag === 'h4' ? (
                                <h4 class='title-font font-medium text-gray-900'>{value?.title}</h4>
                              ) : value?.htmlTag === 'h5' ? (
                                <h5 class='title-font font-medium text-gray-900'>{value?.title}</h5>
                              ) : value?.htmlTag === 'h6' ? (
                                <h6 class='title-font font-medium text-gray-900'>{value?.title}</h6>
                              ) : value?.htmlTag === 'div' ? (
                                <div class='title-font font-medium text-gray-900'>
                                  {value?.title}
                                </div>
                              ) : value?.htmlTag === 'p' ? (
                                <p class='title-font font-medium text-gray-900'>{value?.title}</p>
                              ) : value?.htmlTag === 'span' ? (
                                <span class='title-font font-medium text-gray-900'>
                                  {value?.title}
                                </span>
                              ) : (
                                ''
                              )}

                              <span class='text-gray-500 text-sm'>{value?.role}</span>
                            </span>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </ReactDragListView>
        );
      })}
      <div className='absolute -top-8 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
        <button onClick={(e) => handleFlexBoxClick(e, 'testimonial 1')} className='mr-2'>
          +
        </button>
        <button onClick={() => removeSection(sectionIndex, 'testimonial 1')} className='ml-2'>
          ×
        </button>
      </div>
    </div>
  );
};

export default FirstStaticTestimonialSection;
