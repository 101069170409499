import { Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';
import IconList from './IconList';
import SvgSection from '../../../../controllers/SvgSection';
import { t } from 'i18next';

const RatingSettingSection = ({
  section_id,
  ratingValue,
  HandleRatingChange,
  setIsOpen,
  isOpen,
  handleRatingIconSelectValue,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const fileInputRef = useRef(null);

  const handleRatingValue = (e) => {
    const value = e.target.value;
    const type = e.target.name;
    HandleRatingChange(section_id, type, JSON.parse(value));
  };

  const handleIconSelect = (iconComponent) => {
    handleRatingIconSelectValue(section_id, iconComponent);
  };

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert('No file selected. Please select an SVG file.');
      return;
    }
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;

        const isValidSvg = /<svg[\s\S]*<\/svg>/i.test(imageUrl);
        if (!isValidSvg) {
          alert('Invalid SVG content. Please upload a valid SVG file.');
          return;
        }

        handleRatingIconSelectValue(section_id, imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  return (
    <div className='w-full mb-4 mt-2'>
      <div className='w-full mb-4'>
        <label htmlFor='name'>InitialRate</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='number'
          id='initialRate'
          name='initialRate'
          placeholder='Enter your name...'
          onChange={handleRatingValue}
          value={ratingValue?.initialRate || ''}
        />
      </div>
      <div className='w-full mb-4'>
        <label htmlFor='name'>TotalRate</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='number'
          id='totalRate'
          name='totalRate'
          placeholder='Enter your title...'
          onChange={handleRatingValue}
          value={ratingValue?.totalRate || ''}
        />
      </div>
      <div>
        <label htmlFor='text'>Icon</label>
        <div
          className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {ratingValue?.icon &&
            (React.isValidElement(ratingValue?.icon) ? (
              React.cloneElement(ratingValue?.icon, { width: '100px', height: '100px' })
            ) : (
              <SvgSection
                svgContent={ratingValue?.icon}
                width='100px'
                height='100px'
                fill='#000000'
                stroke='#000000'
              />
            ))}

          {isHovered && (
            <>
              <button
                className='text-sm absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleIconClick('iconList')}
              >
                Icon library
              </button>
              <button
                className='text-sm absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleSvgClick()}
              >
                SVG Icon
              </button>
            </>
          )}
        </div>
      </div>

      <div className='w-full mb-4 mt-2'>
        <div className='flex justify-between items-center'>
          <label>Alignment</label>
          <div className='flex space-x-2'>
            <Tooltip title='Left' arrow>
              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                onClick={() => {
                  HandleRatingChange(section_id, 'alignment', 'left');
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  class='bi bi-text-left'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5'
                  />
                </svg>
              </button>
            </Tooltip>
            <Tooltip title='Center' arrow>
              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                onClick={() => {
                  HandleRatingChange(section_id, 'alignment', 'center');
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  class='bi bi-text-center'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5'
                  />
                </svg>
              </button>
            </Tooltip>
            <Tooltip title='Right' arrow>
              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                onClick={() => {
                  HandleRatingChange(section_id, 'alignment', 'right');
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  class='bi bi-text-right'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5'
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>

      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default RatingSettingSection;
