import { FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import MuiSwitch from '../../../../../controllers/MuiSwitch';
import module from '../../../../../controllers/QuillToolBar';
import SvgSection from '../../../../../controllers/SvgSection';
import IconList from '../IconList';

const StaticFifthContentSection = ({
  section_id,
  section_type,
  handleFifthContentTagChange,
  sectionContent,
  headingLevel,
  handleFifthContentTitleValueChange,
  handleFifthContentDescriptionChange,
  descriptionContent,
  sectionButtonContent,
  handleFifthContentButtonValueChange,
  handleFifthContentSubButtonValueChange,
  sectionSubButtonContent,
  sectionIconContent,
  handleFifthContentIconValueChange,
  sectionIconLinkContent,
  handleFifthContentIconLinkValueChange,
  sectionIconFillContent,
  handleFifthContentIconFillValueChange,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [buttonTitle, setButtonTitle] = useState(sectionButtonContent);
  const [subButton, setSubButton] = useState(sectionSubButtonContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [iconValue, setIconValue] = useState(sectionIconContent);
  const [iconLinkValue, setIconLinkValue] = useState(sectionIconLinkContent);
  const [iconFillValue, setIconFillValue] = useState(sectionIconFillContent);

  const ShortquillRef = useRef(null);
  const fileInputRef = useRef(null);

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  console.log(descriptionContent, 'descriptionContent=-=-=-=->>>');

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleFifthContentTitleValueChange(section_id, value);
  };

  useEffect(() => {
    setGroupTitle(sectionContent);
    setSubButton(sectionSubButtonContent);
    setShortDescription(descriptionContent);
    setSelectedHeadingLevel(headingLevel);
    setIconValue(sectionIconContent);
    setIconLinkValue(sectionIconLinkContent);
    setIconFillValue(sectionIconFillContent);
  }, [
    sectionContent,
    sectionSubButtonContent,
    descriptionContent,
    headingLevel,
    sectionIconContent,
    sectionIconLinkContent,
    sectionIconFillContent,
  ]);


  const onButtonContentChange = (e) => {
    const newValue = e.target.value;
    setButtonTitle(newValue);
    handleFifthContentButtonValueChange(section_id, newValue);
  };

  const onSubTitleContentChange = (e) => {
    const newValue = e.target.value;
    setSubButton(newValue);
    handleFifthContentSubButtonValueChange(section_id, newValue);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleFifthContentTagChange(section_id, newTag);
  };

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleFifthContentTitleValueChange(section_id, newValue);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleFifthContentDescriptionChange(section_id, value);
  };

  console.log(groupTitle, 'groupTitle=-=-=>>>');

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    setIconValue(iconComponent);
    handleFifthContentIconValueChange(section_id, iconComponent);
    setIsOpen(false);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        setIconValue(imageUrl);
        handleFifthContentIconValueChange(section_id, imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  const handleIconLinkChange = (event) => {
    const { name, value } = event.target;
    setIconLinkValue(value);
    handleFifthContentIconLinkValueChange(section_id, value);
  };

  const handleIconFill = (e) => {
    const { checked } = e.target;
    setIconFillValue(checked);
    handleFifthContentIconFillValueChange(section_id, checked);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_title')}</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>{t('lang_title_hTMLTag')}</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div>
        <label>{t('lang_Description')}</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          // value={shortdescription?.length ? shortdescription.split(' ').slice(0, 280).join(' ') : ''}
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>

      <div className='mt-4'>
        <label>{t('lang_button_title')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={buttonTitle}
          onChange={onButtonContentChange}
        />
      </div>

      <div className='mt-4'>
        <label>{t('lang_link_button')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={subButton}
          onChange={onSubTitleContentChange}
        />
      </div>

      <div className='mt-2'>
        <label htmlFor='text'>{t('lang_icon')}</label>
        <div
          className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {iconValue &&
            (React.isValidElement(iconValue) ? (
              React.cloneElement(iconValue, { width: '22px', height: '22px' })
            ) : (
              <SvgSection svgContent={iconValue} width='22px' height='22px' />
            ))}

          {isHovered && (
            <>
              <button
                className='text-sm absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleIconClick('iconList')}
              >
                {t('lang_icon_library')}
              </button>
              <button
                className='text-sm absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleSvgClick()}
              >
                {t('lang_svg_icon')}
              </button>
            </>
          )}
        </div>

        <div className='w-full mb-4 mt-2'>
          <label htmlFor='text capitalize'>{t('lang_link')}</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
            type='link'
            id='link'
            name='link'
            placeholder='Enter URL...'
            value={iconLinkValue || ''}
            onChange={handleIconLinkChange}
          />
        </div>

        <div className='w-full mb-4 mt-2'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>{t('lang_icon_fill')}</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={iconFillValue || false}
                  onChange={handleIconFill}
                  name='isFill'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>
      </div>

      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default StaticFifthContentSection;
