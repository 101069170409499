import React from 'react';
import AddElementsStyleContainer from './AddElementsStyleContainer';

const AddElementsStyleSection = ({
  editSection,
  setSectionAdd,
  sectionAdd,
  handleAddSection,
  activeIndex,
  gridValue,
  dragItems,

  btnLoading,
  handleFlexBoxClick,
  openAddSection,
  setGridValue,
  setOpenTab,
  setClickedItem,
  handleItemClick
}) => {
  return (
    <div>
      <AddElementsStyleContainer
        handleAddSection={handleAddSection}
        activeIndex={activeIndex}
        editSection={editSection}
        gridValue={gridValue}
        dragItems={dragItems}
        btnLoading={btnLoading}
        handleFlexBoxClick={handleFlexBoxClick}
        openAddSection={openAddSection}
        setGridValue={setGridValue}
        setOpenTab={setOpenTab}
        setClickedItem={setClickedItem}
        handleItemClick={handleItemClick}
      />
    </div>
  );
};

export default AddElementsStyleSection;
