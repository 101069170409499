import React from 'react';
import { mapPositionToCSS } from '../../controllers/DynamicEditorSwitchCase';

const DividerSection = ({ obj }) => {
  return (
    <>
      <div
        key={obj?.id}
        id={obj?.id}
        className={`flex justify-${obj?.content?.align} items-center m-4`}
        style={{
          paddingTop: `${obj?.style?.padding_top}px`,
          paddingBottom: `${obj?.style?.padding_bottom}px`,
          paddingLeft: `${obj?.style?.padding_left}px`,
          paddingRight: `${obj?.style?.padding_right}px`,
          marginTop: `${obj?.style?.margin_top}px`,
          marginBottom: `${obj?.style?.margin_bottom}px`,
          marginLeft: `${obj?.style?.margin_left}px`,
          marginRight: `${obj?.style?.margin_right}px`,
          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
          justifyContent:
            obj?.style?.divider_align === 'end'
              ? 'flex-end'
              : obj?.style?.divider_align === 'start'
              ? 'flex-start'
              : obj?.style?.divider_align === 'center'
              ? 'center'
              : undefined,
        }}
      >
        <div
          className='flex items-center'
          style={{
            width: `${obj?.content?.width}%`,
            height: `${obj?.style?.height}${obj?.style?.height_unit}`,
            maxHeight: `${obj?.style?.maxHeight}${obj?.style?.height_unit}`,
            paddingTop: `${obj?.style?.gap}px`,
            paddingBottom: `${obj?.style?.gap}px`,
            transform: `rotate(${obj?.style?.divider_rotate}deg)`,
            background:
              obj?.style?.background_gradient === 'gradient'
                ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                : obj?.style?.background_image
                ? `url(${obj?.style?.background_image})`
                : obj?.style?.background,
            backgroundRepeat: obj?.style?.background_repeat,
            backgroundAttachment: obj?.style?.background_attachment,
            objectFit: obj?.style?.background_fit,
            backgroundSize:
              obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                ? obj?.style?.background_fit
                : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
            backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
          }}
        >
          <hr
            className={`border-black border-${obj?.content?.style}`}
            style={{
              flexGrow: 1,
              borderWidth: `${obj?.style?.divider_weight}px`,
              borderColor: `${obj?.style?.divider_color}`,
            }}
          />
          {obj?.content?.element === 'text' ? (
            <span
              className='mx-2'
              style={{
                color: `${obj?.style?.divider_color}`,
              }}
            >
              {obj?.content?.text}
            </span>
          ) : obj?.content?.element === 'upload' ? (
            // <div dangerouslySetInnerHTML={{ __html: obj?.content?.icon }} />
            ''
          ) : obj?.content?.element === 'none' ? (
            ''
          ) : (
            ''
          )}

          <hr
            className={`border-black border-${obj?.content?.style}`}
            style={{
              flexGrow: 1,
              borderWidth: `${obj?.style?.divider_weight}px`,
              borderColor: `${obj?.style?.divider_color}`,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DividerSection;
