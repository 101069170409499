import { t } from 'i18next';
import React from 'react';

const GoogleMapSettingSection = ({ section_id, handleLocationChange, locationValue }) => {
  const handleLocationValueChange = (e) => {
    const selectLink = e.target.value;
    const type = e.target.name;
    handleLocationChange(section_id, selectLink, type);
  };

  return (
    <div>
      <div className='w-full mb-4 mt-2'>
        <label htmlFor='group title'>Location</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='group title'
          name='location'
          placeholder='Enter location here...'
          onChange={handleLocationValueChange}
          value={locationValue?.location}
        />
      </div>
      <div className='w-full mb-4 mt-2'>
        <label htmlFor='group title'>Latitude</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='group title'
          name='latitude'
          placeholder='Enter Latitude  here...'
          onChange={handleLocationValueChange}
          value={locationValue?.latitude}
        />
      </div>
      <div className='w-full mb-4 mt-2'>
        <label htmlFor='group title'>Longitude</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='group title'
          name='longitude'
          placeholder='Enter Longitude  here...'
          onChange={handleLocationValueChange}
          value={locationValue?.longitude}
        />
      </div>
    </div>
  );
};

export default GoogleMapSettingSection;
