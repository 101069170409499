import { Eye, EyeOff } from 'feather-icons-react/build/IconComponents';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';

function NewPassword() {
  const [password, setPassword] = useState({
    npassword: '',
    cpassword: '',
  });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const handleChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const { npassword, cpassword } = password;
  const [neyeopen, setnEyeopen] = useState(false);
  const [ceyeopen, setcEyeopen] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.npassword !== password.cpassword) {
      setErr(true);
    } else {
      try {
        const payload = encryptData({
          new_password: npassword,
          confirm_password: cpassword,
        })
        const HS = await API.post(`/admin/user/forgot-password/${id}`, payload);
        navigate('/');
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='flex bg h-screen w-screen items-center justify-center'>
        <div
          style={{
            boxShadow: `0 20px 45px 0 rgba(3, 3, 3, 0.2)`,
          }}
          className='w-full form sm:w-8/12 xl:w-1/3 my-5 mx-5 bg-white p-5 sm:p-7 rounded-[6px]'
        >
          <div className='mb-2 text-center'>
            <img
              className='mx-auto h-6 sm:h-7 w-auto'
              src='https://cdn.shopeasy.ai/shopeasy/icons/shop-easy-black.png'
              alt='Your Company'
            />
            <span className='mt-4 text-center block text-base sm:text-xl font-medium text-gray-900'>
              Change your password
            </span>
            <p className=' text-center text-xs sm:text-[13px] text-gray-500'>
              Update your login credentials for added security.
            </p>
          </div>
          <hr className='my-4 border-slate-300'></hr>
          <div className='flex flex-col space-y-4'>
            <div>
              <p className='capitalize text-gray-600 text-[13px] mb-1 font-medium'>New password</p>
              <div className='relative'>
                <input
                  id='npassword'
                  name='npassword'
                  type={neyeopen ? 'text' : 'password'}
                  value={password.npassword}
                  className='w-full py-2 border border-slate-300 rounded-[6px] px-3 focus:outline-none'
                  placeholder='Enter new password'
                  onChange={(e) => handleChange(e)}
                />
                {!neyeopen ? (
                  <Eye
                    className='absolute top-[50%] -translate-y-[50%] right-3 w-4 h-4 text-slate-400 z-10'
                    onClick={() => {
                      setnEyeopen(!neyeopen);
                    }}
                  />
                ) : (
                  <EyeOff
                    className='absolute top-[50%] -translate-y-[50%] right-3 w-4 h-4 text-slate-400 z-10'
                    onClick={() => {
                      setnEyeopen(!neyeopen);
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <p className='capitalize text-gray-600 text-[13px] mb-1 font-medium'>
                Confirm password
              </p>
              <div className='relative'>
                <input
                  id='cpassword'
                  name='cpassword'
                  type={ceyeopen ? 'text' : 'password'}
                  value={password.cpassword}
                  className='w-full py-2 border border-slate-300 rounded-[6px] px-3 focus:outline-none'
                  placeholder='Enter confirm password'
                  onChange={(e) => handleChange(e)}
                />
                {!ceyeopen ? (
                  <Eye
                    className='absolute top-[50%] -translate-y-[50%] right-3 w-4 h-4 text-slate-400 z-10'
                    onClick={() => {
                      setcEyeopen(!ceyeopen);
                    }}
                  />
                ) : (
                  <EyeOff
                    className='absolute top-[50%] -translate-y-[50%] right-3 w-4 h-4 text-slate-400 z-10'
                    onClick={() => {
                      setcEyeopen(!ceyeopen);
                    }}
                  />
                )}
              </div>
              {err ? (
                <p className='m-0 text-red-500 text-xs font-medium'>Password must be same</p>
              ) : (
                ''
              )}
            </div>
            {cpassword === '' || npassword === '' ? (
              <button
                disabled
                className='bg-btn py-2 rounded-[6px] opacity-50 cursor-not-allowed text-white text-sm transition-all duration-300 inline-flex space-x-2 items-center justify-center'
              >
                Change password
              </button>
            ) : (
              <button
                className='btn text-white text-sm transition-all duration-300 inline-flex space-x-2 items-center justify-center'
                onClick={(e) => handleSubmit(e)}
              >
                Change password
              </button>
            )}
          </div>
          <div className='text-center'>
            <Link to='/' className='text-link font-medium text-sm hover:underline'>
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewPassword;
