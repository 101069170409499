import React from 'react';
import EditAccordianStyleContainer from './EditAccordianStyleContainer';

const EditAccordianStyleSection = ({
  previewImage,
  accordianDesign,
  setAccordianDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditAccordianStyleContainer
        accordianDesign={accordianDesign}
        setAccordianDesign={setAccordianDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditAccordianStyleSection;
