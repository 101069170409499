import React, { useRef, useState } from 'react';
import GenerateLiveEditiorTitle from '../../../../controllers/GenerateLiveEditiorTitle';
import IconList from './IconList';
import SvgSection from '../../../../controllers/SvgSection';
import { t } from 'i18next';

const ImageIconSettingSection = ({
  section_id,
  sectionContent,
  section_type,
  handleImageIconTitleChange,
  handleImageIconHtmlTagChange,
  headingLevel,
  sectionContentDescription,
  handleImageIconDescriptionChange,
  handleImageIconImageLinkChange,
  currentImageLink,
  imageIcon,
  setIsOpen,
  isOpen,
  handleIconBoxImageValue,
}) => {
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [groupDescription, setGroupDescription] = useState(sectionContentDescription);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [isHovered, setIsHovered] = useState(false);
  const fileInputRef = useRef(null);

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    handleIconBoxImageValue(section_id, iconComponent);
  };

  const dropDownTagValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
  ];

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleImageIconTitleChange(section_id, value);
  };
  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleImageIconTitleChange(section_id, newValue);
  };
  const onContentDescriptionChange = (e) => {
    const newValue = e.target.value;
    setGroupDescription(newValue);
    handleImageIconDescriptionChange(section_id, newValue);
  };
  const handleHeadingChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleImageIconHtmlTagChange(section_id, newTag);
  };
  const handleLinkChange = (e) => {
    const selectLink = e.target.value;
    handleImageIconImageLinkChange(section_id, selectLink);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconBoxImageValue(section_id, imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  return (
    <div>
      <div
        className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {imageIcon?.icon &&
          (React.isValidElement(imageIcon?.icon) ? (
            React.cloneElement(imageIcon?.icon, { width: '100px', height: '100px' })
          ) : (
            <SvgSection svgContent={imageIcon?.icon} width='100px' height='100px' />
          ))}

        {isHovered && (
          <>
            <button
              className='text-sm absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
              onClick={() => handleIconClick('iconList')}
            >
              Icon library
            </button>
            <button
              className='text-sm absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
              onClick={() => handleSvgClick()}
            >
              SVG Icon
            </button>
          </>
        )}
      </div>

      <div className='mt-2'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
      </div>
      <div className='w-full'>
        <label>HTML Tag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingChange}
          >
            {dropDownTagValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='mt-2'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Description</span>
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupDescription}
          onChange={onContentDescriptionChange}
          rows={6}
        />
      </div>
      <div className='w-full mb-4 mt-2'>
        <label htmlFor='group title'>Link</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='group title'
          name='group title'
          placeholder='Paste URL here...'
          onChange={handleLinkChange}
          value={currentImageLink}
        />
      </div>
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>

      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default ImageIconSettingSection;
