import React from 'react';
import { mapPositionToCSS } from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';
import ReactDragListView from 'react-drag-listview';

const ThirdStaticFeatureSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
  handleFlexBoxClick,
  removeSection,
}) => {
  const filteredSections = sectionAdd?.thirdFeatureComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'feature 3',
  );

  console.log(filteredSections, 'filteredSections=-=-=->>');

  return (
    <div className='w-full relative group'>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.sectionId &&
          editSection?.section_index === itemIndex;

        return (
          <ReactDragListView {...dragProps}>
            <section
              id={obj?.sectionId}
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.sectionId, obj?.blockName, itemIndex),
                  openAddSection(e, 'feature 3', 'setting');
              }}
            >
              <div
                class='container mx-auto flex flex-wrap'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden'>
                  <img
                    alt='feature'
                    class='object-cover object-center h-full w-full'
                    src={obj?.content?.image}
                    style={{
                      objectFit: obj?.content?.objectFit,
                    }}
                  />
                </div>
                <div class='flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center'>
                  {obj?.content?.item?.map((value) => {
                    return (
                      <div class='flex flex-col mb-10 lg:items-start items-center'>
                        <div class='w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5'>
                          {value?.titleIcon && React.isValidElement(value?.titleIcon) ? (
                            React.cloneElement(value?.titleIcon, {
                              width: '50px',
                              height: '50px',
                              color: '#6366f1',
                            })
                          ) : (
                            <SvgSection
                              svgContent={value?.titleIcon}
                              width={'24px'}
                              height={'24px'}
                              fill={value?.titleIconFill ? '#6366f1' : ''}
                              stroke={value?.titleIconFill ? '#6366f1' : ''}
                            />
                          )}
                        </div>
                        <div class='flex-grow'>
                          {value?.htmlTag === 'h1' ? (
                            <h1 class='mb-3'>{value?.title}</h1>
                          ) : value?.htmlTag === 'h2' ? (
                            <h2 class='mb-3'>{value?.title}</h2>
                          ) : value?.htmlTag === 'h3' ? (
                            <h3 class='mb-3'>{value?.title}</h3>
                          ) : value?.htmlTag === 'h4' ? (
                            <h4 class='mb-3'>{value?.title}</h4>
                          ) : value?.htmlTag === 'h5' ? (
                            <h5 class='mb-3'>{value?.title}</h5>
                          ) : value?.htmlTag === 'h6' ? (
                            <h6 class='mb-3'>{value?.title}</h6>
                          ) : value?.htmlTag === 'div' ? (
                            <div class='mb-3'>{value?.title}</div>
                          ) : value?.htmlTag === 'p' ? (
                            <p class='mb-3'>{value?.title}</p>
                          ) : value?.htmlTag === 'span' ? (
                            <span class='mb-3'>{value?.title}</span>
                          ) : (
                            ''
                          )}
                          <span
                            dangerouslySetInnerHTML={{ __html: value?.description || '-' }}
                          ></span>
                          <a class='mt-3 text-indigo-500 inline-flex items-center gap-x-1'>
                            {value?.button}
                            {value?.linkIcon && React.isValidElement(value?.linkIcon) ? (
                              React.cloneElement(value?.linkIcon, {
                                width: '50px',
                                height: '50px',
                                color: '#6366f1',
                              })
                            ) : (
                              <SvgSection
                                svgContent={value?.linkIcon}
                                width={'24px'}
                                height={'24px'}
                                fill={value?.linkIconFill ? '#6366f1' : ''}
                                stroke={value?.linkIconFill ? '#6366f1' : ''}
                              />
                            )}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </ReactDragListView>
        );
      })}
      <div className='absolute -top-8 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
        <button onClick={(e) => handleFlexBoxClick(e, 'feature 3')} className='mr-2'>
          +
        </button>
        <button onClick={() => removeSection(sectionIndex, 'feature 3')} className='ml-2'>
          ×
        </button>
      </div>
    </div>
  );
};

export default ThirdStaticFeatureSection;
