import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import module, { formatCode } from '../../../../controllers/QuillToolBar';
import { t } from 'i18next';

const TextEditorSettingSection = ({
  handleTextEditorChange,
  section_id,
  textEditorValue,
}) => {
  const ShortquillRef = useRef(null);
  const [shortdescription, setShortDescription] = useState('');
  const [meta, setMeta] = useState({
    meta_description: '',
  });

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleTextEditorChange(section_id, value);
  };

  const formats = [
    'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'indent', 'direction', 'clean'
  ];

  return (
    <div>
      <ReactQuill
        modules={module}
        ref={ShortquillRef}
        // formats={formats}
        className='w-full h-auto table-text rounded-[6px]'
        theme='snow'
        name='description'
        // value={shortdescription?.length ? shortdescription.split(' ').slice(0, 280).join(' ') : ''}
        value={textEditorValue}
        onChange={handleShortDescQuillChange}
        placeholder='Enter description'
        onBlur={() => {
          setMeta((prevMeta) => ({
            ...prevMeta,
            meta_description:
              shortdescription
                ?.replace(/<[^>]*>/g, ' ')
                ?.split(' ')
                .slice(0, 50)
                .join(' ') || '',
          }));
        }}
      />

    </div>
  );
};

export default TextEditorSettingSection;
