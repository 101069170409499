import React from 'react';
import EditRatingStyleContainer from './EditRatingContainer';

const EditRatingStyleSection = ({
  previewImage,
  ratingDesign,
  setRatingDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditRatingStyleContainer
        ratingDesign={ratingDesign}
        setRatingDesign={setRatingDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditRatingStyleSection;
