import React from 'react';
import SvgSection from '../../controllers/SvgSection';
import { mapPositionToCSS } from '../../controllers/DynamicEditorSwitchCase';

const GeneralIconBox = ({ obj }) => {
  return (
    <>
      <div
        // className='flex flex-col items-center'
        key={obj?.id}
        id={obj?.id}
        style={{
          paddingTop: `${obj?.style?.padding_top}px`,
          paddingBottom: `${obj?.style?.padding_bottom}px`,
          paddingLeft: `${obj?.style?.padding_left}px`,
          paddingRight: `${obj?.style?.padding_right}px`,
          marginTop: `${obj?.style?.margin_top}px`,
          marginBottom: `${obj?.style?.margin_bottom}px`,
          marginLeft: `${obj?.style?.margin_left}px`,
          marginRight: `${obj?.style?.margin_right}px`,
          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
          background:
            obj?.style?.background_gradient === 'gradient'
              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
              : obj?.style?.background_image
              ? `url(${obj?.style?.background_image})`
              : obj?.style?.background,
          transform: `rotate(${obj?.style?.text_rotate}deg)`,
          backgroundRepeat: obj?.style?.background_repeat,
          backgroundAttachment: obj?.style?.background_attachment,
          objectFit: obj?.style?.background_fit,
          backgroundSize:
            obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
              ? obj?.style?.background_fit
              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
        }}
      >
        <div
          style={{
            flexDirection:
              obj?.style?.icon_position === 'top'
                ? 'column'
                : obj?.style?.icon_position === 'right'
                ? 'row-reverse'
                : obj?.style?.icon_position === 'left'
                ? 'row'
                : 'column',
            justifyContent:
              obj?.style?.vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.vertical_alignment === 'middle'
                ? 'center'
                : obj?.style?.vertical_alignment === 'bottom'
                ? 'flex-end'
                : '',

            display: 'flex',
            alignItems:
              obj?.style?.icon_position === 'left' || obj?.style?.icon_position === 'right'
                ? obj?.style?.vertical_alignment === 'top'
                  ? 'flex-start'
                  : obj?.style?.vertical_alignment === 'middle'
                  ? 'center'
                  : obj?.style?.vertical_alignment === 'bottom'
                  ? 'flex-end'
                  : 'center'
                : obj?.style?.content_alignment === 'left'
                ? 'flex-start'
                : obj?.style?.content_alignment === 'center'
                ? 'center'
                : obj?.style?.content_alignment === 'right'
                ? 'flex-end'
                : obj?.style?.content_alignment === 'justify'
                ? 'flex-start'
                : 'center',
          }}
        >
          <div
            style={{
              borderTop: `${obj?.style?.icon_border_top}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
              borderBottom: `${obj?.style?.icon_border_bottom}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
              borderLeft: `${obj?.style?.icon_border_left}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
              borderRight: `${obj?.style?.icon_border_right}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
              borderTopLeftRadius: `${obj?.style?.icon_border_radius_top}px`,
              borderTopRightRadius: `${obj?.style?.icon_border_radius_right}px`,
              borderBottomLeftRadius: `${obj?.style?.icon_border_radius_left}px`,
              borderBottomRightRadius: `${obj?.style?.icon_border_radius_bottom}px`,
              marginBottom:
                obj?.style?.icon_position === 'top' ? `${obj?.style?.icon_spacing}px` : '',
              marginLeft:
                obj?.style?.icon_position === 'right' ? `${obj?.style?.icon_spacing}px` : '',
              marginRight:
                obj?.style?.icon_position === 'left' ? `${obj?.style?.icon_spacing}px` : '',
            }}
          >
            {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
              React.cloneElement(obj?.content?.icon, {
                width: obj?.style?.icon_width ? `${obj.style.icon_width}px` : '50px',
                height: obj?.style?.icon_width ? `${obj.style.icon_width}px` : '50px',
              })
            ) : (
              <SvgSection
                svgContent={obj?.content?.icon}
                width={obj?.style?.width ? `${obj.style.width}${obj.style.width_unit}` : '50px'}
                height={obj?.style?.height ? `${obj.style.height}${obj.style.height_unit}` : '50px'}
                fill={obj?.style?.icon_color}
                stroke={obj?.style?.icon_color}
                maxWidth={
                  obj?.style?.maxWidth ? `${obj.style.maxWidth}${obj.style.width_unit}` : ''
                }
                maxHeight={
                  obj?.style?.maxHeight ? `${obj.style.maxHeight}${obj.style.height_unit}` : ''
                }
              />
            )}
          </div>
          <div
            style={{
              textAlign:
                obj?.style?.content_alignment === 'left'
                  ? 'left'
                  : obj?.style?.content_alignment === 'center'
                  ? 'center'
                  : obj?.style?.content_alignment === 'right'
                  ? 'right'
                  : obj?.style?.content_alignment === 'justify'
                  ? 'justify'
                  : '',
              //  marginTop: obj?.style?.image_position === 'top' ? `${obj?.style?.image_spacing}px` : ''
            }}
          >
            {obj?.content?.htmlTag === 'h1' ? (
              <div
                className='mt-6'
                style={{
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: obj?.style?.title_font_size
                    ? `${obj?.style?.title_font_size}px`
                    : '40px',
                  lineHeight: obj?.style?.title_font_height
                    ? `${obj?.style?.title_font_height}px`
                    : '45px',
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {obj?.content?.title}
              </div>
            ) : obj?.content?.htmlTag === 'h2' ? (
              <div
                className='mt-6'
                style={{
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: obj?.style?.title_font_size
                    ? `${obj?.style?.title_font_size}px`
                    : '36px',
                  lineHeight: obj?.style?.title_font_height
                    ? `${obj?.style?.title_font_height}px`
                    : '41px',
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {obj?.content?.title}
              </div>
            ) : obj?.content?.htmlTag === 'h3' ? (
              <div
                className='mt-6'
                style={{
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: obj?.style?.title_font_size
                    ? `${obj?.style?.title_font_size}px`
                    : '32px',
                  lineHeight: obj?.style?.title_font_height
                    ? `${obj?.style?.title_font_height}px`
                    : '37px',
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {obj?.content?.title}
              </div>
            ) : obj?.content?.htmlTag === 'h4' ? (
              <div
                className='mt-6'
                style={{
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: obj?.style?.title_font_size
                    ? `${obj?.style?.title_font_size}px`
                    : '26px',
                  lineHeight: obj?.style?.title_font_height
                    ? `${obj?.style?.title_font_height}px`
                    : '33px',
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {obj?.content?.title}
              </div>
            ) : obj?.content?.htmlTag === 'h5' ? (
              <div
                className='mt-6'
                style={{
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: obj?.style?.title_font_size
                    ? `${obj?.style?.title_font_size}px`
                    : '22px',
                  lineHeight: obj?.style?.title_font_height
                    ? `${obj?.style?.title_font_height}px`
                    : '29px',
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {obj?.content?.title}
              </div>
            ) : obj?.content?.htmlTag === 'h6' ? (
              <div
                className='mt-6'
                style={{
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: obj?.style?.title_font_size
                    ? `${obj?.style?.title_font_size}px`
                    : '18px',
                  lineHeight: obj?.style?.title_font_height
                    ? `${obj?.style?.title_font_height}px`
                    : '25px',
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {obj?.content?.title}
              </div>
            ) : obj?.content?.htmlTag === 'span' ? (
              <span
                className='mt-6'
                style={{
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: `${obj?.style?.title_font_size}px`,
                  lineHeight: `${obj?.style?.title_font_height}px`,
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {obj?.content?.title}
              </span>
            ) : obj?.content?.htmlTag === 'div' ? (
              <div
                className='mt-6'
                style={{
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: `${obj?.style?.title_font_size}px`,
                  lineHeight: `${obj?.style?.title_font_height}px`,
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {obj?.content?.title}
              </div>
            ) : (
              <p
                className='mt-6'
                style={{
                  fontWeight: obj?.style?.title_font_weight,
                  fontSize: `${obj?.style?.title_font_size}px`,
                  lineHeight: `${obj?.style?.title_font_height}px`,
                  color: obj?.style?.title_font_color,
                  textAlign: obj?.style?.title_font_align,
                  fontStyle: obj?.style?.title_font_style,
                  textDecoration: obj?.style?.title_font_decoration,
                  textTransform: obj?.style?.title_font_capitalize,
                  wordBreak: obj?.style?.title_font_break,
                  textWrap: obj?.style?.title_font_wrap,
                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                }}
              >
                {obj?.content?.title}
              </p>
            )}
            <p
              // className='mt-6'
              style={{
                fontWeight: obj?.style?.desc_font_weight,
                fontSize: `${obj?.style?.desc_font_size}px`,
                lineHeight: `${obj?.style?.desc_font_height}px`,
                color: obj?.style?.desc_font_color,
                textAlign: obj?.style?.desc_font_align,
                fontStyle: obj?.style?.desc_font_style,
                textDecoration: obj?.style?.desc_font_decoration,
                textTransform: obj?.style?.desc_font_capitalize,
                wordBreak: obj?.style?.desc_font_break,
                textWrap: obj?.style?.desc_font_wrap,
                letterSpacing: `${obj?.style?.desc_letter_spacing}px`,
                wordSpacing: `${obj?.style?.desc_word_spacing}px`,
                WebkitTextStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                textStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                textShadow: `${obj?.style?.desc_shadow_horizontal}px ${obj?.style?.desc_shadow_vertical}px ${obj?.style?.desc_shadow_blur}px ${obj?.style?.desc_shadow_color}`,
                marginTop: `${obj?.style?.content_spacing}px`,
              }}
            >
              {obj?.content?.description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralIconBox;
