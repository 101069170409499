import { Dialog, Skeleton } from '@mui/material';
import { ChevronDown, PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../API';
import { Extention } from '../../../App';
import { staticCartData } from '../../../Assets/StaticData/staticCartData';
import { staticProductFaq } from '../../../Assets/StaticData/staticProductFaq';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import { clientData, GetClient } from '../../../controllers/GetClient';
import { Transition } from '../../../controllers/Transition';
import Notification from '../../Notification';
import AddLayout from '../AddLayout';
import AnnouncementSection from '../AnnouncementBar/AnnouncementSection';
import VisualAnnounccementBar from '../AnnouncementBar/VisualAnnounccementBar';
import EditCartPageOtherDetailsContainer from '../EditSection/CartPage/EditCartPageStyle/EditCartPageOtherDetailsSection/EditCartPageOtherDetailsContainer';
import EditCartPageTableStyleContainer from '../EditSection/CartPage/EditCartPageStyle/EditCartPageTableStyleSection/EditCartPageTableStyleContainer';
import EditCartPageTitleStyleSection from '../EditSection/CartPage/EditCartPageStyle/EditCartPageTitleStyleSection/EditCartPageTitleStyleSection';
import EditBannerSection from '../EditSection/EditBannerSection';
import EditBannerStyleSection from '../EditSection/EditBannerStyleSection/EditBannerStyleSection';
import EditCmsOfferStyleSection from '../EditSection/EditCmsOfferStyleSection/EditCmsOfferStyleSection';
import EditCmsSection from '../EditSection/EditCmsSection';
import EditCartOtherDetails from '../EditSection/ProductPage/EditCartOtherDetails';
import EditCartTableSetting from '../EditSection/ProductPage/EditCartTableSetting';
import EditCartTitleSetting from '../EditSection/ProductPage/EditCartTitleSetting';
import FooterSection from '../FooterSection';
import HeaderSection from '../HeaderSection';
import CartPageSectionList from '../SectionList/CartPageSectionList';
import HomePageSectionList from '../SectionList/HomePageSectionList';
import Footer from '../VisualLayout/Footer';
import StoreNavbar from '../VisualLayout/StoreNavbar';
import CartPageSection from './CartpageSection';
import HomePageSection from './HomePageSection';
import DynamicStateAndEvent from '../../../controllers/DynamicStateAndEvent';

const CartPageEditor = ({
  commonSetting,
  colors,
  cartPageData,
  selectCartSection,
  // cartSection,
}) => {
  const [cartData, setCartData] = useState(staticCartData);
  const [customFaq, setCustomFaq] = useState(staticProductFaq);
  const [isHovered, setIsHovered] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isNotification, setIsNotification] = useState(false);
  const [pending, setPending] = useState(true);
  const [sectionSelect, setSectionSelect] = useState('');
  const [editProductOtherSection, setEditProductOtherSection] = useState('');
  const { installedExtensions } = useContext(Extention);
  const [editSection, setEditSection] = useState({
    section_type: '',
    section_id: '',
    section_index: '',
  });
  const [filterId, setFilterId] = useState(null);
  const [hoverId, setHoverId] = useState(null);
  const [subSecId, setSubSecId] = useState(null);
  const [activeIndex, setActiveIndex] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [gridValue, setGridValue] = useState();
  const [elementorId, setElementorId] = useState(null);
  const [prevSecId, setPrevSecId] = useState(null);

  const ultimatorKitExtension = installedExtensions.find(
    (extension) => extension.extension_name === 'Ultimator Kit',
  );

  const handleSectionSelect = (e, id, type, index) => {
    setSectionSelect({
      section_id: id,
      section_type: type,
      section_index: index,
    });
  };

  const [getLayout, setGetLayout] = useState([]);
  const cartSection = [
    { section_name: 'cart title', section_slug: 'title' },
    { section_name: 'cart table', section_slug: 'tableDetails' },
    { section_name: 'cart other section', section_slug: 'otherDetails' },
  ];

  const [bannerDesign, setBannerDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [cmsOfferDesign, setCmsOfferDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [visualData, setVisualData] = useState({
    product: null,
    blog: null,
    category: null,
    manufacture: null,
    reassurance: null,
    cmsoffer: null,
    slider: null,
    banner: null,
  });

  const [isAddSection, setIsAddSection] = useState(false);
  const closeAddSection = () => {
    setIsAddSection(false);
  };

  const [openTab, setOpenTab] = useState('setting');
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  const [cartTitleSectionDesign, setCartTitleSectionDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: 'px',
    height_unit: 'px',
    min_width_unit: 'px',
    min_height_unit: 'px',
    max_width_unit: 'px',
    max_height_unit: 'px',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: 'auto',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: 'none',
    border_width: '',
    border_color: '#111111',
  });

  const [cartTableSectionDesign, setCartTableSectionDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: 'px',
    height_unit: 'px',
    min_width_unit: 'px',
    min_height_unit: 'px',
    max_width_unit: 'px',
    max_height_unit: 'px',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: 'auto',
    border_style: 'none',
    border_width: '',
    border_color: '#111111',
  });

  const [cartOtherDetailsDesign, setCartOtherDetailsDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: 'px',
    height_unit: 'px',
    min_width_unit: 'px',
    min_height_unit: 'px',
    max_width_unit: 'px',
    max_height_unit: 'px',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [cartPageDetails, setCartPageDetails] = useState({
    showCartName: 'Cart',
    showCartTitle: true,
    showCartTable: true,
    showCartOtherDetails: true,
  });
  const handleEditCartPageData = (value) => {
    setCartPageDetails(value);
  };

  const handleUpdateSetting = async () => {
    try {
      const payload = encryptData({
        clientInfo: {
          cart_page_setting_layout: JSON.stringify(cartPageDetails),
        },
      });
      await API.put('/admin/client/updateClient', payload);
      setIsNotification(true);
      setNotificationMsg('Setting Updated Successfully!');
      setSeverity('success');
      GetClient();
    } catch (error) {
      console.log(error);
    }
  };
  const getSectionData = (value) => {
    if (value === 'call layout') {
      handleGetLayout();
      setVisualData({
        product: null,
        blog: null,
        category: null,
        manufacture: null,
        reassurance: null,
        cmsoffer: null,
        slider: null,
        banner: null,
      });
      setSectionSelect({ section_id: '', section_type: '' });
      setOpen(false);
    } else if (value === 'call common setting') {
      getSettingData();
    } else if (value === 'get client') {
      GetClient();
    } else {
      if (sectionSelect?.section_type === 'product') {
        setVisualData({
          ...visualData,
          product: value,
        });
      } else if (sectionSelect?.section_type === 'blog') {
        setVisualData({
          ...visualData,
          blog: value,
        });
      } else if (sectionSelect?.section_type === 'category') {
        setVisualData({
          ...visualData,
          category: value,
        });
      } else if (sectionSelect?.section_type === 'manufacture') {
        setVisualData({
          ...visualData,
          manufacture: value,
        });
      } else if (sectionSelect?.section_type === 'reassurance') {
        setVisualData({
          ...visualData,
          reassurance: value,
        });
      } else if (sectionSelect?.section_type === 'cmsoffer') {
        setVisualData({
          ...visualData,
          cmsoffer: value,
        });
      } else if (sectionSelect?.section_type === 'slider') {
        setVisualData({
          ...visualData,
          slider: value,
        });
      } else if (sectionSelect?.section_type === 'banner') {
        setVisualData({
          ...visualData,
          banner: value,
        });
      }
    }
  };

  useEffect(() => {
    try {
      if (clientData && clientData?.cart_layout_css) {
        const clientDetails = JSON.parse(clientData?.cart_layout_css);
        const clientDetailsSetting = JSON.parse(clientData?.cart_page_setting_layout);

        const applyClientDetails = () => {
          setCartTitleSectionDesign(clientDetails?.cartTitleSectionDesign);
          setCartTableSectionDesign(clientDetails?.cartTableSectionDesign);
          setCartOtherDetailsDesign(clientDetails?.cartOtherDetailsDesign);
          setBannerDesign(clientDetails?.bannerDesign);
          setCmsOfferDesign(clientDetails?.cmsOfferDesign);
          setCartPageDetails(clientDetailsSetting);
        };

        applyClientDetails();
      } else {
        console.warn('clientData or cart_layout_css is undefined');
      }
    } catch (error) {
      console.error('Error parsing JSON: ', error.message);
    }
  }, []);

  const handleUpdateCss = async () => {
    const payload = {
      cartTitleSectionDesign,
      cartTableSectionDesign,
      cartOtherDetailsDesign,
      bannerDesign,
      cmsOfferDesign,
    };

    try {
      const body = encryptData({
        clientInfo: {
          cart_layout_css: JSON.stringify(payload),
        },
      });
      await API.put('/admin/client/updateClient', body);
      setIsNotification(true);
      setNotificationMsg('Style Updated Successfully!');
      setSeverity('success');
      GetClient();
    } catch (error) {
      console.log(error);
    }
  };

  const [groupHeaderData, setGroupHeaderData] = useState();
  const isChangeHeader = (value) => {
    setGroupHeaderData(value);
  };
  const [groupFooterData, setGroupFooterData] = useState();
  const isChangeFooter = (value) => {
    setGroupFooterData(value);
  };
  const [announcement, setAnnouncement] = useState();
  const handleChangeAnnouncement = (value) => {
    setAnnouncement(value);
  };

  const { sectionAdd, setSectionAdd } = DynamicStateAndEvent();

  const params = useParams();
  const handleGetLayout = async () => {
    setPending(true);
    try {
      const Apicall = await API.get(`/admin/layout/getLayout/${params?.layoutName}`);
      const result = decryptData(Apicall?.data);
      setGetLayout(result?.data?.sections);
      closeAddSection();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setPending(false);
    }
  };

  const [updateSectionSpinner, setUpdateSectionSpinner] = useState(false);
  const [loadingSections, setLoadingSections] = useState({});

  const UpdateSectionStatus = async (e, id, value) => {
    e.stopPropagation();
    setUpdateSectionSpinner(true);
    setLoadingSections((prev) => ({ ...prev, [id]: true }));
    try {
      const payload = encryptData({
        status: value,
      });
      const data = await API.put(`/admin/layout/updateSection/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        handleGetLayout();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setUpdateSectionSpinner(false);
      setLoadingSections((prev) => ({ ...prev, [id]: false }));
    }
  };

  // const handleSort = (result) => {
  //   const newSections = [...getLayout];
  //   const [removed] = newSections.splice(result?.source?.index, 1);
  //   newSections.splice(result?.destination?.index, 0, removed);
  //   newSections.forEach((section, index) => {
  //     section.sort_order = index;
  //   });
  //   setGetLayout(newSections);
  //   sortSection(result?.destination?.index, result?.draggableId);
  // };

  const handleSort = {
    onDragEnd(fromIndex, toIndex) {
      try {
        console.log('handleSort', fromIndex, toIndex);
        const newItems = [...getLayout];
        const [movedItem] = newItems?.splice(fromIndex, 1);
        newItems?.splice(toIndex, 0, movedItem);
        newItems?.forEach((section, index) => {
          section.sort_order = index;
        });
        setGetLayout(newItems);
        if (filterId) {
          sortSection(toIndex, filterId);
        }
        setFilterId(null);
        setHoverId(null);
        setDestinationId(null);
        setDropPosition(null);
      } catch (error) {
        console.error('Error in handleSort:', error);
      }
    },
    nodeSelector: '.draggable-main-item',
    handleSelector: '.draggable-main-item',
    scrollSpeed: 5,
  };

  const openAddSection = (e, value, item) => {
    e.stopPropagation();
    setGridValue(value);
    setOpenTab(item);
  };

  const handleSubItemSort = {
    onDragEnd(fromIndex, toIndex) {
      if (fromIndex === toIndex) return;
      const sectionTypes = [
        'flex_cols',
        'flex_rows',
        'flex_two_grid',
        'flex_grid_two',
        'flex_four_grid',
        'static_content-1',
        'static_content-2',
        'static_content-3',
        'flex_three_grid_three',
        'flex_four_grid_four',
        'flex_two_grid_one',
        'flex_one_grid_two',
        'flex_six_grid_six',
        'flex_five_grid',
        'flex_two_grid_two',
        'grid_two_rows',
        'grid_two_cols',
        'grid_three_cols',
        'grid_three_rows',
        'grid_two_for_two',
        'grid_three_for_three',
      ];

      let matchedItems = [];

      const newItems = getLayout?.map((elem) => {
        Object.keys(sectionAdd)?.forEach((key) => {
          if (key === gridValue) {
            const blocks = elem?.section?.[key]?.filter(
              (block) => sectionTypes?.includes(block?.type) && block?.sectionId === subSecId,
            );
            matchedItems = [...matchedItems, ...(blocks || [])];
          }
        });
        return { ...elem };
      });

      if (matchedItems?.length === 0) {
        console.log('No items matched for reordering');
        return newItems;
      }

      const reorderedItems = [...matchedItems];
      const [movedItem] = reorderedItems?.splice(fromIndex, 1);
      reorderedItems?.splice(toIndex, 0, movedItem);
      console.log('Reordered Items:', reorderedItems);

      setSectionAdd((prevState) => {
        const newState = { ...prevState };
        newState[gridValue][editSection?.section_index] = reorderedItems;
        return newState;
      });
      setGetLayout((prevLayout) => {
        const updatedLayout = prevLayout?.map((item) => {
          if (item?.section_id === reorderedItems[0]?.sectionId) {
            return {
              ...item,
              section: {
                ...item?.section,
                [gridValue]: reorderedItems,
              },
            };
          }
          return item;
        });
        return updatedLayout;
      });

      setSubSecId(null);
    },
    nodeSelector: '.draggable-subMenu-item',
    handleSelector: '.draggable-subMenu-item',
  };

  const handleSectionClick = (e, sectionIndex, itemIndex) => {
    e.stopPropagation();
    setActiveIndex(sectionIndex);
    setSelectedIndex(itemIndex);
  };

  const getPrevSecId = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setPrevSecId(id);
  };

  const sortSection = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/layout/updateSection/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    handleGetLayout();
  }, [params?.layoutName]);

  return (
    <>
      <div className='overflow-hidden relative h-[92.5%]'>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div className='relative live-editor w-full flex items-start h-full'>
          {windowWidth > 768 && windowWidth < 1280 ? (
            <div className='hidden  edit-style-section md:block xl:hidden md:w-[30%] xl:w-[16%] overflow-y-auto p-3 secondary-bg-color h-full'>
              <ul className='flex border-b items-center list-none overflow-x-auto flex-row'>
                <li className='text-center lg:mb-0'>
                  <a
                    className={
                      'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150' +
                      (openTab === 'setting'
                        ? 'text-link border-b-2 border-b-[#28282B]'
                        : ' border-b-2 table-text border-transparent')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('setting');
                    }}
                    data-toggle='tab'
                    href='#link1'
                  >
                    {t('lang_setting')}
                  </a>
                </li>
                {ultimatorKitExtension?.extension_name === 'Ultimator Kit' ? (
                  <li className='text-center me-4 lg:mb-0'>
                    <a
                      className={
                        'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                        (openTab === 'style'
                          ? 'text-link border-b-2 border-b-[#28282B]'
                          : ' border-b-2 table-text border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab('style');
                      }}
                      data-toggle='tab'
                      href='#link1'
                    >
                      {t('lang_style')}
                    </a>
                  </li>
                ) : (
                  ''
                )}
              </ul>
              {openTab === 'setting' && sectionSelect?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {sectionSelect?.section_type} {t('lang_Section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setSectionSelect('');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {sectionSelect?.section_id === 'title' ? (
                    <div>
                      <EditCartTitleSetting
                        handleUpdateSetting={handleUpdateSetting}
                        handleEditCartPageData={handleEditCartPageData}
                        cartPageDetails={cartPageDetails}
                      />
                    </div>
                  ) : sectionSelect?.section_id === 'tableDetails' ? (
                    <div>
                      <EditCartTableSetting
                        handleUpdateSetting={handleUpdateSetting}
                        handleEditCartPageData={handleEditCartPageData}
                        cartPageDetails={cartPageDetails}
                      />
                    </div>
                  ) : sectionSelect?.section_id === 'otherDetails' ? (
                    <div>
                      <EditCartOtherDetails
                        handleUpdateSetting={handleUpdateSetting}
                        handleEditCartPageData={handleEditCartPageData}
                        cartPageDetails={cartPageDetails}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={sectionSelect?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : sectionSelect?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={sectionSelect?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'style' && sectionSelect?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {sectionSelect?.section_type} {t('lang_style_section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setSectionSelect('');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {sectionSelect?.section_id === 'title' ? (
                    <div>
                      <EditCartPageTitleStyleSection
                        setCartTitleSectionDesign={setCartTitleSectionDesign}
                        cartTitleSectionDesign={cartTitleSectionDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : sectionSelect?.section_id === 'tableDetails' ? (
                    <div>
                      <EditCartPageTableStyleContainer
                        setCartTableSectionDesign={setCartTableSectionDesign}
                        cartTableSectionDesign={cartTableSectionDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : sectionSelect?.section_id === 'otherDetails' ? (
                    <div>
                      <EditCartPageOtherDetailsContainer
                        cartOtherDetailsDesign={cartOtherDetailsDesign}
                        setCartOtherDetailsDesign={setCartOtherDetailsDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'banner' ? (
                    <div>
                      <EditBannerStyleSection
                        bannerDesign={bannerDesign}
                        setBannerDesign={setBannerDesign}
                        section_id={sectionSelect?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'cmsoffer' ? (
                    <EditCmsOfferStyleSection
                      cmsOfferDesign={cmsOfferDesign}
                      setCmsOfferDesign={setCmsOfferDesign}
                      section_id={sectionSelect?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'> {t('lang_Customize_Your_Templates')}</p>
                  </div>
                  <hr className='my-2 main-border-color' />
                  <p className='text-sm table-text'>{t('lang_Select_Section')}</p>
                </>
              )}{' '}
              :
            </div>
          ) : (
            <div className='hidden md:block md:w-[30%] xl:w-[16%] overflow-y-auto py-3 secondary-bg-color h-full'>
              {pending ? (
                <div className='w-full px-3 h-[800px]'>
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <hr className='my-4 main-border-color' />
                  <div className='grid grid-cols-1 space-y-2'>
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                  </div>
                  <hr className='my-4 main-border-color' />
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              ) : (
                <div className='theme-setting'>
                  <p className='font-medium text-primary text-base px-3'>{t('lang_cart_page')}</p>
                  <hr className='my-3 border-t main-border-color'></hr>
                  <span className='font-medium text-primary text-xs px-3'>{t('lang_Header')}</span>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Announcement_Bar')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <AnnouncementSection handleChangeAnnouncement={handleChangeAnnouncement} />
                  </details>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Header')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <HeaderSection isChangeHeader={isChangeHeader} />
                  </details>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <div className='w-full'>
                    <>
                      <CartPageSectionList
                        // editCartSection={editCartSection}
                        sectionSelect={sectionSelect}
                        selectCartSection={handleSectionSelect}
                        cartSection={cartSection}
                      />
                      <HomePageSectionList
                        getLayout={getLayout}
                        editSection={editSection}
                        selectSection={handleSectionSelect}
                        UpdateSectionStatus={UpdateSectionStatus}
                        handleSort={handleSort}
                        updateSectionSpinner={updateSectionSpinner}
                        handleGetLayout={handleGetLayout}
                        hoverId={hoverId}
                        setHoverId={setHoverId}
                        setFilterId={setFilterId}
                        sectionAdd={sectionAdd}
                        handleSubItemSort={handleSubItemSort}
                        setSubSecId={setSubSecId}
                        loadingSections={loadingSections}
                        openAddSection={openAddSection}
                        handleSectionClick={handleSectionClick}
                        setElementorId={setElementorId}
                        getPrevSecId={getPrevSecId}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setIsAddSection(true);
                        }}
                        className='inline-flex items-center mx-2.5 text-link hover:underline font-medium transition-all  my-2 duration-150 text-sm'
                      >
                        <PlusCircle className='w-4 h-4 me-1' /> {t('lang_Add_Section')}
                      </button>
                    </>
                  </div>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Footer')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <FooterSection isChangeFooter={isChangeFooter} />
                  </details>
                </div>
              )}
            </div>
          )}
          <div className={`p-0 md:ps-2 lg:px-4 bg-transparent h-full w-full md:w-[70%] xl:w-[68%]`}>
            {pending ? (
              <div className='w-full'>
                <div>
                  <Skeleton
                    className='min-h-[90px] max-h-[90px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[500px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
                <div className='grid mt-6 mx-auto grid-cols-1'>
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[400px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              </div>
            ) : (
              <div
                style={{ backgroundColor: commonSetting?.primary_body_bg_color }}
                className='h-full overflow-auto'
              >
                <VisualAnnounccementBar colors={commonSetting} announcement={announcement} />
                <StoreNavbar
                  clientInfo={clientData}
                  colors={commonSetting}
                  groupHeaderData={groupHeaderData}
                />
                <CartPageSection
                  colors={commonSetting}
                  cartData={cartData}
                  cartPageData={cartPageData}
                  isHovered={isHovered}
                  setIsHovered={setIsHovered}
                  customFaq={customFaq}
                  handleSectionSelect={handleSectionSelect}
                  cartSection={cartSection}
                  sectionSelect={sectionSelect}
                  cartPageDetails={cartPageDetails}
                  cartTitleSectionDesign={cartTitleSectionDesign}
                  cartTableSectionDesign={cartTableSectionDesign}
                  cartOtherDetailsDesign={cartOtherDetailsDesign}
                />
                <HomePageSection
                  commonSetting={commonSetting}
                  getLayout={getLayout}
                  visualData={visualData}
                  selectSection={handleSectionSelect}
                  handleSort={handleSort}
                  editSection={sectionSelect}
                  bannerDesign={bannerDesign}
                  cmsOfferDesign={cmsOfferDesign}
                  setEditSection={setSectionSelect}
                  setEditProductOtherSection={setEditProductOtherSection}
                />
                <Footer colors={commonSetting} groupFooterData={groupFooterData} />
              </div>
            )}
          </div>
          {windowWidth > 1280 && (
            <div className='hidden edit-style-section xl:block xl:w-[16%] h-full p-5 secondary-bg-color overflow-y-auto'>
              <ul className='flex border-b items-center list-none overflow-x-auto flex-row'>
                <li className='text-center lg:mb-0'>
                  <a
                    className={
                      'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150' +
                      (openTab === 'setting'
                        ? 'text-link border-b-2 border-b-[#28282B]'
                        : ' border-b-2 table-text border-transparent')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('setting');
                    }}
                    data-toggle='tab'
                    href='#link1'
                  >
                    {t('lang_setting')}
                  </a>
                </li>
                {ultimatorKitExtension?.extension_name === 'Ultimator Kit' ? (
                  <li className='text-center me-4 lg:mb-0'>
                    <a
                      className={
                        'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                        (openTab === 'style'
                          ? 'text-link border-b-2 border-b-[#28282B]'
                          : ' border-b-2 table-text border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab('style');
                      }}
                      data-toggle='tab'
                      href='#link1'
                    >
                      {t('lang_style')}
                    </a>
                  </li>
                ) : (
                  ''
                )}
              </ul>
              {openTab === 'setting' && sectionSelect?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {sectionSelect?.section_type} {t('lang_Section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setSectionSelect('');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {sectionSelect?.section_type === 'cart title' ? (
                    <div>
                      <EditCartTitleSetting
                        handleUpdateSetting={handleUpdateSetting}
                        handleEditCartPageData={handleEditCartPageData}
                        cartPageDetails={cartPageDetails}
                        section_type={sectionSelect?.section_type}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'cart table' ? (
                    <div>
                      <EditCartTableSetting
                        handleUpdateSetting={handleUpdateSetting}
                        handleEditCartPageData={handleEditCartPageData}
                        cartPageDetails={cartPageDetails}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'cart other section' ? (
                    <div>
                      <EditCartOtherDetails
                        handleUpdateSetting={handleUpdateSetting}
                        handleEditCartPageData={handleEditCartPageData}
                        cartPageDetails={cartPageDetails}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={sectionSelect?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : sectionSelect?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={sectionSelect?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'style' && sectionSelect?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {sectionSelect?.section_type} {t('lang_style_section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setSectionSelect('');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {sectionSelect?.section_type === 'cart title' ? (
                    <div>
                      <EditCartPageTitleStyleSection
                        setCartTitleSectionDesign={setCartTitleSectionDesign}
                        cartTitleSectionDesign={cartTitleSectionDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'cart table' ? (
                    <div>
                      <EditCartPageTableStyleContainer
                        setCartTableSectionDesign={setCartTableSectionDesign}
                        cartTableSectionDesign={cartTableSectionDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'cart other section' ? (
                    <div>
                      <EditCartPageOtherDetailsContainer
                        cartOtherDetailsDesign={cartOtherDetailsDesign}
                        setCartOtherDetailsDesign={setCartOtherDetailsDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'banner' ? (
                    <div>
                      <EditBannerStyleSection
                        bannerDesign={bannerDesign}
                        setBannerDesign={setBannerDesign}
                        section_id={sectionSelect?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : sectionSelect?.section_type === 'cmsoffer' ? (
                    <EditCmsOfferStyleSection
                      cmsOfferDesign={cmsOfferDesign}
                      setCmsOfferDesign={setCmsOfferDesign}
                      section_id={sectionSelect?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'> {t('lang_Customize_Your_Templates')}</p>
                  </div>
                  <hr className='my-2 main-border-color' />
                  <p className='text-sm table-text'>{t('lang_Select_Section')}</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={isAddSection}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsAddSection(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <AddLayout
          closeAddSection={closeAddSection}
          getAllLayout={handleGetLayout}
          bannerAdd={'banner-add'}
        />
      </Dialog>
    </>
  );
};

export default CartPageEditor;
