import { FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import MuiSwitch from '../../../../../controllers/MuiSwitch';
import module from '../../../../../controllers/QuillToolBar';
import SvgSection from '../../../../../controllers/SvgSection';
import IconList from '../IconList';
import ImageSkeleton from '../../../../../controllers/ImageSkeleton';

const ListItem = ({
  dropDownValue,
  item,
  handleItemClick1,
  isSelected,
  handleInputChange,
  onCopy,
  onRemove,
  handleQuillChange,
  isHovered,
  setIsHovered,
  handleIconClick,
  handleSvgClick,
  handleImageUpload,
  handleBgFitChange,
  iconIndex,
  setIconIndex,
  handleIconFill,
  imageLoader,
}) => {
  const ShortquillRef = useRef(null);
  const [shortdescription, setShortDescription] = useState('');
  const [meta, setMeta] = useState({
    meta_description: '',
  });
  return (
    <div
      className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.title}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick1}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Duplicate'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='title'>Title</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='title'
              name='title'
              placeholder='Enter title...'
              value={item?.title || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>HTMLTag</label>
            <div className='relative'>
              <select
                className='w-full mt-2'
                id='htmlTag'
                name='htmlTag'
                value={item?.htmlTag || ''}
                onChange={handleInputChange}
              >
                {dropDownValue?.map((obj) => (
                  <option key={obj?.value} value={obj?.value}>
                    {obj?.label}
                  </option>
                ))}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='role'>Role</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='role'
              name='role'
              placeholder='Enter role...'
              value={item?.role || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>Role HTMLTag</label>
            <div className='relative'>
              <select
                className='w-full mt-2'
                id='role_htmlTag'
                name='role_htmlTag'
                value={item?.role_htmlTag || ''}
                onChange={handleInputChange}
              >
                {dropDownValue?.map((obj) => (
                  <option key={obj?.value} value={obj?.value}>
                    {obj?.label}
                  </option>
                ))}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>

          <div className='w-full mb-4'>
            <label>Description</label>
            <ReactQuill
              modules={module}
              ref={ShortquillRef}
              className='w-full h-auto table-text rounded-[6px] mt-2'
              theme='snow'
              id='description'
              name='description'
              value={item?.description || ''}
              onChange={handleQuillChange}
              placeholder='Enter description'
              onBlur={() => {
                setMeta((prevMeta) => ({
                  ...prevMeta,
                  meta_description:
                    shortdescription
                      ?.replace(/<[^>]*>/g, ' ')
                      ?.split(' ')
                      .slice(0, 50)
                      .join(' ') || '',
                }));
              }}
            />
          </div>

          <div className='w-full mb-4'>
            <label>Image</label>
            <div
              className={`flex justify-center items-center ${imageLoader ? 'h-28 mt-2' : 'h-auto'}`}
            >
              {imageLoader ? (
                <ImageSkeleton />
              ) : (
                <img
                  src={item?.image}
                  alt='Uploaded Preview'
                  className='w-full mb-5 max min-h-28 max-h-72 object-contain'
                />
              )}
            </div>
            {!imageLoader && (
              <input
                type='file'
                accept='image/*'
                name='url'
                onChange={(e) => handleImageUpload(e)}
                className='truncate max-w-full overflow-hidden block mb-5'
              />
            )}
          </div>

          <div className='flex items-center mt-2'>
            <label htmlFor='sub_title' className='text-[#242323] w-[45%]'>
              {t('lang_fit')}
            </label>
            <div className='relative w-[100%] primary-bg-color'>
              <select
                className='edit-select outline-none h-8 primary-bg-color rounded-[6px] table-text w-full capitalize'
                value={item?.objectFit}
                onChange={handleBgFitChange}
                name='objectFit'
              >
                <option>{t('lang_fill')}</option>
                <option>{t('lang_contain')}</option>
                <option>{t('lang_cover')}</option>
                <option>{t('lang_none')}</option>
                <option>{t('lang_scale_down')}</option>
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>

          <div className='mt-2'>
            <label htmlFor='text mb-2'>Icon</label>
            {item?.iconList?.map((val, ind) => {
              return (
                <div
                  className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
                  onMouseEnter={() => {
                    setIsHovered(true), setIconIndex(ind);
                  }}
                  onMouseLeave={() => setIsHovered(false)}
                  key={ind}
                >
                  {val?.icon &&
                    (React.isValidElement(val?.icon) ? (
                      React.cloneElement(val?.icon, { width: '22px', height: '22px' })
                    ) : (
                      <SvgSection svgContent={val?.icon} width='22px' height='22px' />
                    ))}

                  {isHovered && iconIndex === ind && (
                    <>
                      <button
                        className='text-sm absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                        onClick={() => handleIconClick('iconList')}
                      >
                        Icon library
                      </button>
                      <button
                        className='text-sm absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                        onClick={() => handleSvgClick()}
                      >
                        SVG Icon
                      </button>
                    </>
                  )}
                </div>
              );
            })}
          </div>

          <div className='w-full mb-4 mt-2'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                Icon Fill
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={item?.isFill || false}
                    onChange={handleIconFill}
                    name='isFill'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

const StaticSecondTeamSection = ({
  section_id,
  section_type,
  secondTeamValue,
  setSecondTeamValue,
  handleSecondTeamValue,
  tabIndex,
  setTabIndex,
  handleAddSecondTeamItem,
  handleCopySecondTeamItem,
  handleSecondTeamDataRemoveValue,
  handleSecondTeamDescriptionChange,
  descriptionContent,
  sectionContent,
  headingLevel,
  handleSecondTeamTagChange,
  handleSecondTeamTitleValueChange,
  sectionGridContent,
  handleSecondTeamGridValueChange,
  handleSettingImageUpload,
  imageLoader,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const dropDownGridValue = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [gridValue, setGridValue] = useState(sectionGridContent);

  const ShortquillRef = useRef(null);
  const fileInputRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    setGroupTitle(sectionContent);
    setShortDescription(descriptionContent);
    setGridValue(sectionGridContent);
  }, [sectionContent, descriptionContent, sectionGridContent]);

  const handleQuillChange = (content) => {
    const updatedfirstContentValue = {
      ...secondTeamValue,
      item: secondTeamValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, description: content } : item,
      ),
    };

    setSecondTeamValue(updatedfirstContentValue);
    handleSecondTeamValue(section_id, updatedfirstContentValue);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleSecondTeamTagChange(section_id, newTag);
  };

  const onGridContentChange = (e) => {
    const newValue = e.target.value;
    setGridValue(newValue);
    handleSecondTeamGridValueChange(section_id, newValue);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleSecondTeamDescriptionChange(section_id, value);
  };

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedfirstContentValue = {
      ...secondTeamValue,
      item: secondTeamValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setSecondTeamValue(updatedfirstContentValue);
    handleSecondTeamValue(section_id, updatedfirstContentValue);
  };
  const addItem = () => {
    const newId = secondTeamValue?.item?.length
      ? secondTeamValue?.item?.[secondTeamValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      title: 'Lorem',
      htmlTag: 'h2',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit in liber tempor incididunt ut labore et dolore magna aliqu',
      isEdit: false,
      iconList: [
        {
          id: 1,
          icon: ` <svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                  <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                </svg>`,
        },
        {
          id: 2,
          icon: ` <svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                  <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                </svg>`,
        },
        {
          id: 3,
          icon: ` <svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                  <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                </svg>`,
        },
      ],
      image: 'https://dummyimage.com/200x200',
      objectFit: 'fill',
      isFill: false,
    };
    const updatedItems = secondTeamValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleAddSecondTeamItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopySecondTeamItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleSecondTeamDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedfirstContentValue = {
      ...secondTeamValue,
      item: secondTeamValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setSecondTeamValue(updatedfirstContentValue);
    handleSecondTeamValue(section_id, updatedfirstContentValue);
  };

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleSecondTeamTitleValueChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleSecondTeamTitleValueChange(section_id, value);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [iconIndex, setIconIndex] = useState(null);

  const handleImageUpload = async (event) => {
    try {
      const result = await handleSettingImageUpload(event);
      const updatedEightContentValue = {
        ...secondTeamValue,
        item: secondTeamValue?.item?.map((item, i) =>
          i === tabIndex ? { ...item, image: result } : item,
        ),
      };
      setSecondTeamValue(updatedEightContentValue);
      handleSecondTeamValue(section_id, updatedEightContentValue);
    } catch (error) {
      console.log(error);
    }
  };

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    const updatedEightContentValue = {
      ...secondTeamValue,
      item: secondTeamValue?.item?.map((item, i) =>
        i === tabIndex
          ? {
              ...item,
              iconList: item?.iconList?.map((val, id) =>
                id === iconIndex ? { ...val, icon: iconComponent } : val,
              ),
            }
          : item,
      ),
    };

    setSecondTeamValue(updatedEightContentValue);
    handleSecondTeamValue(section_id, updatedEightContentValue);
    setIsOpen(false);
  };

  const handleIconFill = (e) => {
    const { checked } = e.target;
    const updatedEightContentValue = {
      ...secondTeamValue,
      item: secondTeamValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, isFill: checked } : item,
      ),
    };

    setSecondTeamValue(updatedEightContentValue);
    handleSecondTeamValue(section_id, updatedEightContentValue);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconSelect(imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  const handleBgFitChange = (e) => {
    const backgroundFit = e.target.value;
    const updatedEightContentValue = {
      ...secondTeamValue,
      item: secondTeamValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, objectFit: backgroundFit } : item,
      ),
    };
    setSecondTeamValue(updatedEightContentValue);
    handleSecondTeamValue(section_id, updatedEightContentValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='w-full mb-4'>
        <label>Description</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          // value={shortdescription?.length ? shortdescription.split(' ').slice(0, 280).join(' ') : ''}
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>

      <div className='mt-4'>
        <label>Section</label>
        {Array.isArray(secondTeamValue?.item) &&
          secondTeamValue?.item?.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              handleItemClick1={() => handleItemClick(index)}
              isSelected={tabIndex === index}
              onCopy={() => copyItem(item, index)}
              onRemove={removeItem}
              handleInputChange={handleInputChange}
              dropDownValue={dropDownValue}
              handleQuillChange={handleQuillChange}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              handleIconClick={handleIconClick}
              handleSvgClick={handleSvgClick}
              handleImageUpload={handleImageUpload}
              handleBgFitChange={handleBgFitChange}
              iconIndex={iconIndex}
              setIconIndex={setIconIndex}
              handleIconFill={handleIconFill}
              imageLoader={imageLoader}
            />
          ))}
      </div>
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + Add Item
      </button>

      <div className='mt-4 mb-4'>
        <label>Grid</label>
        <div className='relative'>
          <select className='w-full mt-2' value={gridValue} onChange={onGridContentChange}>
            {dropDownGridValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
    </div>
  );
};

export default StaticSecondTeamSection;
