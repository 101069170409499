import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
function ForgotPassword() {
  const [foremail, setForemail] = useState('');
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState()
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSpinner(true)
    try {
      if (foremail === '') {
        setErr(true);
      } else {
        const payload = encryptData({email: foremail})
        let ApiCall = await API.post(`/admin/user/sendForgotPasswordLink`, payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          setIsNotification(true)
          setNotificationMsg('link successfully sent ! Please check your email')
          setSeverity('success');
          setTimeout(() => {
            navigate('/')
          }, 1000);
        } else {
          setIsNotification(true)
          setNotificationMsg('Please Enter Valid Email Address')
          setSeverity('warn')
        }
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }finally{
      setSpinner(false)
    }
  };
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  //captcha
  const [isVarified, setIsVarified] = useState(false);
  const RecaptchaLoaded = () => { };
  return (
    <div className='flex bg h-screen w-screen items-center justify-center'>
      {isNotification && notificationMsg ? (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      ) : (
        <></>
      )}
      <>
        <div
          style={{
            boxShadow: `0 20px 45px 0 rgba(3, 3, 3, 0.2)`,
          }}
          className='w-full form sm:w-8/12 xl:w-1/3 my-5 mx-5 bg-white p-5 sm:p-7 rounded-[6px]'
        >
          <div className='mb-2 text-center'>
            <img
              className='mx-auto h-6 sm:h-7 w-auto'
              src='https://cdn.shopeasy.ai/shopeasy/icons/shop-easy-black.png'
              alt='Your Company'
            />
            <span className='mt-4 text-center block text-base sm:text-xl font-medium text-gray-900'>
              Forgot password
            </span>
            <p className=' text-center text-xs sm:text-[13px] text-gray-500'>
              Retrieve your password to manage your store.
            </p>
          </div>
          <hr className='my-4 border-slate-300'></hr>
          <div>
            <div className='flex flex-col'>
              <label className='capitalize text-gray-600 text-[13px] mb-1 font-medium'>Email</label>
              <input
                id='email'
                name='email'
                type='email'
                className='w-full py-2 border border-slate-300 rounded-[6px] px-3 focus:outline-none'
                placeholder='Enter your email'
                value={foremail}
                onChange={(e) => setForemail(e.target.value)}
              />
              {err ? <p className='m-0 text-red-400 font-medium'>Please enter your email..</p> : ''}

              {/* <div className='max-w-full overflow-hidden overflow-x-auto'>
                <Recaptcha
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  render='explicit'
                  onloadCallback={RecaptchaLoaded}
                  verifyCallback={(response) =>
                    response ? setIsVarified(true) : setIsVarified(false)
                  }
                />
              </div> */}
              <div>
                {foremail === '' ? (
                  <button className='w-full block opacity-40 mt-4 cursor-not-allowed bg-btn text-white text-[14px] font-medium py-2 px-8 rounded-[4px] transition duration-300'>
                    <span>Send link</span>
                  </button>
                ) : spinner ? (
                  <button
                    type='button'
                    className='mt-4 w-full rounded-[6px] cursor-not-allowed py-2 text-white flex items-center justify-center bg-btn text-[14px] space-x-2'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>Loading...</span>
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className='w-full block mt-4 btn text-white text-[14px] font-medium py-2 px-8 rounded-[4px] transition duration-300'
                  >
                    <span>Send link</span>
                  </button>
                )}
                <div className='text-center'>
                  <Link to='/' className='text-link font-medium text-sm hover:underline'>
                    Back to login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default ForgotPassword;
