import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';
import NumberAnimation from '../../../../../controllers/NumberAnimation';

const FirstStaticStatisticSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
  handleFlexBoxClick,
  removeSection,
}) => {
  const filteredSections = sectionAdd?.firstStatisticComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'statistic 1',
  );

  return (
    <div className='w-full relative group'>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.sectionId &&
          editSection?.section_index === itemIndex;

        return (
          <ReactDragListView {...dragProps}>
            <section
              id={obj?.sectionId}
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.sectionId, obj?.blockName, itemIndex),
                  openAddSection(e, 'statistic 1', 'setting');
              }}
            >
              <div
                class='container mx-auto'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='flex flex-wrap -m-4 text-center'>
                  {obj?.content?.item?.map((value, index) => {
                    return (
                      <div
                        class={`p-4 ${getWidthClass(obj?.content?.gridValue?.toString())}`}
                        key={index}
                      >
                        <NumberAnimation
                          obj={value}
                          start={value?.start}
                          end={value?.end}
                          duration={value?.duration}
                          title={value?.title}
                          htmlTag={value?.htmlTag}
                          prefix={value?.prefix}
                          suffix={value?.suffix}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </ReactDragListView>
        );
      })}
      <div className='absolute -top-8 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
        <button onClick={(e) => handleFlexBoxClick(e, 'statistic 1')} className='mr-2'>
          +
        </button>
        <button onClick={() => removeSection(sectionIndex, 'statistic 1')} className='ml-2'>
          ×
        </button>
      </div>
    </div>
  );
};

export default FirstStaticStatisticSection;
