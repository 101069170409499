import React from 'react';
import { applyFontSizeToTags, mapPositionToCSS } from '../../controllers/DynamicEditorSwitchCase';

const TextEditorSection = ({ obj }) => {
  const description = obj?.content?.textEditorValue || '';
  const fontSize = obj?.style?.font_size;
  const lineHeight = obj?.style?.font_height;
  const styledContent = applyFontSizeToTags(description, fontSize, lineHeight);
  return (
    <>
      <div
        key={obj?.id}
        id={obj?.id}
        style={{
          paddingTop: `${obj?.style?.padding_top}px`,
          paddingBottom: `${obj?.style?.padding_bottom}px`,
          paddingLeft: `${obj?.style?.padding_left}px`,
          paddingRight: `${obj?.style?.padding_right}px`,
          marginTop: `${obj?.style?.margin_top}px`,
          marginBottom: `${obj?.style?.margin_bottom}px`,
          marginLeft: `${obj?.style?.margin_left}px`,
          marginRight: `${obj?.style?.margin_right}px`,
          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
        }}
      >
        <span
          className='ql-editor elementor-editor'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            background:
              obj?.style?.background_gradient === 'gradient'
                ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                : obj?.style?.background_image
                ? `url(${obj?.style?.background_image})`
                : obj?.style?.background,
            backgroundRepeat: obj?.style?.background_repeat,
            backgroundAttachment: obj?.style?.background_attachment,
            objectFit: obj?.style?.background_fit,
            backgroundSize:
              obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                ? obj?.style?.background_fit
                : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
            backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
          }}
          dangerouslySetInnerHTML={{
            __html: styledContent,
          }}
        ></span>
      </div>
    </>
  );
};

export default TextEditorSection;
