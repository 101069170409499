import React from 'react';
import { mapPositionToCSS } from '../../controllers/DynamicEditorSwitchCase';

const HeadingSection = ({ obj }) => {
  console.log('HeadingSection', obj);
  return (
    <>
      <div
        key={obj?.id}
        id={obj?.id}
        style={{
          paddingTop: `${obj?.style?.padding_top}px`,
          paddingBottom: `${obj?.style?.padding_bottom}px`,
          paddingLeft: `${obj?.style?.padding_left}px`,
          paddingRight: `${obj?.style?.padding_right}px`,
          marginTop: `${obj?.style?.margin_top}px`,
          marginBottom: `${obj?.style?.margin_bottom}px`,
          marginLeft: `${obj?.style?.margin_left}px`,
          marginRight: `${obj?.style?.margin_right}px`,
          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
        }}
      >
        {obj?.content?.htmlTag === 'h1' ? (
          <div
            style={{
              fontWeight: obj?.style?.font_weight,
              fontSize: obj?.style?.font_size ? `${obj?.style?.font_size}px` : '40px',
              lineHeight: obj?.style?.font_height ? `${obj?.style?.font_height}px` : '45px',
              color: obj?.style?.font_color,
              textAlign: obj?.style?.font_align,
              fontStyle: obj?.style?.font_style,
              textDecoration: obj?.style?.font_decoration,
              textTransform: obj?.style?.font_capitalize,
              wordBreak: obj?.style?.font_break,
              textWrap: obj?.style?.font_wrap,
              letterSpacing: `${obj?.style?.letter_spacing}px`,
              wordSpacing: `${obj?.style?.word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
              transform: `rotate(${obj?.style?.text_rotate}deg)`,
              background:
                obj?.style?.background_gradient === 'gradient'
                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                  : obj?.style?.background_image
                  ? `url(${obj?.style?.background_image})`
                  : obj?.style?.background,
              backgroundRepeat: obj?.style?.background_repeat,
              backgroundAttachment: obj?.style?.background_attachment,
              objectFit: obj?.style?.background_fit,
              backgroundSize:
                obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                  ? obj?.style?.background_fit
                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
            }}
          >
            {obj?.content?.title}
          </div>
        ) : obj?.content?.htmlTag === 'h2' ? (
          <div
            style={{
              fontWeight: obj?.style?.font_weight,
              fontSize: obj?.style?.font_size ? `${obj?.style?.font_size}px` : '36px',
              lineHeight: obj?.style?.font_height ? `${obj?.style?.font_height}px` : '41px',
              color: obj?.style?.font_color,
              textAlign: obj?.style?.font_align,
              fontStyle: obj?.style?.font_style,
              textDecoration: obj?.style?.font_decoration,
              textTransform: obj?.style?.font_capitalize,
              wordBreak: obj?.style?.font_break,
              textWrap: obj?.style?.font_wrap,
              letterSpacing: `${obj?.style?.letter_spacing}px`,
              wordSpacing: `${obj?.style?.word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
              transform: `rotate(${obj?.style?.text_rotate}deg)`,
              background:
                obj?.style?.background_gradient === 'gradient'
                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                  : obj?.style?.background_image
                  ? `url(${obj?.style?.background_image})`
                  : obj?.style?.background,
              backgroundRepeat:
                obj?.style?.mask_size === 'fit' ? 'repeat-x' : obj?.style?.background_repeat,
              backgroundAttachment: obj?.style?.background_attachment,
              objectFit: obj?.style?.background_fit,
              backgroundSize:
                obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                  ? obj?.style?.background_fit
                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
            }}
          >
            {obj?.content?.title}
          </div>
        ) : obj?.content?.htmlTag === 'h3' ? (
          <div
            style={{
              fontWeight: obj?.style?.font_weight,
              fontSize: obj?.style?.font_size ? `${obj?.style?.font_size}px` : '32px',
              lineHeight: obj?.style?.font_height ? `${obj?.style?.font_height}px` : '37px',
              color: obj?.style?.font_color,
              textAlign: obj?.style?.font_align,
              fontStyle: obj?.style?.font_style,
              textDecoration: obj?.style?.font_decoration,
              textTransform: obj?.style?.font_capitalize,
              wordBreak: obj?.style?.font_break,
              textWrap: obj?.style?.font_wrap,
              letterSpacing: `${obj?.style?.letter_spacing}px`,
              wordSpacing: `${obj?.style?.word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
              transform: `rotate(${obj?.style?.text_rotate}deg)`,
              background:
                obj?.style?.background_gradient === 'gradient'
                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                  : obj?.style?.background_image
                  ? `url(${obj?.style?.background_image})`
                  : obj?.style?.background,
              backgroundRepeat: obj?.style?.background_repeat,
              backgroundAttachment: obj?.style?.background_attachment,
              objectFit: obj?.style?.background_fit,
              backgroundSize:
                obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                  ? obj?.style?.background_fit
                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
            }}
          >
            {obj?.content?.title}
          </div>
        ) : obj?.content?.htmlTag === 'h4' ? (
          <div
            style={{
              fontWeight: obj?.style?.font_weight,
              fontSize: obj?.style?.font_size ? `${obj?.style?.font_size}px` : '26px',
              lineHeight: obj?.style?.font_height ? `${obj?.style?.font_height}px` : '33px',
              color: obj?.style?.font_color,
              textAlign: obj?.style?.font_align,
              fontStyle: obj?.style?.font_style,
              textDecoration: obj?.style?.font_decoration,
              textTransform: obj?.style?.font_capitalize,
              wordBreak: obj?.style?.font_break,
              textWrap: obj?.style?.font_wrap,
              letterSpacing: `${obj?.style?.letter_spacing}px`,
              wordSpacing: `${obj?.style?.word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
              transform: `rotate(${obj?.style?.text_rotate}deg)`,
              background:
                obj?.style?.background_gradient === 'gradient'
                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                  : obj?.style?.background_image
                  ? `url(${obj?.style?.background_image})`
                  : obj?.style?.background,
              backgroundRepeat: obj?.style?.background_repeat,
              backgroundAttachment: obj?.style?.background_attachment,
              objectFit: obj?.style?.background_fit,
              backgroundSize:
                obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                  ? obj?.style?.background_fit
                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
            }}
          >
            {obj?.content?.title}
          </div>
        ) : obj?.content?.htmlTag === 'h5' ? (
          <div
            style={{
              fontWeight: obj?.style?.font_weight,
              fontSize: obj?.style?.font_size ? `${obj?.style?.font_size}px` : '22px',
              lineHeight: obj?.style?.font_height ? `${obj?.style?.font_height}px` : '29px',
              color: obj?.style?.font_color,
              textAlign: obj?.style?.font_align,
              fontStyle: obj?.style?.font_style,
              textDecoration: obj?.style?.font_decoration,
              textTransform: obj?.style?.font_capitalize,
              wordBreak: obj?.style?.font_break,
              textWrap: obj?.style?.font_wrap,
              letterSpacing: `${obj?.style?.letter_spacing}px`,
              wordSpacing: `${obj?.style?.word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
              transform: `rotate(${obj?.style?.text_rotate}deg)`,
              background:
                obj?.style?.background_gradient === 'gradient'
                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                  : obj?.style?.background_image
                  ? `url(${obj?.style?.background_image})`
                  : obj?.style?.background,
              backgroundRepeat: obj?.style?.background_repeat,
              backgroundAttachment: obj?.style?.background_attachment,
              objectFit: obj?.style?.background_fit,
              backgroundSize:
                obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                  ? obj?.style?.background_fit
                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
            }}
          >
            {obj?.content?.title}
          </div>
        ) : obj?.content?.htmlTag === 'h6' ? (
          <div
            style={{
              fontWeight: obj?.style?.font_weight,
              fontSize: obj?.style?.font_size ? `${obj?.style?.font_size}px` : '18px',
              lineHeight: obj?.style?.font_height ? `${obj?.style?.font_height}px` : '25px',
              color: obj?.style?.font_color,
              textAlign: obj?.style?.font_align,
              fontStyle: obj?.style?.font_style,
              textDecoration: obj?.style?.font_decoration,
              textTransform: obj?.style?.font_capitalize,
              wordBreak: obj?.style?.font_break,
              textWrap: obj?.style?.font_wrap,
              letterSpacing: `${obj?.style?.letter_spacing}px`,
              wordSpacing: `${obj?.style?.word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
              transform: `rotate(${obj?.style?.text_rotate}deg)`,
              background:
                obj?.style?.background_gradient === 'gradient'
                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                  : obj?.style?.background_image
                  ? `url(${obj?.style?.background_image})`
                  : obj?.style?.background,
              backgroundRepeat: obj?.style?.background_repeat,
              backgroundAttachment: obj?.style?.background_attachment,
              objectFit: obj?.style?.background_fit,
              backgroundSize:
                obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                  ? obj?.style?.background_fit
                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
            }}
          >
            {obj?.content?.title}
          </div>
        ) : obj?.content?.htmlTag === 'span' ? (
          <span
            style={{
              fontWeight: obj?.style?.font_weight,
              fontSize: `${obj?.style?.font_size}px`,
              lineHeight: `${obj?.style?.font_height}px`,
              color: obj?.style?.font_color,
              textAlign: obj?.style?.font_align,
              fontStyle: obj?.style?.font_style,
              textDecoration: obj?.style?.font_decoration,
              textTransform: obj?.style?.font_capitalize,
              wordBreak: obj?.style?.font_break,
              textWrap: obj?.style?.font_wrap,
              letterSpacing: `${obj?.style?.letter_spacing}px`,
              wordSpacing: `${obj?.style?.word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
              transform: `rotate(${obj?.style?.text_rotate}deg)`,
              background:
                obj?.style?.background_gradient === 'gradient'
                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                  : obj?.style?.background_image
                  ? `url(${obj?.style?.background_image})`
                  : obj?.style?.background,
              backgroundRepeat: obj?.style?.background_repeat,
              backgroundAttachment: obj?.style?.background_attachment,
              objectFit: obj?.style?.background_fit,
              backgroundSize:
                obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                  ? obj?.style?.background_fit
                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
            }}
          >
            {obj?.content?.title}
          </span>
        ) : obj?.content?.htmlTag === 'div' ? (
          <div
            style={{
              fontWeight: obj?.style?.font_weight,
              fontSize: `${obj?.style?.font_size}px`,
              lineHeight: `${obj?.style?.font_height}px`,
              color: obj?.style?.font_color,
              textAlign: obj?.style?.font_align,
              fontStyle: obj?.style?.font_style,
              textDecoration: obj?.style?.font_decoration,
              textTransform: obj?.style?.font_capitalize,
              wordBreak: obj?.style?.font_break,
              textWrap: obj?.style?.font_wrap,
              letterSpacing: `${obj?.style?.letter_spacing}px`,
              wordSpacing: `${obj?.style?.word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
              transform: `rotate(${obj?.style?.text_rotate}deg)`,
              background:
                obj?.style?.background_gradient === 'gradient'
                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                  : obj?.style?.background_image
                  ? `url(${obj?.style?.background_image})`
                  : obj?.style?.background,
              backgroundRepeat: obj?.style?.background_repeat,
              backgroundAttachment: obj?.style?.background_attachment,
              objectFit: obj?.style?.background_fit,
              backgroundSize:
                obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                  ? obj?.style?.background_fit
                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
            }}
          >
            {obj?.content?.title}
          </div>
        ) : (
          <p
            style={{
              fontWeight: obj?.style?.font_weight,
              fontSize: `${obj?.style?.font_size}px`,
              lineHeight: `${obj?.style?.font_height}px`,
              color: obj?.style?.font_color,
              textAlign: obj?.style?.font_align,
              fontStyle: obj?.style?.font_style,
              textDecoration: obj?.style?.font_decoration,
              textTransform: obj?.style?.font_capitalize,
              wordBreak: obj?.style?.font_break,
              textWrap: obj?.style?.font_wrap,
              letterSpacing: `${obj?.style?.letter_spacing}px`,
              wordSpacing: `${obj?.style?.word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
              transform: `rotate(${obj?.style?.text_rotate}deg)`,
              background:
                obj?.style?.background_gradient === 'gradient'
                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                  : obj?.style?.background_image
                  ? `url(${obj?.style?.background_image})`
                  : obj?.style?.background,
              backgroundRepeat: obj?.style?.background_repeat,
              backgroundAttachment: obj?.style?.background_attachment,
              objectFit: obj?.style?.background_fit,
              backgroundSize:
                obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                  ? obj?.style?.background_fit
                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
            }}
          >
            {obj?.content?.title}
          </p>
        )}
      </div>
    </>
  );
};

export default HeadingSection;
