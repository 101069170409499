import { Tooltip } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import GenerateUniqueFilename from '../../../../controllers/GenerateUniqueFilename';
import { decryptData } from '../../../../controllers/encryptionUtils';
import API from '../../../../API';
import ImageSkeleton from '../../../../controllers/ImageSkeleton';

const TestimonialSetting = ({
  section_id,
  testiMonialValue,
  HandleTestiMonialChange,
  setIsNotification,
  setNotificationMsg,
  setSeverity,
}) => {
  const [imageLoader, setImageLoader] = useState(false);

  const handleTestiMonialValue = async (event) => {
    const { name, value, type } = event.target;
    const parsedValue = name === 'imageSize' ? JSON.parse(value) : value;
    if (type === 'file') {
      const file = event.target.files[0];
      let fileName = file?.name;
      if (!fileName) {
        fileName = await GenerateUniqueFilename();
      }
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'images');
      try {
        setImageLoader(true);
        const res = await API.post('/admin/fileupload/upload', formData);
        const result = decryptData(res?.data);
        console.log('setBgImage', result);
        HandleTestiMonialChange(section_id, name, result?.data[0]);
        setTimeout(() => {
          setImageLoader(false);
        }, 200);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
        setImageLoader(false);
      }
    } else {
      HandleTestiMonialChange(section_id, name, parsedValue);
    }
  };

  return (
    <div className='w-full mb-4 mt-2'>
      <div className='w-full mb-4'>
        <div className={`flex justify-center items-center ${imageLoader ? 'h-28' : 'h-auto'}`}>
          {imageLoader ? (
            <ImageSkeleton />
          ) : (
            testiMonialValue && (
              <img
                src={testiMonialValue?.url}
                alt='Uploaded Preview'
                className='w-full mb-5 max min-h-28 max-h-72 object-contain'
              />
            )
          )}
        </div>
        <div className='w-full mb-4'>
          {!imageLoader && (
            <input
              type='file'
              accept='image/*'
              name='url'
              onChange={handleTestiMonialValue}
              className='truncate max-w-full overflow-hidden block mb-5'
            />
          )}
        </div>
      </div>
      <div className='w-full mb-4'>
        <label htmlFor='name'>Name</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='name'
          name='name'
          placeholder='Enter your name...'
          onChange={handleTestiMonialValue}
          value={testiMonialValue?.name || ''}
        />
      </div>
      <div className='w-full mb-4'>
        <label htmlFor='name'>Title</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='title'
          name='title'
          placeholder='Enter your title...'
          onChange={handleTestiMonialValue}
          value={testiMonialValue?.title || ''}
        />
      </div>
      <div className='w-full mb-4'>
        <label htmlFor='name'>Description</label>
        <textarea
          name='description'
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={testiMonialValue?.description}
          onChange={handleTestiMonialValue}
          rows={6}
        />
      </div>
      <div className='w-full mb-4'>
        <div className='flex justify-between items-center'>
          <label>Position</label>
          <div className='flex space-x-2'>
            <Tooltip title='Aside' arrow>
              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                name='aside'
                onClick={() => {
                  HandleTestiMonialChange(section_id, 'position', 'aside');
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  class='bi bi-arrow-left'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8'
                  />
                </svg>
              </button>
            </Tooltip>
            <Tooltip title='Top' arrow>
              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                onClick={() => {
                  HandleTestiMonialChange(section_id, 'position', 'top');
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  class='bi bi-arrow-up'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5'
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className='w-full mb-4'>
        <div className='flex justify-between items-center'>
          <label>Alignment</label>
          <div className='flex space-x-2'>
            <Tooltip title='Left' arrow>
              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                onClick={() => {
                  HandleTestiMonialChange(section_id, 'alignment', 'left');
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  class='bi bi-text-left'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5'
                  />
                </svg>
              </button>
            </Tooltip>
            <Tooltip title='Center' arrow>
              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                onClick={() => {
                  HandleTestiMonialChange(section_id, 'alignment', 'center');
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  class='bi bi-text-center'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5'
                  />
                </svg>
              </button>
            </Tooltip>
            <Tooltip title='Right' arrow>
              <button
                className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
                onClick={() => {
                  HandleTestiMonialChange(section_id, 'alignment', 'right');
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  class='bi bi-text-right'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5'
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSetting;
