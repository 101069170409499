import { ChevronDown, Eye, EyeOff, Trash2 } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useRef } from 'react';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup';
import API from '../../../API';
import { useState } from 'react';
import Notification from '../../Notification';
import { encryptData } from '../../../controllers/encryptionUtils';
import ReactDragListView from 'react-drag-listview';
import { getIconByBlockName } from '../../../Assets/StaticData/SubItemIcon';

const HomePageSectionList = ({
  getLayout,
  editSection,
  selectSection,
  UpdateSectionStatus,
  handleSort,
  handleGetLayout,
  hoverId,
  setHoverId,
  setFilterId,
  sectionAdd,
  handleSubItemSort,
  setSubSecId,
  loadingSections,
  openAddSection,
  handleSectionClick,
  setElementorId,
  getPrevSecId,
  updateSectionName,
  UpdateEditorSectionName,
  isEdit,
  setIsEdit,
}) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [sectionDeleteId, setSectionDeleteId] = useState();
  const [openedSections, setOpenedSection] = useState(null);

  const handleDeleteSection = async () => {
    try {
      const payload = encryptData({
        id: [sectionDeleteId],
      });
      const data = await API.post(`/admin/layout/deleteSection`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Section Removed SuccesFully!');
        setSeverity('success');
        handleGetLayout();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };

  const handleToggleSection = (e, key) => {
    e.stopPropagation();
    setOpenedSection((prevKey) => (prevKey === key ? null : key));
  };

  const [scrollTrue, setScrollTrue] = useState(false);

  const handleScroll = (e, id) => {
    e.stopPropagation();
    setScrollTrue(true);
    if (id && scrollTrue) {
      const element = document.getElementById(id);
      if (element) {
        element.style.scrollMarginBottom = '50px';
        element.scrollIntoView({
          behavior: 'smooth',
          // block: 'nearest',
          block: 'center',
        });
        setScrollTrue(false);
      }
    }
  };

  useEffect(() => {
    if (editSection?.section_id && scrollTrue) {
      const element = document.getElementById(editSection?.section_id);
      if (element) {
        element.style.scrollMarginBottom = '50px';
        element.scrollIntoView({
          behavior: 'smooth',
          // block: 'nearest',
          block: 'center',
        });
        setScrollTrue(false);
      }
    }
  }, [editSection, scrollTrue]);

  // const sectionRefs = useRef({});

  // const handleSidebarClick = (id) => {
  //   const sectionRef = sectionRefs.current[id];
  //   if (sectionRef) {
  //     sectionRef.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'nearest',
  //     });
  //   }
  // window.scrollBy(0, -50);
  // };

  const [newUpdateValue, setNewUpdateValue] = useState(null);

  const handleSectionName = (e) => {
    const newTag = e.target.value;
    setNewUpdateValue(newTag);
    updateSectionName(editSection?.section_id, newTag);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      const length = newUpdateValue.length;
      inputRef.current.setSelectionRange(length, length);
    }
  }, [newUpdateValue, isEdit]);
  return (
    <>
      <ConfirmationPopup
        heading='Delete Section'
        subheading='Do you really want to delete this section from home layout?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDeleteSection}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ReactDragListView {...handleSort}>
        <div className='draggable-main-item'>
          {getLayout?.map((elem, index) => {
            console.log('elemelem', elem);
            let parsedSection = {};
            try {
              parsedSection =
                typeof elem?.section === 'string' ? JSON.parse(elem?.section) : elem?.section;
            } catch (error) {
              console.error('Error parsing section:', error);
            }

            const sectionTypes = [
              'flex_cols',
              'flex_rows',
              'flex_two_grid',
              'flex_grid_two',
              'flex_four_grid',
              'flex_three_grid_three',
              'flex_four_grid_four',
              'flex_two_grid_one',
              'flex_one_grid_two',
              'flex_six_grid_six',
              'flex_five_grid',
              'flex_two_grid_two',
              'grid_two_rows',
              'grid_two_cols',
              'grid_three_cols',
              'grid_three_rows',
              'grid_two_for_two',
              'grid_three_for_three',
            ];

            const tailwindItem = [
              'content 1',
              'content 2',
              'content 3',
              'content 4',
              'content 5',
              'content 6',
              'content 7',
              'content 8',
              'feature 1',
              'feature 2',
              'feature 3',
              'feature 4',
              'feature 5',
              'feature 6',
              'feature 7',
              'feature 8',
              'step 1',
              'step 2',
              'step 3',
              'team 1',
              'team 2',
              'team 3',
              'testimonial 1',
              'testimonial 2',
              'testimonial 3',
              'statistic 1',
              'statistic 2',
              'statistic 3',
              'contact 1',
              'contact 2',
              'contact 3',
              'cta 1',
              'cta 2',
              'cta 3',
              'cta 4',
              'hero 1',
              'hero 2',
              'hero 3',
              'hero 4',
              'hero 5',
              'hero 6',
              'pricing 1',
              'pricing 2',
            ];

            let matchingIds;
            if (sectionTypes?.includes(elem?.section_type)) {
              const parsedArray = Object.values(parsedSection).flat();
              matchingIds = parsedArray?.find((item) => item?.id === editSection?.section_id)?.id;
            }
            const isFlexCols = sectionTypes?.includes(elem?.section_type);
            const formatSectionType = (sectionType) => {
              if (sectionTypes.includes(sectionType)) {
                return sectionType
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ');
              }
              return sectionType;
            };

            return (
              <details
                open={matchingIds === editSection?.section_id}
                className='cursor-pointer group main-border-color draggable-main-item'
                key={index}
                onDragStart={() => setFilterId(elem?._id)}
                onDragLeave={(e) => {
                  e.preventDefault();
                  setHoverId(null);
                }}
              >
                <summary
                  className={`${
                    editSection?.section_id === elem?.section_id &&
                    elem?.section_type === editSection?.section_type
                      ? 'primary-bg-color'
                      : 'secondary-bg-color'
                  } headerMenu flex w-full cursor-pointer items-center justify-between`}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      selectSection(e, elem?.section_id, elem?.section_type, elem?.flexBoxIndex);
                      handleScroll(e, elem?.section_id);
                    }}
                  >
                    {isEdit === elem?.section_id ? (
                      <input
                        ref={inputRef}
                        className='border-none w-full primary-bg-color outline-none rounded-[6px] main-border-color text-sm'
                        placeholder='Enter Section Name...'
                        value={newUpdateValue}
                        onChange={handleSectionName}
                        onBlur={(e) => UpdateEditorSectionName(e, elem?._id, newUpdateValue)}
                      />
                    ) : (
                      <span className='text-sm'>
                        {(elem?.section_type && sectionTypes.includes(elem?.section_type)) ||
                        tailwindItem.includes(elem?.section_type)
                          ? formatSectionType(elem?.section_name)
                          : elem?.section_type?.charAt(0).toUpperCase() +
                            elem?.section_type?.slice(1)}
                      </span>
                    )}
                  </div>
                  <div className='relative ms-2 w-[20%] space-x-2 flex items-center justify-end'>
                    {isFlexCols && (
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className='w-4 h-5' />
                      </span>
                    )}
                    <div
                      className='table-text hover:text-black transition-all duration-150'
                      onClick={(e) => {
                        UpdateSectionStatus(e, elem?._id, !elem?.status);
                      }}
                    >
                      {loadingSections[elem?._id] ? (
                        <div className='nb-spinner-small'></div>
                      ) : elem?.status === true ? (
                        <Eye className='w-4 h-4' />
                      ) : (
                        <EyeOff className='w-4 h-4' />
                      )}
                    </div>
                    <div
                      className='table-text hover:text-black transition-all duration-150'
                      onClick={(e) => {
                        setNewUpdateValue(elem?.section_name);
                        setIsEdit(elem?.section_id);
                        selectSection(e, elem?.section_id, elem?.section_type, elem?.flexBoxIndex);
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-pencil-square'
                        viewBox='0 0 16 16'
                      >
                        <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                        <path
                          fill-rule='evenodd'
                          d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
                        />
                      </svg>
                    </div>
                    <div
                      className='table-text hover:text-black transition-all duration-150'
                      onClick={() => {
                        setSectionDeleteId(elem?._id);
                        setConfirmationPopup(true);
                      }}
                    >
                      <Trash2 className='w-4 h-4' />
                    </div>
                  </div>
                </summary>
                <div className={`${isFlexCols ? 'block' : 'hidden'}`}>
                  <ReactDragListView {...handleSubItemSort}>
                    {Object.keys(
                      (sectionTypes?.includes(elem?.section_type) && elem?.section) || {},
                    ).map((key, index) => {
                      const sectionArray = elem?.section[key];
                      const getGridBySectionId = (sectionId) => {
                        for (const gridKey in elem?.section) {
                          if (Array.isArray(elem?.section[gridKey])) {
                            const matchingItem = elem?.section[gridKey]?.find(
                              (block) => block?.id === sectionId,
                            );

                            if (matchingItem) {
                              return gridKey;
                            }
                          }
                        }
                        return null;
                      };
                      const matchedGrid = getGridBySectionId(editSection?.section_id);
                      const matchingIds = sectionArray?.find(
                        (block) => block?.id === editSection?.section_id,
                      )?.id;

                      const isOpen =
                        editSection?.section_id === matchingIds ||
                        openedSections === key ||
                        matchedGrid === key;
                      if (Object.keys(elem?.section).length > 1) {
                        return (
                          <details
                            open={isOpen}
                            className='cursor-pointer group main-border-color accordion'
                          >
                            <summary className='flex w-full cursor-pointer items-center justify-between'>
                              <div
                                onClick={(e) => {
                                  selectSection(e, elem.section_id, key, elem?.flexBoxIndex);
                                  openAddSection(e, key, 'setting');
                                  handleToggleSection(e, key);
                                }}
                                className={`${
                                  editSection?.section_id === elem?.section_id &&
                                  key === editSection?.section_type
                                    ? 'primary-bg-color'
                                    : 'secondary-bg-color'
                                } flex w-full items-center headerMenu justify-between rounded-[6px] normal-item-class p-[10px] text-base font-medium`}
                              >
                                <div
                                  onClick={(e) => {
                                    selectSection(e, elem?.section_id, key, elem?.flexBoxIndex);
                                    openAddSection(e, key, 'setting');
                                    handleToggleSection(e, key);
                                  }}
                                >
                                  <span className='text-sm'>
                                    {key?.charAt(0).toUpperCase() + key?.slice(1)}
                                  </span>
                                </div>
                                <span
                                  onClick={(e) => {
                                    selectSection(e, elem?.section_id, key, elem?.flexBoxIndex);
                                    openAddSection(e, key, 'setting');
                                    handleToggleSection(e, key);
                                  }}
                                  className={`transition-transform duration-300 ease-in-out transform ${
                                    isOpen ? 'rotate-180' : ''
                                  }`}
                                >
                                  <ChevronDown className='w-4 h-5' />
                                </span>
                              </div>
                            </summary>
                            <div className='pl-4'>
                              {sectionArray?.map((block, index) => {
                                return (
                                  <div
                                    key={block?.id || index}
                                    className={`${
                                      editSection?.section_id === block?.id
                                        ? 'primary-bg-color'
                                        : 'secondary-bg-color'
                                    } rounded-[6px] headerMenu draggable-subMenu-item hover:primary-bg-color transition-all duration-150 group select-none p-[10px] text-base flex w-full items-center justify-between font-medium`}
                                    onClick={(e) => {
                                      selectSection(e, block?.id, block?.blockName, index);
                                      openAddSection(e, key, 'setting');
                                      handleScroll(e, block?.id);
                                    }}
                                    onDragStart={(e) => {
                                      setSubSecId(block?.sectionId);
                                    }}
                                    onDragEnter={(e) => {
                                      e.preventDefault();
                                      handleSectionClick(e, elem?.flexBoxIndex, elem?.section_type);
                                      openAddSection(e, key, 'add_section');
                                      selectSection(e, elem?.section_id, key, elem?.flexBoxIndex);
                                      getPrevSecId(e, elem?.flexBoxIndex);
                                      setElementorId(elem?.section_id);
                                    }}
                                    onDragOver={(e) => e.preventDefault()}
                                  >
                                    <div className='flex w-[75%] pe-2 items-center'>
                                      <div className='flex space-x-2 text-[13px] table-text w-full leading-4 capitalize font-normal'>
                                        <span>{getIconByBlockName(block?.blockName)}</span>
                                        <span>{block?.blockName}</span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </details>
                        );
                      } else {
                        return (
                          <div key={key} className='normal-div-class'>
                            {sectionArray?.map((block, index) => (
                              <div
                                key={index}
                                className={`${
                                  editSection?.section_id === block?.id
                                    ? 'primary-bg-color'
                                    : 'secondary-bg-color'
                                } rounded-[6px] draggable-subMenu-item headerMenu normal-item-class p-[10px] text-base flex w-full items-center justify-between font-medium`}
                                onClick={(e) => {
                                  selectSection(e, block?.id, block?.blockName, index);
                                  openAddSection(e, key, 'setting');
                                  handleScroll(e, block?.id);
                                }}
                                onDragStart={() => setSubSecId(block?.sectionId)}
                                onDragEnter={(e) => {
                                  e.preventDefault();
                                  handleSectionClick(e, elem?.flexBoxIndex, elem?.section_type);
                                  openAddSection(e, key, 'add_section');
                                  selectSection(
                                    e,
                                    elem?.section_id,
                                    elem?.section_type,
                                    elem?.flexBoxIndex,
                                  );
                                }}
                                onDragOver={(e) => e.preventDefault()}
                              >
                                <div className='flex w-[75%] pe-2 items-center'>
                                  <div className='flex space-x-2 text-[13px] table-text w-full leading-4 capitalize font-normal'>
                                    <span>{getIconByBlockName(block?.blockName)}</span>
                                    <span>{block?.blockName}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        );
                      }
                    })}
                  </ReactDragListView>
                </div>
              </details>
            );
          })}
        </div>
      </ReactDragListView>
    </>
  );
};

export default HomePageSectionList;
