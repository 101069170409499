import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  applyFontSizeToTags,
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';

const SecondStaticContentSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
  handleFlexBoxClick,
  removeSection,
}) => {
  const filteredSections = sectionAdd?.secondContentComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'content 2',
  );

  console.log(filteredSections, 'filteredSections=-=-=->>');

  return (
    <div className='w-full relative group'>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.sectionId &&
          editSection?.section_index === itemIndex;

        console.log(obj?.style, 'obj=-==-=->>>>');

        const description = obj?.content?.description || '-';
        const fontSize = obj?.style?.description_font_size;
        const lineHeight = obj?.style?.description_font_height;
        const styledContent = applyFontSizeToTags(description, fontSize, lineHeight);

        return (
          <ReactDragListView {...dragProps}>
            <section
              id={obj?.sectionId}
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.sectionId, obj?.blockName, itemIndex),
                  openAddSection(e, 'content 2', 'setting');
              }}
            >
              <div
                class='container mx-auto'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='flex flex-wrap w-full mb-20'>
                  <div class='lg:w-1/2 w-full mb-6 lg:mb-0'>
                    {obj?.content?.htmlTag === 'h1' ? (
                      <div
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: obj?.style?.title_font_size
                            ? `${obj?.style?.title_font_size}px`
                            : '40px',
                          lineHeight: obj?.style?.title_font_height
                            ? `${obj?.style?.title_font_height}px`
                            : '45px',
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </div>
                    ) : obj?.content?.htmlTag === 'h2' ? (
                      <div
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: obj?.style?.title_font_size
                            ? `${obj?.style?.title_font_size}px`
                            : '36px',
                          lineHeight: obj?.style?.title_font_height
                            ? `${obj?.style?.title_font_height}px`
                            : '41px',
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </div>
                    ) : obj?.content?.htmlTag === 'h3' ? (
                      <div
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: obj?.style?.title_font_size
                            ? `${obj?.style?.title_font_size}px`
                            : '32px',
                          lineHeight: obj?.style?.title_font_height
                            ? `${obj?.style?.title_font_height}px`
                            : '37px',
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </div>
                    ) : obj?.content?.htmlTag === 'h4' ? (
                      <div
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: obj?.style?.title_font_size
                            ? `${obj?.style?.title_font_size}px`
                            : '26px',
                          lineHeight: obj?.style?.title_font_height
                            ? `${obj?.style?.title_font_height}px`
                            : '33px',
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </div>
                    ) : obj?.content?.htmlTag === 'h5' ? (
                      <div
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: obj?.style?.title_font_size
                            ? `${obj?.style?.title_font_size}px`
                            : '22px',
                          lineHeight: obj?.style?.title_font_height
                            ? `${obj?.style?.title_font_height}px`
                            : '29px',
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </div>
                    ) : obj?.content?.htmlTag === 'h6' ? (
                      <div
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: obj?.style?.title_font_size
                            ? `${obj?.style?.title_font_size}px`
                            : '18px',
                          lineHeight: obj?.style?.title_font_height
                            ? `${obj?.style?.title_font_height}px`
                            : '25px',
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </div>
                    ) : obj?.content?.htmlTag === 'div' ? (
                      <div
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </div>
                    ) : obj?.content?.htmlTag === 'span' ? (
                      <span
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </span>
                    ) : obj?.content?.htmlTag === 'p' ? (
                      <p
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </p>
                    ) : (
                      <></>
                    )}
                    <div class='h-1 w-20 bg-indigo-500 rounded'></div>
                  </div>
                  <div
                    style={{
                      fontWeight: obj?.style?.description_font_weight,
                      fontSize: `${obj?.style?.description_font_size}px`,
                      lineHeight: `${obj?.style?.description_font_height}px`,
                      color: obj?.style?.description_font_color,
                      textAlign: obj?.style?.description_font_align,
                      fontStyle: obj?.style?.description_font_style,
                      textDecoration: obj?.style?.description_font_decoration,
                      textTransform: obj?.style?.description_font_capitalize,
                      wordBreak: obj?.style?.description_font_break,
                      textWrap: obj?.style?.description_font_wrap,
                      letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.description_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                    }}
                    class='lg:w-1/2 w-full leading-relaxed text-gray-500 ql-editor elementor-editor'
                    dangerouslySetInnerHTML={{ __html: styledContent }}
                  ></div>
                </div>
                <div class='flex flex-wrap -m-4'>
                  {obj?.content?.item?.map((value) => {
                    const styledContent = applyFontSizeToTags(value?.description);

                    return (
                      <div class={`${getWidthClass(obj?.content?.gridValue?.toString())} p-4`}>
                        <div class='bg-gray-100 p-6 rounded-lg'>
                          <img
                            class='h-40 rounded w-full object-cover object-center mb-6'
                            src={value?.image}
                            alt='content'
                            style={{
                              objectFit: value?.objectFit,
                            }}
                          />
                          {value?.sub_title_htmlTag === 'h1' ? (
                            <h1 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h1>
                          ) : value?.sub_title_htmlTag === 'h2' ? (
                            <h2 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h2>
                          ) : value?.sub_title_htmlTag === 'h3' ? (
                            <h3 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h3>
                          ) : value?.sub_title_htmlTag === 'h4' ? (
                            <h4 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h4>
                          ) : value?.sub_title_htmlTag === 'h5' ? (
                            <h5 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h5>
                          ) : value?.sub_title_htmlTag === 'h6' ? (
                            <h6 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h6>
                          ) : value?.sub_title_htmlTag === 'div' ? (
                            <div class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </div>
                          ) : value?.sub_title_htmlTag === 'span' ? (
                            <span class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </span>
                          ) : value?.sub_title_htmlTag === 'p' ? (
                            <p class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </p>
                          ) : (
                            <></>
                          )}
                          {value?.htmlTag === 'h1' ? (
                            <h1 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h1>
                          ) : value?.htmlTag === 'h2' ? (
                            <h2 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h2>
                          ) : value?.htmlTag === 'h3' ? (
                            <h3 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h3>
                          ) : value?.htmlTag === 'h4' ? (
                            <h4 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h4>
                          ) : value?.htmlTag === 'h5' ? (
                            <h5 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h5>
                          ) : value?.htmlTag === 'h6' ? (
                            <h6 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h6>
                          ) : value?.htmlTag === 'p' ? (
                            <p class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </p>
                          ) : value?.htmlTag === 'span' ? (
                            <span class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </span>
                          ) : value?.htmlTag === 'div' ? (
                            <div class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </div>
                          ) : (
                            <></>
                          )}
                          <span
                            className='ql-editor elementor-editor'
                            dangerouslySetInnerHTML={{ __html: styledContent || '-' }}
                          ></span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </ReactDragListView>
        );
      })}
      <div className='absolute -top-8 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
        <button onClick={(e) => handleFlexBoxClick(e, 'content 2')} className='mr-2'>
          +
        </button>
        <button onClick={() => removeSection(sectionIndex, 'content 2')} className='ml-2'>
          ×
        </button>
      </div>
    </div>
  );
};

export default SecondStaticContentSection;
