import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';
import SvgSection from '../../../../../controllers/SvgSection';
import IconList from '../IconList';
import ImageSkeleton from '../../../../../controllers/ImageSkeleton';

const StaticForthHeroSection = ({
  section_id,
  section_type,
  sectionContent,
  firstBtnContent,
  firstBtnSubTitleContent,
  secondBtnSubTitleContent,
  descriptionContent,
  handleForthHeroTitleValueChange,
  handleForthHeroTagChange,
  headingLevel,
  handleForthHeroDescriptionChange,
  handleForthHeroFirstBtnValueChange,
  secondBtnContent,
  handleForthHeroSecondBtnValueChange,
  handleForthHeroFirstBtnSubTitleValueChange,
  firstBtnLinkValue,
  handleForthHeroFirstBtnLinkValueChange,
  handleForthHeroSecondBtnSubTitleValueChange,
  secondBtnLinkValue,
  handleForthHeroSecondBtnLinkValueChange,
  noteContent,
  handleForthHeroNoteValueChange,
  urlValueContent,
  handleForthHeroShortCodeValueChange,
  forthHeroValue,
  setForthHeroValue,
  handleForthHeroValue,
  handleSettingImageUpload,
  isOpen,
  setIsOpen,
  imageLoader,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [firstBtnValue, setFirstBtnValue] = useState(firstBtnContent);
  const [noteValue, setNoteValue] = useState(noteContent);
  const [firstBtnSubTitleValue, setFirstBtnSubTitleValue] = useState(firstBtnSubTitleContent);
  const [secondBtnSubTitleValue, setSecondBtnSubTitleValue] = useState(secondBtnSubTitleContent);
  const [firstBtnLink, setFirstBtnLink] = useState(firstBtnLinkValue);
  const [secondBtnLink, setSecondBtnLink] = useState(secondBtnLinkValue);
  const [secondBtnValue, setSecondBtnValue] = useState(secondBtnContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [shortCodeValue, setShortCodeValue] = useState(urlValueContent);

  const ShortquillRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    setNoteValue(noteContent);
    setGroupTitle(sectionContent);
    setFirstBtnValue(firstBtnContent);
    setFirstBtnSubTitleValue(firstBtnSubTitleContent);
    setFirstBtnLink(firstBtnLinkValue);
    setSecondBtnLink(secondBtnLinkValue);
    setSecondBtnValue(secondBtnContent);
    setSecondBtnSubTitleValue(secondBtnSubTitleContent);
    setShortDescription(descriptionContent);
    setShortCodeValue(urlValueContent);
  }, [
    noteContent,
    sectionContent,
    firstBtnContent,
    firstBtnSubTitleContent,
    firstBtnLinkValue,
    secondBtnLinkValue,
    secondBtnContent,
    secondBtnSubTitleContent,
    descriptionContent,
    urlValueContent,
  ]);

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleForthHeroTitleValueChange(section_id, newValue);
  };

  const firstBtnContentChange = (e) => {
    const newValue = e.target.value;
    setFirstBtnValue(newValue);
    handleForthHeroFirstBtnValueChange(section_id, newValue);
  };

  const noteContentChange = (e) => {
    const newValue = e.target.value;
    setNoteValue(newValue);
    handleForthHeroNoteValueChange(section_id, newValue);
  };

  const firstBtnContentSubTitleChange = (e) => {
    const newValue = e.target.value;
    setFirstBtnSubTitleValue(newValue);
    handleForthHeroFirstBtnSubTitleValueChange(section_id, newValue);
  };

  const secondBtnContentSubTitleChange = (e) => {
    const newValue = e.target.value;
    setSecondBtnSubTitleValue(newValue);
    handleForthHeroSecondBtnSubTitleValueChange(section_id, newValue);
  };

  const firstBtnLinkValueChange = (e) => {
    const newValue = e.target.value;
    setFirstBtnLink(newValue);
    handleForthHeroFirstBtnLinkValueChange(section_id, newValue);
  };

  const secondBtnLinkValueChange = (e) => {
    const newValue = e.target.value;
    setSecondBtnLink(newValue);
    handleForthHeroSecondBtnLinkValueChange(section_id, newValue);
  };

  const secondBtnContentChange = (e) => {
    const newValue = e.target.value;
    setSecondBtnValue(newValue);
    handleForthHeroSecondBtnValueChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleForthHeroTitleValueChange(section_id, value);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleForthHeroTagChange(section_id, newTag);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleForthHeroDescriptionChange(section_id, value);
  };

  const onShortCodeValueChange = (e) => {
    const newValue = e.target.value;
    setShortCodeValue(newValue);
  };

  const handleApplyChanges = () => {
    handleForthHeroShortCodeValueChange(section_id, shortCodeValue);
  };

  const handleImageUpload = async (event) => {
    try {
      const result = await handleSettingImageUpload(event);
      const updatedEightContentValue = {
        ...forthHeroValue,
        image: result,
      };
      setForthHeroValue(updatedEightContentValue);
      handleForthHeroValue(section_id, updatedEightContentValue);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBgFitChange = (e) => {
    const backgroundFit = e.target.value;
    const updatedEightContentValue = {
      ...forthHeroValue,
      objectFit: backgroundFit,
    };

    setForthHeroValue(updatedEightContentValue);
    handleForthHeroValue(section_id, updatedEightContentValue);
  };

  const fileInputRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [hoverIcon, setHoverIcon] = useState(null);

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    const updatedEightContentValue = {
      ...forthHeroValue,
      [hoverIcon]: iconComponent,
    };
    setForthHeroValue(updatedEightContentValue);
    handleForthHeroValue(section_id, updatedEightContentValue);
    setIsOpen(false);
    setHoverIcon(null);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert('No file selected. Please select an SVG file.');
      return;
    }
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        const isValidSvg = /<svg[\s\S]*<\/svg>/i.test(imageUrl);
        if (!isValidSvg) {
          alert('Invalid SVG content. Please upload a valid SVG file.');
          return;
        }
        const updatedEightContentValue = {
          ...forthHeroValue,
          [hoverIcon]: imageUrl,
        };

        setForthHeroValue(updatedEightContentValue);
        handleForthHeroValue(section_id, updatedEightContentValue);
        setHoverIcon(null);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div>
        <label>{t('lang_enter_your_short_code')}</label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your short code here...'
          value={shortCodeValue}
          onChange={onShortCodeValueChange}
        />
      </div>
      <div className='flex justify-between mt-2 items-center'>
        <p>{t('lang_update_changes_to_form')}</p>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[12px] font-medium py-1 px-3 rounded-[4px] transition duration-300'
          onClick={handleApplyChanges}
        >
          {t('lang_apply')}
        </button>
      </div>

      <div className='mb-4 mt-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_title')}</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>{t('lang_title_hTMLTag')}</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_Description')}</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_Note')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={noteValue}
          onChange={noteContentChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_first_button_title')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={firstBtnValue}
          onChange={firstBtnContentChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_first_button_sub_title')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={firstBtnSubTitleValue}
          onChange={firstBtnContentSubTitleChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_first_button_link')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={firstBtnLink}
          onChange={firstBtnLinkValueChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_first_icon')}</label>
        <div
          onMouseEnter={() => {
            setIsHovered(true), setHoverIcon('first_icon');
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
        >
          {forthHeroValue?.first_icon &&
            (React.isValidElement(forthHeroValue?.first_icon) ? (
              React.cloneElement(forthHeroValue?.first_icon, { width: '22px', height: '22px' })
            ) : (
              <SvgSection svgContent={forthHeroValue?.first_icon} width='22px' height='22px' />
            ))}
          {isHovered && hoverIcon === 'first_icon' && (
            <>
              <button
                className='absolute text-sm bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleIconClick('iconList')}
              >
                {t('lang_icon_library')}
              </button>
              <button
                className='absolute text-sm bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleSvgClick()}
              >
                {t('lang_svg_icon')}
              </button>
            </>
          )}
        </div>
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_second_button_title')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={secondBtnValue}
          onChange={secondBtnContentChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_second_button_sub_title')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={secondBtnSubTitleValue}
          onChange={secondBtnContentSubTitleChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_second_button_link')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={secondBtnLink}
          onChange={secondBtnLinkValueChange}
        />
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_second_icon')}</label>
        <div
          onMouseEnter={() => {
            setIsHovered(true), setHoverIcon('second_icon');
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
        >
          {forthHeroValue?.second_icon &&
            (React.isValidElement(forthHeroValue?.second_icon) ? (
              React.cloneElement(forthHeroValue?.second_icon, { width: '22px', height: '22px' })
            ) : (
              <SvgSection svgContent={forthHeroValue?.second_icon} width='22px' height='22px' />
            ))}
          {isHovered && hoverIcon === 'second_icon' && (
            <>
              <button
                className='absolute text-sm bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleIconClick('iconList')}
              >
                {t('lang_icon_library')}
              </button>
              <button
                className='absolute text-sm bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleSvgClick()}
              >
                {t('lang_svg_icon')}
              </button>
            </>
          )}
        </div>
      </div>

      <div className='w-full mb-4'>
        <label>{t('lang_image')}</label>
        <div className={`flex justify-center items-center ${imageLoader ? 'h-28 mt-2' : 'h-auto'}`}>
          {imageLoader ? (
            <ImageSkeleton />
          ) : (
            <img
              src={forthHeroValue?.image}
              alt='Uploaded Preview'
              className='w-full mb-5 max min-h-28 max-h-72 object-contain'
            />
          )}
        </div>
        {!imageLoader && (
          <input
            type='file'
            accept='image/*'
            name='url'
            onChange={(e) => handleImageUpload(e)}
            className='truncate max-w-full overflow-hidden block mb-5'
          />
        )}
      </div>

      <div className='flex items-center mt-2'>
        <label htmlFor='sub_title' className='text-[#242323] w-[45%]'>
          {t('lang_fit')}
        </label>
        <div className='relative w-[100%] primary-bg-color'>
          <select
            className='edit-select outline-none h-8 primary-bg-color rounded-[6px] table-text w-full capitalize'
            value={forthHeroValue?.objectFit}
            onChange={handleBgFitChange}
            name='objectFit'
          >
            <option>{t('lang_fill')}</option>
            <option>{t('lang_contain')}</option>
            <option>{t('lang_cover')}</option>
            <option>{t('lang_none')}</option>
            <option>{t('lang_scale_down')}</option>
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default StaticForthHeroSection;
