import React, { useState } from 'react';
import API from '../API';
import { decryptData } from './encryptionUtils';

const DynamicStateAndEvent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(null);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [flexBoxValue, setFlexBoxValue] = useState([]);
  const [elementorId, setElementorId] = useState(null);
  const [getLayout, setGetLayout] = useState([]);
  const [removedIndices, setRemovedIndices] = useState(new Set());
  const [editSection, setEditSection] = useState({
    section_type: '',
    section_id: '',
    section_index: '',
  });
  const [openTab, setOpenTab] = useState('setting');
  const [isFlexBoxVisible, setIsFlexBoxVisible] = useState(false);
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [isPlusButtonVisible, setIsPlusButtonVisible] = useState(true);
  const [activeIndex, setActiveIndex] = useState();
  const [activeHeadingIndex, setActiveHeadingIndex] = useState(null);
  const [gridValue, setGridValue] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isPlusClick, setIsPlusClick] = useState(true);
  const [editSectionClear, setEditSectionClear] = useState(false);

  const formatSectionType = (sectionType) => {
    return sectionType
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' ');
  };

  const [sectionAdd, setSectionAdd] = useState({
    firstGrid: [],
    secondGridRow: [],
    secondGrid: [],
    thirdGrid: [],
    forthGrid: [],
    fifthGrid: [],
    sixGrid: [],
    subFirstGrid: [],
    subSecondGrid: [],
    subThirdGrid: [],
    subForthGrid: [],
    subFifthGrid: [],
    subSixGrid: [],
    subSecondSectionFirstGrid: [],
    subSecondSectionSecondGrid: [],
    subSecondSectionThirdGrid: [],
    subSecondSectionForthGrid: [],
    subSecondSectionFifthGrid: [],
    subSecondSectionSixGrid: [],
    subThirdSectionFirstGrid: [],
    subThirdSectionSecondGrid: [],
    subThirdSectionThirdGrid: [],
    subThirdSectionForthGrid: [],
    subThirdSectionFifthGrid: [],
    subThirdSectionSixGrid: [],
    subForthSectionFirstGrid: [],
    subForthSectionSecondGrid: [],
    subForthSectionThirdGrid: [],
    subForthSectionForthGrid: [],
    subForthSectionFifthGrid: [],
    subForthSectionSixGrid: [],
    subFifthSectionFirstGrid: [],
    subFifthSectionSecondGrid: [],
    subFifthSectionThirdGrid: [],
    subFifthSectionForthGrid: [],
    subFifthSectionFifthGrid: [],
    subFifthSectionSixGrid: [],
    subSixSectionFirstGrid: [],
    subSixSectionSecondGrid: [],
    subSixSectionThirdGrid: [],
    subSixSectionForthGrid: [],
    subSixSectionFifthGrid: [],
    subSixSectionSixGrid: [],
    firstContentComponment: [],
    secondContentComponment: [],
    thirdContentComponment: [],
    forthContentComponment: [],
    fifthContentComponment: [],
    sixContentComponment: [],
    sevenContentComponment: [],
    eightContentComponment: [],
    firstFeatureComponment: [],
    secondFeatureComponment: [],
    thirdFeatureComponment: [],
    forthFeatureComponment: [],
    fifthFeatureComponment: [],
    sixFeatureComponment: [],
    sevenFeatureComponment: [],
    eightFeatureComponment: [],
    firstStepComponment: [],
    secondStepComponment: [],
    thirdStepComponment: [],
    firstTeamComponment: [],
    secondTeamComponment: [],
    thirdTeamComponment: [],
    firstTestimonialComponment: [],
    secondTestimonialComponment: [],
    thirdTestimonialComponment: [],
    firstStatisticComponment: [],
    secondStatisticComponment: [],
    thirdStatisticComponment: [],
    firstContactComponment: [],
    secondContactComponment: [],
    thirdContactComponment: [],
    firstCtaComponment: [],
    secondCtaComponment: [],
    thirdCtaComponment: [],
    forthCtaComponment: [],
    firstHeroComponment: [],
    secondHeroComponment: [],
    thirdHeroComponment: [],
    forthHeroComponment: [],
    fifthHeroComponment: [],
    sixHeroComponment: [],
    firstPricingComponment: [],
    secondPricingComponment: [],
  });

  const handleFlexBoxClick = (e, sectionType) => {
    console.log(sectionType, 'sectionType=-=-=>>');
    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const flexId = generateCustomObjectId(24);
    console.log(sectionType, 'value=-=-=-=>>>');
    // console.log(flexId, 'value=-=-=-=>>>');
    e.stopPropagation();
    const newSections = [...flexBoxValue, { type: sectionType, id: flexId }];
    const index = newSections?.length - 1;
    console.log(newSections, 'newSections=-=-=-=>>>');
    setFlexBoxValue(newSections);
    setElementorId(flexId);
    const updatedGetLayout = [
      ...getLayout,
      {
        section_id: flexId,
        section_type: sectionType,
        status: true,
        sort_order: getLayout.length,
        section: { [gridValue]: [] },
        flexBoxIndex: newSections?.length - 1,
        section_name: formatSectionType(sectionType),
      },
    ];
    setGetLayout(updatedGetLayout);

    setRemovedIndices((prev) => {
      const newSet = new Set(prev);
      return newSet;
    });

    setEditSection({
      section_id: flexId,
      section_type: sectionType,
    });
    setEditSectionClear(false);
    setOpenTab('setting');
    setIsFlexBoxVisible(false);
    setIsGridVisible(false);
    setIsPlusButtonVisible(true);

    setActiveIndex(newSections?.length - 1);
    setActiveHeadingIndex({ sectionIndex: null, itemIndex: null });

    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);
    let newSection;
    if (sectionType === 'content 1') {
      newSection = {
        id: id,
        type: 'content 1',
        sectionId: flexId,
        content: {
          title: 'ROOF PARTY POLAROID',
          htmlTag: 'h6',
          subTitle: 'Master Cleanse Reliac Heirloom',
          subHtmlTag: 'h2',
          button: 'Button',
          gridValue: 4,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.<p>',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 4,
              title: 'Melanchole',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
              link: '',
              isFill: false,
            },
          ],
        },
        blockName: 'content 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          sub_title_font_weight: '500',
          sub_title_font_size: '',
          sub_title_font_height: '',
          sub_title_font_color: '#6366f1',
          sub_title_font_align: '',
          sub_title_font_style: '',
          sub_title_font_decoration: '',
          sub_title_font_capitalize: '',
          sub_title_font_break: '',
          sub_title_font_wrap: '',
          sub_title_letter_spacing: '1.6',
          sub_title_word_spacing: '',
          sub_title_text_stroke: '',
          sub_title_stroke_color: '',
          sub_title_text_shadow_color: '',
          sub_title_text_shadow_blur: '',
          sub_title_text_shadow_horizontal: '',
          sub_title_text_shadow_vertical: '',
          sub_title_text_rotate: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'content 2') {
      newSection = {
        id: id,
        type: 'content 2',
        sectionId: flexId,
        content: {
          title: 'Pitchfork Kickstarter Taxidermy',
          htmlTag: 'h1',
          gridValue: 4,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
          item: [
            {
              id: 1,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'Chichen Itza',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
            {
              id: 2,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'Colosseum Roma',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
            {
              id: 3,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'Great Pyramid of Giza',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
            {
              id: 4,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'San Francisco',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
          ],
        },
        blockName: 'content 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'content 3') {
      newSection = {
        id: id,
        type: 'content 3',
        sectionId: flexId,
        content: {
          title: 'Pitchfork Kickstarter Taxidermy',
          htmlTag: 'h1',
          gridValue: 3,
          button: 'Button',
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                      >
                        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                    </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-6 h-6'
                      viewBox='0 0 24 24'
                     >
                        <circle cx="6" cy="6" r="3"></circle>
              <circle cx="6" cy="18" r="3"></circle>
              <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                     </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 4,
              title: 'Melanchole',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                         stroke-linejoin='round'
                         stroke-width='2'
                        class='w-6 h-6'
                         viewBox='0 0 24 24'
                     >
                            <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
                      </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 5,
              title: 'Bunker',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                            </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 6,
              title: 'Ramona Falls',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                            </svg>`,
              link: '',
              isFill: false,
            },
          ],
        },
        blockName: 'content 3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'content 4') {
      newSection = {
        id: id,
        type: 'content 4',
        sectionId: flexId,
        content: {
          title: 'Pitchfork Kickstarter Taxidermy',
          htmlTag: 'h1',
          gridValue: 3,
          button: 'Learn more',
          description:
            '<p>Locavore cardigan small batch roof party blue bottle blog meggings sartorial jean shorts kickstarter migas sriracha church-key synth succulents. Actually taiyaki neutra, distillery gastropub pok pok ugh.</p>',
          sub_title: 'CATEGORIES',
          sub_title_htmlTag: 'h2',
          item: [
            {
              id: 1,
              link: 'First link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 2,
              link: 'Second link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 3,
              link: 'Third link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 4,
              link: 'Forth link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 5,
              link: 'Fifth link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 6,
              link: 'Sixth link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 7,
              link: 'Seventh link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 8,
              link: 'Eighth link',
              link_url: '#',
              isEdit: false,
            },
          ],
        },
        blockName: 'content 4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          button_link_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'content 5') {
      newSection = {
        id: id,
        type: 'content 5',
        sectionId: flexId,
        content: {
          title: 'Kickstarter Actually Pinterest Brunch Bitters Occupy',
          htmlTag: 'h1',
          button: 'Button',
          description:
            '<p>Taxidermy bushwick celiac master cleanse microdosing seitan. Fashion axe four dollar toast truffaut, direct trade kombucha brunch williamsburg keffiyeh gastropub tousled squid meh taiyaki drinking vinegar tacos.</p>',
          button_link: 'Learn more',
          icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
              viewBox='0 0 24 24'
          >
           <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
          link: '',
          isFill: false,
        },
        blockName: 'content 5',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'content 6') {
      newSection = {
        id: id,
        type: 'content 6',
        sectionId: flexId,
        content: {
          title: 'Phoebe Caulfield',
          htmlTag: 'h1',
          description:
            '<p>Raclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>',
          content:
            '<p>Meggings portland fingerstache lyft, post-ironic fixie man bun banh mi umami everyday carry hexagon locavore direct trade art party. Locavore small batch listicle gastropub farm-to-table lumbersexual salvia messenger bag. Coloring book flannel truffaut craft beer drinking vinegar sartorial, disrupt fashion axe normcore meh butcher. Portland 90 scenester vexillologist forage post-ironic asymmetrical, chartreuse disrupt butcher paleo intelligentsia pabst before they sold out four loko. 3 wolf moon brooklyn.</p>',
          button_link: 'Learn more',
          link_url: '#',
          image: 'https://dummyimage.com/1200x500',
          profile: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-10 h-10'
                      viewBox='0 0 24 24'
                      >
                        <path d='M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2'></path>
                      <circle cx='12' cy='7' r='4'></circle>
                    </svg>`,
          objectFit: 'fill',
          profileFill: false,
          icon: ` <svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                   >
                      <path d='M5 12h14M12 5l7 7-7 7'></path>
                   </svg>`,
          isFill: false,
        },
        blockName: 'content 6',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          content_font_weight: '',
          content_font_size: '',
          content_font_height: '',
          content_font_color: '#6B7280',
          content_font_align: '',
          content_font_style: '',
          content_font_decoration: '',
          content_font_capitalize: '',
          content_font_break: '',
          content_font_wrap: '',
          content_letter_spacing: '',
          content_word_spacing: '',
          content_text_stroke: '',
          content_stroke_color: '',
          content_text_shadow_color: '',
          content_text_shadow_blur: '',
          content_text_shadow_horizontal: '',
          content_text_shadow_vertical: '',
          content_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'content 7') {
      newSection = {
        id: id,
        type: 'content 7',
        sectionId: flexId,
        content: {
          gridValue: 2,
          item: [
            {
              id: 1,
              title: 'Buy YouTube Videos',
              htmlTag: 'h2',
              description:
                '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
              button: 'Button',
              isEdit: false,
              image: 'https://dummyimage.com/1201x501',
              objectFit: 'fill',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
              button: 'Button',
              isEdit: false,
              image: 'https://dummyimage.com/1201x501',
              objectFit: 'fill',
            },
          ],
        },
        blockName: 'content 7',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          button_link_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'content 8') {
      newSection = {
        id: id,
        type: 'content 8',
        sectionId: flexId,
        content: {
          gridValue: 3,
          title: 'Space The Final Frontier',
          htmlTag: 'h2',
          description:
            '<p>Street art subway tile salvia four dollar toast bitters selfies quinoa yuccie synth meditation iPhone intelligentsia prism tofu. Viral gochujang bitters dreamcatcher.</p>',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              image: 'https://dummyimage.com/1203x503',
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              image: 'https://dummyimage.com/1203x503',
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h2',
              description:
                '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              image: 'https://dummyimage.com/1203x503',
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              objectFit: 'fill',
              isFill: false,
            },
          ],
        },
        blockName: 'content 8',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'feature 1') {
      newSection = {
        id: id,
        type: 'feature 1',
        sectionId: flexId,
        content: {
          gridValue: 3,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-6 h-6'
                      viewBox='0 0 24 24'
                    >
                      <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                  </svg>`,
              linkIcon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-4 h-4 ml-2'
                viewBox='0 0 24 24'
                >
                <path d='M5 12h14M12 5l7 7-7 7'></path>
              </svg>`,
              titleIconFill: false,
              linkIconFill: false,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'feature 2') {
      newSection = {
        id: id,
        type: 'feature 2',
        sectionId: flexId,
        content: {
          gridValue: 3,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          description:
            '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
          fill='none'
          stroke='currentColor'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2'
          class='w-4 h-4 ml-2'
        viewBox='0 0 24 24'
        >
        <path d='M5 12h14M12 5l7 7-7 7'></path>
      </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'feature 3') {
      newSection = {
        id: id,
        type: 'feature 3',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
      >
        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
    </svg>`,
              linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-6 h-6'
                      viewBox='0 0 24 24'
                    >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                  </svg>`,
              linkIcon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
          image: 'https://dummyimage.com/460x500',
          objectFit: 'fill',
        },
        blockName: 'feature 3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'feature 4') {
      newSection = {
        id: id,
        type: 'feature 4',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              titleIconFill: false,
              linkIconFill: false,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              linkIcon: `<svg
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='2'
      class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
      </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature 4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'feature 5') {
      newSection = {
        id: id,
        type: 'feature 5',
        sectionId: flexId,
        content: {
          title: 'ROOF PARTY POLAROID',
          htmlTag: 'h6',
          subTitle: 'Master Cleanse Reliac Heirloom',
          subHtmlTag: 'h2',
          gridValue: 3,
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              linkIcon: `<svg
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='2'
      class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
      </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature 5',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          sub_title_font_weight: '500',
          sub_title_font_size: '',
          sub_title_font_height: '',
          sub_title_font_color: '#6366f1',
          sub_title_font_align: '',
          sub_title_font_style: '',
          sub_title_font_decoration: '',
          sub_title_font_capitalize: '',
          sub_title_font_break: '',
          sub_title_font_wrap: '',
          sub_title_letter_spacing: '1.6',
          sub_title_word_spacing: '',
          sub_title_text_stroke: '',
          sub_title_stroke_color: '',
          sub_title_text_shadow_color: '',
          sub_title_text_shadow_blur: '',
          sub_title_text_shadow_horizontal: '',
          sub_title_text_shadow_vertical: '',
          sub_title_text_rotate: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'feature 6') {
      newSection = {
        id: id,
        type: 'feature 6',
        sectionId: flexId,
        content: {
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
       >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
<circle cx="12" cy="7" r="4"></circle>
     </svg>`,
              linkIcon: `<svg
fill='none'
stroke='currentColor'
stroke-linecap='round'
stroke-linejoin='round'
stroke-width='2'
class='w-4 h-4 ml-2'
viewBox='0 0 24 24'
>
<path d='M5 12h14M12 5l7 7-7 7'></path>
</svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature 6',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'feature 7') {
      newSection = {
        id: id,
        type: 'feature 7',
        sectionId: flexId,
        content: {
          gridValue: 2,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          description:
            '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'Authentic Cliche Forage',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='3'
                            class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
                            viewBox='0 0 24 24'
                          >
                            <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                            <path d='M22 4L12 14.01l-3-3'></path>
                          </svg>`,
              isFill: false,
            },
            {
              id: 2,
              title: 'Kinfolk Chips Snackwave',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
            {
              id: 3,
              title: 'Coloring Book Ethical',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
            {
              id: 4,
              title: 'Typewriter Polaroid Cray',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
            {
              id: 5,
              title: 'Pack Truffaut Blue',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
            {
              id: 6,
              title: 'The Catcher In The Rye',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'feature 7',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'feature 8') {
      newSection = {
        id: id,
        type: 'feature 8',
        sectionId: flexId,
        content: {
          gridValue: 4,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          description:
            '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'SHOOTING STARS',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
              ],
            },
            {
              id: 2,
              title: 'THE 400 BLOWS',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
              ],
            },
            {
              id: 3,
              title: 'THE CATALYZER',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
              ],
            },
            {
              id: 4,
              title: 'NEPTUNE',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight: '',
                  iconWidth: '',
                },
              ],
            },
          ],
        },
        blockName: 'feature 8',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'step 1') {
      newSection = {
        id: id,
        type: 'step 1',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Step 1',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
              <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 2,
              title: 'Step 2',
              htmlTag: 'h5',
              description:
                'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 3,
              title: 'Step 3',
              htmlTag: 'h5',
              description:
                'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 4,
              title: 'Step 4',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              isFill: false,
            },
            {
              id: 5,
              title: 'FINISH',
              htmlTag: 'h5',
              description:
                'Pitchfork ugh tattooed scenester echo park gastropub whatever cold-pressed retro.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
              <path d="M22 4L12 14.01l-3-3"></path>
           </svg>`,
              isFill: false,
            },
          ],
          image: 'https://dummyimage.com/1200x500',
          objectFit: 'fill',
        },
        blockName: 'step 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'step 2') {
      newSection = {
        id: id,
        type: 'step 2',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Master Cleanse Reliac Heirloom',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
           </svg>`,
              iconTitle: 'Step 1',
              image: 'https://dummyimage.com/720x600',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h5',
              description:
                'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
             <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
           </svg>`,
              iconTitle: 'Step 2',
              image: 'https://dummyimage.com/720x600',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h5',
              description:
                'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
             <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
           </svg>`,
              iconTitle: 'Step 3',
              image: 'https://dummyimage.com/720x600',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 4,
              title: 'Neptune',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              iconTitle: 'Step 4',
              image: 'https://dummyimage.com/720x600',
              objectFit: 'fill',
              isFill: false,
            },
          ],
        },
        blockName: 'step 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'step 3') {
      newSection = {
        id: id,
        type: 'step 3',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
              <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h5',
              description:
                'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
             <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h5',
              description:
                'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
             <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 4,
              title: 'Neptune',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'step 3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'team 1') {
      newSection = {
        id: id,
        type: 'team 1',
        sectionId: flexId,
        content: {
          title: 'Our Team',
          htmlTag: 'h2',
          gridValue: 3,
          description:
            'Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.',
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h2',
              description: 'UI Designer',
              isEdit: false,
              image: 'https://dummyimage.com/80x80',
              objectFit: 'fill',
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h2',
              description: 'CTO',
              isEdit: false,
              image: 'https://dummyimage.com/84x84',
              objectFit: 'fill',
            },
            {
              id: 3,
              title: 'Oskar Blinde',
              htmlTag: 'h2',
              description: 'Founder',
              isEdit: false,
              image: 'https://dummyimage.com/88x88',
              objectFit: 'fill',
            },
            {
              id: 4,
              title: 'John Doe',
              htmlTag: 'h2',
              description: 'DevOps',
              isEdit: false,
              image: 'https://dummyimage.com/90x90',
              objectFit: 'fill',
            },
            {
              id: 5,
              title: 'Martin Eden',
              htmlTag: 'h2',
              description: 'Software Engineer',
              isEdit: false,
              image: 'https://dummyimage.com/94x94',
              objectFit: 'fill',
            },
            {
              id: 6,
              title: 'Boris Kitua',
              htmlTag: 'h2',
              description: 'UX Researcher',
              isEdit: false,
              image: 'https://dummyimage.com/98x98',
              objectFit: 'fill',
            },
            {
              id: 7,
              title: 'Atticus Finch',
              htmlTag: 'h2',
              description: 'QA Engineer',
              isEdit: false,
              image: 'https://dummyimage.com/100x90',
              objectFit: 'fill',
            },
            {
              id: 8,
              title: 'Alper Kamu',
              htmlTag: 'h2',
              description: 'System',
              isEdit: false,
              image: 'https://dummyimage.com/104x94',
              objectFit: 'fill',
            },
            {
              id: 9,
              title: 'Rodrigo Monchi',
              htmlTag: 'h2',
              description: 'Product Manager',
              isEdit: false,
              image: 'https://dummyimage.com/108x98',
              objectFit: 'fill',
            },
          ],
        },
        blockName: 'team 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'team 2') {
      newSection = {
        id: id,
        type: 'team 2',
        sectionId: flexId,
        content: {
          title: 'Our Team',
          htmlTag: 'h2',
          gridValue: 2,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.</p>',
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'UI Designer',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/200x200',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'CTO',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/201x201',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 3,
              title: 'Oskar Blinde',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'Founder',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/204x204',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 4,
              title: 'John Doe',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'DevOps',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/206x206',
              objectFit: 'fill',
              isFill: false,
            },
          ],
        },
        blockName: 'team 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'team 3') {
      newSection = {
        id: id,
        type: 'team 3',
        sectionId: flexId,
        content: {
          title: 'Our Team',
          htmlTag: 'h2',
          gridValue: 2,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.</p>',
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'UI Designer',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/200x200',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'CTO',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/201x201',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 3,
              title: 'Oskar Blinde',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'Founder',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/203x203',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 4,
              title: 'John Doe',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'DevOps',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/204x204',
              objectFit: 'fill',
              isFill: false,
            },
          ],
        },
        blockName: 'team 3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'testimonial 1') {
      newSection = {
        id: id,
        type: 'testimonial 1',
        sectionId: flexId,
        content: {
          title: 'Testimonials',
          htmlTag: 'h2',
          gridValue: 2,
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'UI Designer',
              description:
                '<p>Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90s microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/106x106',
              profileFill: false,
              icon: ` <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            class='block w-5 h-5 text-gray-400 mb-4'
                            viewBox='0 0 975.036 975.036'
                          >
                            <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                          </svg>`,
              isFill: false,
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'CTO',
              description:
                '<p>Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90s microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/107x107',
              profileFill: false,
              icon: ` <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            class='block w-5 h-5 text-gray-400 mb-4'
                            viewBox='0 0 975.036 975.036'
                          >
                            <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                          </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'testimonial 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'testimonial 2') {
      newSection = {
        id: id,
        type: 'testimonial 2',
        sectionId: flexId,
        content: {
          title: 'HOLDEN CAULFIELD',
          role: 'Senior Product Designer',
          htmlTag: 'h2',
          role_htmlTag: 'p',
          description:
            '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyaki taxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware. Man bun next level coloring book skateboard four loko knausgaard. Kitsch keffiyeh master cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90s pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>',
          icon: `  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    class='inline-block w-8 h-8 text-gray-400 mb-8'
                    viewBox='0 0 975.036 975.036'
                  >
                    <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                  </svg>`,
          isFill: false,
        },
        blockName: 'testimonial 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'testimonial 3') {
      newSection = {
        id: id,
        type: 'testimonial 3',
        sectionId: flexId,
        content: {
          gridValue: 3,
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'UI Designer',
              description:
                '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyakitaxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyakivape DIY tote bag drinking vinegar cronut adaptogen squid fanny packvaporware.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/302x302',
              profileFill: false,
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'CTO',
              description:
                '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyakitaxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyakivape DIY tote bag drinking vinegar cronut adaptogen squid fanny packvaporware.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/303x303',
              profileFill: false,
            },
            {
              id: 3,
              title: 'Henry Letham',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'CTO',
              description:
                '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyakitaxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyakivape DIY tote bag drinking vinegar cronut adaptogen squid fanny packvaporware.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/305x305',
              profileFill: false,
            },
          ],
        },
        blockName: 'testimonial 3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'statistic 1') {
      newSection = {
        id: id,
        type: 'statistic 1',
        sectionId: flexId,
        content: {
          gridValue: 4,
          item: [
            {
              id: 1,
              start: 0,
              end: 100,
              duration: 2000,
              title: 'Users',
              htmlTag: 'p',
              prefix: '',
              suffix: 'K',
            },
            {
              id: 2,
              start: 0,
              end: 200,
              duration: 2000,
              title: 'Subscribes',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
            {
              id: 3,
              start: 0,
              end: 300,
              duration: 2000,
              title: 'Downloads',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
            {
              id: 4,
              start: 0,
              end: 400,
              duration: 2000,
              title: 'Products',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
          ],
        },
        blockName: 'statistic 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'statistic 2') {
      newSection = {
        id: id,
        type: 'statistic 2',
        sectionId: flexId,
        content: {
          gridValue: 4,
          title: 'Moon hashtag pop-up try-hard offal truffaut',
          htmlTag: 'h2',
          description:
            '<p>Pour-over craft beer pug drinking vinegar live-edge gastropub, keytar neutrasustainable fingerstache kickstarter.</p>',
          item: [
            {
              id: 1,
              start: 0,
              end: 100,
              duration: 2000,
              title: 'Users',
              htmlTag: 'p',
              prefix: '',
              suffix: 'K',
            },
            {
              id: 2,
              start: 0,
              end: 200,
              duration: 2000,
              title: 'Subscribes',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
            {
              id: 3,
              start: 0,
              end: 300,
              duration: 2000,
              title: 'Downloads',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
            {
              id: 4,
              start: 0,
              end: 400,
              duration: 2000,
              title: 'Products',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
          ],
          image: 'https://dummyimage.com/600x300',
          objectFit: 'fill',
        },
        blockName: 'statistic 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'statistic 3') {
      newSection = {
        id: id,
        type: 'statistic 3',
        sectionId: flexId,
        content: {
          gridValue: 4,
          title: 'Moon hashtag pop-up try-hard offal truffaut',
          htmlTag: 'h2',
          description:
            '<p>Pour-over craft beer pug drinking vinegar live-edge gastropub, keytar neutrasustainable fingerstache kickstarter.</p>',
          item: [
            {
              id: 1,
              start: 0,
              end: 100,
              duration: 2000,
              title: 'Users',
              htmlTag: 'p',
              prefix: '',
              suffix: 'K',
              icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='text-indigo-500 w-12 h-12 mb-3 inline-block'
                      viewBox='0 0 24 24'
                    >
                      <path d='M8 17l4 4 4-4m-4-5v9'></path>
                      <path d='M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29'></path>
                    </svg>`,
              isFill: false,
            },
            {
              id: 2,
              start: 0,
              end: 200,
              duration: 2000,
              title: 'Subscribes',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='text-indigo-500 w-12 h-12 mb-3 inline-block'
                    viewBox='0 0 24 24'
                  >
                     <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                  </svg>`,
              isFill: false,
            },
            {
              id: 3,
              start: 0,
              end: 300,
              duration: 2000,
              title: 'Downloads',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='text-indigo-500 w-12 h-12 mb-3 inline-block'
                    viewBox='0 0 24 24'
                  >
                   <path d="M3 18v-6a9 9 0 0118 0v6"></path>
                   <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
                  </svg>`,
              isFill: false,
            },
            {
              id: 4,
              start: 0,
              end: 400,
              duration: 2000,
              title: 'Products',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='text-indigo-500 w-12 h-12 mb-3 inline-block'
                    viewBox='0 0 24 24'
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                  </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'statistic 3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'contact 1') {
      newSection = {
        id: id,
        type: 'contact 1',
        sectionId: flexId,
        content: {
          title: 'Feedback',
          htmlTag: 'h2',
          description: '<p>Post-ironic portland shabby chic echo park, banjo fashion axe</p>',
          note: 'Chicharrones blog helvetica normcore iceland tousled brook viral artisan.',
          shortCode: '',
        },
        blockName: 'contact 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          note_font_weight: '',
          note_font_size: '',
          note_font_height: '',
          note_font_color: '#28282B',
          note_font_align: '',
          note_font_style: '',
          note_font_decoration: '',
          note_font_capitalize: '',
          note_font_break: '',
          note_font_wrap: '',
          note_letter_spacing: '',
          note_word_spacing: '',
          note_text_stroke: '',
          note_stroke_color: '',
          note_text_shadow_color: '',
          note_text_shadow_blur: '',
          note_text_shadow_horizontal: '',
          note_text_shadow_vertical: '',
          note_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'contact 2') {
      newSection = {
        id: id,
        type: 'contact 2',
        sectionId: flexId,
        content: {
          title: 'Feedback',
          htmlTag: 'h2',
          description: '<p>Post-ironic portland shabby chic echo park, banjo fashion axe</p>',
          note: 'Chicharrones blog helvetica normcore iceland tousled brook viral artisan.',
          address_title: 'Address',
          address_title_htmlTag: 'h2',
          address_value: 'Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter',
          email_title: 'Email',
          email_title_htmlTag: 'h2',
          email_value: 'example@email.com',
          phone_title: 'Phone',
          phone_title_htmlTag: 'h2',
          phone_value: '123-456-7890',
        },
        blockName: 'contact 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          note_font_weight: '',
          note_font_size: '',
          note_font_height: '',
          note_font_color: '#28282B',
          note_font_align: '',
          note_font_style: '',
          note_font_decoration: '',
          note_font_capitalize: '',
          note_font_break: '',
          note_font_wrap: '',
          note_letter_spacing: '',
          note_word_spacing: '',
          note_text_stroke: '',
          note_stroke_color: '',
          note_text_shadow_color: '',
          note_text_shadow_blur: '',
          note_text_shadow_horizontal: '',
          note_text_shadow_vertical: '',
          note_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'contact 3') {
      newSection = {
        id: id,
        type: 'contact 3',
        sectionId: flexId,
        content: {
          title: 'Feedback',
          htmlTag: 'h2',
          description: '<p>Post-ironic portland shabby chic echo park, banjo fashion axe</p>',
          address_value: 'Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter',
          email_value: 'example@email.com',
          iconArray: [
            {
              id: 1,
              icon: `
                    <svg
                      fill='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-5 h-5'
                      viewBox='0 0 24 24'
                    >
                      <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                  </svg>`,
              isFill: false,
            },
            {
              id: 2,
              icon: `
                   <svg
                      fill='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-5 h-5'
                      viewBox='0 0 24 24'
                  >
                    <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                  </svg>`,
              isFill: false,
            },
            {
              id: 3,
              icon: `
                     <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-5 h-5'
                      viewBox='0 0 24 24'
                    >
                      <rect width='20' height='20' x='2' y='2' rx='5' ry='5'></rect>
                      <path d='M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01'></path>
                    </svg>`,
              isFill: false,
            },
            {
              id: 4,
              icon: `
                    <svg
                      fill='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-5 h-5'
                      viewBox='0 0 24 24'
                    >
                     <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                   </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'contact 3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          note_font_weight: '',
          note_font_size: '',
          note_font_height: '',
          note_font_color: '#28282B',
          note_font_align: '',
          note_font_style: '',
          note_font_decoration: '',
          note_font_capitalize: '',
          note_font_break: '',
          note_font_wrap: '',
          note_letter_spacing: '',
          note_word_spacing: '',
          note_text_stroke: '',
          note_stroke_color: '',
          note_text_shadow_color: '',
          note_text_shadow_blur: '',
          note_text_shadow_horizontal: '',
          note_text_shadow_vertical: '',
          note_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'cta 1') {
      newSection = {
        id: id,
        type: 'cta 1',
        sectionId: flexId,
        content: {
          description:
            '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
          button: 'Button',
        },
        blockName: 'cta 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'cta 2') {
      newSection = {
        id: id,
        type: 'cta 2',
        sectionId: flexId,
        content: {
          description:
            '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
          title: 'Slow-carb next level shoindcgoitch ethical authentic, poko scenester',
          htmlTag: 'h2',
          form_title: 'Sign Up',
          form_title_htmlTag: 'h2',
          note: 'Literally you probably have not heard of them jean shorts.',
        },
        blockName: 'cta 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          note_font_weight: '500',
          note_font_size: '',
          note_font_height: '',
          note_font_color: '',
          note_font_align: '',
          note_font_style: '',
          note_font_decoration: '',
          note_font_capitalize: '',
          note_font_break: '',
          note_font_wrap: '',
          note_letter_spacing: '',
          note_word_spacing: '',
          note_text_stroke: '',
          note_stroke_color: '',
          note_text_shadow_color: '',
          note_text_shadow_blur: '',
          note_text_shadow_horizontal: '',
          note_text_shadow_vertical: '',
          note_text_rotate: '',
          form_title_font_weight: '500',
          form_title_font_size: '',
          form_title_font_height: '',
          form_title_font_color: '',
          form_title_font_align: '',
          form_title_font_style: '',
          form_title_font_decoration: '',
          form_title_font_capitalize: '',
          form_title_font_break: '',
          form_title_font_wrap: '',
          form_title_letter_spacing: '',
          form_title_word_spacing: '',
          form_title_text_stroke: '',
          form_title_stroke_color: '',
          form_title_text_shadow_color: '',
          form_title_text_shadow_blur: '',
          form_title_text_shadow_horizontal: '',
          form_title_text_shadow_vertical: '',
          form_title_text_rotate: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'cta 3') {
      newSection = {
        id: id,
        type: 'cta 3',
        sectionId: flexId,
        content: {
          description:
            '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
          title: 'Slow-carb next level shoindcgoitch ethical authentic, poko scenester',
          htmlTag: 'h2',
        },
        blockName: 'cta 3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'cta 4') {
      newSection = {
        id: id,
        type: 'cta 4',
        sectionId: flexId,
        content: {
          description:
            '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
          title: 'Slow-carb next level shoindcgoitch ethical authentic, poko scenester',
          htmlTag: 'h2',
          first_btn_title: 'GET IT ON',
          first_btn_sub_title: 'Google Play',
          first_btn_link: '#',
          second_btn_title: 'GET IT ON',
          second_btn_sub_title: 'Google Play',
          second_btn_link: '#',
        },
        blockName: 'cta 4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'hero 1') {
      newSection = {
        id: id,
        type: 'hero 1',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          first_btn: 'Button',
          second_btn: 'Button',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
        },
        blockName: 'hero 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          sub_title_font_weight: '',
          sub_title_font_size: '',
          sub_title_font_height: '',
          sub_title_font_color: '#28282B',
          sub_title_font_align: '',
          sub_title_font_style: '',
          sub_title_font_decoration: '',
          sub_title_font_capitalize: '',
          sub_title_font_break: '',
          sub_title_font_wrap: '',
          sub_title_letter_spacing: '',
          sub_title_word_spacing: '',
          sub_title_text_stroke: '',
          sub_title_stroke_color: '',
          sub_title_text_shadow_color: '',
          sub_title_text_shadow_blur: '',
          sub_title_text_shadow_horizontal: '',
          sub_title_text_shadow_vertical: '',
          sub_title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'hero 2') {
      newSection = {
        id: id,
        type: 'hero 2',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          first_btn: 'Button',
          second_btn: 'Button',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
        },
        blockName: 'hero 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'hero 3') {
      newSection = {
        id: id,
        type: 'hero 3',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          first_btn: 'Button',
          second_btn: 'Button',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
        },
        blockName: 'hero 3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'hero 4') {
      newSection = {
        id: id,
        type: 'hero 4',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          note: 'Neutra shabby chic ramps, viral fixie.',
          first_btn_title: 'GET IT ON',
          first_btn_sub_title: 'Google Play',
          first_btn_link: '#',
          second_btn_title: 'GET IT ON',
          second_btn_sub_title: 'Google Play',
          second_btn_link: '#',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
          first_icon: `
          <svg
             xmlns='http://www.w3.org/2000/svg'
             fill='currentColor'
             class='w-6 h-6'
             viewBox='0 0 512 512'
           >
             <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
          </svg>`,
          second_icon: `
         <svg
             xmlns='http://www.w3.org/2000/svg'
             fill='currentColor'
             class='w-6 h-6'
             viewBox='0 0 305 305'
           >
             <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
             <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
         </svg>`,
        },
        blockName: 'hero 4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'hero 5') {
      newSection = {
        id: id,
        type: 'hero 5',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          note: 'Neutra shabby chic ramps, viral fixie.',
          first_btn_title: 'GET IT ON',
          first_btn_sub_title: 'Google Play',
          first_btn_link: '#',
          second_btn_title: 'GET IT ON',
          second_btn_sub_title: 'Google Play',
          second_btn_link: '#',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
          first_icon: `
          <svg
             xmlns='http://www.w3.org/2000/svg'
             fill='currentColor'
             class='w-6 h-6'
             viewBox='0 0 512 512'
           >
             <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
          </svg>`,
          second_icon: `
         <svg
             xmlns='http://www.w3.org/2000/svg'
             fill='currentColor'
             class='w-6 h-6'
             viewBox='0 0 305 305'
           >
             <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
             <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
         </svg>`,
        },
        blockName: 'hero 5',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'hero 6') {
      newSection = {
        id: id,
        type: 'hero 6',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          note: 'Neutra shabby chic ramps, viral fixie.',
          first_btn_title: 'GET IT ON',
          first_btn_sub_title: 'Google Play',
          first_btn_link: '#',
          second_btn_title: 'GET IT ON',
          second_btn_sub_title: 'Google Play',
          second_btn_link: '#',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
          first_icon: `
           <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              class='w-6 h-6'
              viewBox='0 0 512 512'
            >
              <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
           </svg>`,
          second_icon: `
          <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              class='w-6 h-6'
              viewBox='0 0 305 305'
            >
              <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
              <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
          </svg>`,
        },
        blockName: 'hero 6',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'pricing 1') {
      newSection = {
        id: id,
        type: 'pricing 1',
        sectionId: flexId,
        content: {
          title: 'Pricing',
          htmlTag: 'h1',
          gridValue: 4,
          description: '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical.</p>',
          item: [
            {
              id: 1,
              title: 'Start',
              htmlTag: 'h3',
              price: 'Free',
              button: 'Button',
              time_period: '/mon',
              description: '<p>Literally you probably have not heard of them jean shorts.</p>',
              isEdit: false,
              subItem: [
                {
                  subLink: ' Vexillologist pitchfork',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Tumeric plaid portland',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Mixtape chillwave tumeric',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
              ],
            },
            {
              id: 2,
              title: 'PRO',
              htmlTag: 'h3',
              price: '$38',
              button: 'Button',
              time_period: '/mon',
              description: '<p>Literally you probably have not heard of them jean shorts.</p>',
              isEdit: false,
              subItem: [
                {
                  subLink: ' Vexillologist pitchfork',
                  icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2.5'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                      <path d='M20 6L9 17l-5-5'></path>
                    </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Tumeric plaid portland',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Mixtape chillwave tumeric',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
              ],
            },
            {
              id: 3,
              title: 'BUSINESS',
              htmlTag: 'h3',
              price: '$56',
              button: 'Button',
              time_period: '/mon',
              description: '<p>Literally you probably have not heard of them jean shorts.</p>',
              isEdit: false,
              subItem: [
                {
                  subLink: ' Vexillologist pitchfork',
                  icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2.5'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                      <path d='M20 6L9 17l-5-5'></path>
                    </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Tumeric plaid portland',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Mixtape chillwave tumeric',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
              ],
            },
            {
              id: 4,
              title: 'SPECIAL',
              htmlTag: 'h3',
              price: '$72',
              button: 'Button',
              time_period: '/mon',
              description: '<p>Literally you probably have not heard of them jean shorts.</p>',
              isEdit: false,
              subItem: [
                {
                  subLink: ' Vexillologist pitchfork',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Tumeric plaid portland',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Mixtape chillwave tumeric',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
              ],
            },
          ],
        },
        blockName: 'pricing 1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'pricing 2') {
      newSection = {
        id: id,
        type: 'pricing 2',
        sectionId: flexId,
        content: {
          title: 'Pricing',
          htmlTag: 'h1',
          description:
            '<p>Banh mi cornhole echo park skateboard authentic crucifix neutra tilde lyft biodiesel artisan direct trade mumblecore 3 wolf moon twee</p>',
          link: 'Learn more',
          link_url: '#',
          button: 'Button',
          button_url: '#',
          item: [
            {
              id: 1,
              plan: 'Start',
              htmlTag: 'h3',
              price: 'Free',
              speed: '5 Mb/s',
              storage: '15GB',
              isEdit: false,
            },
            {
              id: 2,
              plan: 'PRO',
              htmlTag: 'h3',
              price: '$38',
              speed: '25 Mb/s',
              storage: '25GB',
              isEdit: false,
            },
            {
              id: 3,
              plan: 'BUSINESS',
              htmlTag: 'h3',
              price: '$56',
              speed: '36 Mb/s',
              storage: '40GB',
              isEdit: false,
            },
            {
              id: 4,
              plan: 'SPECIAL',
              htmlTag: 'h3',
              price: '$72',
              speed: '48 Mb/s',
              storage: '120GB',
              isEdit: false,
            },
          ],
        },
        blockName: 'pricing 2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    }

    if (sectionType === 'content 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content 1',
      });
    } else if (sectionType === 'content 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content 2',
      });
    } else if (sectionType === 'content 3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content 3',
      });
    } else if (sectionType === 'content 4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content 4',
      });
    } else if (sectionType === 'content 5') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content 5',
      });
    } else if (sectionType === 'content 6') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content 6',
      });
    } else if (sectionType === 'content 7') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content 7',
      });
    } else if (sectionType === 'content 8') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content 8',
      });
    } else if (sectionType === 'feature 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature 1',
      });
    } else if (sectionType === 'feature 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature 2',
      });
    } else if (sectionType === 'feature 3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature 3',
      });
    } else if (sectionType === 'feature 4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature 4',
      });
    } else if (sectionType === 'feature 5') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature 5',
      });
    } else if (sectionType === 'feature 6') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature 6',
      });
    } else if (sectionType === 'feature 7') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature 7',
      });
    } else if (sectionType === 'feature 8') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature 8',
      });
    } else if (sectionType === 'step 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'step 1',
      });
    } else if (sectionType === 'step 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'step 2',
      });
    } else if (sectionType === 'step 3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'step 3',
      });
    } else if (sectionType === 'team 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'team 1',
      });
    } else if (sectionType === 'team 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'team 2',
      });
    } else if (sectionType === 'team 3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'team 3',
      });
    } else if (sectionType === 'testimonial 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'testimonial 1',
      });
    } else if (sectionType === 'testimonial 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'testimonial 2',
      });
    } else if (sectionType === 'testimonial 3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'testimonial 3',
      });
    } else if (sectionType === 'statistic 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'statistic 1',
      });
    } else if (sectionType === 'statistic 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'statistic 2',
      });
    } else if (sectionType === 'statistic 3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'statistic 3',
      });
    } else if (sectionType === 'contact 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'contact 1',
      });
    } else if (sectionType === 'contact 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'contact 2',
      });
    } else if (sectionType === 'contact 3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'contact 3',
      });
    } else if (sectionType === 'cta 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'cta 1',
      });
    } else if (sectionType === 'cta 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'cta 2',
      });
    } else if (sectionType === 'cta 3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'cta 3',
      });
    } else if (sectionType === 'cta 4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'cta 4',
      });
    } else if (sectionType === 'hero 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero 1',
      });
    } else if (sectionType === 'hero 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero 2',
      });
    } else if (sectionType === 'hero 3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero 3',
      });
    } else if (sectionType === 'hero 4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero 4',
      });
    } else if (sectionType === 'hero 5') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero 5',
      });
    } else if (sectionType === 'hero 6') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero 6',
      });
    } else if (sectionType === 'pricing 1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'pricing 1',
      });
    } else if (sectionType === 'pricing 2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'pricing 2',
      });
    }
  };

  const handleAddSection = (index, sectionType) => {
    console.log(flexBoxValue, 'flexBoxValue=-=-=->>>');
    console.log(sectionType, 'sectionType=-=-=->>>');

    if (editSectionClear) {
      setSelectedIndex('flex_cols');
      directItemAdd(sectionType);
    }
    setIsButtonEnabled(true);
    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);

    let newSection;

    if (sectionType === 'heading') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { title: 'Your Content Heading here...', htmlTag: 'h2' },
        blockName: 'heading',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'grid') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: '',
        blockName: 'grid',
        sectionId: elementorId,
      };
    } else if (sectionType === 'image') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          url: bgImageDefault,
          imageSize: {
            width: '300',
            height: '300',
          },
          link: '',
        },
        blockName: 'image',
        sectionId: elementorId,
        style: {
          image_align: '',
          image_width: '',
          image_maxWidth: '',
          image_maxHeight: '',
          image_height: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_color: '',
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          opacity: '',
          image_rotate: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          width_unit: 'px',
          height_unit: 'px',
        },
      };
    } else if (sectionType === 'textEditor') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          textEditorValue:
            '<p>This is a sample text editor. You can edit this content, add your own words, or format the text as needed.</p>',
        },
        blockName: 'textEditor',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'video') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          item: {
            source: 'youtube',
            link: 'https://www.youtube.com/watch?v=668nUCeBHyY',
            startTime: '',
            endTime: '',
            autoPlay: true,
            mute: false,
            loop: false,
            playerControl: true,
            modestBranding: false,
            privacyMode: true,
            lazyLoad: false,
            introTitle: true,
            introPortrait: true,
            introByLine: true,
          },
        },
        blockName: 'video',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          aspect_ratio: '',
          width: '',
          video_align: '',
          video_rotate: '',
          background_position: '',
          aspectRatio: '',
        },
      };
    } else if (sectionType === 'button') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { text: 'Click Here', link: '#', icon: '' },
        blockName: 'button',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          text_shadow_color: '',
          font_shadow_blur: '',
          font_shadow_horizontal: '',
          font_shadow_vertical: '',
          font_shadow_spread: '',
          button_padding_top: '',
          button_padding_right: '',
          button_padding_bottom: '',
          button_padding_left: '',
          button_padding_all: '',
          button_border_style: '',
          button_border_top: '',
          button_border_right: '',
          button_border_bottom: '',
          button_border_left: '',
          button_border_all: '',
          button_border_radius_top: '',
          button_border_radius_right: '',
          button_border_radius_bottom: '',
          button_border_radius_left: '',
          button_border_radius_all: '',
          button_border_color: '',
          button_background: '#61CE70',
          button_background_gradient: 'button_classic',
          button_gradient_color_start: '',
          button_gradient_start_position: '',
          button_gradient_color_end: '',
          button_gradient_end_position: '',
          button_gradient_angle: '',
          button_box_shadow_color: '',
          button_box_shadow_blur: '',
          button_box_shadow_horizontal: '',
          button_box_shadow_vertical: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'divider') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          width: '100',
          style: 'solid',
          align: 'left',
          element: 'none',
          text: 'Divider',
          icon: '<svg aria-hidden="true" class="e-font-icon-svg e-fas-star" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>',
        },
        blockName: 'divider',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          divider_weight: '',
          gap: '',
          divider_color: '',
          divider_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          divider_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          maxHeight: '',
          height_unit: 'px',
        },
      };
    } else if (sectionType === 'spacer') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { width: '40' },
        blockName: 'spacer',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'googleMap') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          location: 'TemplateTrip, 4030, Central Bazzar, Varachha Main Rd, Surat, Gujarat 395006',
          latitude: 21.2100675,
          longitude: 72.849892,
        },
        blockName: 'googleMap',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          zoom: '',
          map_color: '',
          map_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          map_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
        },
      };
    } else if (sectionType === 'icon') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          icon: " <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svg>",
          link: '',
        },
        blockName: 'icon',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          icon_color: '',
          icon_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          icon_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
        },
      };
    } else if (sectionType === 'imageBox') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          url: bgImageDefault,
          imageSize: {
            width: '300',
            height: '300',
          },
          link: '',
          title: 'This is the heading',
          description:
            '<p>This is your image box. Add a title, description, or captions to complement the image.</p>',
          htmlTag: 'h2',
        },
        blockName: 'imageBox',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_maxWidth: '',
          image_maxHeight: '',
          image_height: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_spacing: '',
          content_spacing: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          width_unit: 'px',
          height_unit: 'px',
        },
      };
    } else if (sectionType === 'generalIconBox') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,
        content: {
          icon: `<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svgxmlns=>`,
          link: '',
          title: 'This is the heading',
          description:
            '<p>This is your icon box. Add a title, description, or captions to complement the icon.</p>',
          htmlTag: 'h2',
        },
        blockName: 'generalIconBox',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_box_shadow_blur: '',
          icon_border_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'generalIconList') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              text: 'List Item #1',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-check' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'><path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>`,
            },
            {
              id: 2,
              text: 'List Item #2',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-times' viewBox='0 0 352 512'  xmlns='http://www.w3.org/2000/svg'><path d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>`,
            },
            {
              id: 3,
              text: 'List Item #3',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-dot-circle' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'> <path d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z'></path> </svg>`,
            },
          ],
        },
        blockName: 'generalIconList',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          icon_width: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
          icon_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          space_between: '',
          item_align: '',
          divider_visible: '',
          divider_style: '',
          divider_weight: '',
          divider_width: '',
          divider_color: '',
          horizontal_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'counter') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          start: 0,
          end: 100,
          duration: 2000,
          title: 'Cool Number',
          htmlTag: 'h2',
          prefix: '',
          suffix: '',
        },
        blockName: 'counter',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          number_shadow_color: '',
          number_shadow_blur: '',
          number_shadow_horizontal: '',
          number_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          counter_font_weight: '',
          counter_font_size: '',
          counter_font_height: '',
          counter_font_color: '',
          counter_font_align: '',
          counter_font_style: '',
          counter_font_decoration: '',
          counter_font_capitalize: '',
          counter_font_break: '',
          counter_font_wrap: '',
          counter_letter_spacing: '',
          counter_word_spacing: '',
          counter_text_stroke: '',
          counter_stroke_color: '',
          title_position: '',
          title_horizontal_alignment: '',
          title_vertical_alignment: '',
          number_position: '',
          title_spacing: '',
        },
      };
    } else if (sectionType === 'testimonial') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          url: bgImageDefault,
          imageSize: '',
          name: 'John Doe',
          title: 'Designer',
          description:
            '<p>This is your testimonial section.Add your name, a brief bio, and a profile picture to personalize your space.</p>',
          position: 'aside',
          alignment: 'center',
        },
        blockName: 'testimonial',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          name_font_weight: '',
          name_font_size: '',
          name_font_height: '',
          name_font_color: '',
          name_font_align: '',
          name_font_decoration: '',
          name_font_capitalize: '',
          name_font_break: '',
          name_font_wrap: '',
          name_letter_spacing: '',
          name_word_spacing: '',
          name_text_stroke: '',
          name_stroke_color: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          width_unit: 'px',
          height_unit: 'px',
          image_width: '',
          image_height: '',
          image_maxWidth: '',
          image_maxHeight: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          name_shadow_color: '',
          name_shadow_blur: '',
          name_shadow_horizontal: '',
          name_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'tabs') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              tabName: 'tab-1',
              tabDescription:
                'This is the Tab section. Learn more details and information in this section.',
              isEdit: false,
            },
            {
              id: 2,
              tabName: 'tab-2',
              tabDescription:
                'This is the Tab section. Learn more details and information in this section.',
              isEdit: false,
            },
          ],
        },
        blockName: 'tabs',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      };
    } else if (sectionType === 'accordion') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              accordionName: 'accordion-1',
              accordionDescription:
                'This is Accordion Section.Each section can be opened or closed to show additional information',
              isEdit: false,
            },
            {
              id: 2,
              accordionName: 'accordion-2',
              accordionDescription:
                'This is Accordion Section.Each section can be opened or closed to show additional information',
              isEdit: false,
            },
          ],
        },
        blockName: 'accordion',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      };
    } else if (sectionType === 'socialIcon') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-facebook' fill='#3b5998' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z'></path></svg>`,
            },
            {
              id: 2,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-twitter' fill='#1da1f2' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'></path> </svg>`,
            },
            {
              id: 3,
              link: '#',
              icon: `<svg  class='e-font-icon-svg e-fab-youtube' fill='#cd201f' viewBox='0 0 576 512'  xmlns='http://www.w3.org/2000/svg'> <path d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'></path></svg>`,
            },
          ],
        },
        blockName: 'socialIcon',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_border_color: '',
          icon_spacing: '',
          icon_border_radius_all: '',
          icon_padding: '',
          icon_align: '',
          icon_color: '',
        },
      };
    } else if (sectionType === 'alert') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          title: 'This is an Alert',
          content: 'I am a description. Click the edit button to change this text.',
          alertType: 'info',
          showIcon: true,
          icon: `<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-x-lg' viewBox='0 0 16 16'> <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' /></svg>`,
        },
        blockName: 'alert',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_color: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          alert_background: '',
        },
      };
    } else if (sectionType === 'html') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: '<h1>Write Your Code</h1>',
        },
        blockName: 'html',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          html_align: '',
          html_rotate: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'rating') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,
        content: {
          totalRate: 5,
          initialRate: 2,
          icon: `<svg fill='#ccd6df' width='24' height='24' viewBox='0 0 24 24'  xmlns='http://www.w3.org/2000/svg' stroke='none' class='cursor-pointer'><path d='M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z'></path></svg>`,
        },
        blockName: 'rating',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          icon_color: '',
          unmarked_color: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
          icon_spacing: '',
        },
      };
    } else if (sectionType === 'textPath') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'textPath',
      };
    } else if (sectionType === 'mediaImage') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'mediaImage',
      };
    } else if (sectionType === 'mediaVideo') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'mediaVideo',
      };
    } else if (sectionType === 'youtube') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'youtube',
      };
    } else if (sectionType === 'formBlock') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'formBlock',
      };
    } else if (sectionType === 'label') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'label',
      };
    } else if (sectionType === 'input') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'input',
      };
    } else if (sectionType === 'fileUpload') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'fileUpload',
      };
    } else if (sectionType === 'textArea') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'textArea',
      };
    } else if (sectionType === 'checkBox') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'checkBox',
      };
    } else if (sectionType === 'radioButton') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'radioButton',
      };
    } else if (sectionType === 'select') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'select',
      };
    } else if (sectionType === 'formButton') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'formButton',
      };
    }

    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      console.log(updatedSections, 'updatedSections=-=-=->>');
      console.log(gridValue, 'updatedSections=-=-=->>');

      if (!updatedSections[gridValue]) {
        updatedSections[gridValue] = [];
      }

      if (!updatedSections[gridValue][index]) {
        updatedSections[gridValue][index] = [];
      }
      const sectionArray = updatedSections[gridValue][index];
      const newSectionWithProps = {
        ...newSection,
        orderId: sectionArray?.length + 1,
      };

      if (sectionType === 'grid') {
        //one section add only
        // const existingSections = updatedSections[gridValue][index];
        // const hasHeading = existingSections?.some(section => section.blockName === 'heading');
        // if (hasHeading) {
        //   if (sectionType !== 'heading') {
        //     return prevSections;
        //   }
        // }
        const gridSectionExists = updatedSections[gridValue][index]?.some(
          (section) => section?.blockName === 'grid',
        );

        if (!gridSectionExists) {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }
      } else if (
        selectedIndex === 'grid_two_rows' ||
        selectedIndex === 'grid_two_cols' ||
        selectedIndex === 'grid_three_cols' ||
        selectedIndex === 'grid_three_rows' ||
        selectedIndex === 'grid_two_for_two' ||
        selectedIndex === 'grid_three_for_three'
      ) {
        const sectionExists = updatedSections[gridValue][index]?.some(
          (section) =>
            section?.type === 'grid_two_rows' ||
            section?.type === 'grid_two_cols' ||
            section?.type === 'grid_three_cols' ||
            section?.type === 'grid_three_rows' ||
            section?.type === 'grid_two_for_two' ||
            section?.type === 'grid_three_for_three',
        );

        if (!sectionExists) {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }
      } else {
        updatedSections[gridValue][index]?.push(newSectionWithProps);
      }

      setGetLayout((prevLayout) => {
        const updatedLayout = prevLayout.map((item) => {
          if (item.section_id === newSectionWithProps.sectionId) {
            return {
              ...item,
              section: {
                ...item.section,
                [gridValue]: item.section?.[gridValue]
                  ? [...item.section[gridValue], newSectionWithProps]
                  : [newSectionWithProps],
              },
            };
          }
          return item;
        });

        return updatedLayout;
      });

      return updatedSections;
    });
  };

  const directItemAdd = (sectionType) => {
    function generateCustomFlexBoxId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const flexId = generateCustomFlexBoxId(24);
    const newSections = [...flexBoxValue, { type: 'flex_cols', id: flexId }];
    const new_index = newSections?.length - 1;
    console.log(newSections, sectionType, 'newSections=-=-=-=>>>');
    setFlexBoxValue(newSections);
    setElementorId(flexId);
    const updatedGetLayout = [
      ...getLayout,
      {
        section_id: flexId,
        section_type: 'flex_cols',
        status: true,
        sort_order: getLayout.length,
        section: { [gridValue]: [] },
        flexBoxIndex: newSections?.length - 1,
        section_name: formatSectionType('flex_cols'),
      },
    ];
    setGetLayout(updatedGetLayout);

    setRemovedIndices((prev) => {
      const newSet = new Set(prev);
      return newSet;
    });

    setEditSection({
      section_id: flexId,
      section_type: 'flex_cols',
    });

    setOpenTab('setting');
    setIsFlexBoxVisible(false);
    setIsGridVisible(false);
    setIsPlusButtonVisible(true);
    setGridValue('firstGrid');
    setActiveIndex(newSections?.length - 1);
    setActiveHeadingIndex({ sectionIndex: null, itemIndex: null });
    setIsButtonEnabled(true);
    setEditSectionClear(false);

    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);
    let newSection;

    if (sectionType === 'heading') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: { title: 'Your Content Heading here...', htmlTag: 'h2' },
        blockName: 'heading',
        sectionId: flexId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'grid') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: '',
        blockName: 'grid',
        sectionId: flexId,
      };
    } else if (sectionType === 'image') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: {
          url: bgImageDefault,
          imageSize: {
            width: '300',
            height: '300',
          },
          link: '',
        },
        blockName: 'image',
        sectionId: flexId,
        style: {
          image_align: '',
          image_width: '',
          image_maxWidth: '',
          image_maxHeight: '',
          image_height: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_color: '',
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          opacity: '',
          image_rotate: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          width_unit: 'px',
          height_unit: 'px',
        },
      };
    } else if (sectionType === 'textEditor') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: {
          textEditorValue:
            '<p>This is a sample text editor. You can edit this content, add your own words, or format the text as needed.</p>',
        },
        blockName: 'textEditor',
        sectionId: flexId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'video') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: {
          item: {
            source: 'youtube',
            link: 'https://www.youtube.com/watch?v=668nUCeBHyY',
            startTime: '',
            endTime: '',
            autoPlay: true,
            mute: false,
            loop: false,
            playerControl: true,
            modestBranding: false,
            privacyMode: true,
            lazyLoad: false,
            introTitle: true,
            introPortrait: true,
            introByLine: true,
          },
        },
        blockName: 'video',
        sectionId: flexId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          aspect_ratio: '',
          width: '',
          video_align: '',
          video_rotate: '',
          background_position: '',
          aspectRatio: '',
        },
      };
    } else if (sectionType === 'button') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: { text: 'Click Here', link: '#', icon: '' },
        blockName: 'button',
        sectionId: flexId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          text_shadow_color: '',
          font_shadow_blur: '',
          font_shadow_horizontal: '',
          font_shadow_vertical: '',
          font_shadow_spread: '',
          button_padding_top: '',
          button_padding_right: '',
          button_padding_bottom: '',
          button_padding_left: '',
          button_padding_all: '',
          button_border_style: '',
          button_border_top: '',
          button_border_right: '',
          button_border_bottom: '',
          button_border_left: '',
          button_border_all: '',
          button_border_radius_top: '',
          button_border_radius_right: '',
          button_border_radius_bottom: '',
          button_border_radius_left: '',
          button_border_radius_all: '',
          button_border_color: '',
          button_background: '#61CE70',
          button_background_gradient: 'button_classic',
          button_gradient_color_start: '',
          button_gradient_start_position: '',
          button_gradient_color_end: '',
          button_gradient_end_position: '',
          button_gradient_angle: '',
          button_box_shadow_color: '',
          button_box_shadow_blur: '',
          button_box_shadow_horizontal: '',
          button_box_shadow_vertical: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'divider') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: {
          width: '100',
          style: 'solid',
          align: 'left',
          element: 'none',
          text: 'Divider',
          icon: '<svg aria-hidden="true" class="e-font-icon-svg e-fas-star" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>',
        },
        blockName: 'divider',
        sectionId: flexId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          divider_weight: '',
          gap: '',
          divider_color: '',
          divider_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          divider_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          maxHeight: '',
          height_unit: 'px',
        },
      };
    } else if (sectionType === 'spacer') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: { width: '40' },
        blockName: 'spacer',
        sectionId: flexId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'googleMap') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: {
          location: 'TemplateTrip, 4030, Central Bazzar, Varachha Main Rd, Surat, Gujarat 395006',
          latitude: 21.2100675,
          longitude: 72.849892,
        },
        blockName: 'googleMap',
        sectionId: flexId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          zoom: '',
          map_color: '',
          map_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          map_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
        },
      };
    } else if (sectionType === 'icon') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: {
          icon: " <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svg>",
          link: '',
        },
        blockName: 'icon',
        sectionId: flexId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          icon_color: '',
          icon_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          icon_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
        },
      };
    } else if (sectionType === 'imageBox') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: {
          url: bgImageDefault,
          imageSize: {
            width: '300',
            height: '300',
          },
          link: '',
          title: 'This is the heading',
          description:
            '<p>This is your image box. Add a title, description, or captions to complement the image.</p>',
          htmlTag: 'h2',
        },
        blockName: 'imageBox',
        sectionId: flexId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_maxWidth: '',
          image_maxHeight: '',
          image_height: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_spacing: '',
          content_spacing: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          width_unit: 'px',
          height_unit: 'px',
        },
      };
    } else if (sectionType === 'generalIconBox') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,
        content: {
          icon: `<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svgxmlns=>`,
          link: '',
          title: 'This is the heading',
          description:
            '<p>This is your icon box. Add a title, description, or captions to complement the icon.</p>',
          htmlTag: 'h2',
        },
        blockName: 'generalIconBox',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_box_shadow_blur: '',
          icon_border_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'generalIconList') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: {
          item: [
            {
              id: 1,
              text: 'List Item #1',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-check' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'><path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>`,
            },
            {
              id: 2,
              text: 'List Item #2',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-times' viewBox='0 0 352 512'  xmlns='http://www.w3.org/2000/svg'><path d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>`,
            },
            {
              id: 3,
              text: 'List Item #3',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-dot-circle' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'> <path d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z'></path> </svg>`,
            },
          ],
        },
        blockName: 'generalIconList',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          icon_width: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
          icon_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          space_between: '',
          item_align: '',
          divider_visible: '',
          divider_style: '',
          divider_weight: '',
          divider_width: '',
          divider_color: '',
          horizontal_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'counter') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: {
          start: 0,
          end: 100,
          duration: 2000,
          title: 'Cool Number',
          htmlTag: 'h2',
          prefix: '',
          suffix: '',
        },
        blockName: 'counter',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          number_shadow_color: '',
          number_shadow_blur: '',
          number_shadow_horizontal: '',
          number_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          counter_font_weight: '',
          counter_font_size: '',
          counter_font_height: '',
          counter_font_color: '',
          counter_font_align: '',
          counter_font_style: '',
          counter_font_decoration: '',
          counter_font_capitalize: '',
          counter_font_break: '',
          counter_font_wrap: '',
          counter_letter_spacing: '',
          counter_word_spacing: '',
          counter_text_stroke: '',
          counter_stroke_color: '',
          title_position: '',
          title_horizontal_alignment: '',
          title_vertical_alignment: '',
          number_position: '',
          title_spacing: '',
        },
      };
    } else if (sectionType === 'testimonial') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: {
          url: bgImageDefault,
          imageSize: '',
          name: 'John Doe',
          title: 'Designer',
          description:
            '<p>This is your testimonial section.Add your name, a brief bio, and a profile picture to personalize your space.</p>',
          position: 'aside',
          alignment: 'center',
        },
        blockName: 'testimonial',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          name_font_weight: '',
          name_font_size: '',
          name_font_height: '',
          name_font_color: '',
          name_font_align: '',
          name_font_decoration: '',
          name_font_capitalize: '',
          name_font_break: '',
          name_font_wrap: '',
          name_letter_spacing: '',
          name_word_spacing: '',
          name_text_stroke: '',
          name_stroke_color: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          width_unit: 'px',
          height_unit: 'px',
          image_width: '',
          image_height: '',
          image_maxWidth: '',
          image_maxHeight: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          name_shadow_color: '',
          name_shadow_blur: '',
          name_shadow_horizontal: '',
          name_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'tabs') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: {
          item: [
            {
              id: 1,
              tabName: 'tab-1',
              tabDescription:
                'This is the Tab section. Learn more details and information in this section.',
              isEdit: false,
            },
            {
              id: 2,
              tabName: 'tab-2',
              tabDescription:
                'This is the Tab section. Learn more details and information in this section.',
              isEdit: false,
            },
          ],
        },
        blockName: 'tabs',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      };
    } else if (sectionType === 'accordion') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: {
          item: [
            {
              id: 1,
              accordionName: 'accordion-1',
              accordionDescription:
                'This is Accordion Section.Each section can be opened or closed to show additional information',
              isEdit: false,
            },
            {
              id: 2,
              accordionName: 'accordion-2',
              accordionDescription:
                'This is Accordion Section.Each section can be opened or closed to show additional information',
              isEdit: false,
            },
          ],
        },
        blockName: 'accordion',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      };
    } else if (sectionType === 'socialIcon') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: {
          item: [
            {
              id: 1,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-facebook' fill='#3b5998' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z'></path></svg>`,
            },
            {
              id: 2,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-twitter' fill='#1da1f2' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'></path> </svg>`,
            },
            {
              id: 3,
              link: '#',
              icon: `<svg  class='e-font-icon-svg e-fab-youtube' fill='#cd201f' viewBox='0 0 576 512'  xmlns='http://www.w3.org/2000/svg'> <path d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'></path></svg>`,
            },
          ],
        },
        blockName: 'socialIcon',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_border_color: '',
          icon_spacing: '',
          icon_border_radius_all: '',
          icon_padding: '',
          icon_align: '',
          icon_color: '',
        },
      };
    } else if (sectionType === 'alert') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: {
          title: 'This is an Alert',
          content: 'I am a description. Click the edit button to change this text.',
          alertType: 'info',
          showIcon: true,
          icon: `<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-x-lg' viewBox='0 0 16 16'> <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' /></svg>`,
        },
        blockName: 'alert',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_color: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          alert_background: '',
        },
      };
    } else if (sectionType === 'html') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: {
          item: '<h1>Write Your Code</h1>',
        },
        blockName: 'html',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          html_align: '',
          html_rotate: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'rating') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,
        content: {
          totalRate: 5,
          initialRate: 2,
          icon: `<svg fill='#ccd6df' width='24' height='24' viewBox='0 0 24 24'  xmlns='http://www.w3.org/2000/svg' stroke='none' class='cursor-pointer'><path d='M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z'></path></svg>`,
        },
        blockName: 'rating',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          icon_color: '',
          unmarked_color: '',
          height: '',
          maxHeight: '',
          width: '',
          maxWidth: '',
          height_unit: 'px',
          width_unit: 'px',
          icon_spacing: '',
        },
      };
    } else if (sectionType === 'textPath') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'textPath',
      };
    } else if (sectionType === 'mediaImage') {
      newSection = {
        id: id,
        type: 'flex_cols',
        sectionId: flexId,

        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'mediaImage',
      };
    } else if (sectionType === 'mediaVideo') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'mediaVideo',
      };
    } else if (sectionType === 'youtube') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'youtube',
      };
    } else if (sectionType === 'formBlock') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'formBlock',
      };
    } else if (sectionType === 'label') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'label',
      };
    } else if (sectionType === 'input') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'input',
      };
    } else if (sectionType === 'fileUpload') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'fileUpload',
      };
    } else if (sectionType === 'textArea') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'textArea',
      };
    } else if (sectionType === 'checkBox') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'checkBox',
      };
    } else if (sectionType === 'radioButton') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'radioButton',
      };
    } else if (sectionType === 'select') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'select',
      };
    } else if (sectionType === 'formButton') {
      newSection = {
        id: id,
        type: 'flex_cols',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'formButton',
      };
    }

    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      console.log(updatedSections, 'updatedSections=-=-=->>');
      console.log(gridValue, 'updatedSections=-=-=->>');

      if (!updatedSections['firstGrid']) {
        updatedSections['firstGrid'] = [];
      }

      if (!updatedSections['firstGrid'][new_index]) {
        updatedSections['firstGrid'][new_index] = [];
      }
      const sectionArray = updatedSections['firstGrid'][new_index];
      const newSectionWithProps = {
        ...newSection,
        orderId: sectionArray?.length + 1,
      };

      if (sectionType === 'grid') {
        const gridSectionExists = updatedSections['firstGrid'][new_index]?.some(
          (section) => section?.blockName === 'grid',
        );

        if (!gridSectionExists) {
          updatedSections['firstGrid'][new_index]?.push(newSectionWithProps);
        }
      } else if (
        selectedIndex === 'grid_two_rows' ||
        selectedIndex === 'grid_two_cols' ||
        selectedIndex === 'grid_three_cols' ||
        selectedIndex === 'grid_three_rows' ||
        selectedIndex === 'grid_two_for_two' ||
        selectedIndex === 'grid_three_for_three'
      ) {
        const sectionExists = updatedSections['firstGrid'][new_index]?.some(
          (section) =>
            section?.type === 'grid_two_rows' ||
            section?.type === 'grid_two_cols' ||
            section?.type === 'grid_three_cols' ||
            section?.type === 'grid_three_rows' ||
            section?.type === 'grid_two_for_two' ||
            section?.type === 'grid_three_for_three',
        );

        if (!sectionExists) {
          updatedSections['firstGrid'][new_index]?.push(newSectionWithProps);
        }
      } else {
        updatedSections['firstGrid'][new_index]?.push(newSectionWithProps);
      }

      setGetLayout((prevLayout) => {
        const updatedLayout = prevLayout.map((item) => {
          if (item.section_id === newSectionWithProps.sectionId) {
            return {
              ...item,
              section: {
                ...item.section,
                ['firstGrid']: item.section?.['firstGrid']
                  ? [...item.section['firstGrid'], newSectionWithProps]
                  : [newSectionWithProps],
              },
            };
          }
          return item;
        });

        return updatedLayout;
      });

      return updatedSections;
    });
  };

  const [headingSetting, setHeadingSetting] = useState({
    title: 'Your heading content here...',
    htmlTag: '<h2>',
  });

  const bgImageDefault =
    'https://pub-032c6d43db934ca6b027735e13fb15f5.r2.dev/100/icons/dummy-img.svg';

  const [bgImage, setBgImage] = useState({
    url: bgImageDefault,
    imageSize: {
      width: '300',
      height: '300',
    },
    link: '',
  });

  const [textEditorValue, setTextEditorValue] = useState({
    textEditorValue:
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
  });

  const [videoValue, setVideoValue] = useState();

  const [buttonValue, setButtonValue] = useState({ text: 'Click Here', link: '#', icon: '' });

  const [dividerValue, setDividerValue] = useState({
    style: 'solid',
    width: '100',
    align: 'left',
    element: 'none',
    text: 'Divider',
  });

  const [spacerValue, setSpacerValue] = useState({
    width: '100',
  });

  const [locationValue, setLocationValue] = useState({
    location: '',
    latitude: '',
    longitude: '',
  });

  const [iconValue, setIconValue] = useState({
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='50'
        height='50'
        fill='currentColor'
        class='bi bi-star-fill'
        viewBox='0 0 16 16'
      >
        <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
      </svg>
    ),
    link: '#',
  });

  const [imageBox, setImageBox] = useState({
    url: bgImageDefault,
    imageSize: {
      width: '300',
      height: '300',
    },
    link: '',
    title: '',
    htmlTag: '',
    description: '',
  });
  const [imageIcon, setImageIcon] = useState({
    icon: bgImageDefault,
    link: '',
    title: '',
    htmlTag: '',
    description: '',
  });

  const [iconListData, setIconListData] = useState({
    item: [
      {
        id: 1,
        text: 'List Item #1',
        link: '#',
        icon: (
          <svg
            aria-hidden='true'
            class='e-font-icon-svg e-fas-check'
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path>
          </svg>
        ),
      },
      {
        id: 2,
        text: 'List Item #2',
        link: '#',
        icon: (
          <svg
            aria-hidden='true'
            class='e-font-icon-svg e-fas-times'
            viewBox='0 0 352 512'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path>
          </svg>
        ),
      },
      {
        id: 3,
        text: 'List Item #3',
        link: '#',
        icon: (
          <svg
            aria-hidden='true'
            class='e-font-icon-svg e-fas-dot-circle'
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z'></path>
          </svg>
        ),
      },
    ],
  });

  const [socialIconData, setsocialIconData] = useState();

  const [numberCounter, setNumberCounter] = useState({
    start: '',
    end: '',
    duration: '',
    title: '',
    htmlTag: '',
    prefix: '',
    suffix: '',
  });

  const [testiMonialValue, setTestiMonialValue] = useState({
    url: bgImageDefault,
    imageSize: '',
    name: '',
    title: '',
    position: '',
    alignment: '',
    description: '',
  });

  const [ratingValue, setRatingValue] = useState({
    icon: bgImageDefault,
    alignment: '',
    initialRate: 2,
    totalRate: 5,
  });

  const [tabValue, setTabValue] = useState();
  const [accordionValue, setAccordionValue] = useState();

  const [alertValue, setAlertValue] = useState({
    alertType: '',
    title: '',
    content: '',
    showIcon: '',
    icon: '',
  });

  const [htmlValue, setHtmlValue] = useState();

  const [firstContentValue, setFirstContentValue] = useState({
    title: 'ROOF PARTY POLAROID',
    htmlTag: 'h6',
    subTitle: 'Master Cleanse Reliac Heirloom',
    subHtmlTag: 'h2',
    button: 'Button',
    gridValue: 4,
    description:
      '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        icon: `<svg
                       fill='none'
                       stroke='currentColor'
                       stroke-linecap='round'
                       stroke-linejoin='round'
                       stroke-width='2'
                       class='w-4 h-4 ml-2'
                      viewBox='0 0 24 24'
                      >
                      <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
        link: '',
        isFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        icon: `<svg
                       fill='none'
                       stroke='currentColor'
                       stroke-linecap='round'
                       stroke-linejoin='round'
                       stroke-width='2'
                       class='w-4 h-4 ml-2'
                      viewBox='0 0 24 24'
                      >
                      <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
        link: '',
        isFill: false,
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        icon: `<svg
                       fill='none'
                       stroke='currentColor'
                       stroke-linecap='round'
                       stroke-linejoin='round'
                       stroke-width='2'
                       class='w-4 h-4 ml-2'
                      viewBox='0 0 24 24'
                      >
                      <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
        link: '',
        isFill: false,
      },
      {
        id: 4,
        title: 'Melanchole',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        icon: `<svg
                       fill='none'
                       stroke='currentColor'
                       stroke-linecap='round'
                       stroke-linejoin='round'
                       stroke-width='2'
                       class='w-4 h-4 ml-2'
                      viewBox='0 0 24 24'
                      >
                      <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
        link: '',
        isFill: false,
      },
    ],
  });

  const [secondContentValue, setSecondContentValue] = useState({
    title: 'Pitchfork Kickstarter Taxidermy',
    htmlTag: 'h1',
    gridValue: 4,
    description:
      '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
    item: [
      {
        id: 1,
        sub_title: 'SUBTITLE',
        sub_title_htmlTag: 'h3',
        title: 'Chichen Itza',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        image: 'https://dummyimage.com/720x400',
        objectFit: 'fill',
      },
      {
        id: 2,
        sub_title: 'SUBTITLE',
        sub_title_htmlTag: 'h3',
        title: 'Colosseum Roma',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        image: 'https://dummyimage.com/720x400',
        objectFit: 'fill',
      },
      {
        id: 3,
        sub_title: 'SUBTITLE',
        sub_title_htmlTag: 'h3',
        title: 'Great Pyramid of Giza',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        image: 'https://dummyimage.com/720x400',
        objectFit: 'fill',
      },
      {
        id: 4,
        sub_title: 'SUBTITLE',
        sub_title_htmlTag: 'h3',
        title: 'San Francisco',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        image: 'https://dummyimage.com/720x400',
        objectFit: 'fill',
      },
    ],
  });

  const [thirdContentValue, setThirdContentValue] = useState({
    title: 'Pitchfork Kickstarter Taxidermy',
    htmlTag: 'h1',
    gridValue: 3,
    button: 'Button',
    description:
      '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='2'
                                  class='w-6 h-6'
                                  viewBox='0 0 24 24'
                                >
                                  <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                                </svg>`,
        link: '',
        isFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='2'
                                  class='w-6 h-6'
                                  viewBox='0 0 24 24'
                                >
                                   <circle cx="6" cy="6" r="3"></circle>
                  <circle cx="6" cy="18" r="3"></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                                </svg>`,
        link: '',
        isFill: false,
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='2'
                                  class='w-6 h-6'
                                  viewBox='0 0 24 24'
                                >
                                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                                </svg>`,
        link: '',
        isFill: false,
      },
      {
        id: 4,
        title: 'Melanchole',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='2'
                                  class='w-6 h-6'
                                  viewBox='0 0 24 24'
                                >
                                     <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
                                </svg>`,
        link: '',
        isFill: false,
      },
      {
        id: 5,
        title: 'Bunker',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='2'
                                  class='w-6 h-6'
                                  viewBox='0 0 24 24'
                                >
                                  <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                                </svg>`,
        link: '',
        isFill: false,
      },
      {
        id: 6,
        title: 'Ramona Falls',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='2'
                                  class='w-6 h-6'
                                  viewBox='0 0 24 24'
                                >
                                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                </svg>`,
        link: '',
        isFill: false,
      },
    ],
  });

  const [forthContentValue, setForthContentValue] = useState({
    title: 'Pitchfork Kickstarter Taxidermy',
    htmlTag: 'h1',
    gridValue: 3,
    button: 'Learn more',
    description:
      '<p>Locavore cardigan small batch roof party blue bottle blog meggings sartorial jean shorts kickstarter migas sriracha church-key synth succulents. Actually taiyaki neutra, distillery gastropub pok pok ugh.</p>',
    sub_title: 'CATEGORIES',
    sub_title_htmlTag: 'h2',
    item: [
      {
        id: 1,
        link: 'First link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 2,
        link: 'Second link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 3,
        link: 'Third link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 4,
        link: 'Forth link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 5,
        link: 'Fifth link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 6,
        link: 'Sixth link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 7,
        link: 'Seventh link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 8,
        link: 'Eighth link',
        link_url: '#',
        isEdit: false,
      },
    ],
  });

  const [fifthContentValue, setFifthContentValue] = useState({
    title: 'Kickstarter Actually Pinterest Brunch Bitters Occupy',
    htmlTag: 'h1',
    button: 'Button',
    description:
      '<p>Taxidermy bushwick celiac master cleanse microdosing seitan. Fashion axe four dollar toast truffaut, direct trade kombucha brunch williamsburg keffiyeh gastropub tousled squid meh taiyaki drinking vinegar tacos.</p>',
    button_link: 'Learn more',
    icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
              >
               <path d='M5 12h14M12 5l7 7-7 7'></path>
              </svg>`,
    link: '',
    isFill: false,
  });

  const [sixContentValue, setSixContentValue] = useState({
    title: 'Phoebe Caulfield',
    htmlTag: 'h1',
    description:
      '<p>Raclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>',
    content:
      '<p>Meggings portland fingerstache lyft, post-ironic fixie man bun banh mi umami everyday carry hexagon locavore direct trade art party. Locavore small batch listicle gastropub farm-to-table lumbersexual salvia messenger bag. Coloring book flannel truffaut craft beer drinking vinegar sartorial, disrupt fashion axe normcore meh butcher. Portland 90 scenester vexillologist forage post-ironic asymmetrical, chartreuse disrupt butcher paleo intelligentsia pabst before they sold out four loko. 3 wolf moon brooklyn.</p>',
    button_link: 'Learn more',
    link_url: '#',
    image: 'https://dummyimage.com/1200x500',
    profile: `<svg
                    fill='none'
                     stroke='currentColor'
                     stroke-linecap='round'
                     stroke-linejoin='round'
                     stroke-width='2'
                     class='w-10 h-10'
                     viewBox='0 0 24 24'
                    >
                      <path d='M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2'></path>
                    <circle cx='12' cy='7' r='4'></circle>
                  </svg>`,
    objectFit: 'fill',
    profileFill: false,
    icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                       >
                          <path d='M5 12h14M12 5l7 7-7 7'></path>
                       </svg>`,
    isFill: false,
  });

  const [sevenContentValue, setSevenContentValue] = useState({
    gridValue: 2,
    item: [
      {
        id: 1,
        title: 'Buy YouTube Videos',
        htmlTag: 'h2',
        description:
          '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
        button: 'Button',
        isEdit: false,
        image: 'https://dummyimage.com/1201x501',
        objectFit: 'fill',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
        button: 'Button',
        isEdit: false,
        image: 'https://dummyimage.com/1201x501',
        objectFit: 'fill',
      },
    ],
  });

  const [eightContentValue, setEightContentValue] = useState({
    gridValue: 3,
    title: 'Space The Final Frontier',
    htmlTag: 'h2',
    description:
      '<p>Street art subway tile salvia four dollar toast bitters selfies quinoa yuccie synth meditation iPhone intelligentsia prism tofu. Viral gochujang bitters dreamcatcher.</p>',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        image: 'https://dummyimage.com/1203x503',
        icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                      viewBox='0 0 24 24'
                    >
                      <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        image: 'https://dummyimage.com/1203x503',
        icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                      viewBox='0 0 24 24'
                    >
                      <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 3,
        title: 'The 400 Blows',
        htmlTag: 'h2',
        description:
          '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        image: 'https://dummyimage.com/1203x503',
        icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                      viewBox='0 0 24 24'
                    >
                      <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
        objectFit: 'fill',
        isFill: false,
      },
    ],
  });

  const [firstFeatureValue, setFirstFeatureValue] = useState({
    gridValue: 3,
    title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
    htmlTag: 'h2',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-6 h-6'
                          viewBox='0 0 24 24'
                        >
                          <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                      </svg>`,
        linkIcon: `<svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                        >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                      </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
           >
              <circle cx="6" cy="6" r="3"></circle>
    <circle cx="6" cy="18" r="3"></circle>
    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
           </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-6 h-6'
                            viewBox='0 0 24 24'
                           >
                           <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                         </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
    ],
  });

  const [secondFeatureValue, setSecondFeatureValue] = useState({
    gridValue: 3,
    title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
    htmlTag: 'h2',
    description:
      '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
    button: 'Button',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
          >
            <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
        </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
           >
              <circle cx="6" cy="6" r="3"></circle>
    <circle cx="6" cy="18" r="3"></circle>
    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
           </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
           >
           <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
         </svg>`,
        linkIcon: `<svg
    fill='none'
    stroke='currentColor'
    stroke-linecap='round'
    stroke-linejoin='round'
    stroke-width='2'
    class='w-4 h-4 ml-2'
    viewBox='0 0 24 24'
    >
    <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
    ],
  });

  const [thirdFeatureValue, setThirdFeatureValue] = useState({
    image: 'https://dummyimage.com/460x500',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
          >
            <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
        </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
           >
              <circle cx="6" cy="6" r="3"></circle>
    <circle cx="6" cy="18" r="3"></circle>
    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
           </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
           >
           <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
         </svg>`,
        linkIcon: `<svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                        >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
    ],
    objectFit: 'fill',
  });

  const [forthFeatureValue, setForthFeatureValue] = useState({
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
          >
            <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
        </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                      >
                      <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                    </svg>`,
        linkIcon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
    ],
  });

  const [fifthFeatureValue, setFifthFeatureValue] = useState({
    title: 'ROOF PARTY POLAROID',
    htmlTag: 'h6',
    subTitle: 'Master Cleanse Reliac Heirloom',
    subHtmlTag: 'h2',
    gridValue: 3,
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
          >
            <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
        </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
           >
           <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
         </svg>`,
        linkIcon: `<svg
    fill='none'
    stroke='currentColor'
    stroke-linecap='round'
    stroke-linejoin='round'
    stroke-width='2'
    class='w-4 h-4 ml-2'
    viewBox='0 0 24 24'
    >
    <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
           >
              <circle cx="6" cy="6" r="3"></circle>
    <circle cx="6" cy="18" r="3"></circle>
    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
           </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
    ],
  });

  const [sixthFeatureValue, setSixthFeatureValue] = useState({
    button: 'Button',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-10 h-10'
            viewBox='0 0 24 24'
          >
            <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
        </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-10 h-10'
            viewBox='0 0 24 24'
           >
              <circle cx="6" cy="6" r="3"></circle>
    <circle cx="6" cy="18" r="3"></circle>
    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
           </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
          viewBox='0 0 24 24'
          >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
      {
        id: 3,
        title: 'The 400 Blows',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-10 h-10'
            viewBox='0 0 24 24'
           >
           <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
         </svg>`,
        linkIcon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
            </svg>`,
        link: '',
        titleIconFill: false,
        linkIconFill: false,
      },
    ],
  });

  const [sevenFeatureValue, setSevenFeatureValue] = useState({
    gridValue: 2,
    title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
    htmlTag: 'h2',
    description:
      '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
    button: 'Button',
    item: [
      {
        id: 1,
        title: 'Authentic Cliche Forage',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
                      viewBox='0 0 24 24'
                   >
                      <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                      <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>`,
        isFill: false,
      },
      {
        id: 2,
        title: 'Kinfolk Chips Snackwave',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
            viewBox='0 0 24 24'
         >
            <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
            <path d='M22 4L12 14.01l-3-3'></path>
        </svg>`,
        isFill: false,
      },
      {
        id: 3,
        title: 'Coloring Book Ethical',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
            viewBox='0 0 24 24'
         >
            <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
            <path d='M22 4L12 14.01l-3-3'></path>
        </svg>`,
        isFill: false,
      },
      {
        id: 4,
        title: 'Typewriter Polaroid Cray',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
            viewBox='0 0 24 24'
         >
            <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
            <path d='M22 4L12 14.01l-3-3'></path>
        </svg>`,
        isFill: false,
      },
      {
        id: 5,
        title: 'Pack Truffaut Blue',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
            viewBox='0 0 24 24'
         >
            <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
            <path d='M22 4L12 14.01l-3-3'></path>
        </svg>`,
        isFill: false,
      },
      {
        id: 6,
        title: 'The Catcher In The Rye',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
            viewBox='0 0 24 24'
         >
            <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
            <path d='M22 4L12 14.01l-3-3'></path>
        </svg>`,
        isFill: false,
      },
    ],
  });

  const [eightFeatureValue, setEightFeatureValue] = useState({
    gridValue: 4,
    title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
    htmlTag: 'h2',
    description:
      '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
    button: 'Button',
    item: [
      {
        id: 1,
        title: 'SHOOTING STARS',
        htmlTag: 'h2',
        isEdit: false,
        subItem: [
          {
            subLink: 'First Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Second Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Third Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Fourth Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Fifth Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
        ],
      },
      {
        id: 2,
        title: 'THE 400 BLOWS',
        htmlTag: 'h2',
        isEdit: false,
        subItem: [
          {
            subLink: 'First Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Second Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Third Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Fourth Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Fifth Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
        ],
      },
      {
        id: 3,
        title: 'THE CATALYZER',
        htmlTag: 'h2',
        isEdit: false,
        subItem: [
          {
            subLink: 'First Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Second Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Third Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Fourth Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Fifth Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
        ],
      },
      {
        id: 4,
        title: 'NEPTUNE',
        htmlTag: 'h2',
        isEdit: false,
        subItem: [
          {
            subLink: 'First Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Second Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Third Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Fourth Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
          {
            subLink: 'Fifth Link',
            icon: `<svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='3'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>`,
            isFill: false,
            iconHeight: '',
            iconWidth: '',
          },
        ],
      },
    ],
  });

  const [secondStepValue, setSecondStepValue] = useState({
    item: [
      {
        id: 1,
        title: 'Master Cleanse Reliac Heirloom',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
          >
            <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
         </svg>`,
        iconTitle: 'Step 1',
        image: 'https://dummyimage.com/720x600',
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h5',
        description:
          'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
          >
           <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
         </svg>`,
        iconTitle: 'Step 2',
        image: 'https://dummyimage.com/720x600',
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 3,
        title: 'The 400 Blows',
        htmlTag: 'h5',
        description:
          'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
        isEdit: false,
        icon: `  <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
          >
           <circle cx="12" cy="5" r="3"></circle>
            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
         </svg>`,
        iconTitle: 'Step 3',
        image: 'https://dummyimage.com/720x600',
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 4,
        title: 'Neptune',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-6 h-6'
            viewBox='0 0 24 24'
          >
           <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
         </svg>`,
        iconTitle: 'Step 4',
        image: 'https://dummyimage.com/720x600',
        objectFit: 'fill',
        isFill: false,
      },
    ],
  });

  const [firstStepValue, setFirstStepValue] = useState({
    item: [
      {
        id: 1,
        title: 'Step 1',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-5 h-5'
            viewBox='0 0 24 24'
          >
            <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
         </svg>`,
        isFill: false,
      },
      {
        id: 2,
        title: 'Step 2',
        htmlTag: 'h5',
        description:
          'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
        isEdit: false,
        icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-5 h-5'
                  viewBox='0 0 24 24'
                >
                 <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
               </svg>`,
        isFill: false,
      },
      {
        id: 3,
        title: 'Step 3',
        htmlTag: 'h5',
        description:
          'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
        isEdit: false,
        icon: `  <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-5 h-5'
                  viewBox='0 0 24 24'
                >
                 <circle cx="12" cy="5" r="3"></circle>
                  <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
               </svg>`,
        isFill: false,
      },
      {
        id: 4,
        title: 'Step 4',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-5 h-5'
                  viewBox='0 0 24 24'
                >
                 <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
               </svg>`,
        isFill: false,
      },
      {
        id: 5,
        title: 'FINISH',
        htmlTag: 'h5',
        description:
          'Pitchfork ugh tattooed scenester echo park gastropub whatever cold-pressed retro.',
        isEdit: false,
        icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-5 h-5'
                  viewBox='0 0 24 24'
                >
                 <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
               </svg>`,
        isFill: false,
      },
    ],
    image: 'https://dummyimage.com/1200x500',
    objectFit: 'fill',
  });

  const [thirdStepValue, setThirdStepValue] = useState({
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-10 h-10'
            viewBox='0 0 24 24'
          >
            <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
         </svg>`,
        isFill: false,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h5',
        description:
          'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-10 h-10'
            viewBox='0 0 24 24'
          >
           <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
         </svg>`,
        isFill: false,
      },
      {
        id: 3,
        title: 'The 400 Blows',
        htmlTag: 'h5',
        description:
          'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
        isEdit: false,
        icon: `  <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-10 h-10'
            viewBox='0 0 24 24'
          >
           <circle cx="12" cy="5" r="3"></circle>
            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
         </svg>`,
        isFill: false,
      },
      {
        id: 4,
        title: 'Neptune',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='w-10 h-10'
            viewBox='0 0 24 24'
          >
           <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
         </svg>`,
        isFill: false,
      },
    ],
  });

  const [firstTeamValue, setFirstTeamValue] = useState({
    title: 'Our Team',
    htmlTag: 'h2',
    gridValue: 3,
    description:
      'Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.',
    item: [
      {
        id: 1,
        title: 'Holden Caulfield',
        htmlTag: 'h2',
        description: 'UI Designer',
        isEdit: false,
        image: 'https://dummyimage.com/80x80',
        objectFit: 'fill',
      },
      {
        id: 2,
        title: 'Henry Letham',
        htmlTag: 'h2',
        description: 'CTO',
        isEdit: false,
        image: 'https://dummyimage.com/84x84',
        objectFit: 'fill',
      },
      {
        id: 3,
        title: 'Oskar Blinde',
        htmlTag: 'h2',
        description: 'Founder',
        isEdit: false,
        image: 'https://dummyimage.com/88x88',
        objectFit: 'fill',
      },
      {
        id: 4,
        title: 'John Doe',
        htmlTag: 'h2',
        description: 'DevOps',
        isEdit: false,
        image: 'https://dummyimage.com/90x90',
        objectFit: 'fill',
      },
      {
        id: 5,
        title: 'Martin Eden',
        htmlTag: 'h2',
        description: 'Software Engineer',
        isEdit: false,
        image: 'https://dummyimage.com/94x94',
        objectFit: 'fill',
      },
      {
        id: 6,
        title: 'Boris Kitua',
        htmlTag: 'h2',
        description: 'UX Researcher',
        isEdit: false,
        image: 'https://dummyimage.com/98x98',
        objectFit: 'fill',
      },
      {
        id: 7,
        title: 'Atticus Finch',
        htmlTag: 'h2',
        description: 'QA Engineer',
        isEdit: false,
        image: 'https://dummyimage.com/100x90',
        objectFit: 'fill',
      },
      {
        id: 8,
        title: 'Alper Kamu',
        htmlTag: 'h2',
        description: 'System',
        isEdit: false,
        image: 'https://dummyimage.com/104x94',
        objectFit: 'fill',
      },
      {
        id: 9,
        title: 'Rodrigo Monchi',
        htmlTag: 'h2',
        description: 'Product Manager',
        isEdit: false,
        image: 'https://dummyimage.com/108x98',
        objectFit: 'fill',
      },
    ],
  });

  const [secondTeamValue, setSecondTeamValue] = useState({
    title: 'Our Team',
    htmlTag: 'h2',
    gridValue: 2,
    description:
      '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.</p>',
    item: [
      {
        id: 1,
        title: 'Holden Caulfield',
        htmlTag: 'h2',
        role_htmlTag: 'h5',
        role: 'UI Designer',
        description:
          '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
        isEdit: false,
        iconList: [
          {
            id: 1,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                      </svg>`,
          },
          {
            id: 2,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                      </svg>`,
          },
          {
            id: 3,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                      </svg>`,
          },
        ],
        image: 'https://dummyimage.com/200x200',
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 2,
        title: 'Henry Letham',
        htmlTag: 'h2',
        role_htmlTag: 'h5',
        role: 'CTO',
        description:
          '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
        isEdit: false,
        iconList: [
          {
            id: 1,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                      </svg>`,
          },
          {
            id: 2,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                      </svg>`,
          },
          {
            id: 3,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                      </svg>`,
          },
        ],
        image: 'https://dummyimage.com/201x201',
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 3,
        title: 'Oskar Blinde',
        htmlTag: 'h2',
        role_htmlTag: 'h5',
        role: 'Founder',
        description:
          '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
        isEdit: false,
        iconList: [
          {
            id: 1,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                      </svg>`,
          },
          {
            id: 2,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                      </svg>`,
          },
          {
            id: 3,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                      </svg>`,
          },
        ],
        image: 'https://dummyimage.com/204x204',
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 4,
        title: 'John Doe',
        htmlTag: 'h2',
        role_htmlTag: 'h5',
        role: 'DevOps',
        description:
          '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
        isEdit: false,
        iconList: [
          {
            id: 1,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                      </svg>`,
          },
          {
            id: 2,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                      </svg>`,
          },
          {
            id: 3,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                      </svg>`,
          },
        ],
        image: 'https://dummyimage.com/206x206',
        objectFit: 'fill',
        isFill: false,
      },
    ],
  });

  const [thirdTeamValue, setThirdTeamValue] = useState({
    title: 'Our Team',
    htmlTag: 'h2',
    gridValue: 2,
    description:
      '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.</p>',
    item: [
      {
        id: 1,
        title: 'Holden Caulfield',
        htmlTag: 'h2',
        role_htmlTag: 'h5',
        role: 'UI Designer',
        description:
          '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
        isEdit: false,
        iconList: [
          {
            id: 1,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                      </svg>`,
          },
          {
            id: 2,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                      </svg>`,
          },
          {
            id: 3,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                      </svg>`,
          },
        ],
        image: 'https://dummyimage.com/200x200',
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 2,
        title: 'Henry Letham',
        htmlTag: 'h2',
        role_htmlTag: 'h5',
        role: 'CTO',
        description:
          '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
        isEdit: false,
        iconList: [
          {
            id: 1,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                      </svg>`,
          },
          {
            id: 2,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                      </svg>`,
          },
          {
            id: 3,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                      </svg>`,
          },
        ],
        image: 'https://dummyimage.com/201x201',
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 3,
        title: 'Oskar Blinde',
        htmlTag: 'h2',
        role_htmlTag: 'h5',
        role: 'Founder',
        description:
          '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
        isEdit: false,
        iconList: [
          {
            id: 1,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                      </svg>`,
          },
          {
            id: 2,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                      </svg>`,
          },
          {
            id: 3,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                      </svg>`,
          },
        ],
        image: 'https://dummyimage.com/203x203',
        objectFit: 'fill',
        isFill: false,
      },
      {
        id: 4,
        title: 'John Doe',
        htmlTag: 'h2',
        role_htmlTag: 'h5',
        role: 'DevOps',
        description:
          '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
        isEdit: false,
        iconList: [
          {
            id: 1,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                      </svg>`,
          },
          {
            id: 2,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                      </svg>`,
          },
          {
            id: 3,
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          class='w-5 h-5'
                          viewBox='0 0 24 24'
                        >
                        <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                      </svg>`,
          },
        ],
        image: 'https://dummyimage.com/204x204',
        objectFit: 'fill',
        isFill: false,
      },
    ],
  });

  const [firstTestimonialValue, setFirstTestimonialValue] = useState({
    title: 'Testimonials',
    htmlTag: 'h2',
    gridValue: 2,
    item: [
      {
        id: 1,
        title: 'Holden Caulfield',
        htmlTag: 'h5',
        role_htmlTag: 'p',
        role: 'UI Designer',
        description:
          '<p>Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90s microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.</p>',
        isEdit: false,
        profile: 'https://dummyimage.com/106x106',
        profileFill: false,
        icon: ` <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          class='block w-5 h-5 text-gray-400 mb-4'
                          viewBox='0 0 975.036 975.036'
                        >
                          <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                        </svg>`,
        isFill: false,
      },
      {
        id: 2,
        title: 'Henry Letham',
        htmlTag: 'h6',
        role_htmlTag: 'p',
        role: 'CTO',
        description:
          '<p>Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90s microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.</p>',
        isEdit: false,
        profile: 'https://dummyimage.com/107x107',
        profileFill: false,
        icon: ` <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          class='block w-5 h-5 text-gray-400 mb-4'
                          viewBox='0 0 975.036 975.036'
                        >
                          <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                        </svg>`,
        isFill: false,
      },
    ],
  });

  const [secondTestimonialValue, setSecondTestimonialValue] = useState({
    title: 'HOLDEN CAULFIELD',
    role: 'Senior Product Designer',
    htmlTag: 'h2',
    role_htmlTag: 'p',
    description:
      '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyaki taxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware. Man bun next level coloring book skateboard four loko knausgaard. Kitsch keffiyeh master cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90s pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>',
    icon: `  <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='currentColor'
                        class='inline-block w-8 h-8 text-gray-400 mb-8'
                        viewBox='0 0 975.036 975.036'
                      >
                        <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                      </svg>`,
    isFill: false,
  });

  const [thirdTestimonialValue, setThirdTestimonialValue] = useState({
    gridValue: 3,
    item: [
      {
        id: 1,
        title: 'Holden Caulfield',
        htmlTag: 'h5',
        role_htmlTag: 'p',
        role: 'UI Designer',
        description:
          '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyakitaxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyakivape DIY tote bag drinking vinegar cronut adaptogen squid fanny packvaporware.</p>',
        isEdit: false,
        profile: 'https://dummyimage.com/302x302',
        profileFill: false,
      },
      {
        id: 2,
        title: 'Henry Letham',
        htmlTag: 'h6',
        role_htmlTag: 'p',
        role: 'CTO',
        description:
          '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyakitaxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyakivape DIY tote bag drinking vinegar cronut adaptogen squid fanny packvaporware.</p>',
        isEdit: false,
        profile: 'https://dummyimage.com/303x303',
        profileFill: false,
      },
      {
        id: 3,
        title: 'Henry Letham',
        htmlTag: 'h6',
        role_htmlTag: 'p',
        role: 'CTO',
        description:
          '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyakitaxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyakivape DIY tote bag drinking vinegar cronut adaptogen squid fanny packvaporware.</p>',
        isEdit: false,
        profile: 'https://dummyimage.com/305x305',
        profileFill: false,
      },
    ],
  });

  const [firstStatisticValue, setFirstStatisticValue] = useState({
    gridValue: 4,
    item: [
      {
        id: 1,
        start: 0,
        end: 100,
        duration: 2000,
        title: 'Users',
        htmlTag: 'p',
        prefix: '',
        suffix: 'K',
      },
      {
        id: 2,
        start: 0,
        end: 200,
        duration: 2000,
        title: 'Subscribes',
        htmlTag: 'p',
        prefix: '',
        suffix: '',
      },
      {
        id: 3,
        start: 0,
        end: 300,
        duration: 2000,
        title: 'Downloads',
        htmlTag: 'p',
        prefix: '',
        suffix: '',
      },
      {
        id: 4,
        start: 0,
        end: 400,
        duration: 2000,
        title: 'Products',
        htmlTag: 'p',
        prefix: '',
        suffix: '',
      },
    ],
  });

  const [secondStatisticValue, setSecondStatisticValue] = useState({
    gridValue: 4,
    title: 'Moon hashtag pop-up try-hard offal truffaut',
    htmlTag: 'h2',
    description:
      '<p>Pour-over craft beer pug drinking vinegar live-edge gastropub, keytar neutrasustainable fingerstache kickstarter.</p>',
    item: [
      {
        id: 1,
        start: 0,
        end: 100,
        duration: 2000,
        title: 'Users',
        htmlTag: 'p',
        prefix: '',
        suffix: 'K',
      },
      {
        id: 2,
        start: 0,
        end: 200,
        duration: 2000,
        title: 'Subscribes',
        htmlTag: 'p',
        prefix: '',
        suffix: '',
      },
      {
        id: 3,
        start: 0,
        end: 300,
        duration: 2000,
        title: 'Downloads',
        htmlTag: 'p',
        prefix: '',
        suffix: '',
      },
      {
        id: 4,
        start: 0,
        end: 400,
        duration: 2000,
        title: 'Products',
        htmlTag: 'p',
        prefix: '',
        suffix: '',
      },
    ],
    image: 'https://dummyimage.com/600x300',
    objectFit: 'fill',
  });

  const [thirdStatisticValue, setThirdStatisticValue] = useState({
    gridValue: 4,
    title: 'Moon hashtag pop-up try-hard offal truffaut',
    htmlTag: 'h2',
    description:
      '<p>Pour-over craft beer pug drinking vinegar live-edge gastropub, keytar neutrasustainable fingerstache kickstarter.</p>',
    item: [
      {
        id: 1,
        start: 0,
        end: 100,
        duration: 2000,
        title: 'Users',
        htmlTag: 'p',
        prefix: '',
        suffix: 'K',
        icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='text-indigo-500 w-12 h-12 mb-3 inline-block'
            viewBox='0 0 24 24'
          >
            <path d='M8 17l4 4 4-4m-4-5v9'></path>
            <path d='M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29'></path>
          </svg>`,
        isFill: false,
      },
      {
        id: 2,
        start: 0,
        end: 200,
        duration: 2000,
        title: 'Subscribes',
        htmlTag: 'p',
        prefix: '',
        suffix: '',
        icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='text-indigo-500 w-12 h-12 mb-3 inline-block'
            viewBox='0 0 24 24'
          >
             <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
          </svg>`,
        isFill: false,
      },
      {
        id: 3,
        start: 0,
        end: 300,
        duration: 2000,
        title: 'Downloads',
        htmlTag: 'p',
        prefix: '',
        suffix: '',
        icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='text-indigo-500 w-12 h-12 mb-3 inline-block'
            viewBox='0 0 24 24'
          >
           <path d="M3 18v-6a9 9 0 0118 0v6"></path>
           <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
          </svg>`,
        isFill: false,
      },
      {
        id: 4,
        start: 0,
        end: 400,
        duration: 2000,
        title: 'Products',
        htmlTag: 'p',
        prefix: '',
        suffix: '',
        icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            class='text-indigo-500 w-12 h-12 mb-3 inline-block'
            viewBox='0 0 24 24'
          >
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>`,
        isFill: false,
      },
    ],
  });

  const [firstContactValue, setFirstContactValue] = useState({
    title: 'Feedback',
    htmlTag: 'h2',
    description: '<p>Post-ironic portland shabby chic echo park, banjo fashion axe</p>',
    note: 'Chicharrones blog helvetica normcore iceland tousled brook viral artisan.',
    shortCode: '',
  });

  const [secondContactValue, setSecondContactValue] = useState({
    title: 'Feedback',
    htmlTag: 'h2',
    description: '<p>Post-ironic portland shabby chic echo park, banjo fashion axe</p>',
    note: 'Chicharrones blog helvetica normcore iceland tousled brook viral artisan.',
    address_title: 'Address',
    address_title_htmlTag: 'h2',
    address_value: 'Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter',
    email_title: 'Email',
    email_title_htmlTag: 'h2',
    email_value: 'example@email.com',
    phone_title: 'Phone',
    phone_title_htmlTag: 'h2',
    phone_value: '123-456-7890',
    shortCode: '',
  });

  const [thirdContactValue, setThirdContactValue] = useState({
    title: 'Feedback',
    htmlTag: 'h2',
    description: '<p>Post-ironic portland shabby chic echo park, banjo fashion axe</p>',
    address_value: 'Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter',
    email_value: 'example@email.com',
    shortCode: '',
    iconArray: [
      {
        id: 1,
        icon: `
                  <svg
                    fill='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                    <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                </svg>`,
        isFill: false,
        link: '',
      },
      {
        id: 2,
        icon: `
                 <svg
                    fill='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-5 h-5'
                    viewBox='0 0 24 24'
                >
                  <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                </svg>`,
        isFill: false,
        link: '',
      },
      {
        id: 3,
        icon: `
                   <svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                    <rect width='20' height='20' x='2' y='2' rx='5' ry='5'></rect>
                    <path d='M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01'></path>
                  </svg>`,
        isFill: false,
        link: '',
      },
      {
        id: 4,
        icon: `
                  <svg
                    fill='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-5 h-5'
                    viewBox='0 0 24 24'
                  >
                   <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                 </svg>`,
        isFill: false,
        link: '',
      },
    ],
  });

  const [firstCtaValue, setFirstCtaValue] = useState({
    description:
      '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
    button: 'Button',
  });

  const [secondCtaValue, setSecondCtaValue] = useState({
    description:
      '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
    title: 'Slow-carb next level shoindcgoitch ethical authentic, poko scenester',
    htmlTag: 'h2',
    form_title: 'Sign Up',
    form_title_htmlTag: 'h2',
    note: 'Literally you probably have not heard of them jean shorts.',
    shortCode: '',
  });

  const [thirdCtaValue, setThirdCtaValue] = useState({
    description:
      '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
    title: 'Slow-carb next level shoindcgoitch ethical authentic, poko scenester',
    htmlTag: 'h2',
    shortCode: '',
  });

  const [forthCtaValue, setForthCtaValue] = useState({
    description:
      '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
    title: 'Slow-carb next level shoindcgoitch ethical authentic, poko scenester',
    htmlTag: 'h2',
    first_btn_title: 'GET IT ON',
    first_btn_sub_title: 'Google Play',
    first_btn_link: '#',
    second_btn_title: 'GET IT ON',
    second_btn_sub_title: 'Google Play',
    second_btn_link: '#',
  });

  const [firstHeroValue, setFirstHeroValue] = useState({
    description:
      '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
    title: 'Before they sold out readymade gluten',
    htmlTag: 'h2',
    first_btn: 'Button',
    second_btn: 'Button',
    image: 'https://dummyimage.com/720x600',
    objectFit: 'fill',
  });

  const [secondHeroValue, setSecondHeroValue] = useState({
    description:
      '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
    title: 'Before they sold out readymade gluten',
    htmlTag: 'h2',
    first_btn: 'Button',
    second_btn: 'Button',
    image: 'https://dummyimage.com/720x600',
    objectFit: 'fill',
  });

  const [thirdHeroValue, setThirdHeroValue] = useState({
    description:
      '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
    title: 'Before they sold out readymade gluten',
    htmlTag: 'h2',
    first_btn: 'Button',
    second_btn: 'Button',
    image: 'https://dummyimage.com/720x600',
    objectFit: 'fill',
  });

  const [forthHeroValue, setForthHeroValue] = useState({
    description:
      '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
    title: 'Before they sold out readymade gluten',
    htmlTag: 'h2',
    first_btn_title: 'GET IT ON',
    first_btn_sub_title: 'Google Play',
    first_btn_link: '#',
    second_btn_title: 'GET IT ON',
    second_btn_sub_title: 'Google Play',
    second_btn_link: '#',
    note: 'Neutra shabby chic ramps, viral fixie.',
    shortCode: '',
    image: 'https://dummyimage.com/720x600',
    objectFit: 'fill',
    first_icon: `
    <svg
       xmlns='http://www.w3.org/2000/svg'
       fill='currentColor'
       class='w-6 h-6'
       viewBox='0 0 512 512'
     >
       <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
    </svg>`,
    second_icon: `
   <svg
       xmlns='http://www.w3.org/2000/svg'
       fill='currentColor'
       class='w-6 h-6'
       viewBox='0 0 305 305'
     >
       <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
       <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
   </svg>`,
  });

  const [fifthHeroValue, setFifthHeroValue] = useState({
    description:
      '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
    title: 'Before they sold out readymade gluten',
    htmlTag: 'h2',
    first_btn_title: 'GET IT ON',
    first_btn_sub_title: 'Google Play',
    first_btn_link: '#',
    second_btn_title: 'GET IT ON',
    second_btn_sub_title: 'Google Play',
    second_btn_link: '#',
    note: 'Neutra shabby chic ramps, viral fixie.',
    shortCode: '',
    image: 'https://dummyimage.com/720x600',
    objectFit: 'fill',
    first_icon: `
    <svg
       xmlns='http://www.w3.org/2000/svg'
       fill='currentColor'
       class='w-6 h-6'
       viewBox='0 0 512 512'
     >
       <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
    </svg>`,
    second_icon: `
   <svg
       xmlns='http://www.w3.org/2000/svg'
       fill='currentColor'
       class='w-6 h-6'
       viewBox='0 0 305 305'
     >
       <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
       <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
   </svg>`,
  });

  const [sixthHeroValue, setSixthHeroValue] = useState({
    description:
      '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
    title: 'Before they sold out readymade gluten',
    htmlTag: 'h2',
    first_btn_title: 'GET IT ON',
    first_btn_sub_title: 'Google Play',
    first_btn_link: '#',
    second_btn_title: 'GET IT ON',
    second_btn_sub_title: 'Google Play',
    second_btn_link: '#',
    note: 'Neutra shabby chic ramps, viral fixie.',
    shortCode: '',
    image: 'https://dummyimage.com/720x600',
    objectFit: 'fill',
    first_icon: `
    <svg
       xmlns='http://www.w3.org/2000/svg'
       fill='currentColor'
       class='w-6 h-6'
       viewBox='0 0 512 512'
     >
       <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
    </svg>`,
    second_icon: `
   <svg
       xmlns='http://www.w3.org/2000/svg'
       fill='currentColor'
       class='w-6 h-6'
       viewBox='0 0 305 305'
     >
       <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
       <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
   </svg>`,
  });

  const [firstPricingValue, setFirstPricingValue] = useState({
    title: 'Pricing',
    htmlTag: 'h1',
    gridValue: 4,
    description: '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical.</p>',
    item: [
      {
        id: 1,
        title: 'Start',
        htmlTag: 'h3',
        price: 'Free',
        button: 'Button',
        time_period: '/mon',
        description: '<p>Literally you probably have not heard of them jean shorts.</p>',
        isEdit: false,
        subItem: [
          {
            subLink: 'Vexillologist pitchfork',
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2.5'
                          class='w-3 h-3'
                          viewBox='0 0 24 24'
                        >
                          <path d='M20 6L9 17l-5-5'></path>
                        </svg>`,
            isFill: false,
          },
          {
            subLink: 'Tumeric plaid portland',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
          {
            subLink: 'Mixtape chillwave tumeric',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
        ],
      },
      {
        id: 2,
        title: 'PRO',
        htmlTag: 'h3',
        price: '$38',
        button: 'Button',
        time_period: '/mon',
        description: '<p>Literally you probably have not heard of them jean shorts.</p>',
        isEdit: false,
        subItem: [
          {
            subLink: ' Vexillologist pitchfork',
            icon: ` <svg
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2.5'
                          class='w-3 h-3'
                          viewBox='0 0 24 24'
                        >
                          <path d='M20 6L9 17l-5-5'></path>
                        </svg>`,
            isFill: false,
          },
          {
            subLink: 'Tumeric plaid portland',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
          {
            subLink: 'Mixtape chillwave tumeric',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
        ],
      },
      {
        id: 3,
        title: 'BUSINESS',
        htmlTag: 'h3',
        price: '$56',
        button: 'Button',
        time_period: '/mon',
        description: '<p>Literally you probably have not heard of them jean shorts.</p>',
        isEdit: false,
        subItem: [
          {
            subLink: ' Vexillologist pitchfork',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
          {
            subLink: 'Tumeric plaid portland',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
          {
            subLink: 'Mixtape chillwave tumeric',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
        ],
      },
      {
        id: 4,
        title: 'SPECIAL',
        htmlTag: 'h3',
        price: '$72',
        button: 'Button',
        time_period: '/mon',
        description: '<p>Literally you probably have not heard of them jean shorts.</p>',
        isEdit: false,
        subItem: [
          {
            subLink: ' Vexillologist pitchfork',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
          {
            subLink: 'Tumeric plaid portland',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
          {
            subLink: 'Mixtape chillwave tumeric',
            icon: ` <svg
                fill='none'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2.5'
                class='w-3 h-3'
                viewBox='0 0 24 24'
              >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
            isFill: false,
          },
        ],
      },
    ],
  });

  const [secondPricingValue, setSecondPricingValue] = useState({
    title: 'Pricing',
    htmlTag: 'h1',
    description:
      '<p>Banh mi cornhole echo park skateboard authentic crucifix neutra tilde lyft biodiesel artisan direct trade mumblecore 3 wolf moon twee</p>',
    link: 'Learn more',
    link_url: '#',
    button: 'Button',
    button_url: '#',
    item: [
      {
        id: 1,
        plan: 'Start',
        htmlTag: 'h3',
        price: 'Free',
        speed: '5 Mb/s',
        storage: '15GB',
        isEdit: false,
      },
      {
        id: 2,
        plan: 'PRO',
        htmlTag: 'h3',
        price: '$38',
        speed: '25 Mb/s',
        storage: '25GB',
        isEdit: false,
      },
      {
        id: 3,
        plan: 'BUSINESS',
        htmlTag: 'h3',
        price: '$56',
        speed: '36 Mb/s',
        storage: '40GB',
        isEdit: false,
      },
      {
        id: 4,
        plan: 'SPECIAL',
        htmlTag: 'h3',
        price: '$72',
        speed: '48 Mb/s',
        storage: '120GB',
        isEdit: false,
      },
    ],
  });

  const updateSectionContent = (section_id, newContent) => {
    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      Object.keys(updatedSections).forEach((gridKey) => {
        if (Array.isArray(updatedSections[gridKey])) {
          updatedSections[gridKey] = updatedSections[gridKey]?.map((sectionArray) => {
            if (Array.isArray(sectionArray)) {
              return sectionArray?.map((section) => {
                if (section?.id === section_id || section?.sectionId === section_id) {
                  return { ...section, content: { ...section.content, ...newContent } };
                }
                return section;
              });
            }
            return sectionArray;
          });
        }
      });

      return updatedSections;
    });
    setIsButtonEnabled(true);
  };

  const handleContentChange = (section_id, newTitle) => {
    setHeadingSetting((prevState) => ({
      ...prevState,
      title: newTitle,
    }));
    updateSectionContent(section_id, { title: newTitle });
  };

  const handleHtmlTagChange = (section_id, newTag) => {
    setHeadingSetting((prevState) => ({
      ...prevState,
      htmlTag: newTag,
    }));
    updateSectionContent(section_id, { htmlTag: newTag });
  };

  const handleImageSizeChange = (section_id, newSize) => {
    setBgImage((prev) => ({
      ...prev,
      imageSize: newSize,
    }));
    updateSectionContent(section_id, { imageSize: newSize });
  };

  const handleImageLinkChange = (section_id, newValue) => {
    setBgImage((prev) => ({
      ...prev,
      link: newValue,
    }));
    updateSectionContent(section_id, { link: newValue });
  };

  const handleTextEditorChange = (section_id, newValue) => {
    setTextEditorValue((prev) => ({
      ...prev,
      textEditorValue: newValue,
    }));
    updateSectionContent(section_id, { textEditorValue: newValue });
  };

  const handleVideoChange = (section_id, newValue, type) => {
    setVideoValue((prev) => {
      const updatedValue = {
        ...prev,
        [type]: newValue,
      };
      updateSectionContent(section_id, { item: updatedValue });
      return updatedValue;
    });
  };

  const handleButtonChange = (section_id, newValue) => {
    setButtonValue((prev) => ({
      ...prev,
      text: newValue,
    }));
    updateSectionContent(section_id, { text: newValue });
  };

  const handleButtonLinkChange = (section_id, newValue) => {
    setButtonValue((prev) => ({
      ...prev,
      link: newValue,
    }));
    updateSectionContent(section_id, { link: newValue });
  };

  const handleIconSelectValue = (section_id, newValue) => {
    setButtonValue((prev) => ({
      ...prev,
      icon: newValue,
    }));
    setIsOpen(false);
    updateSectionContent(section_id, { icon: newValue });
  };

  const handleDividerStyleChange = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      style: newValue,
    }));
    updateSectionContent(section_id, { style: newValue });
  };

  const handleDividerWidthChange = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      width: newValue,
    }));
    updateSectionContent(section_id, { width: newValue });
  };

  const handleAlignmentChange = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      align: newValue,
    }));
    updateSectionContent(section_id, { align: newValue });
  };

  const handleAddElementChange = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      element: newValue,
    }));
    updateSectionContent(section_id, { element: newValue });
  };

  const handleAddElementValue = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      text: newValue,
    }));
    updateSectionContent(section_id, { text: newValue });
  };

  const handleSpacerWidthChange = (section_id, newValue) => {
    setSpacerValue((prev) => ({
      ...prev,
      width: newValue,
    }));
    updateSectionContent(section_id, { width: newValue });
  };

  const fetchCoordinates = async (address, latitude, longitude) => {
    const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
    if (!apiKey) {
      console.error('Error: API key is not defined in environment variables.');
      return;
    }
    const formattedAddress = encodeURIComponent(address);
    let url;

    if (address) {
      url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${apiKey}`;
    } else if (latitude && longitude) {
      url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log('formattedAddress', data);
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        const getAddress = data.results[0].formatted_address;
        setLocationValue({ location: getAddress || address, latitude: lat, longitude: lng });
        return { location: getAddress || address, latitude: lat, longitude: lng };
      } else {
        setLocationValue({ location: '', latitude: '', longitude: '' });
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const handleLocationChange = async (section_id, newValue, type) => {
    setLocationValue((prev) => ({
      ...prev,
      [type]: newValue,
    }));
    const handler = setTimeout(async () => {
      if (type === 'location' && newValue) {
        const coordinates = await fetchCoordinates(newValue, '', '');
        updateSectionContent(section_id, coordinates);
      } else if (type === 'latitude' && locationValue.longitude) {
        const coordinates = await fetchCoordinates('', newValue, locationValue.longitude);
        updateSectionContent(section_id, coordinates);
      } else if (type === 'longitude' && locationValue.latitude) {
        const coordinates = await fetchCoordinates('', locationValue.latitude, newValue);
        updateSectionContent(section_id, coordinates);
      }
    }, 3000);
    return () => {
      clearTimeout(handler);
    };
  };

  const handleIconElementorValue = (section_id, newValue) => {
    setIconValue((prev) => ({
      ...prev,
      icon: newValue,
    }));

    setIsOpen(false);
    updateSectionContent(section_id, { icon: newValue });
  };

  const handleIconLinkChange = (section_id, newValue) => {
    setIconValue((prev) => ({
      ...prev,
      link: newValue,
    }));
    updateSectionContent(section_id, { link: newValue });
  };

  const handleImageBoxImageSizeChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      imageSize: newSize,
    }));
    updateSectionContent(section_id, { imageSize: newSize });
  };

  const handleImageBoxTitleChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      title: newSize,
    }));
    updateSectionContent(section_id, { title: newSize });
  };

  const handleImageBoxHtmlTagChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      htmlTag: newSize,
    }));
    updateSectionContent(section_id, { htmlTag: newSize });
  };

  const handleImageBoxDescriptionChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      description: newSize,
    }));
    updateSectionContent(section_id, { description: newSize });
  };

  const handleImageBoxImageLinkChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      link: newSize,
    }));
    updateSectionContent(section_id, { link: newSize });
  };

  const handleImageIconTitleChange = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      title: newValue,
    }));
    updateSectionContent(section_id, { title: newValue });
  };

  const handleImageIconHtmlTagChange = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      htmlTag: newValue,
    }));
    updateSectionContent(section_id, { htmlTag: newValue });
  };

  const handleImageIconDescriptionChange = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      description: newValue,
    }));
    updateSectionContent(section_id, { description: newValue });
  };

  const handleImageIconImageLinkChange = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      link: newValue,
    }));
    updateSectionContent(section_id, { link: newValue });
  };

  const handleIconBoxImageValue = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      icon: newValue,
    }));
    setIsOpen(false);
    updateSectionContent(section_id, { icon: newValue });
  };

  const handleIconListDataValue = (section_id, newValue) => {
    setIconListData((prev) => {
      const updatedList = [...(prev.item || []), newValue];
      return { item: updatedList };
    });
    updateSectionContent(section_id, { item: [...iconListData?.item, newValue] });
  };

  const handleIconListDataRemoveValue = (section_id, idToRemove) => {
    setIconListData((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { item: updatedItems };
    });
  };

  const handleIconListDataCopyValue = (section_id, itemToCopy, index) => {
    const newId = iconListData?.item?.length
      ? Math.max(...iconListData.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
    };

    setIconListData((prev) => {
      const updatedList = [...(prev.item || [])];
      updatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: updatedList });
      return { item: updatedList };
    });
  };

  const handleIconListValue = (section_id, updatedTabValue) => {
    setIconListData((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedTabValue,
      };
    });
    setIsOpen(false);
    updateSectionContent(section_id, updatedTabValue);
  };

  const handleIconListIconValue = (section_id, newIcon, tabIndex) => {
    setIconListData((prev) => {
      const updatedItems = prev?.item?.map((item, index) => {
        if (index === tabIndex) {
          return {
            ...item,
            icon: newIcon,
          };
        }
        return item;
      });
      updateSectionContent(section_id, { item: updatedItems });
      return {
        ...prev,
        item: updatedItems,
      };
    });
    setIsOpen(false);
  };

  const handleCounterChange = (section_id, type, value) => {
    const numericFields = ['start', 'end', 'duration'];
    const updatedValue = numericFields.includes(type) ? parseInt(value, 10) : value;
    setNumberCounter((prev) => {
      const updatedCounter = {
        ...prev,
        [type]: updatedValue,
      };
      updateSectionContent(section_id, updatedCounter);
      return updatedCounter;
    });
  };

  const HandleTestiMonialChange = (section_id, type, value) => {
    setTestiMonialValue((prev) => {
      const updatedValue = {
        ...prev,
        [type]: value,
      };
      updateSectionContent(section_id, updatedValue);
      return updatedValue;
    });
  };

  const handleTabValue = (section_id, updatedTabValue) => {
    setTabValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedTabValue,
      };
    });
    updateSectionContent(section_id, updatedTabValue);
  };

  const handleAddTabsItem = (section_id, newValue) => {
    setTabValue((prev) => {
      return { item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleCopyTabsItem = (section_id, itemToCopy, index) => {
    const newId = tabValue?.item?.length
      ? Math.max(...tabValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setTabValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { item: newUpdatedList };
    });
  };

  const handleTabsDataRemoveValue = (section_id, idToRemove) => {
    setTabValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { item: updatedItems };
    });
  };

  const handleAccordionValue = (section_id, updatedTabValue) => {
    setAccordionValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedTabValue,
      };
    });
    updateSectionContent(section_id, updatedTabValue);
  };

  const handleAddAccordionItem = (section_id, newValue) => {
    setAccordionValue((prev) => {
      return { item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleCopyAccordionItem = (section_id, itemToCopy, index) => {
    const newId = accordionValue?.item?.length
      ? Math.max(...accordionValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setAccordionValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { item: newUpdatedList };
    });
  };

  const handleAccordionDataRemoveValue = (section_id, idToRemove) => {
    setAccordionValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { item: updatedItems };
    });
  };

  const handleAlertChange = (section_id, type, value) => {
    setAlertValue((prev) => {
      const updateAlert = {
        ...prev,
        [type]: value,
      };
      updateSectionContent(section_id, updateAlert);
      return updateAlert;
    });
    setIsOpen(false);
  };

  const handleSocialIconDataValue = (section_id, newValue) => {
    setsocialIconData((prev) => {
      const updatedList = [...(prev.item || []), newValue];
      return { item: updatedList };
    });
    updateSectionContent(section_id, { item: [...socialIconData?.item, newValue] });
  };

  const handleSocialIconDataRemoveValue = (section_id, idToRemove) => {
    setsocialIconData((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { item: updatedItems };
    });
  };

  const handleSocialIconCopyValue = (section_id, itemToCopy, index) => {
    console.log('socialIconData', section_id, itemToCopy, index);

    const currentItemIds = socialIconData?.item?.map((item) => item.id) || [];
    const newId = currentItemIds.length ? Math.max(...currentItemIds) + 1 : 1;
    const copiedItem = {
      ...itemToCopy,
      id: newId,
      // text: `${itemToCopy.text}`,
    };
    setsocialIconData((prev) => {
      const updatedList = [...(prev?.item || [])];
      updatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: updatedList });
      return { item: updatedList };
    });
  };

  const handleSocialIconListValue = (section_id, updatedTabValue) => {
    setsocialIconData((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedTabValue,
      };
    });
    setIsOpen(false);
    updateSectionContent(section_id, updatedTabValue);
  };

  const handleSocialIconValue = (section_id, newIcon, tabIndex) => {
    setsocialIconData((prev) => {
      const updatedItems = prev?.item?.map((item, index) => {
        if (index === tabIndex) {
          return {
            ...item,
            icon: newIcon,
          };
        }
        return item;
      });
      updateSectionContent(section_id, { item: updatedItems });
      return {
        ...prev,
        item: updatedItems,
      };
    });
    setIsOpen(false);
  };

  const handleHtmlChange = (section_id, value) => {
    updateSectionContent(section_id, { item: value });
  };

  const HandleRatingChange = (section_id, type, value) => {
    setRatingValue((prev) => {
      const updatedValue = {
        ...prev,
        [type]: value,
      };
      updateSectionContent(section_id, updatedValue);
      return updatedValue;
    });
  };

  const handleRatingIconSelectValue = (section_id, newValue) => {
    setRatingValue((prev) => ({
      ...prev,
      icon: newValue,
    }));
    setIsOpen(false);
    updateSectionContent(section_id, { icon: newValue });
  };

  const handleFirstContentValue = (section_id, updatedfirstContentValue) => {
    setFirstContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedfirstContentValue,
      };
    });
    console.log(updatedfirstContentValue, 'updatedfirstContentValue=-=->>');
    updateSectionContent(section_id, updatedfirstContentValue);
  };

  const handleAddFirstContentItem = (section_id, newValue) => {
    setFirstContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleCopyFirstContentItem = (section_id, itemToCopy, index) => {
    const newId = firstContentValue?.item?.length
      ? Math.max(...firstContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFifthFeatureItem = (section_id, itemToCopy, index) => {
    const newId = fifthFeatureValue?.item?.length
      ? Math.max(...fifthFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFifthFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySixthFeatureItem = (section_id, itemToCopy, index) => {
    const newId = sixthFeatureValue?.item?.length
      ? Math.max(...sixthFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSixthFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySecondContentItem = (section_id, itemToCopy, index) => {
    const newId = secondContentValue?.item?.length
      ? Math.max(...secondContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSecondContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFirstPricingItem = (section_id, itemToCopy, index) => {
    const newId = firstPricingValue?.item?.length
      ? Math.max(...firstPricingValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstPricingValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySecondPricingItem = (section_id, itemToCopy, index) => {
    const newId = secondPricingValue?.item?.length
      ? Math.max(...secondPricingValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSecondPricingValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyThirdContentItem = (section_id, itemToCopy, index) => {
    const newId = thirdContentValue?.item?.length
      ? Math.max(...thirdContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setThirdContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyForthContentItem = (section_id, itemToCopy, index) => {
    const newId = forthContentValue?.item?.length
      ? Math.max(...forthContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setForthContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySevenContentItem = (section_id, itemToCopy, index) => {
    const newId = sevenContentValue?.item?.length
      ? Math.max(...sevenContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSevenContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyEightContentItem = (section_id, itemToCopy, index) => {
    const newId = eightContentValue?.item?.length
      ? Math.max(...eightContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setEightContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySecondFeatureItem = (section_id, itemToCopy, index) => {
    const newId = secondFeatureValue?.item?.length
      ? Math.max(...secondFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSecondFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySevenFeatureItem = (section_id, itemToCopy, index) => {
    const newId = sevenFeatureValue?.item?.length
      ? Math.max(...sevenFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSevenFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyEightFeatureItem = (section_id, itemToCopy, index) => {
    const newId = eightFeatureValue?.item?.length
      ? Math.max(...eightFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setEightFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFirstStepItem = (section_id, itemToCopy, index) => {
    const newId = firstStepValue?.item?.length
      ? Math.max(...firstStepValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstStepValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySecondStepItem = (section_id, itemToCopy, index) => {
    const newId = secondStepValue?.item?.length
      ? Math.max(...secondStepValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSecondStepValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyThirdStepItem = (section_id, itemToCopy, index) => {
    const newId = thirdStepValue?.item?.length
      ? Math.max(...thirdStepValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setThirdStepValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFirstTeamItem = (section_id, itemToCopy, index) => {
    const newId = firstTeamValue?.item?.length
      ? Math.max(...firstTeamValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstTeamValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySecondTeamItem = (section_id, itemToCopy, index) => {
    const newId = secondTeamValue?.item?.length
      ? Math.max(...secondTeamValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSecondTeamValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyThirdTeamItem = (section_id, itemToCopy, index) => {
    const newId = thirdTeamValue?.item?.length
      ? Math.max(...thirdTeamValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setThirdTeamValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFirstTestimonialItem = (section_id, itemToCopy, index) => {
    const newId = firstTestimonialValue?.item?.length
      ? Math.max(...firstTestimonialValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstTestimonialValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyThirdTestimonialItem = (section_id, itemToCopy, index) => {
    const newId = thirdTestimonialValue?.item?.length
      ? Math.max(...thirdTestimonialValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setThirdTestimonialValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFirstStatisticItem = (section_id, itemToCopy, index) => {
    const newId = firstStatisticValue?.item?.length
      ? Math.max(...firstStatisticValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstStatisticValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySecondStatisticItem = (section_id, itemToCopy, index) => {
    const newId = secondStatisticValue?.item?.length
      ? Math.max(...secondStatisticValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSecondStatisticValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyThirdStatisticItem = (section_id, itemToCopy, index) => {
    const newId = thirdStatisticValue?.item?.length
      ? Math.max(...thirdStatisticValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setThirdStatisticValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyThirdFeatureItem = (section_id, itemToCopy, index) => {
    const newId = thirdFeatureValue?.item?.length
      ? Math.max(...thirdFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setThirdFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyForthFeatureItem = (section_id, itemToCopy, index) => {
    const newId = forthFeatureValue?.item?.length
      ? Math.max(...forthFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setForthFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFirstFeatureItem = (section_id, itemToCopy, index) => {
    const newId = firstFeatureValue?.item?.length
      ? Math.max(...firstFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleFirstContentDataRemoveValue = (section_id, idToRemove) => {
    setFirstContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleFifthFeatureDataRemoveValue = (section_id, idToRemove) => {
    setFifthFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSixthFeatureDataRemoveValue = (section_id, idToRemove) => {
    setSixthFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSecondContentDataRemoveValue = (section_id, idToRemove) => {
    setSecondContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return {
        ...prev,
        item: updatedItems,
      };
    });
  };

  const handleFirstPricingDataRemoveValue = (section_id, idToRemove) => {
    setFirstPricingValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return {
        ...prev,
        item: updatedItems,
      };
    });
  };

  const handleSecondPricingDataRemoveValue = (section_id, idToRemove) => {
    setSecondPricingValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return {
        ...prev,
        item: updatedItems,
      };
    });
  };

  const handleThirdContentDataRemoveValue = (section_id, idToRemove) => {
    setThirdContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleForthContentDataRemoveValue = (section_id, idToRemove) => {
    setForthContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSevenContentDataRemoveValue = (section_id, idToRemove) => {
    setSevenContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleEightContentDataRemoveValue = (section_id, idToRemove) => {
    setEightContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleFirstFeatureDataRemoveValue = (section_id, idToRemove) => {
    setFirstFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSecondFeatureDataRemoveValue = (section_id, idToRemove) => {
    setSecondFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSevenFeatureDataRemoveValue = (section_id, idToRemove) => {
    setSevenFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleEightFeatureDataRemoveValue = (section_id, idToRemove) => {
    setEightFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleFirstStepDataRemoveValue = (section_id, idToRemove) => {
    setFirstStepValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleThirdStepDataRemoveValue = (section_id, idToRemove) => {
    setThirdStepValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleFirstTeamDataRemoveValue = (section_id, idToRemove) => {
    setFirstTeamValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSecondTeamDataRemoveValue = (section_id, idToRemove) => {
    setSecondTeamValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSecondStepDataRemoveValue = (section_id, idToRemove) => {
    setSecondStepValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleThirdTeamDataRemoveValue = (section_id, idToRemove) => {
    setThirdTeamValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleFirstTestimonialDataRemoveValue = (section_id, idToRemove) => {
    setFirstTestimonialValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleThirdTestimonialDataRemoveValue = (section_id, idToRemove) => {
    setThirdTestimonialValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleFirstStatisticDataRemoveValue = (section_id, idToRemove) => {
    setFirstStatisticValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSecondStatisticDataRemoveValue = (section_id, idToRemove) => {
    setSecondStatisticValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleThirdStatisticDataRemoveValue = (section_id, idToRemove) => {
    setThirdStatisticValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleThirdFeatureDataRemoveValue = (section_id, idToRemove) => {
    setThirdFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleForthFeatureDataRemoveValue = (section_id, idToRemove) => {
    setForthFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handlefifthFeatureValue = (section_id, updatedfirstContentValue) => {
    setFifthFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedfirstContentValue,
      };
    });
    console.log(updatedfirstContentValue, 'updatedfirstContentValue=-=->>');
    updateSectionContent(section_id, updatedfirstContentValue);
  };

  const handleSixthFeatureValue = (section_id, updatedfirstContentValue) => {
    setSixthFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedfirstContentValue,
      };
    });
    console.log(updatedfirstContentValue, 'updatedfirstContentValue=-=->>');
    updateSectionContent(section_id, updatedfirstContentValue);
  };

  const handleSecondContentValue = (section_id, updatedsecondContentValue) => {
    setSecondContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstPricingValue = (section_id, updatedfirstPricingValue) => {
    setFirstPricingValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedfirstPricingValue,
      };
    });
    updateSectionContent(section_id, updatedfirstPricingValue);
  };

  const handleSecondPricingValue = (section_id, updatedfirstPricingValue) => {
    setSecondPricingValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedfirstPricingValue,
      };
    });
    updateSectionContent(section_id, updatedfirstPricingValue);
  };

  const handleThirdContentValue = (section_id, updatedsecondContentValue) => {
    setThirdContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleForthContentValue = (section_id, updatedsecondContentValue) => {
    console.log(updatedsecondContentValue, 'updatedsecondContentValue=-=-=->>');
    setForthContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSevenContentValue = (section_id, updatedsecondContentValue) => {
    setSevenContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleEightContentValue = (section_id, updatedsecondContentValue) => {
    setEightContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstFeatureValue = (section_id, updatedsecondContentValue) => {
    setFirstFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSecondFeatureValue = (section_id, updatedsecondContentValue) => {
    setSecondFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSevenFeatureValue = (section_id, updatedsecondContentValue) => {
    setSevenFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleEightFeatureValue = (section_id, updatedsecondContentValue) => {
    setEightFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstStepValue = (section_id, updatedsecondContentValue) => {
    setFirstStepValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSecondStepValue = (section_id, updatedsecondContentValue) => {
    setSecondStepValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdStepValue = (section_id, updatedsecondContentValue) => {
    setThirdStepValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstTeamValue = (section_id, updatedsecondContentValue) => {
    setFirstTeamValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSecondTeamValue = (section_id, updatedsecondContentValue) => {
    setSecondTeamValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdTeamValue = (section_id, updatedsecondContentValue) => {
    setThirdTeamValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstTestimonialValue = (section_id, updatedsecondContentValue) => {
    setFirstTestimonialValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdTestimonialValue = (section_id, updatedsecondContentValue) => {
    setThirdTestimonialValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstStatisticValue = (section_id, updatedsecondContentValue) => {
    setFirstStatisticValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSecondStatisticValue = (section_id, updatedsecondContentValue) => {
    setSecondStatisticValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstHeroValue = (section_id, updatedsecondContentValue) => {
    setFirstHeroValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSecondHeroValue = (section_id, updatedsecondContentValue) => {
    setSecondHeroValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdHeroValue = (section_id, updatedsecondContentValue) => {
    setThirdHeroValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleForthHeroValue = (section_id, updatedsecondContentValue) => {
    setForthHeroValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFifthHeroValue = (section_id, updatedsecondContentValue) => {
    setFifthHeroValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSixthHeroValue = (section_id, updatedsecondContentValue) => {
    setSixthHeroValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdStatisticValue = (section_id, updatedsecondContentValue) => {
    setThirdStatisticValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdFeatureValue = (section_id, updatedsecondContentValue) => {
    setThirdFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdFeatureImageValue = (section_id, image) => {
    setThirdFeatureValue((prevState) => ({
      ...prevState,
      image: image,
    }));
    updateSectionContent(section_id, { image: image });
  };

  const handleThirdFeatureObjectFitValue = (section_id, objectFit) => {
    setThirdFeatureValue((prevState) => ({
      ...prevState,
      objectFit: objectFit,
    }));
    updateSectionContent(section_id, { objectFit: objectFit });
  };

  const handleForthFeatureValue = (section_id, updatedsecondContentValue) => {
    setForthFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleAddFifthFeatureItem = (section_id, newValue) => {
    setFifthFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSixthFeatureItem = (section_id, newValue) => {
    setSixthFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSecondContentItem = (section_id, newValue) => {
    setSecondContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstPricingItem = (section_id, newValue) => {
    setFirstPricingValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSecondPricingItem = (section_id, newValue) => {
    setSecondPricingValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddThirdContentItem = (section_id, newValue) => {
    setThirdContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddForthContentItem = (section_id, newValue) => {
    setForthContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleSevenContentItem = (section_id, newValue) => {
    setSevenContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddEightContentItem = (section_id, newValue) => {
    setEightContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstFeatureItem = (section_id, newValue) => {
    setFirstFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSecondFeatureItem = (section_id, newValue) => {
    setSecondFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSevenFeatureItem = (section_id, newValue) => {
    setSevenFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddEightFeatureItem = (section_id, newValue) => {
    setEightFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstStepItem = (section_id, newValue) => {
    setFirstStepValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSecondStepItem = (section_id, newValue) => {
    setSecondStepValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddThirdStepItem = (section_id, newValue) => {
    setThirdStepValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstTeamItem = (section_id, newValue) => {
    setFirstTeamValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSecondTeamItem = (section_id, newValue) => {
    setSecondTeamValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddThirdTeamItem = (section_id, newValue) => {
    setThirdTeamValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstTestimonialItem = (section_id, newValue) => {
    setFirstTestimonialValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddThirdTestimonialItem = (section_id, newValue) => {
    setThirdTestimonialValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstStatisticItem = (section_id, newValue) => {
    setFirstStatisticValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSecondStatisticItem = (section_id, newValue) => {
    setSecondStatisticValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddThirdStatisticItem = (section_id, newValue) => {
    setThirdStatisticValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddThirdFeatureItem = (section_id, newValue) => {
    setThirdFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddForthFeatureItem = (section_id, newValue) => {
    setForthFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleFirstContentTagChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFifthFeatureTagChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSixthFeatureTagChange = (section_id, newTitle) => {
    setSixthFeatureValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleSecondContentTagChange = (section_id, newTitle) => {
    setSecondContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFirstPricingTagChange = (section_id, newTitle) => {
    setFirstPricingValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondPricingTagChange = (section_id, newTitle) => {
    setSecondPricingValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleThirdContentTagChange = (section_id, newTitle) => {
    setThirdContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFirstTeamTagChange = (section_id, newTitle) => {
    setFirstTeamValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondTeamTagChange = (section_id, newTitle) => {
    setSecondTeamValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleThirdTeamTagChange = (section_id, newTitle) => {
    setThirdTeamValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondStatisticTagChange = (section_id, newTitle) => {
    setSecondStatisticValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleThirdStatisticTagChange = (section_id, newTitle) => {
    setThirdStatisticValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFirstContactTagChange = (section_id, newTitle) => {
    setFirstContactValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondContactTagChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleThirdContactTagChange = (section_id, newTitle) => {
    setThirdContactValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondCtaTagChange = (section_id, newTitle) => {
    setSecondCtaValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleThirdCtaTagChange = (section_id, newTitle) => {
    setThirdCtaValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleForthCtaTagChange = (section_id, newTitle) => {
    setForthCtaValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFirstHeroTagChange = (section_id, newTitle) => {
    setFirstHeroValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondHeroTagChange = (section_id, newTitle) => {
    setSecondHeroValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleThirdHeroTagChange = (section_id, newTitle) => {
    setThirdHeroValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleForthHeroTagChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFifthHeroTagChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSixthHeroTagChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondCtaFormTitleTagChange = (section_id, newTitle) => {
    setSecondCtaValue((prevState) => ({
      ...prevState,
      form_title_htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { form_title_htmlTag: newTitle });
  };

  const handleSecondContactAddressTitleTagChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      address_title_htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { address_title_htmlTag: newTitle });
  };

  const handleSecondContactEmailTitleTagChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      email_title_htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { email_title_htmlTag: newTitle });
  };

  const handleSecondContactPhoneTitleTagChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      phone_title_htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { phone_title_htmlTag: newTitle });
  };

  const handleFirstTestimonialTagChange = (section_id, newTitle) => {
    setFirstTestimonialValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondTestimonialTagChange = (section_id, newTitle) => {
    setSecondTestimonialValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondTestimonialRoleTagChange = (section_id, newTitle) => {
    setSecondTestimonialValue((prevState) => ({
      ...prevState,
      role_htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { role_htmlTag: newTitle });
  };

  const handleForthContentTagChange = (section_id, newTitle) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFifthContentTagChange = (section_id, newTitle) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSixContentTagChange = (section_id, newTitle) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFirstContentSubTitleTagChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      subHtmlTag: newTitle,
    }));

    updateSectionContent(section_id, { subHtmlTag: newTitle });
  };

  const handleFifthFeatureSubTitleTagChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      subHtmlTag: newTitle,
    }));

    updateSectionContent(section_id, { subHtmlTag: newTitle });
  };

  const handleEightContentTitleTagChange = (section_id, newTitle) => {
    setEightContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFirstFeatureTitleTagChange = (section_id, newTitle) => {
    setFirstFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondFeatureTitleTagChange = (section_id, newTitle) => {
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSevenFeatureTitleTagChange = (section_id, newTitle) => {
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleEightFeatureTitleTagChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleEightContentDescriptionChange = (section_id, newTitle) => {
    setEightContentValue((prevState) => ({
      ...prevState,
      description: newTitle,
    }));

    updateSectionContent(section_id, { description: newTitle });
  };

  const handleSecondFeatureDescriptionChange = (section_id, newTitle) => {
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      description: newTitle,
    }));

    updateSectionContent(section_id, { description: newTitle });
  };

  const handleSevenFeatureDescriptionChange = (section_id, newTitle) => {
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      description: newTitle,
    }));

    updateSectionContent(section_id, { description: newTitle });
  };

  const handleEightFeatureDescriptionChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      description: newTitle,
    }));

    updateSectionContent(section_id, { description: newTitle });
  };

  const handleFirstContentButtonValueChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleSecondFeatureButtonValueChange = (section_id, newTitle) => {
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleSevenFeatureButtonValueChange = (section_id, newTitle) => {
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleEightFeatureButtonValueChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleThirdContentButtonValueChange = (section_id, newTitle) => {
    setThirdContentValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleForthContentButtonValueChange = (section_id, newTitle) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleFifthContentButtonValueChange = (section_id, newTitle) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleForthContentSubTitleValueChange = (section_id, newTitle) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      sub_title: newTitle,
    }));

    updateSectionContent(section_id, { sub_title: newTitle });
  };

  const handleFifthContentSubButtonValueChange = (section_id, newTitle) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      button_link: newTitle,
    }));

    updateSectionContent(section_id, { button_link: newTitle });
  };

  const handleFifthContentIconValueChange = (section_id, icon) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      icon: icon,
    }));

    updateSectionContent(section_id, { icon: icon });
  };

  const handleFifthContentIconLinkValueChange = (section_id, link) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      link: link,
    }));

    updateSectionContent(section_id, { link: link });
  };

  const handleFifthContentIconFillValueChange = (section_id, fill) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      isFill: fill,
    }));

    updateSectionContent(section_id, { isFill: fill });
  };

  const handleSixContentSubButtonValueChange = (section_id, newTitle) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      button_link: newTitle,
    }));

    updateSectionContent(section_id, { button_link: newTitle });
  };

  const handleSixContentButtonLinkChange = (section_id, newTitle) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      link_url: newTitle,
    }));

    updateSectionContent(section_id, { link_url: newTitle });
  };

  const handleSixContentImageChange = (section_id, newImage) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      image: newImage,
    }));

    updateSectionContent(section_id, { image: newImage });
  };

  const handleSixContentProfileChange = (section_id, newProfile) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      profile: newProfile,
    }));

    updateSectionContent(section_id, { profile: newProfile });
  };

  const handleSixContentObjectFitChange = (section_id, objectFit) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      objectFit: objectFit,
    }));

    updateSectionContent(section_id, { objectFit: objectFit });
  };

  const handleSixContentProfileFillValueChange = (section_id, fill) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      profileFill: fill,
    }));

    updateSectionContent(section_id, { profileFill: fill });
  };

  const handleSixContentIconFillValueChange = (section_id, fill) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      isFill: fill,
    }));

    updateSectionContent(section_id, { isFill: fill });
  };

  const handleSixContentIconChange = (section_id, icon) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      icon: icon,
    }));

    updateSectionContent(section_id, { icon: icon });
  };

  const handleFirstContentGridValueChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFirstTeamGridValueChange = (section_id, newTitle) => {
    setFirstTeamValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSecondTeamGridValueChange = (section_id, newTitle) => {
    setSecondTeamValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleThirdTeamGridValueChange = (section_id, newTitle) => {
    setThirdTeamValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFirstTestimonialGridValueChange = (section_id, newTitle) => {
    setFirstTestimonialValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleThirdTestimonialGridValueChange = (section_id, newTitle) => {
    setThirdTestimonialValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFirstStatisticGridValueChange = (section_id, newTitle) => {
    setFirstStatisticValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSecondStatisticGridValueChange = (section_id, newTitle) => {
    setSecondStatisticValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleThirdStatisticGridValueChange = (section_id, newTitle) => {
    setThirdStatisticValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFifthFeatureGridValueChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSecondContentGridValueChange = (section_id, newTitle) => {
    setSecondContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFirstPricingGridValueChange = (section_id, newTitle) => {
    setFirstPricingValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleThirdContentGridValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=->>>');
    setThirdContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleForthContentGridValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=->>>');
    setForthContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSevenContentGridValueChange = (section_id, newTitle) => {
    setSevenContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleEightContentGridValueChange = (section_id, newTitle) => {
    setEightContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFirstFeatureGridValueChange = (section_id, newTitle) => {
    setFirstFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSecondFeatureGridValueChange = (section_id, newTitle) => {
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSevenFeatureGridValueChange = (section_id, newTitle) => {
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleEightFeatureGridValueChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFirstContentTitleValueChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFifthFeatureTitleValueChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleEightContentTitleValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=>');
    setEightContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFirstFeatureTitleValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=>');
    setFirstFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondFeatureTitleValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=>');
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSevenFeatureTitleValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=>');
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleEightFeatureTitleValueChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondContentTitleValueChange = (section_id, newTitle) => {
    setSecondContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFirstPricingTitleValueChange = (section_id, newTitle) => {
    setFirstPricingValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondPricingTitleValueChange = (section_id, newTitle) => {
    setSecondPricingValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondPricingLinkValueChange = (section_id, newTitle) => {
    setSecondPricingValue((prevState) => ({
      ...prevState,
      link: newTitle,
    }));

    updateSectionContent(section_id, { link: newTitle });
  };

  const handleSecondPricingButtonValueChange = (section_id, newTitle) => {
    setSecondPricingValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleSecondPricingButtonUrlValueChange = (section_id, newTitle) => {
    setSecondPricingValue((prevState) => ({
      ...prevState,
      button_url: newTitle,
    }));

    updateSectionContent(section_id, { button_url: newTitle });
  };

  const handleSecondPricingLinkUrlValueChange = (section_id, newTitle) => {
    setSecondPricingValue((prevState) => ({
      ...prevState,
      link_url: newTitle,
    }));

    updateSectionContent(section_id, { link_url: newTitle });
  };

  const handleThirdContentTitleValueChange = (section_id, newTitle) => {
    setThirdContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFirstTeamTitleValueChange = (section_id, newTitle) => {
    setFirstTeamValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondTeamTitleValueChange = (section_id, newTitle) => {
    setSecondTeamValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleThirdTeamTitleValueChange = (section_id, newTitle) => {
    setThirdTeamValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondStatisticTitleValueChange = (section_id, newTitle) => {
    setSecondStatisticValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleThirdStatisticTitleValueChange = (section_id, newTitle) => {
    setThirdStatisticValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFirstContactTitleValueChange = (section_id, newTitle) => {
    setFirstContactValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondContactTitleValueChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleThirdContactTitleValueChange = (section_id, newTitle) => {
    setThirdContactValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondCtaTitleValueChange = (section_id, newTitle) => {
    setSecondCtaValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleThirdCtaTitleValueChange = (section_id, newTitle) => {
    setThirdCtaValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleForthCtaTitleValueChange = (section_id, newTitle) => {
    setForthCtaValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFisrtHeroTitleValueChange = (section_id, newTitle) => {
    setFirstHeroValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondHeroTitleValueChange = (section_id, newTitle) => {
    setSecondHeroValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleThirdHeroTitleValueChange = (section_id, newTitle) => {
    setThirdHeroValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleForthHeroTitleValueChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFifthHeroTitleValueChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSixthHeroTitleValueChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFisrtHeroFirstBtnValueChange = (section_id, newTitle) => {
    setFirstHeroValue((prevState) => ({
      ...prevState,
      first_btn: newTitle,
    }));

    updateSectionContent(section_id, { first_btn: newTitle });
  };

  const handleSecondHeroFirstBtnValueChange = (section_id, newTitle) => {
    setSecondHeroValue((prevState) => ({
      ...prevState,
      first_btn: newTitle,
    }));

    updateSectionContent(section_id, { first_btn: newTitle });
  };

  const handleThirdHeroFirstBtnValueChange = (section_id, newTitle) => {
    setThirdHeroValue((prevState) => ({
      ...prevState,
      first_btn: newTitle,
    }));

    updateSectionContent(section_id, { first_btn: newTitle });
  };

  const handleForthHeroFirstBtnValueChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      first_btn_title: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_title: newTitle });
  };

  const handleFifthHeroFirstBtnValueChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      first_btn_title: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_title: newTitle });
  };

  const handleSixthHeroFirstBtnValueChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      first_btn_title: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_title: newTitle });
  };

  const handleForthHeroNoteValueChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      note: newTitle,
    }));

    updateSectionContent(section_id, { note: newTitle });
  };

  const handleFifthHeroNoteValueChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      note: newTitle,
    }));

    updateSectionContent(section_id, { note: newTitle });
  };

  const handleSixthHeroNoteValueChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      note: newTitle,
    }));

    updateSectionContent(section_id, { note: newTitle });
  };

  const handleForthHeroFirstBtnSubTitleValueChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      first_btn_sub_title: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_sub_title: newTitle });
  };

  const handleFifthHeroFirstBtnSubTitleValueChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      first_btn_sub_title: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_sub_title: newTitle });
  };

  const handleSixthHeroFirstBtnSubTitleValueChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      first_btn_sub_title: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_sub_title: newTitle });
  };

  const handleForthCtaFirstBtnTitleValueChange = (section_id, newTitle) => {
    setForthCtaValue((prevState) => ({
      ...prevState,
      first_btn_title: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_title: newTitle });
  };

  const handleForthCtaFirstBtnSubTitleValueChange = (section_id, newTitle) => {
    setForthCtaValue((prevState) => ({
      ...prevState,
      first_btn_sub_title: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_sub_title: newTitle });
  };

  const handleForthCtaSecondBtnTitleValueChange = (section_id, newTitle) => {
    setForthCtaValue((prevState) => ({
      ...prevState,
      second_btn_title: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_title: newTitle });
  };

  const handleForthCtaSecondBtnSubTitleValueChange = (section_id, newTitle) => {
    setForthCtaValue((prevState) => ({
      ...prevState,
      second_btn_sub_title: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_sub_title: newTitle });
  };

  const handleForthHeroSecondBtnSubTitleValueChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      second_btn_sub_title: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_sub_title: newTitle });
  };

  const handleFifthHeroSecondBtnSubTitleValueChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      second_btn_sub_title: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_sub_title: newTitle });
  };

  const handlSixthHeroSecondBtnSubTitleValueChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      second_btn_sub_title: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_sub_title: newTitle });
  };

  const handleForthHeroFirstBtnLinkValueChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      first_btn_link: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_link: newTitle });
  };

  const handleFifthHeroFirstBtnLinkValueChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      first_btn_link: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_link: newTitle });
  };

  const handleSixthHeroFirstBtnLinkValueChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      first_btn_link: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_link: newTitle });
  };

  const handleForthCtaFirstBtnLinkValueChange = (section_id, newTitle) => {
    setForthCtaValue((prevState) => ({
      ...prevState,
      first_btn_link: newTitle,
    }));

    updateSectionContent(section_id, { first_btn_link: newTitle });
  };

  const handleForthCtaSecondBtnLinkValueChange = (section_id, newTitle) => {
    setForthCtaValue((prevState) => ({
      ...prevState,
      second_btn_link: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_link: newTitle });
  };

  const handleForthHeroSecondBtnLinkValueChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      second_btn_link: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_link: newTitle });
  };

  const handleFifthHeroSecondBtnLinkValueChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      second_btn_link: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_link: newTitle });
  };

  const handleSixthHeroSecondBtnLinkValueChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      second_btn_link: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_link: newTitle });
  };

  const handleFisrtHeroSecondBtnValueChange = (section_id, newTitle) => {
    setFirstHeroValue((prevState) => ({
      ...prevState,
      second_btn: newTitle,
    }));

    updateSectionContent(section_id, { second_btn: newTitle });
  };

  const handleSecondHeroSecondBtnValueChange = (section_id, newTitle) => {
    setSecondHeroValue((prevState) => ({
      ...prevState,
      second_btn: newTitle,
    }));

    updateSectionContent(section_id, { second_btn: newTitle });
  };

  const handleThirdHeroSecondBtnValueChange = (section_id, newTitle) => {
    setThirdHeroValue((prevState) => ({
      ...prevState,
      second_btn: newTitle,
    }));

    updateSectionContent(section_id, { second_btn: newTitle });
  };

  const handleForthHeroSecondBtnValueChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      second_btn_title: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_title: newTitle });
  };

  const handleFifthHeroSecondBtnValueChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      second_btn_title: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_title: newTitle });
  };

  const handleSixthHeroSecondBtnValueChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      second_btn_title: newTitle,
    }));

    updateSectionContent(section_id, { second_btn_title: newTitle });
  };

  const handleSecondCtaFormTitleValueChange = (section_id, newTitle) => {
    setSecondCtaValue((prevState) => ({
      ...prevState,
      form_title: newTitle,
    }));

    updateSectionContent(section_id, { form_title: newTitle });
  };

  const handleSecondCtaFormNoteValueChange = (section_id, newTitle) => {
    setSecondCtaValue((prevState) => ({
      ...prevState,
      note: newTitle,
    }));

    updateSectionContent(section_id, { note: newTitle });
  };

  const handleFirstContactNoteValueChange = (section_id, newTitle) => {
    setFirstContactValue((prevState) => ({
      ...prevState,
      note: newTitle,
    }));

    updateSectionContent(section_id, { note: newTitle });
  };

  const handleFirstContentSubTitleValueChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      subTitle: newTitle,
    }));

    updateSectionContent(section_id, { subTitle: newTitle });
  };

  const handleFifthFeatureSubTitleValueChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      subTitle: newTitle,
    }));

    updateSectionContent(section_id, { subTitle: newTitle });
  };

  const handleFirstContentDescriptionChange = (section_id, newValue) => {
    setFirstContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSecondContentDescriptionChange = (section_id, newValue) => {
    setSecondContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFirstPricingDescriptionChange = (section_id, newValue) => {
    setFirstPricingValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSecondPricingDescriptionChange = (section_id, newValue) => {
    setSecondPricingValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleThirdContentDescriptionChange = (section_id, newValue) => {
    setThirdContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFirstTeamDescriptionChange = (section_id, newValue) => {
    setFirstTeamValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSecondTeamDescriptionChange = (section_id, newValue) => {
    setSecondTeamValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleThirdTeamDescriptionChange = (section_id, newValue) => {
    setThirdTeamValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSecondStatisticDescriptionChange = (section_id, newValue) => {
    setSecondStatisticValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleThirdStatisticDescriptionChange = (section_id, newValue) => {
    setThirdStatisticValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFirstContactDescriptionChange = (section_id, newValue) => {
    setFirstContactValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSecondContactDescriptionChange = (section_id, newValue) => {
    setSecondContactValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleThirdContactDescriptionChange = (section_id, newValue) => {
    setThirdContactValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSecondCtaDescriptionChange = (section_id, newValue) => {
    setSecondCtaValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleThirdCtaDescriptionChange = (section_id, newValue) => {
    setThirdCtaValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleForthCtaDescriptionChange = (section_id, newValue) => {
    setForthCtaValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFirstHeroDescriptionChange = (section_id, newValue) => {
    setFirstHeroValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSecondHeroDescriptionChange = (section_id, newValue) => {
    setSecondHeroValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleThirdHeroDescriptionChange = (section_id, newValue) => {
    setThirdHeroValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleForthHeroDescriptionChange = (section_id, newValue) => {
    setForthHeroValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFifthHeroDescriptionChange = (section_id, newValue) => {
    setFifthHeroValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSixthHeroDescriptionChange = (section_id, newValue) => {
    setSixthHeroValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFirstCtaDescriptionChange = (section_id, newValue) => {
    setFirstCtaValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFirstTestimonialDescriptionChange = (section_id, newValue) => {
    setFirstTestimonialValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSecondTestimonialDescriptionChange = (section_id, newValue) => {
    setSecondTestimonialValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleForthContentDescriptionChange = (section_id, newValue) => {
    setForthContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFifthContentDescriptionChange = (section_id, newValue) => {
    setFifthContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSixContentDescriptionChange = (section_id, newValue) => {
    setSixContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleForthContentTitleValueChange = (section_id, newTitle) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFifthContentTitleValueChange = (section_id, newTitle) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSixContentTitleValueChange = (section_id, newTitle) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSixContentContentChange = (section_id, newValue) => {
    setSixContentValue((prev) => ({
      ...prev,
      content: newValue,
    }));

    updateSectionContent(section_id, { content: newValue });
  };

  const handleSecondTestimonialTitleValueChange = (section_id, newTitle) => {
    setSecondTestimonialValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondTestimonialRoleValueChange = (section_id, newTitle) => {
    setSecondTestimonialValue((prevState) => ({
      ...prevState,
      role: newTitle,
    }));

    updateSectionContent(section_id, { role: newTitle });
  };

  const handleSecondTestimonialIconFillValueChange = (section_id, isFill) => {
    setSecondTestimonialValue((prevState) => ({
      ...prevState,
      isFill: isFill,
    }));

    updateSectionContent(section_id, { isFill: isFill });
  };

  const handleSecondTestimonialIconChange = (section_id, icon) => {
    setSecondTestimonialValue((prevState) => ({
      ...prevState,
      icon: icon,
    }));

    updateSectionContent(section_id, { icon: icon });
  };

  const handleFirstTestimonialTitleValueChange = (section_id, newTitle) => {
    setFirstTestimonialValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFormGetData = async (section_id, newTitle) => {
    try {
      const ApiCall = await API.get(`/admin/form/getform/${newTitle}`);
      if (ApiCall.data) {
        const result = decryptData(ApiCall?.data);
        console.log(ApiCall?.data, 'ApiCall?.data=-=-=->>');
        updateSectionContent(section_id, { shortCode: newTitle });
        updateSectionContent(section_id, { form: JSON.parse(ApiCall?.data?.data?.form) });
        setIsNotification(true);
        setNotificationMsg(ApiCall?.data?.message);
        setSeverity('success');
        return result?.data;
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const handleFirstContactShortCodeValueChange = (section_id, newTitle) => {
    setFirstContactValue((prevState) => ({
      ...prevState,
      shortCode: newTitle,
    }));
    handleFormGetData(section_id, newTitle);
  };

  const handleSecondContactShortCodeValueChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      shortCode: newTitle,
    }));
    handleFormGetData(section_id, newTitle);
  };

  const handleThirdContactShortCodeValueChange = (section_id, newTitle) => {
    setThirdContactValue((prevState) => ({
      ...prevState,
      shortCode: newTitle,
    }));
    handleFormGetData(section_id, newTitle);
  };

  const handleSecondCtaShortCodeValueChange = (section_id, newTitle) => {
    setSecondCtaValue((prevState) => ({
      ...prevState,
      shortCode: newTitle,
    }));
    handleFormGetData(section_id, newTitle);
  };

  const handleForthHeroShortCodeValueChange = (section_id, newTitle) => {
    setForthHeroValue((prevState) => ({
      ...prevState,
      shortCode: newTitle,
    }));
    handleFormGetData(section_id, newTitle);
  };

  const handleFifthHeroShortCodeValueChange = (section_id, newTitle) => {
    setFifthHeroValue((prevState) => ({
      ...prevState,
      shortCode: newTitle,
    }));
    handleFormGetData(section_id, newTitle);
  };

  const handleSixthHeroShortCodeValueChange = (section_id, newTitle) => {
    setSixthHeroValue((prevState) => ({
      ...prevState,
      shortCode: newTitle,
    }));
    handleFormGetData(section_id, newTitle);
  };

  const handleThirdCtaShortCodeValueChange = (section_id, newTitle) => {
    setThirdCtaValue((prevState) => ({
      ...prevState,
      shortCode: newTitle,
    }));
    handleFormGetData(section_id, newTitle);
  };

  const handleSecondContactNoteValueChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      note: newTitle,
    }));

    updateSectionContent(section_id, { note: newTitle });
  };

  const handleSecondContactAddressTitleValueChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      address_title: newTitle,
    }));

    updateSectionContent(section_id, { address_title: newTitle });
  };

  const handleSecondContactAddressValueChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      address_value: newTitle,
    }));

    updateSectionContent(section_id, { address_value: newTitle });
  };

  const handleThirdContactAddressValueChange = (section_id, newTitle) => {
    setThirdContactValue((prevState) => ({
      ...prevState,
      address_value: newTitle,
    }));

    updateSectionContent(section_id, { address_value: newTitle });
  };

  const handleSecondContactEmailTitleChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      email_title: newTitle,
    }));

    updateSectionContent(section_id, { email_title: newTitle });
  };

  const handleSecondContactEmailValueChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      email_value: newTitle,
    }));

    updateSectionContent(section_id, { email_value: newTitle });
  };

  const handleThirdContactValueChange = (section_id, updatedfirstContentValue) => {
    setThirdContactValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedfirstContentValue,
      };
    });
    console.log(updatedfirstContentValue, 'updatedfirstContentValue=-=->>');
    updateSectionContent(section_id, updatedfirstContentValue);
  };

  const handleThirdContactEmailValueChange = (section_id, newTitle) => {
    setThirdContactValue((prevState) => ({
      ...prevState,
      email_value: newTitle,
    }));

    updateSectionContent(section_id, { email_value: newTitle });
  };

  const handleSecondContactPhoneTitleChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      phone_title: newTitle,
    }));

    updateSectionContent(section_id, { phone_title: newTitle });
  };

  const handleSecondContactPhoneValueChange = (section_id, newTitle) => {
    setSecondContactValue((prevState) => ({
      ...prevState,
      phone_value: newTitle,
    }));

    updateSectionContent(section_id, { phone_value: newTitle });
  };

  const handleFirstCtaButtonValueChange = (section_id, newTitle) => {
    setFirstCtaValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  return {
    sectionAdd,
    setSectionAdd,
    isOpen,
    setIsOpen,
    tabIndex,
    setTabIndex,
    isNotification,
    setIsNotification,
    notificationMsg,
    setNotificationMsg,
    severity,
    setSeverity,
    updateSectionContent,
    editSection,
    setEditSection,
    handleContentChange,
    handleHtmlTagChange,
    handleImageSizeChange,
    handleImageLinkChange,
    handleTextEditorChange,
    handleVideoChange,
    handleButtonChange,
    handleButtonLinkChange,
    handleIconSelectValue,
    handleDividerStyleChange,
    handleDividerWidthChange,
    handleAlignmentChange,
    handleAddElementChange,
    handleAddElementValue,
    handleSpacerWidthChange,
    handleLocationChange,
    handleIconElementorValue,
    handleIconLinkChange,
    handleImageBoxImageSizeChange,
    handleImageBoxTitleChange,
    handleImageBoxHtmlTagChange,
    handleImageBoxDescriptionChange,
    handleImageBoxImageLinkChange,
    handleImageIconTitleChange,
    handleImageIconHtmlTagChange,
    handleImageIconDescriptionChange,
    handleImageIconImageLinkChange,
    handleIconBoxImageValue,
    handleIconListDataValue,
    handleIconListDataRemoveValue,
    handleIconListDataCopyValue,
    handleIconListValue,
    handleIconListIconValue,
    handleCounterChange,
    HandleTestiMonialChange,
    handleTabValue,
    handleAddTabsItem,
    handleCopyTabsItem,
    handleTabsDataRemoveValue,
    handleAccordionValue,
    handleAddAccordionItem,
    handleCopyAccordionItem,
    handleAccordionDataRemoveValue,
    handleAlertChange,
    handleSocialIconDataValue,
    handleSocialIconDataRemoveValue,
    handleSocialIconCopyValue,
    handleSocialIconListValue,
    handleSocialIconValue,
    handleHtmlChange,
    HandleRatingChange,
    handleRatingIconSelectValue,
    headingSetting,
    setHeadingSetting,
    bgImageDefault,
    bgImage,
    setBgImage,
    textEditorValue,
    setTextEditorValue,
    videoValue,
    setVideoValue,
    buttonValue,
    setButtonValue,
    dividerValue,
    setDividerValue,
    spacerValue,
    setSpacerValue,
    locationValue,
    setLocationValue,
    imageBox,
    setImageBox,
    iconValue,
    setIconValue,
    imageIcon,
    setImageIcon,
    iconListData,
    setIconListData,
    socialIconData,
    setsocialIconData,
    numberCounter,
    setNumberCounter,
    testiMonialValue,
    setTestiMonialValue,
    ratingValue,
    setRatingValue,
    tabValue,
    setTabValue,
    accordionValue,
    setAccordionValue,
    alertValue,
    setAlertValue,
    htmlValue,
    setHtmlValue,
    firstContentValue,
    setFirstContentValue,
    secondContentValue,
    setSecondContentValue,
    thirdContentValue,
    setThirdContentValue,
    forthContentValue,
    setForthContentValue,
    fifthContentValue,
    setFifthContentValue,
    sixContentValue,
    setSixContentValue,
    sevenContentValue,
    setSevenContentValue,
    eightContentValue,
    setEightContentValue,
    firstFeatureValue,
    setFirstFeatureValue,
    secondFeatureValue,
    setSecondFeatureValue,
    thirdFeatureValue,
    setThirdFeatureValue,
    forthFeatureValue,
    setForthFeatureValue,
    fifthFeatureValue,
    setFifthFeatureValue,
    sixthFeatureValue,
    setSixthFeatureValue,
    sevenFeatureValue,
    setSevenFeatureValue,
    eightFeatureValue,
    setEightFeatureValue,
    secondStepValue,
    setSecondStepValue,
    firstStepValue,
    setFirstStepValue,
    thirdStepValue,
    setThirdStepValue,
    firstTeamValue,
    setFirstTeamValue,
    secondTeamValue,
    setSecondTeamValue,
    thirdTeamValue,
    setThirdTeamValue,
    firstTestimonialValue,
    setFirstTestimonialValue,
    secondTestimonialValue,
    setSecondTestimonialValue,
    thirdTestimonialValue,
    setThirdTestimonialValue,
    firstStatisticValue,
    setFirstStatisticValue,
    secondStatisticValue,
    setSecondStatisticValue,
    thirdStatisticValue,
    setThirdStatisticValue,
    firstContactValue,
    setFirstContactValue,
    secondContactValue,
    setSecondContactValue,
    thirdContactValue,
    setThirdContactValue,
    firstCtaValue,
    setFirstCtaValue,
    secondCtaValue,
    setSecondCtaValue,
    thirdCtaValue,
    setThirdCtaValue,
    forthCtaValue,
    setForthCtaValue,
    firstHeroValue,
    setFirstHeroValue,
    secondHeroValue,
    setSecondHeroValue,
    thirdHeroValue,
    setThirdHeroValue,
    forthHeroValue,
    setForthHeroValue,
    fifthHeroValue,
    setFifthHeroValue,
    sixthHeroValue,
    setSixthHeroValue,
    firstPricingValue,
    setFirstPricingValue,
    secondPricingValue,
    setSecondPricingValue,
    handleFirstContentValue,
    handleAddFirstContentItem,
    handleCopyFirstContentItem,
    handleCopyFifthFeatureItem,
    handleCopySixthFeatureItem,
    handleCopySecondContentItem,
    handleCopyFirstPricingItem,
    handleCopySecondPricingItem,
    handleCopyThirdContentItem,
    handleCopyForthContentItem,
    handleCopySevenContentItem,
    handleCopyEightContentItem,
    handleCopySecondFeatureItem,
    handleCopySevenFeatureItem,
    handleCopyEightFeatureItem,
    handleCopyFirstStepItem,
    handleCopySecondStepItem,
    handleCopyThirdStepItem,
    handleCopyFirstTeamItem,
    handleCopySecondTeamItem,
    handleCopyThirdTeamItem,
    handleCopyFirstTestimonialItem,
    handleCopyThirdTestimonialItem,
    handleCopyFirstStatisticItem,
    handleCopySecondStatisticItem,
    handleCopyThirdStatisticItem,
    handleCopyThirdFeatureItem,
    handleCopyForthFeatureItem,
    handleCopyFirstFeatureItem,
    handleFirstContentDataRemoveValue,
    handleFifthFeatureDataRemoveValue,
    handleSixthFeatureDataRemoveValue,
    handleSecondContentDataRemoveValue,
    handleFirstPricingDataRemoveValue,
    handleSecondPricingDataRemoveValue,
    handleThirdContentDataRemoveValue,
    handleForthContentDataRemoveValue,
    handleSevenContentDataRemoveValue,
    handleEightContentDataRemoveValue,
    handleFirstFeatureDataRemoveValue,
    handleSecondFeatureDataRemoveValue,
    handleSevenFeatureDataRemoveValue,
    handleEightFeatureDataRemoveValue,
    handleFirstStepDataRemoveValue,
    handleThirdStepDataRemoveValue,
    handleFirstTeamDataRemoveValue,
    handleSecondTeamDataRemoveValue,
    handleSecondStepDataRemoveValue,
    handleThirdTeamDataRemoveValue,
    handleFirstTestimonialDataRemoveValue,
    handleThirdTestimonialDataRemoveValue,
    handleFirstStatisticDataRemoveValue,
    handleSecondStatisticDataRemoveValue,
    handleThirdStatisticDataRemoveValue,
    handleThirdFeatureDataRemoveValue,
    handleForthFeatureDataRemoveValue,
    handlefifthFeatureValue,
    handleSixthFeatureValue,
    handleSecondContentValue,
    handleFirstPricingValue,
    handleSecondPricingValue,
    handleThirdContentValue,
    handleForthContentValue,
    handleSevenContentValue,
    handleEightContentValue,
    handleFirstFeatureValue,
    handleSecondFeatureValue,
    handleSevenFeatureValue,
    handleEightFeatureValue,
    handleFirstStepValue,
    handleSecondStepValue,
    handleThirdStepValue,
    handleFirstTeamValue,
    handleSecondTeamValue,
    handleThirdTeamValue,
    handleFirstTestimonialValue,
    handleThirdTestimonialValue,
    handleFirstStatisticValue,
    handleSecondStatisticValue,
    handleFirstHeroValue,
    handleSecondHeroValue,
    handleThirdHeroValue,
    handleForthHeroValue,
    handleFifthHeroValue,
    handleSixthHeroValue,
    handleThirdStatisticValue,
    handleThirdFeatureValue,
    handleThirdFeatureImageValue,
    handleThirdFeatureObjectFitValue,
    handleForthFeatureValue,
    handleAddFifthFeatureItem,
    handleAddSixthFeatureItem,
    handleAddSecondContentItem,
    handleAddFirstPricingItem,
    handleAddSecondPricingItem,
    handleAddThirdContentItem,
    handleAddForthContentItem,
    handleSevenContentItem,
    handleAddEightContentItem,
    handleAddFirstFeatureItem,
    handleAddSecondFeatureItem,
    handleAddSevenFeatureItem,
    handleAddEightFeatureItem,
    handleAddFirstStepItem,
    handleAddSecondStepItem,
    handleAddThirdStepItem,
    handleAddFirstTeamItem,
    handleAddSecondTeamItem,
    handleAddThirdTeamItem,
    handleAddFirstTestimonialItem,
    handleAddThirdTestimonialItem,
    handleAddFirstStatisticItem,
    handleAddSecondStatisticItem,
    handleAddThirdStatisticItem,
    handleAddThirdFeatureItem,
    handleAddForthFeatureItem,
    handleFirstContentTagChange,
    handleFifthFeatureTagChange,
    handleSixthFeatureTagChange,
    handleSecondContentTagChange,
    handleFirstPricingTagChange,
    handleSecondPricingTagChange,
    handleThirdContentTagChange,
    handleFirstTeamTagChange,
    handleSecondTeamTagChange,
    handleThirdTeamTagChange,
    handleSecondStatisticTagChange,
    handleThirdStatisticTagChange,
    handleFirstContactTagChange,
    handleSecondContactTagChange,
    handleThirdContactTagChange,
    handleSecondCtaTagChange,
    handleThirdCtaTagChange,
    handleForthCtaTagChange,
    handleFirstHeroTagChange,
    handleSecondHeroTagChange,
    handleThirdHeroTagChange,
    handleForthHeroTagChange,
    handleFifthHeroTagChange,
    handleSixthHeroTagChange,
    handleSecondCtaFormTitleTagChange,
    handleSecondContactAddressTitleTagChange,
    handleSecondContactEmailTitleTagChange,
    handleSecondContactPhoneTitleTagChange,
    handleFirstTestimonialTagChange,
    handleSecondTestimonialTagChange,
    handleSecondTestimonialRoleTagChange,
    handleForthContentTagChange,
    handleFifthContentTagChange,
    handleSixContentTagChange,
    handleFirstContentSubTitleTagChange,
    handleFifthFeatureSubTitleTagChange,
    handleEightContentTitleTagChange,
    handleFirstFeatureTitleTagChange,
    handleSecondFeatureTitleTagChange,
    handleSevenFeatureTitleTagChange,
    handleEightFeatureTitleTagChange,
    handleEightContentDescriptionChange,
    handleSecondFeatureDescriptionChange,
    handleSevenFeatureDescriptionChange,
    handleEightFeatureDescriptionChange,
    handleFirstContentButtonValueChange,
    handleSecondFeatureButtonValueChange,
    handleSevenFeatureButtonValueChange,
    handleEightFeatureButtonValueChange,
    handleThirdContentButtonValueChange,
    handleForthContentButtonValueChange,
    handleFifthContentButtonValueChange,
    handleForthContentSubTitleValueChange,
    handleFifthContentSubButtonValueChange,
    handleFifthContentIconValueChange,
    handleFifthContentIconLinkValueChange,
    handleFifthContentIconFillValueChange,
    handleSixContentSubButtonValueChange,
    handleSixContentButtonLinkChange,
    handleSixContentImageChange,
    handleSixContentObjectFitChange,
    handleSixContentProfileChange,
    handleSixContentProfileFillValueChange,
    handleSixContentIconFillValueChange,
    handleSixContentIconChange,
    handleFirstContentGridValueChange,
    handleFirstTeamGridValueChange,
    handleSecondTeamGridValueChange,
    handleThirdTeamGridValueChange,
    handleFirstTestimonialGridValueChange,
    handleThirdTestimonialGridValueChange,
    handleFirstStatisticGridValueChange,
    handleSecondStatisticGridValueChange,
    handleThirdStatisticGridValueChange,
    handleFifthFeatureGridValueChange,
    handleSecondContentGridValueChange,
    handleFirstPricingGridValueChange,
    handleThirdContentGridValueChange,
    handleForthContentGridValueChange,
    handleSevenContentGridValueChange,
    handleEightContentGridValueChange,
    handleFirstFeatureGridValueChange,
    handleSecondFeatureGridValueChange,
    handleSevenFeatureGridValueChange,
    handleEightFeatureGridValueChange,
    handleFirstContentTitleValueChange,
    handleFifthFeatureTitleValueChange,
    handleEightContentTitleValueChange,
    handleFirstFeatureTitleValueChange,
    handleSecondFeatureTitleValueChange,
    handleSevenFeatureTitleValueChange,
    handleEightFeatureTitleValueChange,
    handleSecondContentTitleValueChange,
    handleFirstPricingTitleValueChange,
    handleSecondPricingTitleValueChange,
    handleSecondPricingLinkValueChange,
    handleSecondPricingButtonValueChange,
    handleSecondPricingButtonUrlValueChange,
    handleSecondPricingLinkUrlValueChange,
    handleThirdContentTitleValueChange,
    handleFirstTeamTitleValueChange,
    handleSecondTeamTitleValueChange,
    handleSecondStatisticTitleValueChange,
    handleThirdTeamTitleValueChange,
    handleThirdStatisticTitleValueChange,
    handleFirstContactTitleValueChange,
    handleSecondContactTitleValueChange,
    handleThirdContactTitleValueChange,
    handleSecondCtaTitleValueChange,
    handleThirdCtaTitleValueChange,
    handleForthCtaTitleValueChange,
    handleFisrtHeroTitleValueChange,
    handleSecondHeroTitleValueChange,
    handleThirdHeroTitleValueChange,
    handleForthHeroTitleValueChange,
    handleFifthHeroTitleValueChange,
    handleSixthHeroTitleValueChange,
    handleFisrtHeroFirstBtnValueChange,
    handleSecondHeroFirstBtnValueChange,
    handleThirdHeroFirstBtnValueChange,
    handleForthHeroFirstBtnValueChange,
    handleFifthHeroFirstBtnValueChange,
    handleSixthHeroFirstBtnValueChange,
    handleForthHeroNoteValueChange,
    handleFifthHeroNoteValueChange,
    handleSixthHeroNoteValueChange,
    handleForthHeroFirstBtnSubTitleValueChange,
    handleFifthHeroFirstBtnSubTitleValueChange,
    handleSixthHeroFirstBtnSubTitleValueChange,
    handleForthCtaFirstBtnTitleValueChange,
    handleForthCtaFirstBtnSubTitleValueChange,
    handleForthCtaSecondBtnTitleValueChange,
    handleForthCtaSecondBtnSubTitleValueChange,
    handleForthHeroSecondBtnSubTitleValueChange,
    handleFifthHeroSecondBtnSubTitleValueChange,
    handlSixthHeroSecondBtnSubTitleValueChange,
    handleForthHeroFirstBtnLinkValueChange,
    handleFifthHeroFirstBtnLinkValueChange,
    handleSixthHeroFirstBtnLinkValueChange,
    handleForthCtaFirstBtnLinkValueChange,
    handleForthCtaSecondBtnLinkValueChange,
    handleForthHeroSecondBtnLinkValueChange,
    handleFifthHeroSecondBtnLinkValueChange,
    handleSixthHeroSecondBtnLinkValueChange,
    handleFisrtHeroSecondBtnValueChange,
    handleSecondHeroSecondBtnValueChange,
    handleThirdHeroSecondBtnValueChange,
    handleForthHeroSecondBtnValueChange,
    handleFifthHeroSecondBtnValueChange,
    handleSixthHeroSecondBtnValueChange,
    handleSecondCtaFormTitleValueChange,
    handleSecondCtaFormNoteValueChange,
    handleFirstContactNoteValueChange,
    handleFirstContentSubTitleValueChange,
    handleFifthFeatureSubTitleValueChange,
    handleFirstContentDescriptionChange,
    handleSecondContentDescriptionChange,
    handleFirstPricingDescriptionChange,
    handleSecondPricingDescriptionChange,
    handleThirdContentDescriptionChange,
    handleFirstTeamDescriptionChange,
    handleSecondTeamDescriptionChange,
    handleThirdTeamDescriptionChange,
    handleSecondStatisticDescriptionChange,
    handleThirdStatisticDescriptionChange,
    handleFirstContactDescriptionChange,
    handleSecondContactDescriptionChange,
    handleThirdContactDescriptionChange,
    handleSecondCtaDescriptionChange,
    handleThirdCtaDescriptionChange,
    handleForthCtaDescriptionChange,
    handleFirstHeroDescriptionChange,
    handleSecondHeroDescriptionChange,
    handleThirdHeroDescriptionChange,
    handleForthHeroDescriptionChange,
    handleFifthHeroDescriptionChange,
    handleSixthHeroDescriptionChange,
    handleFirstCtaDescriptionChange,
    handleFirstTestimonialDescriptionChange,
    handleSecondTestimonialDescriptionChange,
    handleForthContentDescriptionChange,
    handleFifthContentDescriptionChange,
    handleSixContentDescriptionChange,
    handleForthContentTitleValueChange,
    handleFifthContentTitleValueChange,
    handleSixContentTitleValueChange,
    handleSixContentContentChange,
    handleSecondTestimonialTitleValueChange,
    handleSecondTestimonialRoleValueChange,
    handleSecondTestimonialIconFillValueChange,
    handleSecondTestimonialIconChange,
    handleFirstTestimonialTitleValueChange,
    handleFirstContactShortCodeValueChange,
    handleSecondContactShortCodeValueChange,
    handleThirdContactShortCodeValueChange,
    handleSecondCtaShortCodeValueChange,
    handleForthHeroShortCodeValueChange,
    handleFifthHeroShortCodeValueChange,
    handleSixthHeroShortCodeValueChange,
    handleThirdCtaShortCodeValueChange,
    handleSecondContactNoteValueChange,
    handleSecondContactAddressTitleValueChange,
    handleSecondContactAddressValueChange,
    handleThirdContactAddressValueChange,
    handleSecondContactEmailTitleChange,
    handleSecondContactEmailValueChange,
    handleThirdContactValueChange,
    handleThirdContactEmailValueChange,
    handleSecondContactPhoneTitleChange,
    handleSecondContactPhoneValueChange,
    handleFirstCtaButtonValueChange,
    flexBoxValue,
    setFlexBoxValue,
    elementorId,
    setElementorId,
    getLayout,
    setGetLayout,
    removedIndices,
    setRemovedIndices,
    editSection,
    setEditSection,
    openTab,
    setOpenTab,
    isFlexBoxVisible,
    setIsFlexBoxVisible,
    isGridVisible,
    setIsGridVisible,
    isPlusButtonVisible,
    setIsPlusButtonVisible,
    activeIndex,
    setActiveIndex,
    activeHeadingIndex,
    setActiveHeadingIndex,
    gridValue,
    setGridValue,
    handleFlexBoxClick,
    selectedIndex,
    setSelectedIndex,
    handleAddSection,
    isButtonEnabled,
    setIsButtonEnabled,
    directItemAdd,
    setIsPlusClick,
    setEditSectionClear,
    editSectionClear,
    formatSectionType,
  };
};

export default DynamicStateAndEvent;
