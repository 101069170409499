import React from 'react';
import EditTestiMonialStyleContainer from './EditTestiMonialStyleContainer';

const EditTestiMonialStyleSection = ({
  previewImage,
  testiMonialDesign,
  setTestiMonialDesign,
  section_id,
  updateSectionStyle,
  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditTestiMonialStyleContainer
        testiMonialDesign={testiMonialDesign}
        setTestiMonialDesign={setTestiMonialDesign}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditTestiMonialStyleSection;
