import { Eye, EyeOff } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';

const StaffVarification = () => {
  const [password, setPassword] = useState({
    npassword: '',
    cpassword: '',
  });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const handleChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const { npassword, cpassword } = password;
  const [neyeopen, setnEyeopen] = useState(false);
  const [ceyeopen, setcEyeopen] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.npassword !== password.cpassword) {
      setErr(true);
    } else {
      try {
        const payload = encryptData({
          new_password: npassword,
          confirm_password: cpassword,
        })
        const HS = await API.post(`/admin/client/staffVerification/${id}`, payload);
        setIsNotification(true);
        setNotificationMsg('Password set successfully!');
        setSeverity('success');
        localStorage.removeItem("token")
        setTimeout(() => {
          navigate('/');
        }, 1000);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };

  //captcha
  const [isVarified, setIsVarified] = useState(false);
  const RecaptchaLoaded = () => { };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='flex bg h-screen w-screen items-center justify-center'>
        <div
          style={{
            boxShadow: `0 20px 45px 0 rgba(3, 3, 3, 0.2)`,
          }}
          className='w-full form sm:w-8/12 xl:w-1/3 my-5 mx-5 bg-white p-5 sm:p-7 rounded-[6px]'
        >
          <div className='mb-2 text-center'>
            <img
              className='mx-auto h-6 sm:h-7 w-auto'
              src='https://cdn.shopeasy.ai/shopeasy/icons/shop-easy-black.png'
              alt='Your Company'
            />
            <span className='mt-4 text-center block text-base sm:text-xl font-medium text-gray-900'>
              {t('lang_Create_Your_Password')}
            </span>
            <p className=' text-center text-xs sm:text-[13px] text-gray-500'>
              {t('lang_Create_Your_Login_Credentials_For_Login')}
            </p>
          </div>
          <hr className='my-4 border-slate-300'></hr>
          <div>
            <div className='flex flex-col space-y-5'>
              <label htmlFor='email'>
                <p className='font-medium text-slate-700 pb-1'>{t('lang_New_Password')}</p>
                <div className='relative'>
                  <input
                    id='npassword'
                    name='npassword'
                    type={neyeopen ? 'text' : 'password'}
                    value={password.npassword}
                    className='w-full py-2 border border-slate-300 rounded-[6px] px-3 focus:outline-none'
                    placeholder='Enter new password'
                    onChange={(e) => handleChange(e)}
                  />
                  {!neyeopen ? (
                    <Eye
                      className='absolute top-3.5 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setnEyeopen(!neyeopen);
                      }}
                    />
                  ) : (
                    <EyeOff
                      className='absolute top-3.5 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setnEyeopen(!neyeopen);
                      }}
                    />
                  )}
                </div>
              </label>
              <label htmlFor='email'>
                <p className='font-medium text-slate-700 pb-1'>{t('lang_Confirm_Password')}</p>
                <div className='relative'>
                  <input
                    id='cpassword'
                    name='cpassword'
                    type={ceyeopen ? 'text' : 'password'}
                    value={password.cpassword}
                    className='w-full py-2 border border-slate-300 rounded-[6px] px-3 focus:outline-none'
                    placeholder='Enter confirm password'
                    onChange={(e) => handleChange(e)}
                  />
                  {!ceyeopen ? (
                    <Eye
                      className='absolute top-3.5 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setcEyeopen(!ceyeopen);
                      }}
                    />
                  ) : (
                    <EyeOff
                      className='absolute top-3.5 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setcEyeopen(!ceyeopen);
                      }}
                    />
                  )}
                </div>
                {err ? (
                  <p className='m-0 text-red-400 font-medium'>{t('lang_password_must_be_same')}</p>
                ) : (
                  ''
                )}
              </label>
              {/* <Recaptcha
              sitekey={process.env.REACT_APP_SITE_KEY}
              render='explicit'
              onloadCallback={RecaptchaLoaded}
              verifyCallback={(response) => (response ? setIsVarified(true) : setIsVarified(false))}
            /> */}
              <button
                className='btn text-white text-sm transition-all duration-300 inline-flex space-x-2 items-center justify-center'
                onClick={(e) => handleSubmit(e)}
              >
                <span>{t('lang_Apply_Password')}</span>
              </button>
              {/* {isVarified ? (
            ) : (
              <button className='opacity-40 w-full py-2.5 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-[6px] border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z'
                  />
                </svg>
                <span>Change password</span>
              </button>
            )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffVarification;
