import React from 'react';
import EditHtmlStyleContainer from './EditHtmlStyleContainer';

const EditHtmlStyleSection = ({
  htmlDesign,
  setHtmlDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  previewImage,
  handleBackgroundImageUpload
}) => {
  return (
    <>
      <EditHtmlStyleContainer
        htmlDesign={htmlDesign}
        setHtmlDesign={setHtmlDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditHtmlStyleSection;
