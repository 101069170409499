import React, { useState } from 'react';
import Banner from '../VisualLayout/Banner';
import Blog from '../VisualLayout/Blog';
import Category from '../VisualLayout/Category';
import CmsOffer from '../VisualLayout/CmsOffer';
import ImageSlider from '../VisualLayout/ImageSlider';
import Manufacture from '../VisualLayout/Manufacture';
import Product from '../VisualLayout/Product';
import Reassurance from '../VisualLayout/Reassurance';
import { SelectSection } from '../../../App';
import ReactDragListView from 'react-drag-listview';
import FirstSecondSectionAdd from '../EditSection/DynamicSectionEditor/FirstSecondSectionAdd';
import SecondSectionAdd from '../EditSection/DynamicSectionEditor/SecondSectionAdd';
import ThirdSectionAdd from '../EditSection/DynamicSectionEditor/ThirdSectionAdd';
import ForthSectionAdd from '../EditSection/DynamicSectionEditor/ForthSectionAdd';
import FiveSectionAdd from '../EditSection/DynamicSectionEditor/FiveSectionAdd';
import SixSectionAdd from '../EditSection/DynamicSectionEditor/SixSectionAdd';
import SevenSectionAdd from '../EditSection/DynamicSectionEditor/SevenSectionAdd';
import EightSecionAdd from '../EditSection/DynamicSectionEditor/EightSecionAdd';
import NineSecionAdd from '../EditSection/DynamicSectionEditor/NineSectionAdd';
import TenSectionAdd from '../EditSection/DynamicSectionEditor/TenSectionAdd';
import ElevenSectionAdd from '../EditSection/DynamicSectionEditor/ElevenSectionAdd';
import TwelveSectionAdd from '../EditSection/DynamicSectionEditor/TwelveSectionAdd';
import GridTwoRows from '../EditSection/DynamicSectionEditor/GridTwoRows';
import GridTwoCols from '../EditSection/DynamicSectionEditor/GridTwoCols';
import GridThreeCols from '../EditSection/DynamicSectionEditor/GridThreeCols';
import GridThreeRows from '../EditSection/DynamicSectionEditor/GridThreeRows';
import GridTwoForTwo from '../EditSection/DynamicSectionEditor/GridTwoForTwo';
import GridThreeForThree from '../EditSection/DynamicSectionEditor/GridThreeForThree';
import FirstStaticContentSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticContentSection';
import SecondStaticContentSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticContentSection';
import ThirdStaticContentSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticContentSection';
import ForthStaticContentSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ForthStaticContentSection';
import FifthStaticContentSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FifthStaticContentSection';
import SixStaticContentSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SixStaticContentSection';
import SevenStaticContentSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SevenStaticContentSection';
import EightStaticContentSection from '../EditSection/DynamicSectionEditor/StaticContentSection/EightStaticContentSection';
import FirstStaticFeatureSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticFeatureSection';
import SecondStaticFeatureSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticFeatureSection';
import ThirdStaticFeatureSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticFeatureSection';
import ForthStaticFeatureSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ForthStaticFeatureSection';
import FifthStaticFeatureSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FifthStaticFeatureSection';
import SixthStaticFeatureSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SixthStaticFeatureSection';
import SevenStaticFeatureSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SevenStaticFeatureSection';
import EightStaticFeatureSection from '../EditSection/DynamicSectionEditor/StaticContentSection/EightStaticFeatureSection';
import FirstStaticStepSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticStepSection';
import ThirdStaticStepSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticStepSection';
import FirstStaticTeamSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticTeamSection';
import SecondStaticStepSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticStepSection';
import SecondStaticTeamSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticTeamSection';
import ThirdStaticTeamSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticTeamSection';
import FirstStaticTestimonialSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticTestimonialSection';
import SecondStaticTestimonialSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticTestimonialSection';
import ThirdStaticTestimonialSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticTestimonialSection';
import FirstStaticStatisticSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticStatisticSection';
import SecondStaticStatisticSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticStatisticSection';
import ThirdStaticStatisticSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticStatisticSection';
import FirstStaticContactSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticContactSection';
import SecondStaticContactSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticContactSection';
import ThirdStaticContactSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticContactSection';
import FirstStaticCtaSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticCtaSection';
import SecondStaticCtaSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticCtaSection';
import ThirdStaticCtaSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticCtaSection';
import ForthStaticCtaSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ForthStaticCtaSection';
import FirstStaticHeroSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticHeroSection';
import SecondStaticHeroSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticHeroSection';
import ThirdStaticHeroSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ThirdStaticHeroSection';
import ForthStaticHeroSection from '../EditSection/DynamicSectionEditor/StaticContentSection/ForthStaticHeroSection';
import FifthStaticHeroSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FifthStaticHeroSection';
import SixthStaticHeroSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SixthStaticHeroSection';
import FirstStaticPricingSection from '../EditSection/DynamicSectionEditor/StaticContentSection/FirstStaticPricingSection';
import SecondStaticPricingSection from '../EditSection/DynamicSectionEditor/StaticContentSection/SecondStaticPricingSection';

const HomePageSection = ({
  commonSetting,
  getLayout,
  visualData,
  selectSection,
  handleSort,
  editSection,
  manufactureDesign,
  categoryDesign,
  productDesign,
  reassuranceDesign,
  bannerDesign,
  sliderDesign,
  cmsOfferDesign,
  blogDesign,
  setEditSection,
  setEditProductOtherSection,
  hoverId,
  setHoverId,
  setFilterId,
  flexBoxValue,
  setSectionAdd,
  removeSection,
  gridValue,
  handleFlexBoxClick,
  openAddSection,
  activeIndex,
  handleSectionClick,
  sectionAdd,
  handleHeadingClick,
  activeHeadingIndex,
  activeHeading,
  selectedIndex,
  handleImageClick,
  dragProps,
  dropContainerRef,
  destinationId,
  setDestinationId,
  gridDestinationId,
  setGridDestinationId,
  setElementorId,
  dropPosition,
  setDropPosition,
  handleRemoveSingleItem,
  handleDuplicateSingleItem,
  handleCopySingleItem,
  handleCutSingleItem,
  getPrevSecId,
  menuVisible,
  setMenuVisible,
  menuPosition,
  setMenuPosition,
  selectedSingleItem,
  handleRightClick,
  handleMenuItemClick,
  newItem,
  cutItem,
  setCutItem,
  setCopiedItem,
  handleCopyItem,
  copyItem,
  isEdit,
  setEditItem,
  getContextMenuItem,
}) => {
  const [selectSectionName, setSelectSectionName] = useState();

  const localItems = Array.isArray(flexBoxValue)
    ? flexBoxValue?.map((item) => ({ ...item, isLocal: true }))
    : [];
  const databaseItems = Array.isArray(getLayout)
    ? getLayout?.map((item) => ({ ...item, isLocal: false }))
    : [];
  console.log('getLayout', databaseItems);

  return (
    <SelectSection.Provider value={{ selectSectionName, setSelectSectionName }}>
      <ReactDragListView {...handleSort}>
        <div
          style={{ gap: commonSetting?.section_space }}
          // className='w-full live-editor-component h-auto mx-auto grid grid-cols-1'
          className='w-full h-auto mx-auto grid grid-cols-1 draggable-main-item'
        >
          {[...databaseItems, ...localItems]
            ?.filter((elem) => elem?.isLocal === false)
            ?.map((elem, index) => {
              const isActive =
                elem?.section_id === editSection?.section_id &&
                elem?.section_type === editSection?.section_type;
              return (
                elem?.status && (
                  <div
                    // style={{
                    //   boxShadow: hoverId === index ? '6px 9px 35px 7px rgba(0,0,0,0.2)' : 'none',
                    //   background: commonSetting?.primary_body_bg_color,
                    // }}
                    key={index}
                    onDragStart={() => setFilterId(elem?._id)}
                    onDragOver={() => setHoverId(index)}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      setHoverId(null);
                    }}
                    className='draggable-main-item'
                  >
                    {elem?.section_type === 'product' && elem?.status ? (
                      <div
                        style={{
                          overflow: productDesign?.overflow,
                          border:
                            elem?.section_id === editSection?.section_id ? '2px solid #2c6ecb' : '',
                        }}
                        className='page_builder_container'
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                          });
                          setEditProductOtherSection('');
                        }}
                      >
                        <Product
                          colors={commonSetting}
                          productData={elem}
                          productDesign={productDesign}
                        />
                      </div>
                    ) : elem?.section_type === 'blog' && elem?.status ? (
                      <div
                        style={{
                          overflow: blogDesign?.overflow,
                          border:
                            elem?.section_id === editSection?.section_id ? '2px solid #2c6ecb' : '',
                        }}
                        className='page_builder_container'
                        // onClick={(e) =>
                        //   selectSection(e, elem?.section_id, elem?.section_type)
                        // }
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                          });
                          setEditProductOtherSection('');
                        }}
                      >
                        <Blog colors={commonSetting} blogData={elem} blogDesign={blogDesign} />
                      </div>
                    ) : elem?.section_type === 'category' && elem?.status ? (
                      <div
                        style={{
                          overflow: categoryDesign?.overflow,
                          border:
                            elem?.section_id === editSection?.section_id ? '2px solid #2c6ecb' : '',
                        }}
                        className='page_builder_container'
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                          });
                          setEditProductOtherSection('');
                        }}
                      >
                        <Category
                          colors={commonSetting}
                          categoryData={elem}
                          categoryDesign={categoryDesign}
                        />
                      </div>
                    ) : elem?.section_type === 'manufacture' && elem?.status ? (
                      <div
                        style={{
                          overflow: manufactureDesign?.overflow,
                          border:
                            elem?.section_id === editSection?.section_id ? '2px solid #2c6ecb' : '',
                        }}
                        className='page_builder_container'
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                          });
                          setEditProductOtherSection('');
                        }}
                      >
                        <Manufacture
                          section_type={elem?.section_type}
                          colors={commonSetting}
                          brandData={elem}
                          manufactureDesign={manufactureDesign}
                        />
                      </div>
                    ) : elem?.section_type === 'reassurance' && elem?.status ? (
                      <div
                        style={{
                          overflow: reassuranceDesign?.overflow,
                          border:
                            elem?.section_id === editSection?.section_id ? '2px solid #2c6ecb' : '',
                        }}
                        className='page_builder_container'
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                          });
                          setEditProductOtherSection('');
                        }}
                      >
                        <Reassurance
                          colors={commonSetting}
                          reassuranceData={elem}
                          reassuranceDesign={reassuranceDesign}
                        />
                      </div>
                    ) : elem?.section_type === 'cmsoffer' && elem?.status ? (
                      <div
                        style={{
                          overflow: cmsOfferDesign?.overflow,
                          border:
                            elem?.section_id === editSection?.section_id ? '2px solid #2c6ecb' : '',
                        }}
                        className='page_builder_container'
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                          });
                          setEditProductOtherSection('');
                        }}
                      >
                        <CmsOffer
                          colors={commonSetting}
                          cmsOfferData={elem}
                          cmsOfferDesign={cmsOfferDesign}
                        />
                      </div>
                    ) : elem?.section_type === 'slider' && elem?.status ? (
                      <div
                        style={{
                          overflow: sliderDesign?.overflow,
                          border:
                            elem?.section_id === editSection?.section_id ? '2px solid #2c6ecb' : '',
                        }}
                        className='page_builder_container'
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                          });
                          setEditProductOtherSection('');
                        }}
                      >
                        <ImageSlider
                          colors={commonSetting}
                          sliderData={elem}
                          sliderDesign={sliderDesign}
                        />
                      </div>
                    ) : elem?.section_type === 'banner' && elem?.status ? (
                      <div
                        style={{
                          overflow: bannerDesign?.overflow,
                          border:
                            elem?.section_id === editSection?.section_id ? '2px solid #2c6ecb' : '',
                        }}
                        className='page_builder_container'
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                          });
                          setEditProductOtherSection('');
                        }}
                      >
                        <Banner
                          colors={commonSetting}
                          // bannerData={
                          //   elem?.section_id === editSection?.section_id && visualData?.banner
                          //     ? visualData?.banner
                          //     : elem
                          // }
                          bannerData={elem}
                          bannerDesign={bannerDesign}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_cols' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstSecondSectionAdd
                          flexBoxValue={flexBoxValue}
                          setSectionAdd={setSectionAdd}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          selectedIndex={selectedIndex}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          setElementorId={setElementorId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_rows' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondSectionAdd
                          flexBoxValue={flexBoxValue}
                          setSectionAdd={setSectionAdd}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          selectedIndex={selectedIndex}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          setElementorId={setElementorId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_two_grid' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdSectionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_grid_two' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ForthSectionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_four_grid' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FiveSectionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_three_grid_three' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SixSectionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_four_grid_four' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SevenSectionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_two_grid_one' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <EightSecionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_one_grid_two' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <NineSecionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_six_grid_six' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <TenSectionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_five_grid' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ElevenSectionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'flex_two_grid_two' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <TwelveSectionAdd
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'grid_two_rows' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <GridTwoRows
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'grid_two_cols' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <GridTwoCols
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'grid_three_cols' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <GridThreeCols
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'grid_three_rows' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <GridThreeRows
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'grid_two_for_two' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <GridTwoForTwo
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'grid_three_for_three' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <GridThreeForThree
                          flexBoxValue={flexBoxValue}
                          removeSection={removeSection}
                          gridValue={gridValue}
                          handleFlexBoxClick={handleFlexBoxClick}
                          openAddSection={openAddSection}
                          activeIndex={activeIndex}
                          handleSectionClick={handleSectionClick}
                          sectionAdd={sectionAdd}
                          editSection={editSection}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          handleHeadingClick={handleHeadingClick}
                          activeHeadingIndex={activeHeadingIndex}
                          activeHeading={activeHeading}
                          setSectionAdd={setSectionAdd}
                          handleImageClick={handleImageClick}
                          selectSection={selectSection}
                          setEditSection={setEditSection}
                          dragProps={dragProps}
                          dropContainerRef={dropContainerRef}
                          destinationId={destinationId}
                          setDestinationId={setDestinationId}
                          gridDestinationId={gridDestinationId}
                          setGridDestinationId={setGridDestinationId}
                          dropPosition={dropPosition}
                          setDropPosition={setDropPosition}
                          handleRemoveSingleItem={handleRemoveSingleItem}
                          handleDuplicateSingleItem={handleDuplicateSingleItem}
                          handleCopySingleItem={handleCopySingleItem}
                          handleCutSingleItem={handleCutSingleItem}
                          getPrevSecId={getPrevSecId}
                          setElementorId={setElementorId}
                          menuVisible={menuVisible}
                          setMenuVisible={setMenuVisible}
                          menuPosition={menuPosition}
                          setMenuPosition={setMenuPosition}
                          selectedSingleItem={selectedSingleItem}
                          handleRightClick={handleRightClick}
                          handleMenuItemClick={handleMenuItemClick}
                          newItem={newItem}
                          cutItem={cutItem}
                          setCutItem={setCutItem}
                          setCopiedItem={setCopiedItem}
                          handleCopyItem={handleCopyItem}
                          copyItem={copyItem}
                          isEdit={isEdit}
                          setEditItem={setEditItem}
                          getContextMenuItem={getContextMenuItem}
                        />
                      </div>
                    ) : elem?.section_type === 'content 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticContentSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'content 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticContentSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'content 3' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdStaticContentSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'content 4' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ForthStaticContentSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'content 5' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FifthStaticContentSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'content 6' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SixStaticContentSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'content 7' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SevenStaticContentSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'content 8' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <EightStaticContentSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'feature 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticFeatureSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'feature 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticFeatureSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'feature 3' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdStaticFeatureSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'feature 4' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ForthStaticFeatureSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'feature 5' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FifthStaticFeatureSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'feature 6' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SixthStaticFeatureSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'feature 7' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SevenStaticFeatureSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'feature 8' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <EightStaticFeatureSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'step 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticStepSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'step 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticStepSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'step 3' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdStaticStepSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'team 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticTeamSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'team 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticTeamSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'team 3' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdStaticTeamSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'testimonial 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticTestimonialSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'testimonial 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticTestimonialSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'testimonial 3' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdStaticTestimonialSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'statistic 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticStatisticSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'statistic 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticStatisticSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'statistic 3' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdStaticStatisticSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'contact 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticContactSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'contact 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticContactSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'contact 3' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdStaticContactSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'cta 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticCtaSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'cta 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticCtaSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'cta 3' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdStaticCtaSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'cta 4' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ForthStaticCtaSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'hero 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticHeroSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'hero 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticHeroSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'hero 3' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ThirdStaticHeroSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'hero 4' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <ForthStaticHeroSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'hero 5' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FifthStaticHeroSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'hero 6' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SixthStaticHeroSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'pricing 1' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <FirstStaticPricingSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : elem?.section_type === 'pricing 2' && elem?.status ? (
                      <div
                        className={`page_builder_container hover:border-2 hover:border-blue-500 ${
                          isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                        }`}
                        onClick={(e) => {
                          selectSection(e, elem?.section_id, elem?.section_type);
                          setEditSection({
                            section_id: elem?.section_id,
                            section_type: elem?.section_type,
                            section_index: elem?.flexBoxIndex,
                          });
                        }}
                      >
                        <SecondStaticPricingSection
                          handleSectionClick={handleSectionClick}
                          openAddSection={openAddSection}
                          selectSection={selectSection}
                          setElementorId={setElementorId}
                          sectionIndex={elem?.flexBoxIndex}
                          section={elem}
                          dropContainerRef={dropContainerRef}
                          sectionAdd={sectionAdd}
                          dragProps={dragProps}
                          editSection={editSection}
                          handleFlexBoxClick={handleFlexBoxClick}
                          removeSection={removeSection}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )
              );
            })}
        </div>
      </ReactDragListView>
    </SelectSection.Provider>
  );
};

export default HomePageSection;
