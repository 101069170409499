import React from 'react';
import EditButtonStyleContainer from './EditButtonStyleContainer';

const EditButtonStyleSection = ({
  previewImage,
  buttonDesign,
  setButtonDesign,
  section_id,
  updateSectionStyle,

  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditButtonStyleContainer
        buttonDesign={buttonDesign}
        setButtonDesign={setButtonDesign}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}

        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditButtonStyleSection;
