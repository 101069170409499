import React from 'react';

const ImageSkeleton = () => {
  return (
    <>
      <div className='w-full h-full bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 animate-pulse rounded-lg shadow-md mb-5'></div>
    </>
  );
};

export default ImageSkeleton;
