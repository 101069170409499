
export const IconTab1 = {
  title: 'https://cdn.shopeasy.ai/shopeasy/icons/public.png',
  icons: [
    `https://cdn.shopeasy.ai/shopeasy/icons/headset.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/local-shipping.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/lock.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/loop.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/loyalty.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/mood.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/payment1.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/public.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/redeem.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/thumb.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/timer.png`,
    `https://cdn.shopeasy.ai/shopeasy/icons/touch-app.png`,
  ],
};

export const IconTab2 = {
    title:`https://cdn.shopeasy.ai/shopeasy/icons/globe2.png`,
    icons: [
        `https://cdn.shopeasy.ai/shopeasy/icons/carrier2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/creditcard2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/gift2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/globe2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/hotline2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/parcel2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/phone2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/return2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/satisfaction2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/security2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/support2.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/trust2.png`
    ]
}

export const IconTab3 = {
    title:`https://cdn.shopeasy.ai/shopeasy/icons/globe3.png`,
    icons: [
        `https://cdn.shopeasy.ai/shopeasy/icons/carrier3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/clock3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/comment3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/gift3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/globe3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/location3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/payment3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/return3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/customer3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/security3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/star3.png`,
        `https://cdn.shopeasy.ai/shopeasy/icons/support3.png`
    ]
}