import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import module from '../../../../../controllers/QuillToolBar';
import GenerateUniqueFilename from '../../../../../controllers/GenerateUniqueFilename';
import API from '../../../../../API';
import { decryptData } from '../../../../../controllers/encryptionUtils';
import ImageSkeleton from '../../../../../controllers/ImageSkeleton';

const ListItem = ({
  dropDownValue,
  item,
  handleItemClick1,
  isSelected,
  handleInputChange,
  onCopy,
  onRemove,
  handleQuillChange,
  handleImageUpload,
  handleBgFitChange,
  imageLoader,
}) => {
  const ShortquillRef = useRef(null);
  const [shortdescription, setShortDescription] = useState('');
  const [meta, setMeta] = useState({
    meta_description: '',
  });

  return (
    <div
      className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.title}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick1}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Duplicate'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='title'>Title</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='title'
              name='title'
              placeholder='Enter title...'
              value={item?.title || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>HTMLTag</label>
            <div className='relative'>
              <select
                className='w-full mt-2'
                id='htmlTag'
                name='htmlTag'
                value={item?.htmlTag || ''}
                onChange={handleInputChange}
              >
                {dropDownValue?.map((obj) => (
                  <option key={obj?.value} value={obj?.value}>
                    {obj?.label}
                  </option>
                ))}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='description'>Description</label>
            <ReactQuill
              modules={module}
              ref={ShortquillRef}
              className='w-full h-auto table-text rounded-[6px] mt-2'
              theme='snow'
              name='description'
              value={item?.description || ''}
              onChange={handleQuillChange}
              placeholder='Enter description'
              onBlur={() => {
                setMeta((prevMeta) => ({
                  ...prevMeta,
                  meta_description:
                    shortdescription
                      ?.replace(/<[^>]*>/g, ' ')
                      ?.split(' ')
                      .slice(0, 50)
                      .join(' ') || '',
                }));
              }}
            />
          </div>
          <div className='w-full mb-4'>
            <label htmlFor='button'>Button</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='button'
              name='button'
              placeholder='Enter button...'
              value={item?.button || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className='w-full mb-4'>
            <label>Image</label>
            <div
              className={`flex justify-center items-center ${imageLoader ? 'h-28 mt-2' : 'h-auto'}`}
            >
              {imageLoader ? (
                <ImageSkeleton />
              ) : (
                <img
                  src={item?.image}
                  alt='Uploaded Preview'
                  className='w-full mb-5 max min-h-28 max-h-72 object-contain'
                />
              )}
            </div>
          </div>
          {!imageLoader && (
            <input
              type='file'
              accept='image/*'
              name='url'
              onChange={(e) => handleImageUpload(e)}
              className='truncate max-w-full overflow-hidden block mb-5'
            />
          )}

          <div className='flex items-center mt-2'>
            <label htmlFor='sub_title' className='text-[#242323] w-[45%]'>
              {t('lang_fit')}
            </label>
            <div className='relative w-[100%] primary-bg-color'>
              <select
                className='edit-select outline-none h-8 primary-bg-color rounded-[6px] table-text w-full capitalize'
                value={item?.objectFit}
                onChange={handleBgFitChange}
                name='objectFit'
              >
                <option>{t('lang_fill')}</option>
                <option>{t('lang_contain')}</option>
                <option>{t('lang_cover')}</option>
                <option>{t('lang_none')}</option>
                <option>{t('lang_scale_down')}</option>
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const StaticSevenContentSection = ({
  section_id,
  sevenContentValue,
  setSevenContentValue,
  handleSevenContentValue,
  tabIndex,
  setTabIndex,
  handleSevenContentItem,
  handleCopySevenContentItem,
  handleSevenContentDataRemoveValue,
  sectionGridContent,
  handleSevenContentGridValueChange,
  setIsNotification,
  setNotificationMsg,
  setSeverity,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];
  const dropDownGridValue = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];
  const [gridValue, setGridValue] = useState(sectionGridContent);
  const [imageLoader, setImageLoader] = useState(false);

  useEffect(() => {
    setGridValue(sectionGridContent);
  }, [sectionGridContent]);

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedfirstContentValue = {
      ...sevenContentValue,
      item: sevenContentValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setSevenContentValue(updatedfirstContentValue);
    handleSevenContentValue(section_id, updatedfirstContentValue);
  };
  const addItem = () => {
    const newId = sevenContentValue?.item?.length
      ? sevenContentValue?.item?.[sevenContentValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      title: 'Lorem',
      description: 'Lorem ipsum',
      button: 'Button',
      htmlTag: 'h2',
      isEdit: false,
      image: 'https://dummyimage.com/1201x501',
      objectFit: 'fill',
    };
    const updatedItems = sevenContentValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleSevenContentItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopySevenContentItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleSevenContentDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedfirstContentValue = {
      ...sevenContentValue,
      item: sevenContentValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setSevenContentValue(updatedfirstContentValue);
    handleSevenContentValue(section_id, updatedfirstContentValue);
  };

  const handleQuillChange = (content) => {
    const updatedfirstContentValue = {
      ...sevenContentValue,
      item: sevenContentValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, description: content } : item,
      ),
    };

    setSevenContentValue(updatedfirstContentValue);
    handleSevenContentValue(section_id, updatedfirstContentValue);
  };

  const onGridContentChange = (e) => {
    const newValue = e.target.value;
    setGridValue(newValue);
    handleSevenContentGridValueChange(section_id, newValue);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'images');
    try {
      setImageLoader(true);
      const res = await API.post('/admin/fileupload/upload', formData);
      const result = decryptData(res?.data);
      console.log('setBgImage', result);
      const updatedfirstContentValue = {
        ...sevenContentValue,
        item: sevenContentValue?.item?.map((item, i) =>
          i === tabIndex ? { ...item, image: result?.data[0] } : item,
        ),
      };
      setIsNotification(true);
      setNotificationMsg(result?.message);
      setSeverity('success');
      setSevenContentValue(updatedfirstContentValue);
      handleSevenContentValue(section_id, updatedfirstContentValue);
      setTimeout(() => {
        setImageLoader(false);
      }, 200);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
      setImageLoader(false);
    }
  };

  const handleBgFitChange = (e) => {
    const backgroundFit = e.target.value;
    const updatedfirstContentValue = {
      ...sevenContentValue,
      item: sevenContentValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, objectFit: backgroundFit } : item,
      ),
    };

    setSevenContentValue(updatedfirstContentValue);
    handleSevenContentValue(section_id, updatedfirstContentValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mt-4'>
        <label>Section</label>
        {Array.isArray(sevenContentValue?.item) &&
          sevenContentValue?.item?.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              handleItemClick1={() => handleItemClick(index)}
              isSelected={tabIndex === index}
              onCopy={() => copyItem(item, index)}
              onRemove={removeItem}
              handleInputChange={handleInputChange}
              dropDownValue={dropDownValue}
              handleQuillChange={handleQuillChange}
              handleImageUpload={handleImageUpload}
              handleBgFitChange={handleBgFitChange}
              imageLoader={imageLoader}
            />
          ))}
      </div>

      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + Add Item
      </button>

      <div className='mt-4'>
        <label>Grid</label>
        <div className='relative'>
          <select className='w-full mt-2' value={gridValue} onChange={onGridContentChange}>
            {dropDownGridValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
    </div>
  );
};

export default StaticSevenContentSection;
