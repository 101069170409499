import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';

const StaticSecondContactSection = ({
  section_id,
  section_type,
  sectionContent,
  descriptionContent,
  handleSecondContactTitleValueChange,
  handleSecondContactTagChange,
  headingLevel,
  handleSecondContactDescriptionChange,
  noteValueContent,
  handleSecondContactNoteValueChange,
  addressTitleValueContent,
  handleSecondContactAddressTitleValueChange,
  addressTitleHtmlTag,
  handleSecondContactAddressTitleTagChange,
  addressValueContent,
  handleSecondContactAddressValueChange,
  emailTitleContent,
  handleSecondContactEmailTitleChange,
  emailValueContent,
  handleSecondContactEmailValueChange,
  phoneTitleContent,
  handleSecondContactPhoneTitleChange,
  phoneValueContent,
  handleSecondContactPhoneValueChange,
  emailTitleHtmlTag,
  handleSecondContactEmailTitleTagChange,
  emailPhoneHtmlTag,
  handleSecondContactPhoneTitleTagChange,
  urlValueContent,
  handleSecondContactShortCodeValueChange,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [noteValue, setNoteValue] = useState(noteValueContent);
  const [addressTitle, setAddressTitle] = useState(addressTitleValueContent);
  const [addressvalue, setAddressValue] = useState(addressValueContent);
  const [emailTitle, setEmailTitle] = useState(emailTitleContent);
  const [emailValue, setEmailValue] = useState(emailValueContent);
  const [phoneTitle, setPhoneTitle] = useState(phoneTitleContent);
  const [phoneValue, setPhoneValue] = useState(phoneValueContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [selectedAddressTitle, setSelectedAddressTitle] = useState(addressTitleHtmlTag || 'h2');
  const [selectedEmailTitle, setSelectedEmailTitle] = useState(emailTitleHtmlTag || 'h2');
  const [selectedPhoneTitle, setSelectedPhoneTitle] = useState(emailPhoneHtmlTag || 'h2');
  const [shortCodeValue, setShortCodeValue] = useState(urlValueContent);

  const ShortquillRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    const updates = {
      groupTitle: sectionContent,
      selectedHeadingLevel: headingLevel,
      shortCodeValue: urlValueContent,
      shortDescription: descriptionContent,
      noteValue: noteValueContent,
      addressTitle: addressTitleValueContent,
      selectedAddressTitle: addressTitleHtmlTag,
      selectedEmailTitle: emailTitleHtmlTag,
      addressValue: addressValueContent,
      emailTitle: emailTitleContent,
      emailValue: emailValueContent,
      phoneTitle: phoneTitleContent,
      phoneValue: phoneValueContent,
      selectedPhoneTitle: emailPhoneHtmlTag,
    };

    Object.entries(updates).forEach(([key, value]) => {
      const setterFunction = window[`set${key.charAt(0).toUpperCase() + key.slice(1)}`];
      if (setterFunction) setterFunction(value);
    });
  }, [
    sectionContent,
    headingLevel,
    urlValueContent,
    descriptionContent,
    noteValueContent,
    addressTitleValueContent,
    addressTitleHtmlTag,
    emailTitleHtmlTag,
    addressValueContent,
    emailTitleContent,
    emailValueContent,
    phoneTitleContent,
    phoneValueContent,
    emailPhoneHtmlTag,
  ]);

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleSecondContactTitleValueChange(section_id, newValue);
  };

  const onNoteValueChange = (e) => {
    const newValue = e.target.value;
    setNoteValue(newValue);
    handleSecondContactNoteValueChange(section_id, newValue);
  };

  const onAddressTitleValueChange = (e) => {
    const newValue = e.target.value;
    setAddressTitle(newValue);
    handleSecondContactAddressTitleValueChange(section_id, newValue);
  };

  const onAddressValueChange = (e) => {
    const newValue = e.target.value;
    setAddressValue(newValue);
    handleSecondContactAddressValueChange(section_id, newValue);
  };

  const onEmailTitleChange = (e) => {
    const newValue = e.target.value;
    setEmailTitle(newValue);
    handleSecondContactEmailTitleChange(section_id, newValue);
  };

  const onEmailValueChange = (e) => {
    const newValue = e.target.value;
    setEmailValue(newValue);
    handleSecondContactEmailValueChange(section_id, newValue);
  };

  const onPhoneTitleChange = (e) => {
    const newValue = e.target.value;
    setPhoneTitle(newValue);
    handleSecondContactPhoneTitleChange(section_id, newValue);
  };

  const onPhoneValueChange = (e) => {
    const newValue = e.target.value;
    setPhoneValue(newValue);
    handleSecondContactPhoneValueChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleSecondContactTitleValueChange(section_id, value);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleSecondContactTagChange(section_id, newTag);
  };

  const handleAddressTitleTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedAddressTitle(newTag);
    handleSecondContactAddressTitleTagChange(section_id, newTag);
  };

  const handleEmailTitleTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedEmailTitle(newTag);
    handleSecondContactEmailTitleTagChange(section_id, newTag);
  };

  const handlePhoneTitleTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedEmailTitle(newTag);
    handleSecondContactPhoneTitleTagChange(section_id, newTag);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleSecondContactDescriptionChange(section_id, value);
  };

  const onShortCodeValueChange = (e) => {
    const newValue = e.target.value;
    setShortCodeValue(newValue);
  };

  const handleApplyChanges = () => {
    console.log(typeof shortCodeValue, 'shortCodeValue=-=->>');
    handleSecondContactShortCodeValueChange(section_id, shortCodeValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div>
        <label>{t('lang_enter_your_short_code')}</label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={shortCodeValue}
          onChange={onShortCodeValueChange}
        />
      </div>
      <div className='flex justify-between mt-2 items-center'>
        <p>{t('lang_update_changes_to_form')}</p>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[12px] font-medium py-1 px-3 rounded-[4px] transition duration-300'
          onClick={handleApplyChanges}
        >
          {t('lang_apply')}
        </button>
      </div>
      <div className='mb-4 mt-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_title')}</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>{t('lang_title_hTMLTag')}</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_Description')}</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>
      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_Note')}</span>
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading note here...'
          value={noteValue}
          onChange={onNoteValueChange}
        />
      </div>

      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_address_title')}</span>
        </label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your address title here...'
          value={addressTitle}
          onChange={onAddressTitleValueChange}
        />
      </div>

      <div>
        <label>{t('lang_address_title_htmltag')}</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedAddressTitle}
            onChange={handleAddressTitleTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_address')}</span>
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your address here...'
          value={addressvalue}
          onChange={onAddressValueChange}
        />
      </div>

      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_email_title')}</span>
        </label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your email title here...'
          value={emailTitle}
          onChange={onEmailTitleChange}
        />
      </div>

      <div>
        <label>{t('lang_email_title_htmltag')}</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedEmailTitle}
            onChange={handleEmailTitleTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image capitalize'>{t('lang_email')}</span>
        </label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your email here...'
          value={emailValue}
          onChange={onEmailValueChange}
        />
      </div>

      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_Phone_Title')}</span>
        </label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your phone title here...'
          value={phoneTitle}
          onChange={onPhoneTitleChange}
        />
      </div>

      <div>
        <label>{t('lang_Phone_Title_htmltag')}</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedPhoneTitle}
            onChange={handlePhoneTitleTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='mb-3'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image ccapitalize'>{t('lang_phone')}</span>
        </label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your phone here...'
          value={phoneValue}
          onChange={onPhoneValueChange}
        />
      </div>
    </div>
  );
};

export default StaticSecondContactSection;
